import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PermissionDeniedFooterContentComponent } from './permission-denied-footer-content/permission-denied-footer-content.component';
import { PermissionDeniedRouteComponent } from './permission-denied-route/permission-denied-route.component';
import { PermissionDeniedGuard } from './permission-denied.guard';

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forChild([
      {
        // root is '/permission-denied'
        path: '',
        canActivate: [PermissionDeniedGuard],
        canDeactivate: [PermissionDeniedGuard],
        data: { title: 'permissiondenied_title' },
        children: [
          { path: '', component: PermissionDeniedRouteComponent },
          { path: '', component: PermissionDeniedFooterContentComponent, outlet: 'footer-content' },
        ],
      },
    ]),
  ],
})
export class PermissionDeniedRoutingModule {}
