import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ProxyValueAccessor } from '../form/proxy-value-accessor';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'nib-switch' },
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: SwitchComponent, multi: true }],
  selector: 'nib-switch',
  styleUrls: ['switch.component.scss'],
  templateUrl: 'switch.component.html',
})
export class SwitchComponent extends ProxyValueAccessor {
  /** Whether the switch should be checked. */
  @Input()
  get checked(): boolean {
    return this.proxiedControl.value;
  }
  set checked(value: boolean) {
    this.proxiedControl.setValue(coerceBooleanProperty(value));
  }

  /** Whether the switch should be disabled. */
  @Input()
  get disabled(): boolean {
    return this.proxiedControl.disabled;
  }
  set disabled(value: boolean) {
    if (coerceBooleanProperty(value)) {
      this.proxiedControl.disable();
    } else {
      this.proxiedControl.enable();
    }
  }

  /** Form control name. */
  @Input() formControlName = '';

  /** Input id and name. */
  @Input() id = '';

  /** Input label. */
  @Input() label = '';

  /** Whether the switch should be required. */
  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }
  private _required = false;

  /** Switch type. */
  @Input() type = 'text';

  /** Emit checked state. */
  @Output() readonly checkedChange = new EventEmitter<boolean>();

  /** Class to apply to switch. */
  get ngClass(): { [key: string]: boolean } {
    return {
      'nib-switch-disabled': this.disabled,
    };
  }

  readonly proxiedControl = new FormControl();
}
