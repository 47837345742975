<nib-form [formGroup]="form">
  <nib-form-title>{{ 'account_personalinfo_subtitle' | translate }}</nib-form-title>
  <nib-form-description>
    {{ 'account_personalinfo_body' | translate }}
  </nib-form-description>
  <div [fxLayout]="'column'" [fxLayoutGap]="'16px'">
    <div [fxLayout]="'column'" [fxLayout.gt-xs]="'row'" [fxLayoutGap]="'16px'">
      <nib-form-field [fxFlex]="100" [fxFlex.gt-xs]="50">
        <nib-input
          [formControlName]="'firstName'"
          [fullWidth]="true"
          [id]="'firstName'"
          [label]="'inputs_text_firstname_label' | translate"
        ></nib-input>
      </nib-form-field>
      <nib-form-field [fxFlex]="100" [fxFlex.gt-xs]="50">
        <nib-input
          [formControlName]="'lastName'"
          [fullWidth]="true"
          [id]="'lastName'"
          [label]="'inputs_text_lastname_label' | translate"
        ></nib-input>
      </nib-form-field>
    </div>
    <nib-form-field [fxFlex]="100" [hint]="'inputs_text_email_caption' | translate">
      <nib-input
        [disabled]="isEmailEditingDisabled"
        [formControlName]="'email'"
        [fullWidth]="true"
        [id]="'email'"
        [label]="'inputs_text_email_label' | translate"
        [type]="'email'"
      ></nib-input>
    </nib-form-field>
    <nib-form-field
      *featureToggle="'TOGGLE_SHOW_PHONE'"
      [fxFlex]="100"
      [hint]="'inputs_text_phone_caption' | translate"
    >
      <label class="nib-body" for="phone">{{ 'inputs_text_phone_label' | translate }}</label>
      <nib-select
        *featureToggle="'TOGGLE_ENABLE_PHONE_PREFIX'"
        [formControlName]="'phonePrefix'"
        [id]="'phonePrefix'"
        [options]="phonePrefixes"
      ></nib-select>
      <nib-input
        *featureToggle="'!TOGGLE_ENABLE_PHONE_PREFIX'"
        [formControlName]="'phonePrefix'"
        [fxFlex]="'44px'"
        [id]="'phonePrefix'"
        [readonly]="true"
        [value]="defaultPhonePrefixValue"
        class="nib-personal-info-form-phone-prefix"
      ></nib-input>
      <nib-input
        [formControlName]="'phone'"
        [fullWidth]="true"
        [fxFlex]="100"
        [id]="'phone'"
        [type]="'tel'"
      ></nib-input>
    </nib-form-field>
  </div>
</nib-form>
