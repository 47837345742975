import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateSlotFormValue } from './date-slot-form/date-slot-form-value.type';
import { SpecialClosingDaysFormValue } from './special-closing-days-form-value.type';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SpecialClosingDaysFormComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SpecialClosingDaysFormComponent),
      multi: true,
    },
  ],
  selector: 'nib-special-closing-days-form',
  styleUrls: ['special-closing-days-form.component.scss'],
  templateUrl: 'special-closing-days-form.component.html',
})
export class SpecialClosingDaysFormComponent implements ControlValueAccessor, Validator {
  dateSlots: FormControl[] = [];

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  addDateSlot(): void {
    const newDateSlot = this.newDateSlotFormControl({
      from: null,
      on: null,
      to: null,
    });

    newDateSlot.setErrors({ blank: true });
    this.dateSlots = [...this.dateSlots, newDateSlot];
    this.onChange();
  }

  dateSlotIndex(index: number): number {
    return index;
  }

  removeDateSlot(index: number): void {
    this.dateSlots = this.dateSlots.filter((_value, i) => i !== index);
    this.onChange();
  }

  registerOnChange(callback: (value: SpecialClosingDaysFormValue) => void): void {
    this.onChange = () => {
      const dateSlots = this.dateSlots.map(formControl => formControl.value);

      callback(dateSlots);
    };
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  validate(): ValidationErrors | null {
    return this.dateSlots
      .map(dateSlot => dateSlot.valid)
      .reduce((previous, current) => previous && current, true)
      ? null
      : { valid: false };
  }

  writeValue(value: SpecialClosingDaysFormValue): void {
    if (value) {
      const dateSlots = Object.values(value);

      this.dateSlots = dateSlots.map(dateSlot => this.newDateSlotFormControl(dateSlot));
      this.changeDetectorRef.markForCheck();
    }
  }

  private newDateSlotFormControl(dateSlot: DateSlotFormValue): FormControl {
    const newDateSlot = new FormControl(dateSlot);

    newDateSlot.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.onChange();
    });

    return newDateSlot;
  }

  // tslint:disable-next-line: no-empty
  private onChange: () => void = () => {};

  // tslint:disable-next-line: no-empty
  private onTouched: () => void = () => {};
}
