import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivationStart, Router, RouterOutlet } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'nib-app-shell-wizard',
  styleUrls: ['app-shell-wizard.component.scss'],
  templateUrl: 'app-shell-wizard.component.html',
})
export class AppShellWizardComponent implements OnInit {
  @Input() logo?: string;
  @Input() completionPercentage = 0;

  @ViewChild('helpOutlet', { read: RouterOutlet }) helpOutlet?: RouterOutlet;

  constructor(private readonly router: Router) {}

  ngOnInit(): void {
    this.manageHeaderActionsDeactivation();
  }

  private manageHeaderActionsDeactivation(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof ActivationStart && event.snapshot.outlet === 'help'),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.helpOutlet?.deactivate();
      });
  }
}
