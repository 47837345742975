import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'nib-avatar',
  styleUrls: ['avatar.component.scss'],
  templateUrl: 'avatar.component.html',
})
export class AvatarComponent {
  @Input() image = '';

  @Input() size = 48;

  @Input() text = '';
}
