import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { TabGroupComponent } from './tab-group/tab-group.component';
import { TabComponent } from './tab.component';

@NgModule({
  imports: [CommonModule, MatTabsModule],
  exports: [TabComponent, TabGroupComponent],
  declarations: [TabComponent, TabGroupComponent],
})
export class TabModule {}
