import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { VersionsStateModelType } from './versions-state-model.type';
import { VersionsStateAction } from './versions.actions';

@State<VersionsStateModelType | null>({
  name: 'versions',
  defaults: null,
})
@Injectable()
export class VersionsState {
  @Action(VersionsStateAction.Init.Try)
  changeVersion(
    { dispatch, getState, setState }: StateContext<VersionsStateModelType>,
    { versions }: VersionsStateAction.Init.Try,
  ) {
    try {
      setState(versions);

      dispatch(new VersionsStateAction.Init.Success(getState()));
    } catch (error) {
      dispatch(new VersionsStateAction.Init.Failure(error));
    }
  }
}
