import { Building } from '@marketplace/api';
import { VenueStateModel } from '../venue/venue.model';

export function mapVenuesToClient(venues: Building.Venues.Read.Response): VenueStateModel[] {
  return venues.map(venue => ({
    id: venue.id,
    mapFilter: venue.map_filter || venue.mode,
    mode: venue.mode,
    name: venue.name,
    promoted: venue.promoted,
    status: venue.status,
    type: venue.type,
  }));
}
