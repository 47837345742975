import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nib-hub-route-card',
  styleUrls: ['hub-route-card.component.scss'],
  templateUrl: 'hub-route-card.component.html',
})
export class HubRouteCardComponent {
  @Input() disabled = false;
  @Input() link = '';
}
