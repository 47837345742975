import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { API_ROOT_URL } from '../tokens/api-root-url.token';
import { AUTH_ROOT_URL } from '../tokens/auth-root-url.token';
import { Auth } from './auth.type';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    @Inject(AUTH_ROOT_URL) private readonly authRootUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  checkIdentity(body: Auth.CheckIdentity.Body): Observable<Auth.CheckIdentity.Response> {
    return this.httpClient
      .post<Auth.CheckIdentity.Response>(`${this.authRootUrl}/identity`, body)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  createPassword(body: Auth.CreatePassword.Body): Observable<Auth.CreatePassword.Response> {
    return this.httpClient
      .post<Auth.CreatePassword.Response>(`${this.apiRootUrl}/user/reset-logged-password`, body)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  login(body: Auth.Login.Body): Observable<Auth.Login.Response> {
    return this.httpClient.post<Auth.Login.Response>(`${this.authRootUrl}/auth`, body).pipe(
      catchError(error => {
        throw error;
      }),
    );
  }

  loginTemporary(body: Auth.LoginTemporary.Body): Observable<Auth.LoginTemporary.Response> {
    return this.httpClient
      .post<Auth.LoginTemporary.Response>(`${this.authRootUrl}/auth-temp`, body)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  logout(): Observable<unknown> {
    return of();
  }

  passwordCriteria(): Observable<Auth.PasswordCriteria.Response> {
    return this.httpClient.get<Auth.PasswordCriteria.Response>(`${this.authRootUrl}/password`).pipe(
      catchError(error => {
        throw error;
      }),
    );
  }

  recoverPassword(body: Auth.RecoverPassword.Body): Observable<Auth.RecoverPassword.Response> {
    return this.httpClient
      .post<Auth.RecoverPassword.Response>(`${this.apiRootUrl}/user/recover-password`, body)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  refreshToken(): Observable<Auth.RefreshToken.Response> {
    return this.httpClient
      .post<Auth.RefreshToken.Response>(`${this.authRootUrl}/refreshToken`, {})
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  registration(body: Auth.Registration.Body): Observable<Auth.Registration.Response> {
    return this.httpClient.post<Auth.Registration.Response>(
      `${this.apiRootUrl}/user/register`,
      body,
    );
  }

  resetPassword(body: Auth.ResetPassword.Body): Observable<Auth.ResetPassword.Response> {
    return this.httpClient
      .post<Auth.ResetPassword.Response>(`${this.apiRootUrl}/user/reset-password`, body)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  termsAndPrivacyPolicy(): Observable<Auth.TermsAndPrivacyPolicy.Read.Response> {
    return this.httpClient
      .get<Auth.TermsAndPrivacyPolicy.Read.Response>(`${this.authRootUrl}/terms-privacy`)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  updateTermsAndPrivacyPolicy(
    body: Auth.TermsAndPrivacyPolicy.Update.Body,
  ): Observable<Auth.TermsAndPrivacyPolicy.Update.Response> {
    return this.httpClient
      .post<Auth.TermsAndPrivacyPolicy.Update.Response>(`${this.authRootUrl}/terms-privacy`, body)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  supportContacts(body: Auth.SupportContacts.Body): Observable<Auth.SupportContacts.Response> {
    return this.httpClient
      .post<Auth.SupportContacts.Response>(`${this.authRootUrl}/support-contacts`, body)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }
}
