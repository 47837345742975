<div [fxLayoutAlign]="layoutAlign" [fxLayoutGap]="'24px'" class="nib-step-actions-container">
  <nib-button *ngIf="showBack" [color]="'tertiary'" (onClick)="back($event)">
    {{ 'signup_button_back' | translate }}
  </nib-button>
  <nib-button
    [color]="'primary'"
    [disabled]="disableNext"
    [fullWidth]="true"
    [fxFlex]="100"
    [fxFlex.gt-sm]="'fit-content'"
    (onClick)="next($event)"
  >
    {{ 'signup_button_next' | translate }}
  </nib-button>
</div>
