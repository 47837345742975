import { Selector } from '@ngxs/store';
import { fullname } from '../../helpers/user.helper';
import { UserStateModel } from './user.model';
import { UserState } from './user.state';

export class UserSelectors {
  @Selector([UserState])
  static avatar({ avatar }: UserStateModel): UserStateModel['avatar'] {
    return avatar;
  }

  @Selector([UserState])
  static email({ email }: UserStateModel): UserStateModel['email'] {
    return email;
  }

  @Selector([UserState])
  static fullname({
    firstname,
    lastname,
  }: UserStateModel): UserStateModel['firstname'] & UserStateModel['lastname'] {
    return fullname(firstname, lastname);
  }

  @Selector([UserState])
  static lastPasswordChange({
    lastPasswordChange,
  }: UserStateModel): UserStateModel['lastPasswordChange'] {
    return lastPasswordChange;
  }

  @Selector([UserState])
  static notifications({ notifications }: UserStateModel): UserStateModel['notifications'] {
    return notifications;
  }

  @Selector([UserState])
  static profile({
    email,
    firstname,
    language,
    lastname,
    phone,
  }: UserStateModel): Pick<
    UserStateModel,
    'email' | 'firstname' | 'language' | 'lastname' | 'phone'
  > {
    return {
      email,
      firstname,
      language,
      lastname,
      phone,
    };
  }
}
