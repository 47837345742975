<ng-container *ngIf="view$ | async as view">
  <div
    [fxLayout]="'column'"
    [fxLayout.gt-sm]="'row'"
    [fxLayoutAlign]="'space-between'"
    class="nib-calendar-header"
  >
    <h1 [fxFlex]="100" [fxFlex.gt-sm]="'calc(100% - 325px)'" class="nib-calendar-title">
      {{ viewDate | calendarDate: view + 'ViewTitle':locale:1 }}
    </h1>
    <nav
      [fxFlex]="100"
      [fxFlex.gt-sm]="'325px'"
      [fxFlexAlign]="'center'"
      [fxFlexAlign.gt-xs]="'stretch'"
      [fxLayoutAlign]="'space-between'"
      class="nib-calendar-navigation-container"
    >
      <nib-button-group class="nib-calendar-view-switch">
        <nib-button (onClick)="view$.next('week')">
          {{ translations.weekViewName }}
        </nib-button>
        <nib-button (onClick)="view$.next('day')">
          {{ translations.dayViewName }}
        </nib-button>
      </nib-button-group>
      <div [fxFlexAlign]="'center'" [fxFlexAlign.gt-xs]="'start'" class="nib-calendar-day-switch">
        <nib-button
          [color]="'primary'"
          [(viewDate)]="viewDate"
          class="nib-calendar-today-button"
          mwlCalendarToday
        >
          {{ translations.goToTodayCTA }}
        </nib-button>
        <nib-button-group>
          <nib-button
            [view]="view"
            [(viewDate)]="viewDate"
            class="nib-calendar-previous-button"
            mwlCalendarPreviousView
          >
            {{ translations.goToPrevCTA }}
          </nib-button>
          <nib-button
            [view]="view"
            [(viewDate)]="viewDate"
            class="nib-calendar-next-button"
            mwlCalendarNextView
          >
            {{ translations.goToNextCTA }}
          </nib-button>
        </nib-button-group>
      </div>
    </nav>
  </div>
  <ng-container [ngSwitch]="view">
    <mwl-calendar-week-view
      *ngSwitchCase="'week'"
      [currentTimeMarkerTemplate]="currentTimeMarkerTemplate"
      [events]="events ? events : []"
      [eventTitleTemplate]="eventContentTemplate"
      [locale]="locale"
      [refresh]="refresh"
      [viewDate]="viewDate"
      [weekStartsOn]="weekStartsOn"
      [weekendDays]="weekendDays"
    >
    </mwl-calendar-week-view>
    <mwl-calendar-day-view
      *ngSwitchCase="'day'"
      [currentTimeMarkerTemplate]="currentTimeMarkerTemplate"
      [events]="events ? events : []"
      [eventTitleTemplate]="eventContentTemplate"
      [locale]="locale"
      [refresh]="refresh"
      [viewDate]="viewDate"
    >
    </mwl-calendar-day-view>
  </ng-container>
</ng-container>
<ng-template #currentTimeMarkerTemplate let-isVisible="isVisible" let-topPx="topPx">
  <div *ngIf="isVisible" [style.top.px]="topPx" class="nib-calendar-now"></div>
</ng-template>
