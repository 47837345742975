import {
  Venue,
  WeekDay as WeekDayAPI,
  WorkHoursDailyResponse,
  WorkHoursHalfDayResponse,
  WorkHoursHourlyResponse,
} from '@marketplace/api/venue';
import { mapTimeNumberToTimeString } from '@nibol/shared';
import { format, parse } from 'date-fns';
import {
  NameAndAddressStateModel,
  SeatsAndAmenitiesStateModel,
  SettingsStateModel,
  VenueStateModel,
} from './venue.model';
import {
  CreatingStatus,
  ImportedCalendar,
  Mode,
  SpecialClosingTimes,
  TimeModes,
  WeekDayName,
  WorkHours,
} from './venue.type';

export function mapImportedCalendarsToClient(
  importedCalendars: Venue.ImportedCalendar.Read.Response,
): ImportedCalendar[] {
  return importedCalendars.calendars.map(mapImportedCalendarToClient);
}

export function mapImportedCalendarToClient(
  importedCalendar:
    | Venue.ImportedCalendar.Read.Response['calendars'][number]
    | Venue.ImportedCalendar.Create.Response,
): ImportedCalendar {
  return {
    id: importedCalendar._id,
    isImported: importedCalendar.health,
    name: importedCalendar.name,
    url: importedCalendar.url,
  };
}

export function mapNameAndAddressToClient(
  nameAndAddress: Venue.NameAndAddress.Read.Response,
): NameAndAddressStateModel & { mode: Mode } {
  return {
    additionalInfo: nameAndAddress.position?.additional,
    address: nameAndAddress.position?.address,
    area: nameAndAddress.area_key,
    coordinates: {
      lat: nameAndAddress.position?.coordinates.lat,
      lng: nameAndAddress.position?.coordinates.lng,
    },
    description: nameAndAddress.description,
    name: nameAndAddress.name,
    mode: nameAndAddress.mode,
  };
}

export function mapPhotosToClient(photos: Venue.Photos.Read.Response): VenueStateModel['photos'] {
  return photos.pictures;
}

export function mapSeatsAndAmenitiesToClient(
  seatsAndAmenities: Venue.SeatsAndAmenities.Read.Response,
): SeatsAndAmenitiesStateModel {
  return {
    amenities: seatsAndAmenities.amenities,
    info: seatsAndAmenities.info,
    privacyLevel: seatsAndAmenities.privacy_level,
    seats: seatsAndAmenities.seats,
    toilette: seatsAndAmenities.toilet_clean,
    wifi: {
      ...(seatsAndAmenities.wifi?.ssid ? { ssid: seatsAndAmenities.wifi.ssid } : {}),
      ...(seatsAndAmenities.wifi?.mode ? { type: seatsAndAmenities.wifi.mode } : {}),
      password: seatsAndAmenities.wifi?.password || '',
      speed: seatsAndAmenities.wifi_fast ? 'fast' : 'standard',
    },
  };
}

export function mapVenueSettingsToClient(
  settings: Venue.Settings.Read.Response,
): SettingsStateModel {
  return {
    activities: settings.activities,
    allowedGuests: settings.allow,
    discount: settings.discount,
    instantBooking: settings.instant,
    sanitized: settings.sanitized,
    status: settings.status,
    type: settings.type,
  };
}

export function mapSpecialClosingTimesToClient(
  specialClosingTimes: Venue.SpecialClosingTimes.Read.Response['closings'],
): SpecialClosingTimes {
  return {
    dateSlots: specialClosingTimes.map(specialClosingTime => ({
      ...(specialClosingTime.to
        ? { to: parse(specialClosingTime.to.toString(), 'yyyyMMdd', new Date()) }
        : {}),
      from: parse(specialClosingTime.from.toString(), 'yyyyMMdd', new Date()),
    })),
  };
}

export function mapVenueToApi(
  venue: Partial<
    Pick<VenueStateModel, 'mode'> &
      NameAndAddressStateModel &
      Pick<VenueStateModel, 'photos' | 'photosLink'> &
      SeatsAndAmenitiesStateModel &
      SettingsStateModel &
      Pick<VenueStateModel, 'specialClosingTimes'> & {
        creatingStatus?: Partial<VenueStateModel['creatingStatus']>;
      } & {
        workHoursFullDay?: Partial<VenueStateModel['workHoursFullDay']>;
      } & {
        workHoursHalfDay?: Partial<VenueStateModel['workHoursHalfDay']>;
      } & {
        workHoursHourly?: Partial<VenueStateModel['workHoursHourly']>;
      }
  >,
): Venue.Item.Update.Params['data'] {
  return {
    ...mapCreatingStatusToApi(venue.creatingStatus),
    ...mapModeToApi(venue.mode),
    ...mapNameAndAddressToApi(venue),
    ...mapPhotosToApi(venue.photos, venue.photosLink),
    ...mapSeatsAndAmenitiesToApi(venue),
    ...mapVenueSettingsToApi(venue),
    ...mapSpecialClosingTimesToApi(venue.specialClosingTimes),
    ...mapWorkHoursFullDayToApi(venue.workHoursFullDay),
    ...mapWorkHoursHalfDayToApi(venue.workHoursHalfDay),
    ...mapWorkHoursHourlyToApi(venue.workHoursHourly),
  };
}

// tslint:disable-next-line: cyclomatic-complexity
export function mapWorkHoursFullDayToClient(
  workHoursDaily: WorkHoursDailyResponse['work'],
): WorkHours {
  return {
    price: { currency: '€', price: workHoursDaily.price },
    timeSlots: {
      mon: mapWorkHoursWeekDayTimeSlotToClient(
        workHoursDaily.slots.days.indexOf('mon') > -1 ? workHoursDaily.slots.times : [],
      ),
      tue: mapWorkHoursWeekDayTimeSlotToClient(
        workHoursDaily.slots.days.indexOf('tue') > -1 ? workHoursDaily.slots.times : [],
      ),
      wed: mapWorkHoursWeekDayTimeSlotToClient(
        workHoursDaily.slots.days.indexOf('wed') > -1 ? workHoursDaily.slots.times : [],
      ),
      thu: mapWorkHoursWeekDayTimeSlotToClient(
        workHoursDaily.slots.days.indexOf('thu') > -1 ? workHoursDaily.slots.times : [],
      ),
      fri: mapWorkHoursWeekDayTimeSlotToClient(
        workHoursDaily.slots.days.indexOf('fri') > -1 ? workHoursDaily.slots.times : [],
      ),
      sat: mapWorkHoursWeekDayTimeSlotToClient(
        workHoursDaily.slots.days.indexOf('sat') > -1 ? workHoursDaily.slots.times : [],
      ),
      sun: mapWorkHoursWeekDayTimeSlotToClient(
        workHoursDaily.slots.days.indexOf('sun') > -1 ? workHoursDaily.slots.times : [],
      ),
    },
  };
}

// tslint:disable-next-line: cyclomatic-complexity
export function mapWorkHoursHalfDayToClient(
  workHoursHalfDay: WorkHoursHalfDayResponse['work'],
): WorkHours {
  return {
    price: { currency: '€', price: workHoursHalfDay.price },
    timeSlots: {
      mon: mapWorkHoursWeekDayTimeSlotToClient(
        workHoursHalfDay.slots.days.indexOf('mon') > -1 ? workHoursHalfDay.slots.times : [],
      ),
      tue: mapWorkHoursWeekDayTimeSlotToClient(
        workHoursHalfDay.slots.days.indexOf('tue') > -1 ? workHoursHalfDay.slots.times : [],
      ),
      wed: mapWorkHoursWeekDayTimeSlotToClient(
        workHoursHalfDay.slots.days.indexOf('wed') > -1 ? workHoursHalfDay.slots.times : [],
      ),
      thu: mapWorkHoursWeekDayTimeSlotToClient(
        workHoursHalfDay.slots.days.indexOf('thu') > -1 ? workHoursHalfDay.slots.times : [],
      ),
      fri: mapWorkHoursWeekDayTimeSlotToClient(
        workHoursHalfDay.slots.days.indexOf('fri') > -1 ? workHoursHalfDay.slots.times : [],
      ),
      sat: mapWorkHoursWeekDayTimeSlotToClient(
        workHoursHalfDay.slots.days.indexOf('sat') > -1 ? workHoursHalfDay.slots.times : [],
      ),
      sun: mapWorkHoursWeekDayTimeSlotToClient(
        workHoursHalfDay.slots.days.indexOf('sun') > -1 ? workHoursHalfDay.slots.times : [],
      ),
    },
  };
}

export function mapWorkHoursHourlyToClient(
  workHoursHourly: WorkHoursHourlyResponse['work'],
): WorkHours {
  return {
    price: { currency: '€', price: workHoursHourly.price },
    timeSlots: {
      mon: mapWorkHoursWeekDayTimeSlotToClient(workHoursHourly.slots.mon),
      tue: mapWorkHoursWeekDayTimeSlotToClient(workHoursHourly.slots.tue),
      wed: mapWorkHoursWeekDayTimeSlotToClient(workHoursHourly.slots.wed),
      thu: mapWorkHoursWeekDayTimeSlotToClient(workHoursHourly.slots.thu),
      fri: mapWorkHoursWeekDayTimeSlotToClient(workHoursHourly.slots.fri),
      sat: mapWorkHoursWeekDayTimeSlotToClient(workHoursHourly.slots.sat),
      sun: mapWorkHoursWeekDayTimeSlotToClient(workHoursHourly.slots.sun),
    },
  };
}

function mapCreatingStatusToApi(
  creatingStatus?: Partial<CreatingStatus>,
): Pick<Venue.Item.Update.Params['data'], 'creation_status'> {
  const creatingStatusValues = creatingStatus && Object.values(creatingStatus);

  return creatingStatusValues &&
    creatingStatusValues.length > 0 &&
    creatingStatusValues.every(creatingStatusValue => typeof creatingStatusValue !== 'undefined')
    ? {
        creation_status: {
          accountCompleted: creatingStatus?.accountCompleted,
          companyCompleted: creatingStatus?.companyCompleted,
          yourSpaceCompleted: creatingStatus?.yourSpaceCompleted,
        },
      }
    : {};
}

function mapModeToApi(
  mode?: VenueStateModel['mode'],
): Pick<Venue.Item.Update.Params['data'], 'mode'> {
  return mode ? { mode } : {};
}

function mapNameAndAddressToApi(
  venue: Partial<NameAndAddressStateModel>,
): Pick<Venue.Item.Update.Params['data'], 'area_key' | 'name' | 'position' | 'description'> {
  const nameAndAddress: Pick<
    Venue.Item.Update.Params['data'],
    'area_key' | 'name' | 'position' | 'description'
  > = {
    area_key: venue.area,
    description: venue.description,
    name: venue.name,
  };

  if (venue.additionalInfo || venue.address || venue.coordinates) {
    nameAndAddress.position = {
      ...(venue.coordinates
        ? {
            coordinates: {
              lat: venue.coordinates?.lat,
              lng: venue.coordinates?.lng,
            },
          }
        : {}),
      additional: venue.additionalInfo,
      address: venue.address,
    };
  }

  return nameAndAddress;
}

function mapPhotosToApi(
  photos?: VenueStateModel['photos'],
  link?: VenueStateModel['photosLink'],
): Pick<Venue.Item.Update.Params['data'], 'pictures' | 'picture_link'> {
  return {
    pictures: photos,
    picture_link: link,
  };
}

function mapSeatsAndAmenitiesToApi(
  venue: SeatsAndAmenitiesStateModel,
): Pick<
  Venue.Item.Update.Params['data'],
  'amenities' | 'info' | 'seats' | 'wifi' | 'privacy_level' | 'toilet_clean' | 'wifi_fast'
> {
  return {
    ...(venue.wifi
      ? {
          wifi: {
            ssid: venue.wifi.ssid,
            mode: venue.wifi.type,
            password: venue.wifi.password,
          },
        }
      : {}),
    ...(typeof venue.wifi?.speed !== 'undefined' ? { wifi_fast: venue.wifi.speed === 'fast' } : {}),
    amenities: venue.amenities,
    info: venue.info,
    privacy_level: venue.privacyLevel,
    seats: venue.seats,
    toilet_clean: venue.toilette,
  };
}

function mapVenueSettingsToApi(
  venue: SettingsStateModel,
): Pick<
  Venue.Item.Update.Params['data'],
  'activities' | 'allow' | 'discount' | 'instant' | 'sanitized' | 'status' | 'type'
> {
  return {
    activities: venue.activities,
    allow: venue.allowedGuests,
    discount: venue.discount,
    instant: venue.instantBooking,
    sanitized: venue.sanitized,
    status: venue.status,
    type: venue.type,
  };
}

function mapSpecialClosingTimesToApi(
  specialClosingTimes?: SpecialClosingTimes,
): Pick<Venue.Item.Update.Params['data'], 'closings'> {
  return specialClosingTimes
    ? {
        closings: specialClosingTimes.dateSlots.map(dateSlot => ({
          ...(dateSlot.to ? { to: parseInt(format(dateSlot.to, 'yyyyMMdd'), 10) } : {}),
          from: parseInt(format(dateSlot.from, 'yyyyMMdd'), 10),
        })),
      }
    : {};
}

function mapTimeSlotToApi({
  timeSlots,
  availability,
}: WorkHours['timeSlots'][WeekDayName]): NonNullable<
  NonNullable<WorkHoursHourlyResponse['work']>['slots']
>[WeekDayAPI] {
  return timeSlots.map(timeSlot => ({
    accept: availability,
    from: parseInt(timeSlot.from.split(':').join(''), 10),
    to: parseInt(timeSlot.to.split(':').join(''), 10),
  }));
}

function mapWorkHoursFullDayToApi(
  workHoursFullDay?: Partial<WorkHours>,
): Partial<Pick<Venue.Item.Update.Params['data'], 'work'>> & { time_mode?: TimeModes.daily } {
  if (workHoursFullDay) {
    return {
      time_mode: TimeModes.daily,
      work: {
        price: workHoursFullDay.price?.price,
        ...(workHoursFullDay.timeSlots
          ? {
              slots: {
                days: Object.entries(workHoursFullDay.timeSlots)
                  .filter(([_weekDayName, { availability }]) => availability)
                  .map(([weekDayName]) => weekDayName as WeekDayAPI),
                times: Array()
                  .concat.apply(
                    [],
                    Object.entries(
                      workHoursFullDay.timeSlots,
                    ).map(([_weekDayName, weekDaytimeSlots]) => mapTimeSlotToApi(weekDaytimeSlots)),
                  )
                  .filter(
                    (item, index, items) =>
                      items.findIndex(t => JSON.stringify(t) === JSON.stringify(item)) === index,
                  ),
              },
            }
          : {}),
      },
    };
  }

  return {};
}

function mapWorkHoursHalfDayToApi(
  workHoursHalfDay?: Partial<WorkHours>,
): Partial<Pick<Venue.Item.Update.Params['data'], 'work'>> & { time_mode?: TimeModes.halfday } {
  if (workHoursHalfDay) {
    return {
      time_mode: TimeModes.halfday,
      work: {
        price: workHoursHalfDay.price?.price,
        ...(workHoursHalfDay.timeSlots
          ? {
              slots: {
                days: Object.entries(workHoursHalfDay.timeSlots)
                  .filter(([_weekDayName, { availability }]) => availability)
                  .map(([weekDayName]) => weekDayName as WeekDayAPI),
                times: Array()
                  .concat.apply(
                    [],
                    Object.entries(
                      workHoursHalfDay.timeSlots,
                    ).map(([_weekDayName, weekDaytimeSlots]) => mapTimeSlotToApi(weekDaytimeSlots)),
                  )
                  .filter(
                    (item, index, items) =>
                      items.findIndex(t => JSON.stringify(t) === JSON.stringify(item)) === index,
                  ),
              },
            }
          : {}),
      },
    };
  }

  return {};
}

function mapWorkHoursHourlyToApi(
  workHoursHourly?: Partial<WorkHours>,
): Partial<Pick<Venue.Item.Update.Params['data'], 'work'>> & { time_mode?: TimeModes.hourly } {
  if (workHoursHourly) {
    return {
      time_mode: TimeModes.hourly,
      work: {
        price: workHoursHourly.price?.price,
        ...(workHoursHourly.timeSlots
          ? {
              slots: {
                mon: mapTimeSlotToApi(workHoursHourly.timeSlots.mon),
                tue: mapTimeSlotToApi(workHoursHourly.timeSlots.tue),
                wed: mapTimeSlotToApi(workHoursHourly.timeSlots.wed),
                thu: mapTimeSlotToApi(workHoursHourly.timeSlots.thu),
                fri: mapTimeSlotToApi(workHoursHourly.timeSlots.fri),
                sat: mapTimeSlotToApi(workHoursHourly.timeSlots.sat),
                sun: mapTimeSlotToApi(workHoursHourly.timeSlots.sun),
              },
            }
          : {}),
      },
    };
  }

  return {};
}

function mapWorkHoursWeekDayAvailabilityForClient(
  weekDayTimeSlot: WorkHoursHourlyResponse['work']['slots'][WeekDayAPI],
): WorkHours['timeSlots'][WeekDayName]['availability'] {
  return (weekDayTimeSlot && weekDayTimeSlot?.length > 0) || false;
}

function mapWorkHoursWeekDayTimeSlotToClient(
  weekDayTimeSlot: WorkHoursHourlyResponse['work']['slots'][WeekDayAPI],
): WorkHours['timeSlots'][WeekDayName] {
  return weekDayTimeSlot
    ? {
        availability: mapWorkHoursWeekDayAvailabilityForClient(weekDayTimeSlot),
        timeSlots: weekDayTimeSlot
          .filter(slot => slot.accept || typeof slot.accept === 'undefined')
          .map(slot => ({
            from: mapTimeNumberToTimeString(slot.from),
            to: mapTimeNumberToTimeString(slot.to),
          })),
      }
    : { availability: false, timeSlots: [] };
}
