/**
 * Deep merge two objects.
 * @see https://gist.github.com/ahtcx/0cd94e62691f539160b32ecda18af3d6?permalink_comment_id=4058994
 * @param target
 * @param ...sources
 */
// tslint:disable-next-line: cyclomatic-complexity no-any
export function mergeDeep(source: any, target: any) {
  let newTarget = target;

  if (typeof source !== 'undefined' && source !== null) {
    for (const [key, val] of Object.entries(source)) {
      if (val !== null && typeof val === `object`) {
        if (typeof newTarget !== 'undefined') {
          if (!(key in newTarget)) {
            newTarget = { ...newTarget, [key]: val };
          }

          mergeDeep(val, newTarget[key]);
        }
      } else {
        newTarget = { ...newTarget, [key]: val };
      }
    }
  }

  return newTarget;
}
