// tslint:disable: no-namespace max-classes-per-file

import { BuildingStateModel } from '../building';

export namespace BuildingsStateAction {
  export namespace List {
    export namespace Read {
      export class Try {
        static readonly type = '[Buildings] try Read Buildings List';
      }
      export class Success {
        static readonly type = '[Buildings] then Read Buildings List success';
        constructor(public buildings: BuildingStateModel[]) {}
      }
      export class Failure {
        static readonly type = '[Buildings] catch Read Buildings List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Buildings] try Update Buildings List';
        constructor(public building: Partial<BuildingStateModel>) {}
      }
      export class Success {
        static readonly type = '[Buildings] then Update Buildings List success';
        constructor(public buildings: BuildingStateModel[]) {}
      }
      export class Failure {
        static readonly type = '[Buildings] catch Update Buildings List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }
}
