import { animate, AnimationEvent, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/** @dynamic */
@Component({
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [animate('200ms ease-in', style({ transform: 'translateX(100%)' }))]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'nib-drawer' },
  selector: 'nib-drawer',
  styleUrls: ['drawer.component.scss'],
  templateUrl: 'drawer.component.html',
})
export class DrawerComponent {
  @Output() onBackdropClick = new EventEmitter();

  enableCdkFocus$ = new BehaviorSubject(false);

  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  manageAnimationDone(event: AnimationEvent): void {
    if (event.toState === 'void') {
      (this.document.getElementsByClassName(
        'nib-app-shell-main-container',
      )[0] as HTMLElement).style.overflow = '';
    }

    if (event.toState.toString() === 'true') {
      this.enableCdkFocus$.next(true);
    }
  }

  manageAnimationStart(event: AnimationEvent): void {
    if (event.fromState === 'void') {
      (this.document.getElementsByClassName(
        'nib-app-shell-main-container',
      )[0] as HTMLElement).style.overflow = 'hidden';
    }
  }
}
