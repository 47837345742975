import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    class: 'nib-tab',
  },
  selector: 'nib-tab',
  styleUrls: ['tab.component.scss'],
  templateUrl: 'tab.component.html',
})
export class TabComponent implements AfterContentInit {
  /** Whether the tab should be disabled */
  @Input() disabled = false;

  /** Label of the tab. */
  @Input() label = '';

  /** Content of the tab. */
  @ViewChild(TemplateRef) content!: ElementRef;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngAfterContentInit(): void {
    this.changeDetectorRef.detectChanges();
  }
}
