<a
  *ngIf="to; else menuItemWithButton"
  [attr.disabled]="disabled"
  [fxLayoutAlign]="'start center'"
  [routerLink]="to"
  [routerLinkActive]="'nib-app-shell-menu-item-active'"
  (click)="appShellService.closeMenu()"
  class="nib-app-shell-menu-item-content nib-app-shell-menu-item-link"
>
  <ng-container *ngTemplateOutlet="menuItemContent"></ng-container>
</a>
<ng-content select="nib-app-shell-menu-item, ng-container"></ng-content>

<ng-template #menuItemWithButton>
  <button
    *ngIf="hasClickObservers; else menuItemWithoutLink"
    [disabled]="disabled"
    [fxLayoutAlign]="'start center'"
    (click)="onClick.emit()"
    class="nib-app-shell-menu-item-content nib-app-shell-menu-item-button"
  >
    <ng-container *ngTemplateOutlet="menuItemContent"></ng-container>
  </button>
</ng-template>
<ng-template #menuItemWithoutLink>
  <span
    [fxLayoutAlign]="'start center'"
    class="nib-app-shell-menu-item-content nib-app-shell-menu-item-no-link"
  >
    <ng-container *ngTemplateOutlet="menuItemContent"></ng-container>
  </span>
</ng-template>
<ng-template #menuItemContent>
  <div
    *ngIf="withPrefix"
    [fxFlex]="'24px'"
    [fxLayoutAlign]="'center center'"
    class="nib-app-shell-menu-item-icon"
  >
    <ng-content select="nib-icon:not([position='end']), [nibAppShellMenuItemPrefix]"></ng-content>
  </div>
  <div
    [fxLayout]="'row'"
    [fxLayoutAlign]="'space-between start'"
    class="nib-app-shell-menu-item-label nib-heading-4"
  >
    <div [fxFlex]="100" class="nib-app-shell-menu-item-label-text">
      <ng-content></ng-content>
    </div>
  </div>
  <ng-content select="nib-icon[position='end'], [position='end']"></ng-content>
</ng-template>
