import { Injectable } from '@angular/core';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Action, State, StateContext } from '@ngxs/store';
import { PermissionsStateAction, Roles } from '@nibol/auth';
import { ErrorHandlerService } from '@nibol/error-handler';
import {
  TranslationSelectors,
  TranslationStateAction,
  TranslationStateModel,
} from '@nibol/translation';
import { UserStateAction } from './user/user.actions';

@State({
  name: 'usersaga',
})
@Injectable()
export class UserSaga {
  @SelectSnapshot(TranslationSelectors.currentLanguage)
  currentLanguage!: TranslationStateModel['currentLanguage'];

  constructor(private readonly errorHandlerService: ErrorHandlerService) {}

  @Action(UserStateAction.Item.Read.Success)
  userReadSuccess(
    { dispatch }: StateContext<unknown>,
    { user }: UserStateAction.Item.Read.Success,
  ) {
    this.errorHandlerService.configureScopeUser(user.id, user.email);

    dispatch([
      new TranslationStateAction.CurrentLanguage.Change.Try(user.language),
      new PermissionsStateAction.List.Change.Try([
        ...user.roles,
        ...user.permissions,
        Roles.hasValidSubscription,
      ]),
    ]);
  }

  @Action(UserStateAction.Profile.Update.Success)
  userUpdateSuccess(
    { dispatch }: StateContext<unknown>,
    { user: { language } }: UserStateAction.Profile.Update.Success,
  ) {
    if (this.currentLanguage !== language) {
      dispatch(new TranslationStateAction.CurrentLanguage.Change.Try(language));
    }
  }
}
