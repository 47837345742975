import { Injectable } from '@angular/core';
import { UserService as NibolUserService } from '@nibol/shared';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { User } from './user.type';

@Injectable({ providedIn: 'root' })
export class UserService extends NibolUserService {
  createAssociatedVenueAndBuilding({
    building,
    venue,
  }: User.AssociatedVenueAndBuilding.Create.Params): Observable<
    User.AssociatedVenueAndBuilding.Create.Response
  > {
    return this.httpClient
      .post<User.AssociatedVenueAndBuilding.Create.Response>(`${this.apiRootUrl}/user/signup`, {
        building,
        venue,
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }
}
