import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslationModule } from '@nibol/translation';
import {
  AvatarModule,
  ButtonModule,
  CheckboxModule,
  FormModule,
  IconModule,
  InputModule,
  LetModule,
  TooltipModule,
} from '@nibol/ui';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { GoogleButtonModule } from '../../components/google-button/google-button.module';
import { MicrosoftButtonModule } from '../../components/microsoft-button/microsoft-button.module';
import { OktaButtonModule } from '../../components/okta-button/okta-button.module';
import { LoginFooterContentComponent } from './login-route/login-footer-content/login-footer-content.component';
import { LoginFormComponent } from './login-route/login-form/login-form.component';
import { LoginRouteComponent } from './login-route/login-route.component';
import { LoginRoutingModule } from './login-routing.module';

@NgModule({
  declarations: [LoginFooterContentComponent, LoginFormComponent, LoginRouteComponent],
  imports: [
    AvatarModule,
    ButtonModule,
    CheckboxModule,
    CommonModule,
    FeatureToggleModule,
    FormModule,
    FormsModule,
    GoogleButtonModule,
    IconModule,
    InputModule,
    LetModule,
    LoginRoutingModule,
    MicrosoftButtonModule,
    OktaButtonModule,
    ReactiveFormsModule,
    TooltipModule,
    TranslationModule,
  ],
})
export class LoginModule {}
