import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslationModule } from '@nibol/translation';
import { ButtonModule, IconModule, LetModule } from '@nibol/ui';
import { MicrosoftButtonComponent } from './microsoft-button.component';

@NgModule({
  declarations: [MicrosoftButtonComponent],
  exports: [MicrosoftButtonComponent],
  imports: [ButtonModule, CommonModule, IconModule, LetModule, TranslationModule],
})
export class MicrosoftButtonModule {}
