import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslationModule } from '@nibol/translation';
import { ButtonModule, FormModule, InputModule } from '@nibol/ui';
import { ResetPasswordFormComponent } from './reset-password-route/reset-password-form/reset-password-form.component';
import { ResetPasswordRouteComponent } from './reset-password-route/reset-password-route.component';
import { ResetPasswordRoutingModule } from './reset-password-routing.module';

@NgModule({
  imports: [
    ButtonModule,
    CommonModule,
    FormModule,
    InputModule,
    ResetPasswordRoutingModule,
    ReactiveFormsModule,
    TranslationModule,
  ],
  declarations: [ResetPasswordFormComponent, ResetPasswordRouteComponent],
})
export class ResetPasswordModule {}
