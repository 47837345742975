import { createSelector, Selector } from '@ngxs/store';
import { PermissionsState } from './permissions.state';

/** @dynamic */
export class PermissionsSelectors {
  @Selector([PermissionsSelectors.hasRole('couldLogIn')])
  static couldLogIn(couldLogIn: boolean): boolean {
    return couldLogIn;
  }

  static hasRole<Role extends string>(role: Role) {
    return createSelector([PermissionsState], (roles: Role[]): boolean => roles.includes(role));
  }

  @Selector([PermissionsSelectors.hasRole('hasToChangePassword')])
  static hasToChangePassword(hasToChangePassword: boolean): boolean {
    return hasToChangePassword;
  }

  @Selector([PermissionsSelectors.hasRole('admin')])
  static isAdmin(isAdmin: boolean): boolean {
    return isAdmin;
  }

  @Selector([PermissionsSelectors.hasRole('ambassador')])
  static isAmbassador(isAdmin: boolean): boolean {
    return isAdmin;
  }

  @Selector([PermissionsSelectors.hasRole('manager')])
  static isManager(isAdmin: boolean): boolean {
    return isAdmin;
  }
}
