import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBottomSheetModule, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { ButtonModule } from '../button/public-api';
import { IconModule } from '../icon/public-api';
import { BottomSheetDataModel } from './bottom-sheet-data.model';
import { BottomSheetComponent } from './bottom-sheet.component';

@NgModule({
  entryComponents: [BottomSheetComponent],
  declarations: [BottomSheetComponent],
  exports: [BottomSheetComponent],
  imports: [ButtonModule, CommonModule, IconModule, MatBottomSheetModule],
  providers: [{ provide: MAT_BOTTOM_SHEET_DATA, useValue: BottomSheetDataModel }],
})
export class BottomSheetModule {}
