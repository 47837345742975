import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { FormValueAccessor } from '@nibol/ui';
import { ResetPasswordFormValue } from './reset-password-form-value.type';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ResetPasswordFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ResetPasswordFormComponent),
      multi: true,
    },
  ],
  selector: 'nib-reset-password-form',
  styleUrls: ['reset-password-form.component.scss'],
  templateUrl: 'reset-password-form.component.html',
})
export class ResetPasswordFormComponent extends FormValueAccessor<ResetPasswordFormValue> {
  @Input() disabled = false;

  form = new FormGroup({
    password: new FormControl(null, [
      Validators.pattern(/.{8,}/),
      Validators.pattern(/[a-zA-Z]/),
      Validators.pattern(/[0-9]/),
      Validators.required,
    ]),
    token: new FormControl(null, Validators.required),
  });
}
