import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslationModule } from '@nibol/translation';
import { ButtonModule, CardModule, FormModule, InputModule } from '@nibol/ui';
import { CreatePasswordFormComponent } from './create-password-route/create-password-form/create-password-form.component';
import { CreatePasswordRouteComponent } from './create-password-route/create-password-route.component';
import { CreatePasswordRoutingModule } from './create-password-routing.module';

@NgModule({
  imports: [
    ButtonModule,
    CardModule,
    CommonModule,
    CreatePasswordRoutingModule,
    FormModule,
    InputModule,
    ReactiveFormsModule,
    TranslationModule,
  ],
  declarations: [CreatePasswordFormComponent, CreatePasswordRouteComponent],
})
export class CreatePasswordModule {}
