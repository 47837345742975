import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '../button/public-api';
import { IconModule } from '../icon/public-api';
import { AppShellExplodeMenuItemDirective } from './app-shell-explode-menu-item.directive';
import { AppShellHeaderComponent } from './app-shell-header.component';
import { AppShellLogoComponent } from './app-shell-logo.component';
import { AppShellMainHeaderComponent } from './app-shell-main-header.component';
import { AppShellMainComponent } from './app-shell-main.component';
import { AppShellMenuDividerComponent } from './app-shell-menu-divider.component';
import { AppShellMenuItemPrefixDirective } from './app-shell-menu-item/app-shell-menu-item-prefix.directive';
import { AppShellMenuItemComponent } from './app-shell-menu-item/app-shell-menu-item.component';
import { AppShellMenuComponent } from './app-shell-menu/app-shell-menu.component';
import { AppShellComponent } from './app-shell.component';

@NgModule({
  declarations: [
    AppShellComponent,
    AppShellExplodeMenuItemDirective,
    AppShellHeaderComponent,
    AppShellLogoComponent,
    AppShellMainComponent,
    AppShellMainHeaderComponent,
    AppShellMenuComponent,
    AppShellMenuDividerComponent,
    AppShellMenuItemComponent,
    AppShellMenuItemPrefixDirective,
  ],
  exports: [
    AppShellComponent,
    AppShellExplodeMenuItemDirective,
    AppShellHeaderComponent,
    AppShellLogoComponent,
    AppShellMainComponent,
    AppShellMainHeaderComponent,
    AppShellMenuComponent,
    AppShellMenuDividerComponent,
    AppShellMenuItemComponent,
    AppShellMenuItemPrefixDirective,
  ],
  imports: [ButtonModule, CommonModule, FlexLayoutModule, IconModule, RouterModule],
})
export class AppShellModule {}
