import { CdkDropList } from '@angular/cdk/drag-drop';

// tslint:disable-next-line: no-any
export function indexOf(collection: any, node: any): any {
  return Array.prototype.indexOf.call(collection, node);
}

/** Determines whether an event is a touch event. */
export function isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
  return event.type.startsWith('touch');
}

export function isInsideDropListClientRect(dropList: CdkDropList, x: number, y: number) {
  const { top, bottom, left, right } = dropList.element.nativeElement.getBoundingClientRect();
  return y >= top && y <= bottom && x >= left && x <= right;
}
