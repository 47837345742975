<nib-form [formGroup]="form">
  <nib-form-title>{{ 'account_notifannouncements_subtitle' | translate }}</nib-form-title>
  <nib-form-description>{{ 'account_notifannouncements_body' | translate }}</nib-form-description>
  <nib-form-field>
    <nib-switch
      [disabled]="true"
      [checked]="((form.valueChanges | async) || form.value).announcementsEmail"
      [label]="'inputs_text_email_label' | translate"
    ></nib-switch>
  </nib-form-field>
  <nib-form-field *featureToggle="'TOGGLE_SHOW_PUSH_MOBILE'">
    <nib-switch
      [formControlName]="'announcementsPush'"
      [label]="'inputs_text_push_label' | translate"
    ></nib-switch>
  </nib-form-field>
  <nib-form-field>
    <nib-switch
      [formControlName]="'announcementsSms'"
      [label]="'inputs_text_sms_label' | translate"
    ></nib-switch>
  </nib-form-field>
  <nib-form-field>
    <nib-switch
      [formControlName]="'announcementsPushWeb'"
      [label]="'inputs_text_push_label' | translate"
    ></nib-switch>
  </nib-form-field>
</nib-form>
