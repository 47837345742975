<div [class.nib-table-container-with-paginator]="paginator" class="nib-table-container">
  <div class="nib-table-wrapper">
    <table [dataSource]="dataSource" mat-table>
      <ng-content></ng-content>

      <tr *matHeaderRowDef="columns; sticky: true" mat-header-row></tr>
      <tr *matRowDef="let row; columns: columns" mat-row></tr>
    </table>
  </div>
</div>
<ng-content select="mat-paginator"></ng-content>
