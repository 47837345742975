<ng-container *nibLet="couldLogIn$ | async as couldLogIn" [formGroup]="form">
  <nib-form-field [error]="emailErrorMessage | translate">
    <nib-input
      [class.nib-input-disabled]="couldLogIn"
      [formControlName]="'username'"
      [fullWidth]="true"
      [id]="'username'"
      [label]="'inputs_text_email_label' | translate"
      [readonly]="couldLogIn$ | async"
      [type]="'email'"
    ></nib-input>
  </nib-form-field>
  <p *ngIf="couldLogIn" class="nib-login-form-change-email">
    <a [routerLink]="'.'" (click)="changeEmail()">{{ 'login_changeemail' | translate }}</a>
  </p>
  <ng-container *ngIf="isLoginWithPasswordEnabled$ | async">
    <nib-form-field
      [class.nib-login-form-password-hidden]="!couldLogIn"
      [error]="passwordErrorMessage | translate"
      class="nib-login-form-password"
    >
      <label class="nib-login-form-password-label nib-body" for="password">
        {{ 'inputs_text_psw_label' | translate }}
        <a
          *featureToggle="'TOGGLE_ENABLE_RESET_PASSWORD'"
          [routerLink]="'/recover-password'"
          class="nib-login-form-forgot-password"
        >
          {{ 'login_recoverpassword_text' | translate }}
        </a>
      </label>
      <nib-input
        [formControlName]="'password'"
        [fullWidth]="true"
        [hasError]="form.get('password')?.errors"
        [id]="'password'"
        [type]="'password'"
        [visibilityToggle]="true"
      ></nib-input>
    </nib-form-field>
    <nib-form-field *ngIf="couldLogIn" class="nib-login-form-stay-signed-in">
      <nib-checkbox [formControlName]="'staySignedIn'" [id]="'staySignedIn'">
        <span class="nib-login-form-stay-signed-in-label">
          {{ 'login_staysignedin' | translate }}
          <nib-icon
            [color]="'var(--color-gray-2)'"
            [name]="'question-medium'"
            [nibTooltip]="staySignInHelp"
          ></nib-icon>
          <ng-template #staySignInHelp>
            <nib-tooltip>{{ 'login_staysignedin_help' | translate }}</nib-tooltip>
          </ng-template>
        </span>
      </nib-checkbox>
    </nib-form-field>
  </ng-container>
  <nib-form-action class="nib-login-form-actions">
    <ng-container *ngIf="couldLogIn; else verifyEmailButton">
      <nib-google-button
        *ngIf="isLoginWithGoogleEnabled$ | async"
        (onGoogleFailedToLogin)="manageErrorMessage($event)"
      ></nib-google-button>
      <nib-microsoft-button
        *ngIf="isLoginWithMicrosoftEnabled$ | async"
        (onMicrosoftFailedToLogin)="manageErrorMessage($event)"
      ></nib-microsoft-button>
      <nib-okta-button
        *ngIf="isLoginWithOktaEnabled$ | async"
        (onOktaFailedToLogin)="manageErrorMessage($event)"
      ></nib-okta-button>
      <nib-button
        *ngIf="isLoginWithPasswordEnabled$ | async"
        [color]="'primary'"
        [disabled]="!form.valid || (inProgress$ | async)"
        [fullWidth]="true"
        [submit]="true"
      >
        <nib-icon *ngIf="inProgress$ | async" [name]="'progress'"></nib-icon>
        {{ 'login_button1_text' | translate }}
      </nib-button>
    </ng-container>
    <ng-template #verifyEmailButton>
      <nib-button
        *ngIf="!couldLogIn"
        (onClick)="verifyEmail()"
        [color]="'primary'"
        [disabled]="!form.get('username')?.valid || (inProgress$ | async)"
        [fullWidth]="true"
      >
        <nib-icon *ngIf="inProgress$ | async" [name]="'progress'"></nib-icon>
        {{ 'login_next' | translate }}
      </nib-button>
    </ng-template>
  </nib-form-action>
  <div *ngIf="ssoErrorMessage$ | async as ssoErrorMessage" class="nib-login-form-errors">
    {{ ssoErrorMessage | translate }}
  </div>
</ng-container>
