import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Sizes } from '../core/sizes';
import { TagColor } from './tag-color.type';
import { TagSize } from './tag-size.type';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'nib-tag',
  styleUrls: ['tag.component.scss'],
  templateUrl: 'tag.component.html',
})
export class TagComponent {
  /** Define the color of the tag. */
  @Input() color: TagColor = 'default';

  /** Define the size of the tag. */
  @Input() size: TagSize = Sizes.xs;

  /** Class to apply to tag. */
  get ngClass(): { [key: string]: boolean } {
    return {
      [`nib-tag-${this.color}`]: true,
      [`nib-tag-${this.size}`]: true,
      'nib-tag': true,
    };
  }
}
