import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Size } from '../core/sizes';
import { ProgressBarColor } from './progress-bar-color.type';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'nib-progress-bar',
  styleUrls: ['progress-bar.component.scss'],
  templateUrl: 'progress-bar.component.html',
})
export class ProgressBarComponent {
  /** Color of the progress bar. */
  @Input() color: ProgressBarColor = 'primary';

  /** Height of the progress bar. */
  @Input() size: Size = 'xs';

  /** Value of the progress bar. */
  @Input() value = 0;

  get ngClass(): { [key: string]: boolean } {
    return {
      ['nib-progress-bar']: true,
      [`nib-progress-bar-${this.color}`]: true,
      [`nib-progress-bar-${this.size}`]: true,
    };
  }
}
