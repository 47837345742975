// tslint:disable: no-namespace

export namespace User {
  export namespace Item {
    export namespace Read {
      export type Response = Info.Read.Response & Notification.Read.Response;
    }
  }

  export namespace Info {
    export namespace Read {
      export type Response = {
        user: {
          auth: {
            email: string;
          };
          data: {
            first_name: string;
            last_name: string;
            pic: string;
            phone: {
              country: string;
              number: string;
            } | null;
          };
          id: string;
          info: {
            language: 'en' | 'es' | 'fr' | 'it';
          };
          roles: Array<-1 | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 1001>;
          need_password_change?: boolean;
          permissions?: string[];
        };
      };
    }
    export namespace Update {
      export type Params = {
        user: {
          auth?: Partial<Pick<Read.Response['user']['auth'], 'email'>>;
          data?: Partial<Pick<Read.Response['user']['data'], 'first_name' | 'last_name' | 'phone'>>;
          info?: Partial<Pick<Read.Response['user']['info'], 'language'>>;
        };
      };
      export type Response = { status: boolean };
    }
  }

  export namespace Notification {
    export namespace Read {
      export type Response = {
        notification: {
          [notification in 'email' | 'push' | 'sms']?: {
            [type in 'account' | 'announcements' | 'newsletter' | 'service']?: boolean;
          };
        };
      };
    }
    export namespace Update {
      export type Params = {
        notification: Partial<Read.Response['notification']>;
      };
      export type Response = { status: boolean };
    }
  }
}
