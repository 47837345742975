import { ErrorHandler, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { TraceService } from '@sentry/angular';
import { ErrorHandlerService } from './error-handler.service';

@NgModule({
  providers: [
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: TraceService, deps: [Router] },
  ],
})
export class ErrorHandlerModule {}
