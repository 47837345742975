import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslationModule } from '@nibol/translation';
import { ButtonModule, CardModule, FormModule, InputModule } from '@nibol/ui';
import { RecoverPasswordFormComponent } from './recover-password-route/recover-password-form/recover-password-form.component';
import { RecoverPasswordRouteComponent } from './recover-password-route/recover-password-route.component';
import { RecoverPasswordRoutingModule } from './recover-password-routing.module';

@NgModule({
  imports: [
    ButtonModule,
    CardModule,
    CommonModule,
    FormModule,
    InputModule,
    RecoverPasswordRoutingModule,
    ReactiveFormsModule,
    TranslationModule,
  ],
  declarations: [RecoverPasswordFormComponent, RecoverPasswordRouteComponent],
})
export class RecoverPasswordModule {}
