<nib-form>
  <nib-form-title>{{ 'toggle_version_subtitle' | translate }}</nib-form-title>
  <nib-form-description>
    {{ 'toggle_version_body' | translate }}
  </nib-form-description>
  <nib-form-field>
    <nib-select
      [fullWidth]="true"
      [options]="options$ | async"
      [(ngModel)]="version"
      (onChange)="versionChange.emit(version)"
    ></nib-select>
  </nib-form-field>
</nib-form>
