import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { IconConfig } from './icon-config.type';
import { ICONS_ROOT_URL_LOCATION } from './icon-root-url-location.token';
import { Icon, Icons } from './icon.type';

/** @dynamic */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    class: 'nib-icon',
    '[attr.role]': 'role',
  },
  selector: 'nib-icon',
  styleUrls: ['icon.component.scss'],
  templateUrl: 'icon.component.html',
})
export class IconComponent {
  /** Icon css color */
  @Input() color = 'currentColor';

  /** Icon name */
  @Input() name!: Icon;

  /** Icon stroke css color */
  @Input() stroke = '';

  get role(): 'progressbar' | undefined {
    return this.name === 'progress' ? 'progressbar' : undefined;
  }

  /** List of attributes to apply to svg */
  get svgAttributes(): { [key: string]: string } {
    return {
      fill: this.color,
      stroke: this.stroke,
    };
  }

  /** Path to svg asset file */
  get svgSource(): string {
    return this.name ? `${this.iconsRootUrlLocation}${Icons[this.name]}` : '';
  }

  constructor(
    @Inject(ICONS_ROOT_URL_LOCATION)
    readonly iconsRootUrlLocation: IconConfig['iconsRootUrlLocation'],
  ) {}
}
