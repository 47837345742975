// tslint:disable: no-namespace max-classes-per-file

export namespace FeatureTogglesStateAction {
  export namespace Update {
    export class Try<FeatureToggles extends { [key: string]: boolean }> {
      static readonly type = '[FeatureToggles] try Update Feature Toggles';
      constructor(public featureToggles: FeatureToggles) {}
    }
    export class Success<FeatureToggles extends { [key: string]: boolean }> {
      static readonly type = '[FeatureToggles] then Update Feature Toggles success';
      constructor(public featureToggles: FeatureToggles) {}
    }

    export class Failure {
      static readonly type = '[FeatureToggles] catch Update Feature Toggles failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }
}
