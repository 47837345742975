import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule, MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { ButtonModule } from '../button/public-api';
import { IconModule } from '../icon/public-api';
import { ProgressBarModule } from '../progress-bar/public-api';
import { ToastDataModel } from './toast-data.model';
import { ToastComponent } from './toast.component';

@NgModule({
  entryComponents: [ToastComponent],
  declarations: [ToastComponent],
  exports: [ToastComponent],
  imports: [ButtonModule, CommonModule, IconModule, MatSnackBarModule, ProgressBarModule],
  providers: [
    {
      provide: MAT_SNACK_BAR_DATA,
      useValue: ToastDataModel,
    },
    { provide: MatSnackBarRef, useValue: MatSnackBarRef },
  ],
})
export class ToastModule {}
