import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'nib-app-shell-ghost',
  styleUrls: ['app-shell-ghost.component.scss'],
  templateUrl: 'app-shell-ghost.component.html',
})
export class AppShellGhostComponent {
  @Input() showAvatar = false;
  @Input() logo?: string;
  @Input() logoLink?: string;
}
