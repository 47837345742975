import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'nib-app-shell-menu',
  styleUrls: ['app-shell-menu.component.scss'],
  templateUrl: 'app-shell-menu.component.html',
})
export class AppShellMenuComponent {}
