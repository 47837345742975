import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxsModule } from '@ngxs/store';
import { TranslationModule } from '@nibol/translation';
import { ButtonModule, CardModule, CheckboxModule, FormModule, InputModule } from '@nibol/ui';
import { MarkdownToHtmlModule } from 'markdown-to-html-pipe';
import { TermsAndPrivacyPolicyState } from './store/terms-and-privacy-policy.state';
import { TermsAndPrivacyPolicyRouteComponent } from './terms-and-privacy-policy-route/terms-and-privacy-policy-route.component';
import { TermsAndPrivacyPolicyRoutingModule } from './terms-and-privacy-policy-routing.module';

@NgModule({
  imports: [
    ButtonModule,
    CardModule,
    CheckboxModule,
    CommonModule,
    FormModule,
    InputModule,
    MarkdownToHtmlModule,
    ReactiveFormsModule,
    TermsAndPrivacyPolicyRoutingModule,
    TranslationModule,
    NgxsModule.forFeature([TermsAndPrivacyPolicyState]),
  ],
  declarations: [TermsAndPrivacyPolicyRouteComponent],
})
export class TermsAndPrivacyPolicyModule {}
