import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { AppendAuthorizationToApiEndpointHeaderInterceptor } from './interceptors/append-authorization-to-api-endpoint-header.interceptor';
import { AppendClientToApiEndpointHeaderInterceptor } from './interceptors/append-client-to-api-endpoint-header.interceptor';
import { AppendPlatformToApiEndpointHeaderInterceptor } from './interceptors/append-platform-to-api-endpoint-header.interceptor';
import { AppendTenantToApiEndpointHeaderInterceptor } from './interceptors/append-tenant-to-api-endpoint-header.interceptor';
import { LogoutAfterJwtErrorInterceptor } from './interceptors/logout-after-expired.interceptor';
import { AuthState } from './store/auth.state';
import { PermissionsState } from './store/permissions/permissions.state';

@NgModule({
  imports: [NgxsModule.forFeature([AuthState, PermissionsState])],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppendAuthorizationToApiEndpointHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppendClientToApiEndpointHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppendPlatformToApiEndpointHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppendTenantToApiEndpointHeaderInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: LogoutAfterJwtErrorInterceptor, multi: true },
  ],
})
export class AuthModule {}
