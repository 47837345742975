<nib-app-shell
  [fullHeight]="fullHeight"
  [fullWidth]="fullWidth"
  [nibNps]="nps$ | async"
  [showHeader]="showHeader"
  [showMenu]="showMenu"
  [showTitle]="showTitle"
  [withTabs]="withTabs"
  [ngClass]="shellClasses$ | async"
>
  <nib-app-shell-logo
    [fxFlex]="100"
    [fxLayout]="'row-reverse'"
    [fxLayout.gt-sm]="'row'"
    [fxLayoutAlign]="'space-between center'"
    [fxLayoutAlign.gt-sm]="'start center'"
  >
    <a *ngIf="logo" [routerLink]="logoLink">
      <div [inlineSVG]="logo"></div>
    </a>
    <span *ngxPermissionsOnly="'admin'" class="nib-app-shell-auth-admin-label">ADMIN</span>
  </nib-app-shell-logo>
  <nib-app-shell-header>
    <nib-app-status-resume [avatar]="avatar" [scope]="scope" [user]="user">
      <ng-content></ng-content>
    </nib-app-status-resume>
  </nib-app-shell-header>
  <ng-content select="nib-app-shell-menu"></ng-content>
  <nib-app-shell-main-header
    *nibLet="title | async | translate as title"
    [class.nib-app-shell-auth-disabled]="disableHeader$ | async"
  >
    <nib-breadcrumbs
      *ngIf="breadcrumbs"
      [breadcrumbs]="breadcrumbs"
      class="nib-app-shell-auth-breadcrumbs"
    >
      <span [attr.title]="title">{{ title }}</span>
    </nib-breadcrumbs>
    <div
      [fxLayout]="'row'"
      [fxLayoutAlign]="'space-between end'"
      class="nib-app-shell-auth-title-container"
    >
      <h1 [attr.title]="title" class="nib-app-shell-auth-title">{{ title }}</h1>
      <router-outlet *ngIf="showHeaderActions$ | async" name="header-actions"></router-outlet>
    </div>
  </nib-app-shell-main-header>
  <nib-app-shell-main>
    <router-outlet></router-outlet>
  </nib-app-shell-main>
</nib-app-shell>
<nib-form-saver-snackbar></nib-form-saver-snackbar>
