import { UserProfile } from '../../../store/user/user.model';
import { LanguageSelectionFormValue } from './language-selection-form/language-selection-form-value.type';
import { PersonalInfoFormValue } from './personal-info-form/personal-info-form-value.type';

export function mapAccountProfileToStore({
  language: { language },
  personalInfo: { email, firstName, lastName, phone, phonePrefix },
}: {
  language: LanguageSelectionFormValue;
  personalInfo: PersonalInfoFormValue;
}): UserProfile {
  return {
    ...(phonePrefix || phone ? { phone: { countryCode: phonePrefix, phoneNumber: phone } } : {}),
    email,
    firstname: firstName,
    language,
    lastname: lastName,
  };
}

export function mapAccountProfileToForm({
  email,
  firstname,
  language,
  lastname,
  phone,
}: UserProfile): {
  language: LanguageSelectionFormValue;
  personalInfo: PersonalInfoFormValue;
} {
  return {
    personalInfo: {
      email,
      firstName: firstname,
      lastName: lastname,
      phone: phone?.phoneNumber || '',
      phonePrefix: phone?.countryCode || '+39',
    },
    language: {
      language,
    },
  };
}
