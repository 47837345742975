import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { PermissionsSelectors } from '@nibol/auth';
import { TranslationService } from '@nibol/translation';
import { FormValueAccessor, SelectOption } from '@nibol/ui';
import { Observable } from 'rxjs';
import { distinctUntilKeyChanged, map } from 'rxjs/operators';
import { FormSaverSnackbarService } from '../app-shell/app-shell-auth/form-saver-snackbar/form-saver-snackbar.service';
import { WifiInformationFormValue } from './wifi-information-form-value.type';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => WifiInformationFormComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WifiInformationFormComponent),
      multi: true,
    },
  ],
  selector: 'nib-wifi-information-form',
  styleUrls: ['wifi-information-form.component.scss'],
  templateUrl: 'wifi-information-form.component.html',
})
export class WifiInformationFormComponent
  extends FormValueAccessor<WifiInformationFormValue>
  implements OnInit {
  @Input() showSpeed = true;

  @Select(PermissionsSelectors.isAdmin) isAdmin$!: Observable<boolean>;

  // @todo(heavybeard): get available password types from service
  readonly passwordTypes: SelectOption[] = [
    {
      label: this.translationService.translate('inputs_text_wifi_type_drop1'),
      value: 'password',
    },
    {
      label: this.translationService.translate('inputs_text_wifi_type_drop2'),
      value: 'social',
    },
    {
      label: this.translationService.translate('inputs_text_wifi_type_drop3'),
      value: 'phone',
    },
    {
      hidden: true,
      label: this.translationService.translate('inputs_text_wifi_type_drop4'),
      value: 'code',
    },
  ];

  // @todo(heavybeard): get available wifi speeds from service
  readonly speeds: SelectOption[] = [
    {
      label: this.translationService.translate('inputs_text_wifi_speed_drop1'),
      value: 'standard',
    },
    {
      label: this.translationService.translate('inputs_text_wifi_speed_drop2'),
      value: 'fast',
    },
  ];

  form = new FormGroup({
    name: new FormControl('', Validators.required),
    password: new FormControl(''),
    // @todo(heavybeard): get default password type from service
    passwordType: new FormControl(this.passwordTypes[0].value, Validators.required),
  });

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly formSaverSnackbarService: FormSaverSnackbarService,
    private readonly translationService: TranslationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.addSpeedFormControl();
    this.manageDisablingPasswordField();
  }

  validate(_control: AbstractControl): ValidationErrors | null {
    if (!this.form.valid) {
      return { valid: false };
    }

    if (this.form.value.passwordType === 'text' && !this.form.value.password) {
      return { valid: false };
    }

    return null;
  }

  private async addSpeedFormControl(): Promise<void> {
    this.isAdmin$.pipe(untilDestroyed(this)).subscribe(isAdmin => {
      if (isAdmin && this.showSpeed) {
        // @todo(heavybeard): get default wifi speed from service
        this.form.addControl('speed', new FormControl(this.speeds[0].value, Validators.required));
      } else {
        this.form.removeControl('speed');
      }
    });
  }

  private manageDisablingPasswordField(): void {
    this.form.valueChanges
      .pipe(
        distinctUntilKeyChanged('passwordType'),
        map((values: WifiInformationFormValue) => values.passwordType),
        untilDestroyed(this),
      )
      .subscribe(passwordType => {
        const passwordFormControl = this.form.get('password');

        if (passwordType !== 'password' && passwordFormControl) {
          passwordFormControl.disable();
        } else if (passwordFormControl) {
          passwordFormControl.enable();
        }
      });

    this.formSaverSnackbarService.valueChanges$.pipe(untilDestroyed(this)).subscribe(() => {
      this.changeDetectorRef.markForCheck();
    });
  }
}
