<mat-tab-group
  [disableRipple]="true"
  [ngClass]="ngClass"
  [selectedIndex]="selectedIndex"
  (selectedIndexChange)="onTabChange.emit($event)"
  dynamicHeight
>
  <mat-tab *ngFor="let tab of tabs" [disabled]="tab.disabled" [label]="tab.label">
    <ng-container *ngTemplateOutlet="tab.content"></ng-container>
  </mat-tab>
</mat-tab-group>
