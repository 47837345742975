import { Injectable } from '@angular/core';
import { CalendarUtils } from 'angular-calendar';
import { GetWeekViewArgs, WeekView, WeekViewTimeEvent } from 'calendar-utils';
import { groupBy } from '../helpers/array-group-by.helper';

@Injectable()
export class CustomCalendarUtils extends CalendarUtils {
  private readonly percentageMargin = 5;
  private readonly percentageMax = 100;

  getWeekView(args: GetWeekViewArgs): WeekView {
    const view = super.getWeekView(args);

    view.hourColumns.forEach(({ events }) => {
      const eventsGroupByTop = groupBy<WeekViewTimeEvent, 'top'>(events, event => event.top);

      let indexOfGroupByTop = 0;
      let startOfLeft = 0;

      // tslint:disable-next-line: cyclomatic-complexity
      events.forEach((eventView, index) => {
        const isTouchingPrev =
          !!events[index - 1] && events[index - 1].top + events[index - 1].height >= eventView.top;
        const isTouchingNext =
          !!events[index + 1] && eventView.top + eventView.height > events[index + 1].top;

        eventView.event.cssClass +=
          (!isTouchingPrev && ' nib-calendar-event-container-first-in-row') || '';

        if (!isTouchingPrev && !isTouchingNext) {
          indexOfGroupByTop = 0;
          eventView.width = this.percentageMax - this.percentageMargin;
        } else {
          eventView.width =
            (this.percentageMax - (startOfLeft + this.percentageMargin)) /
            eventsGroupByTop[eventView.top].length;
          eventView.left = eventView.width * indexOfGroupByTop + startOfLeft;

          indexOfGroupByTop++;

          if (eventsGroupByTop[eventView.top].length === indexOfGroupByTop) {
            indexOfGroupByTop = 0;
            startOfLeft += this.percentageMargin;
          }
        }

        eventView.height -= 2;
      });
    });

    return view;
  }
}
