import { UserStateModel } from '@nibol/shared';

export class ReservationStateModel {
  end!: Date;
  isExternal!: boolean;
  start!: Date;
  title!: string;
  venue!: string;
  credit?: number;
  discount?: number;
  id?: string;
  isCancelled?: boolean;
  isCheckedIn?: boolean;
  isLost?: boolean;
  people?: number;
  summary?: string;
  user?: {
    firstname: UserStateModel['firstname'];
    image: string;
    language: UserStateModel['language'];
    lastname: UserStateModel['lastname'];
  };
}
