<ng-container *ngIf="couldLogIn$ | async; else loginTitle" class="nib-login-route-title">
  <nib-avatar
    *ngIf="avatar$ | async as avatar"
    [image]="avatar"
    [size]="32"
    class="nib-login-route-avatar"
  ></nib-avatar>
  <h1>{{ 'login_enterpassword' | translate }}</h1>
  <p *ngIf="isLoginWithPasswordEnabled$ | async">
    {{
      'login_passwordhelp' | translate: { date: lastPasswordChange$ | async | date: 'dd/MM/yyyy' }
    }}
  </p>
  <p *ngIf="isLoginWithSsoEnabled$ | async">
    {{ 'login_ssohelp' | translate }}
  </p>
</ng-container>
<ng-template #loginTitle>
  <h1>{{ 'login_title' | translate }}</h1>
  <p>{{ 'login_body' | translate }}</p>
</ng-template>
<form [formGroup]="form" (ngSubmit)="login()">
  <nib-login-form [formControlName]="'login'"></nib-login-form>
</form>
