import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { AuthStateAction } from '@nibol/auth';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nib-logout-form',
  styleUrls: ['logout-form.component.scss'],
  templateUrl: 'logout-form.component.html',
})
export class LogoutFormComponent {
  @Dispatch() logout = () => new AuthStateAction.Logout.Try();

  doLogout() {
    this.logout();
  }
}
