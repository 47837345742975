import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  Validators as AngularValidators,
} from '@angular/forms';

export class Validators {
  static emails(control: AbstractControl): ValidationErrors | null {
    if (
      control.value === '' ||
      !control.value ||
      (control.value as string)
        .replace(/\s/g, '')
        .split(',')
        .map(email => AngularValidators.email(new FormControl(email)))
        .find(validationError => validationError !== null) === undefined
    ) {
      return null;
    }
    return { emails: true };
  }
}
