import { Component, Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router } from '@angular/router';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { AppStateAction } from '@nibol/store';

@Injectable({ providedIn: 'root' })
export class AccessDeniedGuard implements CanActivate, CanDeactivate<Component> {
  @Dispatch() disableNotifications = () => new AppStateAction.Notifications.Change.Try(false);
  @Dispatch() enableNotifications = () => new AppStateAction.Notifications.Change.Try(true);

  constructor(private readonly router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const emailQueryParam = route.queryParamMap.get('email') || '';
    const errorType = route.queryParamMap.get('error');
    const isNotValidEmail =
      emailQueryParam.length === 0 || Validators.email(new FormControl(emailQueryParam))?.email;

    this.disableNotifications();

    if (isNotValidEmail || errorType?.length === 0) {
      this.router.navigate(['login']);

      return false;
    }

    return true;
  }

  canDeactivate(): boolean {
    this.enableNotifications();

    return true;
  }
}
