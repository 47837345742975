import { TranslationService } from '@nibol/translation';
import { ImageManagerTranslationsConfig } from '@nibol/ui';

export function imageManagerTranslationsFactory(
  translationService: TranslationService,
): ImageManagerTranslationsConfig {
  return {
    caption: translationService.translate('space_photo_cover'),
  };
}
