<nib-version-switcher
  [version]="currentVersion$ | async"
  (versionChange)="changeCurrentVersion($event)"
  class="nib-section"
>
</nib-version-switcher>
<nib-feature-toggles-list
  [featureToggles]="featureToggles$ | async"
  (onChangeFeatureToggleValue)="onChangeFeatureToggleValue($event)"
  class="nib-section"
></nib-feature-toggles-list>
