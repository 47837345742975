// tslint:disable: no-namespace max-classes-per-file

import { BuildingStateModel } from '../building';
import { VenueStateModel } from '../venue';

export namespace VenuesStateAction {
  export namespace List {
    export namespace Read {
      export class Try {
        static readonly type = '[Venues] try Read Venues';
        constructor(public buildingId: BuildingStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Venues] then Read Venues success';
        constructor(
          public venues: VenueStateModel[],
          public buildingId: BuildingStateModel['id'],
        ) {}
      }
      export class Failure {
        static readonly type = '[Venues] catch Read Venues failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Remove {
      export class Try {
        static readonly type = '[Venues] try Remove Venue from List';
        constructor(public id: VenueStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Venues] then Remove Venue from List success';
        constructor(
          public venues: VenueStateModel[],
          public buildingId: BuildingStateModel['id'],
        ) {}
      }
      export class Failure {
        static readonly type = '[Venues] catch Remove Venue from List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Venues] try Update Venues List';
        constructor(public venue: VenueStateModel) {}
      }
      export class Success {
        static readonly type = '[Venues] then Update Venues List success';
        constructor(
          public venues: VenueStateModel[],
          public buildingId: BuildingStateModel['id'],
        ) {}
      }
      export class Failure {
        static readonly type = '[Venues] catch Update Venues List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }
}
