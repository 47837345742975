import { MapComponent } from './map.component';

export function mapMarkersToObject(markers: MapComponent['markers']) {
  return markers.reduce(
    (
      newMarkers: { [key: string]: MapComponent['markers'][number] },
      marker: MapComponent['markers'][number],
    ) => Object.assign(newMarkers, { [`${marker.x}.${marker.y}`]: marker }),
    {},
  );
}

export function mapPolygonsToObject(polygons: MapComponent['polygons']) {
  return polygons.reduce(
    (
      newPolygons: { [key: string]: MapComponent['polygons'][number] },
      polygon: MapComponent['polygons'][number],
    ) => Object.assign(newPolygons, { [`${polygon.points[0].x}.${polygon.points[0].y}`]: polygon }),
    {},
  );
}

export function syncPathElementToFirstPolygonTemplateElement(
  pathElement: SVGPathElement,
  polygonTemplateElement: HTMLElement,
): void {
  pathElement.classList.value
    .split(' ')
    .filter(className => !className.startsWith('leaflet-'))
    .forEach(pathElementClassToRemove => {
      pathElement.classList.remove(pathElementClassToRemove);
    });

  polygonTemplateElement.children[0].classList.forEach(className => {
    pathElement.classList.add(className);
  });
}

export function getFirstPolygonTemplateElementMutationObserver(
  pathElement: SVGPathElement,
  polygonTemplateElement: HTMLElement,
): MutationObserver {
  const mutationObserver = new MutationObserver(mutations => {
    mutations.forEach(() => {
      syncPathElementToFirstPolygonTemplateElement(pathElement, polygonTemplateElement);
    });
  });

  mutationObserver.observe(polygonTemplateElement.children[0], {
    attributeFilter: ['class'],
  });

  return mutationObserver;
}
