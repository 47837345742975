import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ContentChildren,
  Input,
  QueryList,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import {
  MatColumnDef,
  MatHeaderRowDef,
  MatRowDef,
  MatTable,
  MatTableDataSource,
} from '@angular/material/table';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    class: 'nib-table',
  },
  selector: 'nib-table',
  styleUrls: ['table.component.scss'],
  templateUrl: 'table.component.html',
})
export class TableComponent<T> implements AfterContentInit {
  @Input() dataSource!: MatTableDataSource<T>;

  @ContentChild(MatPaginator) paginator?: MatPaginator;

  @ContentChildren(MatHeaderRowDef) headerRowDefs = new QueryList<MatHeaderRowDef>();
  @ContentChildren(MatRowDef) rowDefs = new QueryList<MatRowDef<T>>();
  @ContentChildren(MatColumnDef) columnDefs = new QueryList<MatColumnDef>();

  @ViewChild(MatTable, { static: true }) table!: MatTable<T>;

  get columns(): string[] {
    return this.columnDefs.map(columnDef => columnDef.name);
  }

  ngAfterContentInit(): void {
    this.addColumns();
    this.addHeaderRows();
    this.addRows();
  }

  private addColumns(): void {
    this.columnDefs.forEach(columnDef => {
      this.table.addColumnDef(columnDef);
    });
  }

  private addHeaderRows(): void {
    this.headerRowDefs.forEach(headerRowDef => {
      this.table.addHeaderRowDef(headerRowDef);
    });
  }

  private addRows(): void {
    this.rowDefs.forEach(rowDef => {
      this.table.addRowDef(rowDef);
    });
  }
}
