<ng-container [formGroup]="form">
  <div [fxLayout]="'column'" [fxLayoutGap]="'16px'">
    <div
      [fxLayout]="'column'"
      [fxLayout.gt-xs]="'row'"
      [fxLayoutGap]="'16px'"
      class="nib-singup-form-generalities"
    >
      <nib-form-field [fxFlex]="100" [fxFlex.gt-xs]="50">
        <nib-input
          [autocomplete]="'given-name'"
          [formControlName]="'firstname'"
          [fullWidth]="true"
          [label]="'inputs_text_firstname_label' | translate"
        ></nib-input>
      </nib-form-field>
      <nib-form-field [fxFlex]="100" [fxFlex.gt-xs]="50">
        <nib-input
          [autocomplete]="'family-name'"
          [formControlName]="'lastname'"
          [fullWidth]="true"
          [label]="'inputs_text_lastname_label' | translate"
        ></nib-input>
      </nib-form-field>
    </div>
  </div>
  <nib-form-field>
    <nib-input
      [formControlName]="'buildingName'"
      [fullWidth]="true"
      [id]="'buildingName'"
      [label]="'inputs_text_venue_name_label' | translate"
    ></nib-input>
  </nib-form-field>
  <nib-form-field>
    <nib-input
      [autocomplete]="'email'"
      [formControlName]="'username'"
      [fullWidth]="true"
      [label]="'inputs_text_email_label' | translate"
      [type]="'email'"
    ></nib-input>
  </nib-form-field>
  <nib-form-field [hint]="'inputs_text_psw_caption' | translate">
    <nib-input
      [formControlName]="'password'"
      [fullWidth]="true"
      [label]="'inputs_text_psw_label' | translate"
      [type]="'password'"
      [visibilityToggle]="true"
    ></nib-input>
  </nib-form-field>
  <div
    [innerHTML]="'signup_agreecontinue_text' | translate"
    class="nib-signup-form-disclaimer nib-heading-5"
  ></div>
  <nib-form-action>
    <nib-button
      [color]="'primary'"
      [disabled]="!form.valid || disabled"
      [fullWidth]="true"
      [submit]="true"
    >
      {{ 'signup_agreecontinue_button' | translate }}
    </nib-button>
  </nib-form-action>
</ng-container>
