import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Size, Sizes } from '../core/sizes';
import { ProxyValueAccessor } from '../form/proxy-value-accessor';
import { SelectOption } from './select-option.type';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    class: 'nib-select',
  },
  providers: [
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
    },
    {
      multi: true,
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectComponent),
    },
  ],
  selector: 'nib-select',
  styleUrls: ['select.component.scss'],
  templateUrl: 'select.component.html',
})
export class SelectComponent extends ProxyValueAccessor implements AfterViewInit {
  /** Whether select should be disabled. */
  @Input()
  get disabled(): boolean {
    return this.proxiedControl.disabled;
  }
  set disabled(disabled: boolean) {
    if (coerceBooleanProperty(disabled)) {
      this.proxiedControl.disable();
    } else {
      this.proxiedControl.enable();
    }
  }

  /** Form control name. */
  @Input() formControlName = '';

  /** Whether the select should be in full-width mode. */
  @Input() fullWidth = false;

  /** Select hint. */
  @Input() hint = '';

  /** Select id and name. */
  @Input() id = '';

  /** Select label. */
  @Input() label = '';

  /** Options to show. */
  @Input()
  get options(): SelectOption[] {
    return this._options;
  }
  set options(value: SelectOption[]) {
    const selectedOption = value.find(option => option.selected);

    if (this.placeholder !== null) {
      value.unshift(this.placeholder);
    }

    if (selectedOption) {
      this.proxiedControl.setValue(selectedOption.value);
    }

    this._options = value;
  }
  private _options: SelectOption[] = [];

  /** Option used as a placeholder. */
  @Input() placeholder: SelectOption | null = null;

  /** Whether the select should be required. */
  @Input() required = false;

  /** Define the size of the select. */
  @Input() size: Size = Sizes.md;

  /** Tabindex to set to element. */
  @Input() tabIndex?: number;

  /** Select value. */
  @Input()
  get value(): string | number {
    return this.proxiedControl.value;
  }

  @Output() onChange = new EventEmitter<{ value: SelectOption['value'] }>();

  /** Class to apply to select. */
  get ngClass(): { [key: string]: boolean } {
    return {
      [`nib-select-${this.size}`]: true,
      'nib-select-disabled': this.disabled,
      'nib-select-full-width': this.fullWidth,
    };
  }

  proxiedControl = new FormControl();

  ngAfterViewInit(): void {
    this.writeValue(this.proxiedControl.value);
  }

  trackByOptionValue(_index: number, item: SelectOption): string {
    return item.value;
  }
}
