import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxFeatureToggleCanActivateGuard } from 'ngx-feature-toggle';
import { SignupFooterContentComponent } from './signup-route/signup-footer-content/signup-footer-content.component';
import { SignupRouteComponent } from './signup-route/signup-route.component';

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forChild([
      {
        // root is '/signup'
        path: '',
        canActivate: [NgxFeatureToggleCanActivateGuard],
        data: { featureToggle: ['TOGGLE_ENABLE_SIGNUP'], title: 'signup_title' },
        children: [
          { path: '', component: SignupRouteComponent },
          { path: '', component: SignupFooterContentComponent, outlet: 'footer-content' },
        ],
      },
    ]),
  ],
})
export class SignupRoutingModule {}
