import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { BehaviorSubject } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { ProxyValueAccessor } from '../form/proxy-value-accessor';
import { SelectOption } from '../select/select-option.type';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  // tslint:disable-next-line: no-host-metadata-property
  host: { class: 'nib-multi-select' },
  providers: [
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectComponent),
    },
    {
      multi: true,
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MultiSelectComponent),
    },
  ],
  selector: 'nib-multi-select',
  styleUrls: ['../input/input.component.scss', 'multi-select.component.scss'],
  templateUrl: 'multi-select.component.html',
})
export class MultiSelectComponent extends ProxyValueAccessor implements OnChanges {
  /** Whether the control should accept custom values */
  @Input() isCustomValuesAccepted = false;

  /** Form control name. */
  @Input() formControlName = '';

  /** Input id and name. */
  @Input() id = '';

  /** Multi select label. */
  @Input() label = '';

  /** Max amount of items to be selected. 0 = infinite */
  @Input() maxSelectedItems = 0;

  /** Options to show. */
  @Input() options: SelectOption[] = [];

  /** Option used as a placeholder. */
  @Input() placeholder: string | null = null;

  @Output() onChange = new EventEmitter<{ value: SelectOption['value'] }>();
  @Output() onTyping = new EventEmitter<string>();

  @ViewChild('valueInput') valueInput!: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete!: MatAutocomplete;

  optionsHeight = '35px';

  /** The control with list of selected values */
  readonly proxiedControl = new FormControl('');

  /** The control for managing field */
  readonly typingControl = new FormControl('');

  /** List of selected values */
  selectedValues$ = new BehaviorSubject<SelectOption[]>([]);

  /** List of key codes valid to separate values */
  readonly separatorKeysCodes = [ENTER, COMMA];

  /** Filtered values */
  // This must be after the typingControl declaration
  readonly filteredOptions$ = this.typingControl.valueChanges.pipe(
    startWith(''),
    map((search: string) => {
      if (search) {
        return [
          ...(this.isCustomValuesAccepted ? [{ label: search, value: search }] : []),
          ...this.getFilteredOptions(search),
        ];
      }

      return this.getProcessedOptions();
    }),
    tap(filteredOptions => {
      this.optionsHeight =
        filteredOptions.length < 4 ? `${filteredOptions.length * 35}px` : `${4 * 35}px`;
    }),
  );

  get hasNotReachedMaxSelectedValues(): boolean {
    return (
      this.maxSelectedItems === 0 || this.selectedValues$.getValue().length < this.maxSelectedItems
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('options' in changes) {
      this.typingControl.updateValueAndValidity();
    }
  }

  add(event: MatChipInputEvent): void {
    if (this.isCustomValuesAccepted) {
      const value = (event.value || '').trim();

      if (value) {
        this.selectedValues$.next([...this.selectedValues$.getValue(), { label: value, value }]);
        this.proxiedControl.setValue(
          this.selectedValues$
            .getValue()
            .map(selectedValue => selectedValue.value)
            .join(','),
        );
      }

      if (event.input) {
        event.input.value = '';
      }

      this.typingControl.setValue('');
    }
  }

  remove(valueToRemove: SelectOption): void {
    const index = this.selectedValues$
      .getValue()
      .findIndex(({ value }) => value === valueToRemove.value);

    if (index >= 0) {
      this.selectedValues$.next(
        this.selectedValues$.getValue().filter((_value, valueIndex) => index !== valueIndex),
      );
      this.proxiedControl.setValue(
        this.selectedValues$
          .getValue()
          .map(selectedValue => selectedValue.value)
          .join(','),
      );
    }

    this.typingControl.setValue('');
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedValues$.next([
      ...this.selectedValues$.getValue(),
      {
        label: event.option.viewValue,
        value: event.option.value,
      },
    ]);
    this.proxiedControl.setValue(
      this.selectedValues$
        .getValue()
        .map(selectedValue => selectedValue.value)
        .join(','),
    );

    this.valueInput.nativeElement.value = '';
    this.typingControl.setValue(null);
  }

  writeValue(value: string | null) {
    const values = value?.split(',') ?? [];

    this.selectedValues$.next([]);

    if (values.length === 0) {
      this.typingControl.setValue(null);
    } else {
      values.forEach(splittedValue => {
        const selectedValue = this.options.find(option => option.value === splittedValue);

        if (selectedValue) {
          this.selectedValues$.next([...this.selectedValues$.getValue(), selectedValue]);
        } else if (this.isCustomValuesAccepted && splittedValue !== '') {
          const customValue = { label: splittedValue, value: splittedValue };

          this.options.push(customValue);
          this.selectedValues$.next([...this.selectedValues$.getValue(), customValue]);
        }
      });
    }

    super.writeValue(value);
  }

  private getFilteredOptions(value: string): SelectOption[] {
    return this.options
      .filter(option =>
        [option.value, option.label].some(valueWhereSearch =>
          valueWhereSearch.toLowerCase().includes(value.toLowerCase()),
        ),
      )
      .map(option => ({
        ...option,
        disabled: this.selectedValues$
          .getValue()
          .map(selectedValue => selectedValue.value)
          .includes(option.value),
      }));
  }

  private getProcessedOptions(): SelectOption[] {
    return this.options.map(option => ({
      ...option,
      disabled: this.selectedValues$
        .getValue()
        .some(selectedValue => selectedValue.value.toLowerCase() === option.value.toLowerCase()),
    }));
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return control.valid ? null : control.errors;
  }
}
