<feature-toggle-provider [features]="featuretoggles$ | async">
  <ng-container *ngIf="isInProgress$ | async; else appShell">
    <nib-app-shell-ghost [logo]="logo"></nib-app-shell-ghost>
  </ng-container>
  <ng-template #appShell>
    <nib-app-shell-wizard
      *ngIf="isWizard$ | async; else appShellAuth"
      [completionPercentage]="completionPercentage$ | async"
      [logo]="logo"
    ></nib-app-shell-wizard>
    <ng-template #appShellAuth>
      <nib-app-shell-auth
        *ngIf="isAuthenticated$ | async; else appShellPublic"
        [breadcrumbs]="breadcrumbs$ | async"
        [fullHeight]="fullHeight$ | async"
        [fullWidth]="fullWidth$ | async"
        [logo]="logo"
        [logoLink]="homepage"
        [scope]="buildingFullname$ | async"
        [showHeader]="showHeader$ | async"
        [showMenu]="showMenu$ | async"
        [showTitle]="showTitle$ | async"
        [title]="title$ | async"
        [user]="userFullname$ | async"
        [withTabs]="withTabs$ | async"
      >
        <ng-container *nibIfBreakpointIs="'gt-sm'">
          <mkt-change-building class="mkt-app-shell-change-building"></mkt-change-building>
        </ng-container>
        <nib-app-shell-menu>
          <nib-app-shell-menu-item [to]="homepage">
            <nib-icon [name]="'reservations-received'"></nib-icon>
            {{ 'calendar_title' | translate }}
            <nib-chip [color]="'informative'" position="end">{{
              reservationsAmount$ | async
            }}</nib-chip>
          </nib-app-shell-menu-item>
          <ng-container *ngxPermissionsOnly="['admin', 'manager']">
            <nib-app-shell-menu-item
              *featureToggle="'TOGGLE_ENABLE_RESERVATIONS_LIST'"
              [to]="'/reservations-list'"
            >
              <nib-icon [name]="'connections-list'"></nib-icon>
              {{ 'reservationslist_title' | translate }}
            </nib-app-shell-menu-item>
            <nib-app-shell-menu-item
              *featureToggle="'!TOGGLE_ENABLE_RESERVATIONS_LIST'"
              [disabled]="true"
            >
              <nib-icon [name]="'connections-list'"></nib-icon>
              {{ 'reservationslist_title' | translate }}
            </nib-app-shell-menu-item>
          </ng-container>
          <ng-container *featureToggle="'TOGGLE_SHOW_ANALYTICS'">
            <nib-app-shell-menu-item *featureToggle="'TOGGLE_ENABLE_ANALYTICS'" [to]="'/analytics'">
              <nib-icon [name]="'analytics'"></nib-icon> {{ 'analytics_title' | translate }}
            </nib-app-shell-menu-item>
            <nib-app-shell-menu-item *featureToggle="'!TOGGLE_ENABLE_ANALYTICS'" [disabled]="true">
              <nib-icon [name]="'analytics'"></nib-icon> {{ 'analytics_title' | translate }}
            </nib-app-shell-menu-item>
          </ng-container>
          <nib-app-shell-menu-divider>
            {{ 'main_sidebar_services' | translate }}
          </nib-app-shell-menu-divider>
          <nib-app-shell-menu-item
            *ngFor="let space of spaces$ | async; trackBy: trackByVenueId"
            [to]="['/spaces', space.id]"
          >
            <mkt-space-icon
              [mapFilter]="space.mapFilter"
              [nibAppShellExplodeMenuItem]
              [nibAppShellMenuItemPrefix]
            ></mkt-space-icon>
            <div [nibAppShellExplodeMenuItem]>{{ space.name }}</div>
            <mkt-space-status
              [nibAppShellExplodeMenuItem]
              [status]="space.status"
              position="end"
            ></mkt-space-status>
            <nib-app-shell-menu-item [to]="['/spaces', space.id, 'name-and-address']">
              {{ 'sharedspaces_nameaddress_title' | translate }}
            </nib-app-shell-menu-item>
            <nib-app-shell-menu-item [to]="['/spaces', space.id, 'seats-and-amenities']">
              {{ 'sharedspaces_seatsamenities_title' | translate }}
            </nib-app-shell-menu-item>
            <ng-container *ngxPermissionsOnly="['admin', 'manager']">
              <nib-app-shell-menu-item
                *ngIf="space.mode === 'private' || space.type === 'coworking'"
                [to]="['/spaces', space.id, 'price-and-times']"
              >
                {{ 'privatespaces_pricehours_title' | translate }}
              </nib-app-shell-menu-item>
              <nib-app-shell-menu-item
                *ngIf="space.mode === 'shared' && space.type !== 'coworking'"
                [to]="['/spaces', space.id, 'work-hours']"
              >
                {{ 'sharedspaces_workhours_title' | translate }}
              </nib-app-shell-menu-item>
            </ng-container>
            <nib-app-shell-menu-item [to]="['/spaces', space.id, 'settings']">
              {{ 'sharedspaces_settings_title' | translate }}
            </nib-app-shell-menu-item>
            <nib-app-shell-menu-item
              *featureToggle="'TOGGLE_ENABLE_PHOTOS'"
              [to]="['/spaces', space.id, 'photos']"
            >
              {{ 'sharedspaces_photos_title' | translate }}
            </nib-app-shell-menu-item>
            <nib-app-shell-menu-item *featureToggle="'!TOGGLE_ENABLE_PHOTOS'" [disabled]="true">
              {{ 'sharedspaces_photos_title' | translate }}
            </nib-app-shell-menu-item>
            <nib-app-shell-menu-item
              *ngxPermissionsOnly="['admin', 'manager']"
              [to]="['/spaces', space.id, 'special-closing-times']"
            >
              {{ 'sharedspaces_specialclose_title' | translate }}
            </nib-app-shell-menu-item>
            <ng-container *ngxPermissionsOnly="['admin', 'manager']">
              <nib-app-shell-menu-item
                *featureToggle="'TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR'"
                [to]="['/spaces', space.id, 'calendar-import-export']"
              >
                {{ 'sharedspaces_importexportcalendar_title' | translate }}
              </nib-app-shell-menu-item>
              <nib-app-shell-menu-item
                *featureToggle="'!TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR'"
                [disabled]="true"
              >
                {{ 'sharedspaces_importexportcalendar_title' | translate }}
              </nib-app-shell-menu-item>
            </ng-container>
          </nib-app-shell-menu-item>

          <nib-app-shell-menu-divider></nib-app-shell-menu-divider>
          <nib-app-shell-menu-item [to]="['/add-new-venue/space/mode']">
            <nib-icon [name]="'plus-small'"></nib-icon>
            {{ 'space_addspace_title' | translate }}
          </nib-app-shell-menu-item>

          <ng-container *featureToggle="'TOGGLE_SHOW_PAYMENTS'">
            <nib-app-shell-menu-divider>
              {{ 'main_sidebar_payments' | translate }}
            </nib-app-shell-menu-divider>
            <nib-app-shell-menu-item
              *featureToggle="'TOGGLE_ENABLE_INCOMES'"
              [to]="'/transfers/incomes'"
            >
              <nib-icon [name]="'payments-in'"></nib-icon> {{ 'paymentsinc_title' | translate }}
            </nib-app-shell-menu-item>
            <nib-app-shell-menu-item *featureToggle="'!TOGGLE_ENABLE_INCOMES'" [disabled]="true">
              <nib-icon [name]="'payments-in'"></nib-icon> {{ 'paymentsinc_title' | translate }}
            </nib-app-shell-menu-item>
            <nib-app-shell-menu-item
              *featureToggle="'TOGGLE_ENABLE_PAYMENTS'"
              [to]="'/transfers/payments'"
            >
              <nib-icon [name]="'payments-out'"></nib-icon> {{ 'paymentsout_title' | translate }}
            </nib-app-shell-menu-item>
            <nib-app-shell-menu-item *featureToggle="'!TOGGLE_ENABLE_PAYMENTS'" [disabled]="true">
              <nib-icon [name]="'payments-out'"></nib-icon> {{ 'paymentsout_title' | translate }}
            </nib-app-shell-menu-item>
          </ng-container>
          <ng-container *ngxPermissionsOnly="'admin'">
            <nib-app-shell-menu-divider>
              {{ 'main_sidebar_admin' | translate }}
            </nib-app-shell-menu-divider>
            <nib-app-shell-menu-item [to]="'/admin/demo'">
              <nib-icon [name]="'settings'"></nib-icon> {{ 'demo_title' | translate }}
            </nib-app-shell-menu-item>
            <nib-app-shell-menu-item [to]="'/admin/enable-features'">
              <nib-icon [name]="'feature-toggle'"></nib-icon>
              {{ 'toggle_title' | translate }}
            </nib-app-shell-menu-item>
            <nib-app-shell-menu-item
              *featureToggle="'TOGGLE_ENABLE_CREATE_NEW_VENUE'"
              [to]="'/admin/create-venue'"
            >
              <nib-icon [name]="'cafe-add'"></nib-icon> {{ 'createvenue_title' | translate }}
            </nib-app-shell-menu-item>
          </ng-container>

          <nib-app-shell-menu-divider
            *nibIfBreakpointIs="'!gt-sm'"
            class="mkt-app-shell-menu-change-building"
            position="end"
          >
            <mkt-change-building></mkt-change-building>
          </nib-app-shell-menu-divider>
          <nib-app-shell-menu-item
            *featureToggle="'TOGGLE_ENABLE_HELP'"
            [withPrefix]="false"
            (onClick)="openHelpCenterOnNewTab()"
            position="end"
          >
            {{ 'main_sidebar_helpcenter' | translate }}
            <nib-icon [name]="'external-link'" position="end"></nib-icon>
          </nib-app-shell-menu-item>
          <nib-app-shell-menu-item
            *featureToggle="'!TOGGLE_ENABLE_HELP'"
            [disabled]="true"
            [withPrefix]="false"
            position="end"
          >
            {{ 'main_sidebar_helpcenter' | translate }}
            <nib-icon [name]="'external-link'" position="end"></nib-icon>
          </nib-app-shell-menu-item>
          <nib-app-shell-menu-item
            *ngxPermissionsOnly="['admin', 'manager']"
            [to]="'/billing'"
            [withPrefix]="false"
            position="end"
          >
            {{ 'company_billing_title' | translate }}
          </nib-app-shell-menu-item>
          <nib-app-shell-menu-item [to]="'/settings'" [withPrefix]="false" position="end">
            {{ 'settings_settings_title' | translate }}
            <span class="mkt-app-shell-version" position="end">v{{ version$ | async }}</span>
          </nib-app-shell-menu-item>
        </nib-app-shell-menu>
      </nib-app-shell-auth>
      <ng-template #appShellPublic>
        <nib-app-shell-public [logo]="logo"></nib-app-shell-public>
      </ng-template>
    </ng-template>
  </ng-template>
</feature-toggle-provider>
