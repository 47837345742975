import { CalendarEventTitleFormatter } from 'angular-calendar';

export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {
  dayTooltip(): string {
    return '';
  }

  weekTooltip(): string {
    return '';
  }
}
