import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { AppShellService } from '../app-shell.service';

// tslint:disable-next-line: validate-decorators
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'nib-app-shell-menu-item',
    '[class.nib-app-shell-menu-item-disabled]': 'disabled',
  },
  selector: 'nib-app-shell-menu-item',
  styleUrls: ['app-shell-menu-item.component.scss'],
  templateUrl: 'app-shell-menu-item.component.html',
})
export class AppShellMenuItemComponent {
  /** Whether the item should do nothing */
  @Input() disabled = false;

  /** Path of the router. */
  @Input() to: string | string[] = '';

  /** Whether should show prefix. */
  @Input() withPrefix = true;

  /** Click emitter. */
  @Output() onClick = new EventEmitter<Event>();

  get hasClickObservers(): boolean {
    return this.onClick.observers.length > 0;
  }

  constructor(readonly appShellService: AppShellService) {}
}
