import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_ROOT_URL } from '@nibol/auth';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Venue } from './venue.type';

@Injectable({ providedIn: 'root' })
export class VenueService {
  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  createImportedCalendar({
    id,
    calendar,
  }: Venue.ImportedCalendar.Create.Params): Observable<Venue.ImportedCalendar.Create.Response> {
    return this.httpClient
      .post<Venue.ImportedCalendar.Create.Response>(
        `${this.apiRootUrl}/venue/importCalendars/add`,
        { id, calendar },
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  delete({ id }: Venue.Item.Delete.Params): Observable<Venue.Item.Delete.Response> {
    return this.httpClient
      .request<Venue.Item.Delete.Response>('delete', `${this.apiRootUrl}/venue`, {
        body: { id },
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  deleteImportedCalendar({
    calendar,
    id,
  }: Venue.ImportedCalendar.Delete.Params): Observable<Venue.ImportedCalendar.Delete.Response> {
    return this.httpClient
      .post<Venue.ImportedCalendar.Delete.Response>(
        `${this.apiRootUrl}/venue/importCalendars/remove`,
        { calendar, id },
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  readCreatingStatus({
    id,
  }: Venue.CreatingStatus.Read.Params): Observable<Venue.CreatingStatus.Read.Response> {
    return this.httpClient
      .get<Venue.CreatingStatus.Read.Response>(`${this.apiRootUrl}/venue/creationStatus`, {
        params: { id },
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  readExportCalendar({
    id,
  }: Venue.ExportCalendar.Read.Params): Observable<Venue.ExportCalendar.Read.Response> {
    return this.httpClient
      .get<Venue.ExportCalendar.Read.Response>(`${this.apiRootUrl}/venue/exportCalendar`, {
        params: { id },
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  readImportedCalendars({
    id,
  }: Venue.ImportedCalendar.Read.Params): Observable<Venue.ImportedCalendar.Read.Response> {
    return this.httpClient
      .get<Venue.ImportedCalendar.Read.Response>(`${this.apiRootUrl}/venue/importCalendars`, {
        params: { id },
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  readNameAndAddress({
    id,
  }: Venue.NameAndAddress.Read.Params): Observable<Venue.NameAndAddress.Read.Response> {
    return this.httpClient
      .get<Venue.NameAndAddress.Read.Response>(`${this.apiRootUrl}/venue/base`, { params: { id } })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  readPhotos({ id }: Venue.Photos.Read.Params): Observable<Venue.Photos.Read.Response> {
    return this.httpClient
      .get<Venue.Photos.Read.Response>(`${this.apiRootUrl}/venue/pictures`, { params: { id } })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  readSeatsAndAmenities({
    id,
  }: Venue.SeatsAndAmenities.Read.Params): Observable<Venue.SeatsAndAmenities.Read.Response> {
    return this.httpClient
      .get<Venue.SeatsAndAmenities.Read.Response>(`${this.apiRootUrl}/venue/services`, {
        params: { id },
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  readSettings({ id }: Venue.Settings.Read.Params): Observable<Venue.Settings.Read.Response> {
    return this.httpClient
      .get<Venue.Settings.Read.Response>(`${this.apiRootUrl}/venue/settings`, { params: { id } })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  readSpecialClosingTimes({
    id,
  }: Venue.SpecialClosingTimes.Read.Params): Observable<Venue.SpecialClosingTimes.Read.Response> {
    return this.httpClient
      .get<Venue.SpecialClosingTimes.Read.Response>(`${this.apiRootUrl}/venue/closings`, {
        params: { id },
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  readWorkHours({
    id,
    time_mode,
  }: Venue.WorkHours.Read.Params): Observable<
    Venue.WorkHours.Read.Response & { time_mode: typeof time_mode }
  > {
    return this.httpClient
      .get<Venue.WorkHours.Read.Response & { time_mode: typeof time_mode }>(
        `${this.apiRootUrl}/venue/hours/work`,
        {
          params: { ...(time_mode && { time_mode }), id },
        },
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  requestApproval({
    id,
  }: Venue.RequestApproval.Params): Observable<Venue.RequestApproval.Response> {
    return this.httpClient
      .post<Venue.RequestApproval.Response>(`${this.apiRootUrl}/venue/requestApproval`, { id })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  update({ data, id }: Venue.Item.Update.Params): Observable<Venue.Item.Update.Response> {
    return this.httpClient
      .put<Venue.Item.Update.Response>(`${this.apiRootUrl}/venue`, { ...data, id })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  updateImportedCalendar({
    calendar,
    id,
  }: Venue.ImportedCalendar.Update.Params): Observable<Venue.ImportedCalendar.Update.Response> {
    return this.httpClient
      .post<Venue.ImportedCalendar.Update.Response>(
        `${this.apiRootUrl}/venue/importCalendars/health`,
        { calendar, id },
      )
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }
}
