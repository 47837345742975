<ng-container *ngIf="isRecoverPasswordRequested$ | async; else recoverPasswordForm">
  <h1>{{ 'recoverpassword_title' | translate }}</h1>
  <p>{{ 'recoverpassword_waitingforemail' | translate }}</p>
</ng-container>
<ng-template #recoverPasswordForm>
  <h1>{{ 'recoverpassword_title' | translate }}</h1>
  <form [formGroup]="form" (ngSubmit)="recoverPassword()">
    <nib-recover-password-form
      [disabled]="isRecoverPasswordFormDisabled$ | async"
      [formControlName]="'recoverPassword'"
    ></nib-recover-password-form>
  </form>
</ng-template>
