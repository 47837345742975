import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { AuthStateAction } from '@nibol/auth';
import { TermsAndPrivacyPolicyModel } from './terms-and-privacy-policy.model';

@State<TermsAndPrivacyPolicyModel>({
  name: 'termsandprivacypolicy',
})
@Injectable()
export class TermsAndPrivacyPolicyState {
  @Action(AuthStateAction.TermsAndPrivacyPolicy.Read.Success)
  readSuccess(
    { setState }: StateContext<TermsAndPrivacyPolicyModel>,
    { response }: AuthStateAction.TermsAndPrivacyPolicy.Read.Success,
  ) {
    setState(response);
  }
}
