import { Selector } from '@ngxs/store';
import { AppStateModel } from './app.model';
import { AppState } from './app.state';

export class AppSelectors {
  @Selector([AppState])
  static client({ client }: AppStateModel): AppStateModel['client'] {
    return client;
  }

  @Selector([AppState])
  static notifications({ notifications }: AppStateModel): AppStateModel['notifications'] {
    return notifications;
  }

  @Selector([AppState])
  static platform({ platform }: AppStateModel): AppStateModel['platform'] {
    return platform;
  }

  @Selector([AppState])
  static state({ state }: AppStateModel): AppStateModel['state'] {
    return state;
  }

  @Selector([AppState])
  static supportContacts({ supportContacts }: AppStateModel): AppStateModel['supportContacts'] {
    return supportContacts;
  }
}
