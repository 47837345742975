import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppShellModule } from '@marketplace/components/app-shell';
import { environment } from '@marketplace/environment';
import { versions } from '@marketplace/feature-toggles';
import { states } from '@marketplace/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { SharedModule } from '@nibol/shared';
import { registerLocalesData } from '@nibol/translation';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

registerLocalesData();

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    AppShellModule,
    CommonModule,
    SharedModule.forRoot({ environment, versions }),
    NgxsModule.forRoot(states, { developmentMode: !environment.production }),
    NgxsStoragePluginModule.forRoot({
      key: ['auth.token', 'currentbuilding', 'translation.currentLanguage'],
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
