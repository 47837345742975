<section [formGroup]="form" [fxLayout]="'column'" [fxLayoutGap]="'16px'">
  <nib-form-field [fxFlex]="100">
    <nib-switch [formControlName]="'availability'" [label]="weekdayName"></nib-switch>
  </nib-form-field>
  <nib-card
    *ngIf="form.value.availability"
    [fxLayout]="'column'"
    [fxLayoutGap]="'32px'"
    [fxLayoutGap.gt-sm]="'16px'"
  >
    <div
      *ngFor="let timeslot of timeSlotsControls; let i = index; trackBy: trackByTimeSlotIndex"
      [fxLayout]="'row'"
      [fxLayoutAlign]="'start end'"
      class="nib-weekday-form-control-field-container"
    >
      <div
        [class.nib-weekday-form-control-field-invalid]="
          timeslot.controls.from.value && timeslot.controls.to.value && timeslot.invalid
        "
        [fxFlex]="100"
        [fxLayout]="'column'"
        [fxLayout.gt-sm]="'row'"
        [fxLayoutGap]="'16px'"
      >
        <nib-form-field [fxFlex]="100" [fxFlex.gt-sm]="50">
          <nib-input
            [formControl]="timeslot.controls.from"
            [fullWidth]="true"
            [label]="'inputs_text_times_from_label' | translate"
            [list]="'timelist'"
            [step]="'900'"
            [type]="'time'"
          ></nib-input>
        </nib-form-field>
        <nib-form-field [fxFlex]="100" [fxFlex.gt-sm]="50">
          <nib-input
            [formControl]="timeslot.controls.to"
            [fullWidth]="true"
            [label]="'inputs_text_times_to_label' | translate"
            [list]="'timelist'"
            [step]="'900'"
            [type]="'time'"
          ></nib-input>
        </nib-form-field>
      </div>
      <nib-button
        *ngIf="i > 0; else addTimeSlotButton"
        [clean]="true"
        [size]="'sm'"
        (onClick)="removeTimeSlot(i)"
        class="nib-weekday-form-control-action-button"
      >
        <nib-icon [color]="'var(--color-gray-4)'" [name]="'delete'"></nib-icon>
      </nib-button>
      <ng-template #addTimeSlotButton>
        <nib-button
          [clean]="true"
          [size]="'sm'"
          [title]="'main_button_add_time_slots' | translate"
          (onClick)="addNewTimeSlot($event)"
          class="nib-weekday-form-control-action-button"
        >
          <nib-icon [color]="'var(--color-gray-4)'" [name]="'plus'"></nib-icon>
        </nib-button>
      </ng-template>
    </div>
  </nib-card>
  <input [formControlName]="'weekday'" hidden />
</section>
