import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { AuthStateAction } from '@nibol/auth';

@Injectable({ providedIn: 'root' })
export class TermsAndPrivacyPolicyResolver implements Resolve<void> {
  @Dispatch() readTerms = () => new AuthStateAction.TermsAndPrivacyPolicy.Read.Try();

  resolve(): void {
    this.readTerms();
  }
}
