<nib-card class="nib-terms-and-privacy-policy-route-content">
  <h1>{{ 'termsandprivacypolicy_title' | translate: { company: company$ | async } }}</h1>
  <p>{{ body$ | async }}</p>
  <form
    [formGroup]="form"
    (ngSubmit)="acceptTermsAndPrivacyPolicy()"
    class="nib-terms-and-privacy-policy-form"
  >
    <nib-form-field *ngFor="let flag of flags$ | async; trackBy: flagValue">
      <nib-checkbox
        [formControlName]="flag.value"
        [id]="flag.value"
        [label]="flag.text | MarkdownToHtml"
        [required]="flag.required"
        class="nib-terms-and-privacy-policy-checkbox"
      ></nib-checkbox>
    </nib-form-field>
    <nib-form-action>
      <nib-button
        [color]="'primary'"
        [disabled]="!form.valid || (isTermsAndPrivacyPolicyFormDisabled$ | async)"
        [fullWidth]="true"
        [submit]="true"
      >
        {{ 'termsandprivacypolicy_next' | translate }}
      </nib-button>
    </nib-form-action>
  </form>
</nib-card>
