import { Selector } from '@ngxs/store';
import { TermsAndPrivacyPolicyModel } from './terms-and-privacy-policy.model';
import { TermsAndPrivacyPolicyState } from './terms-and-privacy-policy.state';

export class TermsAndPrivacyPolicySelectors {
  @Selector([TermsAndPrivacyPolicyState])
  static body({ body }: TermsAndPrivacyPolicyModel): TermsAndPrivacyPolicyModel['body'] {
    return body;
  }

  @Selector([TermsAndPrivacyPolicyState])
  static company({ company }: TermsAndPrivacyPolicyModel): TermsAndPrivacyPolicyModel['company'] {
    return company;
  }

  @Selector([TermsAndPrivacyPolicyState])
  static flags({ flags }: TermsAndPrivacyPolicyModel): TermsAndPrivacyPolicyModel['flags'] {
    return flags;
  }
}
