import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslationModule } from '@nibol/translation';
import { FormModule, InputModule, SelectModule } from '@nibol/ui';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { PriceFormFieldComponent } from './price-form-field.component';

@NgModule({
  imports: [
    CommonModule,
    InputModule,
    FeatureToggleModule,
    FlexModule,
    FormModule,
    ReactiveFormsModule,
    SelectModule,
    TranslationModule,
  ],
  exports: [PriceFormFieldComponent],
  declarations: [PriceFormFieldComponent],
})
export class PriceFormFieldModule {}
