<div [fxLayoutAlign]="'space-between center'">
  <div
    [class.nib-app-status-resume-ghost-with-avatar]="showAvatar"
    class="nib-app-status-resume-ghost"
  >
    <div class="nib-app-status-resume-ghost-scope nib-ghost nib-ghost-size-full nib-ghost-darker">
      &nbsp;
    </div>
    <div class="nib-app-status-resume-ghost-user nib-ghost nib-ghost-size-full nib-ghost-lighter">
      &nbsp;
    </div>
  </div>
  <div
    *ngIf="showAvatar"
    class="nib-app-status-resume-ghost-avatar nib-ghost nib-ghost-lighter"
  ></div>
</div>
