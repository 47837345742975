/**
 * Group array of objects by key or property value
 * @param array Array to process
 * @param key Key to group by
 * @see https://gist.github.com/JamieMason/0566f8412af9fe6a1d470aa1e089a752#gistcomment-2999506
 * @example
 * ```
 * let arr = [
 *  { test: "abc", a: 1, color: { value: "black" } },
 *  { test: "abc", a: 2, color: { value: "black" } },
 *  { test: "def", a: 1, color: { value: "white" } },
 * ]
 *
 * groupBy(arr, obj => obj.color.value)
 * ```
 */
// tslint:disable-next-line: no-any
export function groupBy<T extends Record<string, any>, K extends keyof T>(
  array: T[],
  attributeGetter: (item: T) => T[K],
): Record<T[K], T[]> {
  return array.reduce((previous, current) => {
    const value = attributeGetter(current);
    previous[value] = (previous[value] || []).concat(current);
    return previous;
    // tslint:disable-next-line: no-object-literal-type-assertion
  }, {} as Record<T[K], T[]>);
}
