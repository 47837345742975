// tslint:disable: no-namespace max-classes-per-file

import {
  NameAndAddressStateModel,
  SeatsAndAmenitiesStateModel,
  SettingsStateModel,
  VenueStateModel,
} from './venue.model';
import { ImportedCalendar } from './venue.type';

export namespace VenueStateAction {
  export namespace CreatingStatus {
    export namespace Read {
      export class Try {
        static readonly type = '[Venue] try Read Venue Creating Status';
        constructor(public id: VenueStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Venue] then Read Venue Creating Status success';
        constructor(public venue: VenueStateModel) {}
      }
      export class Failure {
        static readonly type = '[Venue] catch Read Venue Creating Status failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Venue] try Update Venue Creating Status';
        constructor(
          public id: VenueStateModel['id'],
          public data: Partial<VenueStateModel['creatingStatus']>,
        ) {}
      }
      export class Success {
        static readonly type = '[Venue] then Update Venue Creating Status success';
        constructor(public venue: VenueStateModel) {}
      }
      export class Failure {
        static readonly type = '[Venue] catch Update Venue Creating Status failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace ExportCalendarUrl {
    export namespace Read {
      export class Try {
        static readonly type = '[Venue] try Read Venue Export Calendar Url';
        constructor(public id: VenueStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Venue] then Read Venue Export Calendar Url success';
        constructor(public venue: VenueStateModel) {}
      }
      export class Failure {
        static readonly type = '[Venue] catch Read Venue Export Calendar Url failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace ImportedCalendars {
    export namespace Create {
      export class Try {
        static readonly type = '[Venue] try Create Venue Imported Calendar';
        constructor(
          public id: VenueStateModel['id'],
          public calendar: Pick<ImportedCalendar, 'name' | 'url'>,
        ) {}
      }
      export class Success {
        static readonly type = '[Venue] then Create Venue Imported Calendar Url success';
        constructor(public venue: VenueStateModel) {}
      }
      export class Failure {
        static readonly type = '[Venue] catch Create Venue Imported Calendar Url failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Delete {
      export class Try {
        static readonly type = '[Venue] try Delete Venue Imported Calendar';
        constructor(public id: VenueStateModel['id'], public calendarId: ImportedCalendar['id']) {}
      }
      export class Success {
        static readonly type = '[Venue] then Delete Venue Imported Calendar Url success';
        constructor(public venue: VenueStateModel) {}
      }
      export class Failure {
        static readonly type = '[Venue] catch Delete Venue Imported Calendar Url failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Read {
      export class Try {
        static readonly type = '[Venue] try Read Venue Imported Calendars';
        constructor(public id: VenueStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Venue] then Read Venue Imported Calendars Url success';
        constructor(public venue: VenueStateModel) {}
      }
      export class Failure {
        static readonly type = '[Venue] catch Read Venue Imported Calendars Url failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Venue] try Update Venue Imported Calendar';
        constructor(public id: VenueStateModel['id'], public calendarId: ImportedCalendar['id']) {}
      }
      export class Success {
        static readonly type = '[Venue] then Update Venue Imported Calendar Url success';
        constructor(public venue: VenueStateModel) {}
      }
      export class Failure {
        static readonly type = '[Venue] catch Update Venue Imported Calendar Url failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace Item {
    export namespace Delete {
      export class Try {
        static readonly type = '[Venue] try Delete Venue Item';
        constructor(public id: VenueStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Venue] then Delete Venue Item success';
        constructor(public venue: VenueStateModel) {}
      }
      export class Failure {
        static readonly type = '[Venue] catch Delete Venue Item failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Read {
      export class Try {
        static readonly type = '[Venue] try Read Venue Item';
        constructor(
          public id: VenueStateModel['id'],
          public mode: VenueStateModel['mode'],
          public type: VenueStateModel['type'],
        ) {}
      }
      export class Success {
        static readonly type = '[Venue] then Read Venue Item success';
        constructor(public venue: VenueStateModel) {}
      }
      export class Failure {
        static readonly type = '[Venue] catch Read Venue Item failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Venue] try Update Venue Item';
        constructor(
          public id: VenueStateModel['id'],
          public data: Partial<
            Pick<VenueStateModel, 'mode'> &
              NameAndAddressStateModel &
              Pick<VenueStateModel, 'photos'> &
              Pick<VenueStateModel, 'photosLink'> &
              SeatsAndAmenitiesStateModel &
              SettingsStateModel &
              Pick<VenueStateModel, 'specialClosingTimes'> & {
                creatingStatus?: Partial<VenueStateModel['creatingStatus']>;
              } & {
                workHoursFullDay?: Partial<VenueStateModel['workHoursFullDay']>;
              } & {
                workHoursHalfDay?: Partial<VenueStateModel['workHoursHalfDay']>;
              } & {
                workHoursHourly?: Partial<VenueStateModel['workHoursHourly']>;
              }
          >,
        ) {}
      }
      export class Success {
        static readonly type = '[Venue] then Update Venue Item success';
        constructor(public venue: VenueStateModel) {}
      }
      export class Failure {
        static readonly type = '[Venue] catch Update Venue Item failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace Mode {
    export namespace Update {
      export class Try {
        static readonly type = '[Venue] try Update Venue Mode';
        constructor(public id: VenueStateModel['id'], public mode: VenueStateModel['mode']) {}
      }
      export class Success {
        static readonly type = '[Venue] then Update Venue Mode success';
        constructor(public venue: VenueStateModel) {}
      }
      export class Failure {
        static readonly type = '[Venue] catch Update Venue Mode failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace NameAndAddress {
    export namespace Read {
      export class Try {
        static readonly type = '[Venue] try Read Venue Name And Address';
        constructor(public id: VenueStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Venue] then Read Venue Name And Address success';
        constructor(public venue: VenueStateModel) {}
      }
      export class Failure {
        static readonly type = '[Venue] catch Read Venue Name And Address failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Venue] try Update Venue Name And Address';
        constructor(
          public id: VenueStateModel['id'],
          public data: Partial<NameAndAddressStateModel>,
        ) {}
      }
      export class Success {
        static readonly type = '[Venue] then Update Venue Name And Address success';
        constructor(public venue: VenueStateModel) {}
      }
      export class Failure {
        static readonly type = '[Venue] catch Update Venue Name And Address failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace Photos {
    export namespace Create {
      export class Try {
        static readonly type = '[Venue] try Create Venue Photos';
        constructor(public id: VenueStateModel['id'], public data: File[]) {}
      }
      export class Success {
        static readonly type = '[Venue] then Create Venue Photos success';
        constructor(public venue: VenueStateModel) {}
      }
      export class Failure {
        static readonly type = '[Venue] catch Create Venue Photos failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Read {
      export class Try {
        static readonly type = '[Venue] try Read Venue Photos';
        constructor(public id: VenueStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Venue] then Read Venue Photos success';
        constructor(public venue: VenueStateModel) {}
      }
      export class Failure {
        static readonly type = '[Venue] catch Read Venue Photos failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Venue] try Update Venue Photos';
        constructor(public id: VenueStateModel['id'], public data: VenueStateModel['photos']) {}
      }
      export class Success {
        static readonly type = '[Venue] then Update Venue Photos success';
        constructor(public venue: VenueStateModel) {}
      }
      export class Failure {
        static readonly type = '[Venue] catch Update Venue Photos failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace PhotosLink {
    export namespace Update {
      export class Try {
        static readonly type = '[Venue] try Update Venue Photos Link';
        constructor(public id: VenueStateModel['id'], public link: VenueStateModel['photosLink']) {}
      }
      export class Success {
        static readonly type = '[Venue] then Update Venue Photos Link success';
        constructor(public venue: VenueStateModel) {}
      }
      export class Failure {
        static readonly type = '[Venue] catch Update Venue Photos Link failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace SeatsAndAmenities {
    export namespace Read {
      export class Try {
        static readonly type = '[Venue] try Read Venue Seats And Amenities';
        constructor(public id: VenueStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Venue] then Read Venue Seats And Amenities success';
        constructor(public venue: VenueStateModel) {}
      }
      export class Failure {
        static readonly type = '[Venue] catch Read Venue Seats And Amenities failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Venue] try Update Venue Seats And Amenities';
        constructor(
          public id: VenueStateModel['id'],
          public data: Partial<SeatsAndAmenitiesStateModel>,
        ) {}
      }
      export class Success {
        static readonly type = '[Venue] then Update Venue Seats And Amenities success';
        constructor(public venue: VenueStateModel) {}
      }
      export class Failure {
        static readonly type = '[Venue] catch Update Venue Seats And Amenities failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace Settings {
    export namespace Read {
      export class Try {
        static readonly type = '[Venue] try Read Venue Settings';
        constructor(public id: VenueStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Venue] then Read Venue Settings success';
        constructor(public venue: VenueStateModel) {}
      }
      export class Failure {
        static readonly type = '[Venue] catch Read Venue Settings failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Venue] try Update Venue Settings';
        constructor(public id: VenueStateModel['id'], public data: Partial<SettingsStateModel>) {}
      }
      export class Success {
        static readonly type = '[Venue] then Update Venue Settings success';
        constructor(public venue: VenueStateModel) {}
      }
      export class Failure {
        static readonly type = '[Venue] catch Update Venue Settings failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace SpecialClosingTimes {
    export namespace Read {
      export class Try {
        static readonly type = '[Venue] try Read Venue Special Closing Times';
        constructor(public id: VenueStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Venue] then Read Venue Special Closing Times success';
        constructor(public venue: VenueStateModel) {}
      }
      export class Failure {
        static readonly type = '[Venue] catch Read Venue Special Closing Times failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Venue] try Update Venue Special Closing Times';
        constructor(
          public id: VenueStateModel['id'],
          public data: Pick<VenueStateModel, 'specialClosingTimes'>,
        ) {}
      }
      export class Success {
        static readonly type = '[Venue] then Update Venue Special Closing Times success';
        constructor(public venue: VenueStateModel) {}
      }
      export class Failure {
        static readonly type = '[Venue] catch Update Venue Special Closing Times failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace WorkHoursFullDay {
    export namespace Read {
      export class Try {
        static readonly type = '[Venue] try Read Venue Full Day Work Hours';
        constructor(public id: VenueStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Venue] then Read Venue Full Day Work Hours success';
        constructor(public venue: VenueStateModel) {}
      }
      export class Failure {
        static readonly type = '[Venue] catch Read Venue Full Day Work Hours failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Venue] try Update Venue Full Day Work Hours';
        constructor(
          public id: VenueStateModel['id'],
          public data: Partial<VenueStateModel['workHoursFullDay']>,
        ) {}
      }
      export class Success {
        static readonly type = '[Venue] then Update Venue Full Day Work Hours success';
        constructor(public venue: VenueStateModel) {}
      }
      export class Failure {
        static readonly type = '[Venue] catch Update Venue Full Day Work Hours failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace WorkHoursHalfDay {
    export namespace Read {
      export class Try {
        static readonly type = '[Venue] try Read Venue Half Day Work Hours';
        constructor(public id: VenueStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Venue] then Read Venue Half Day Work Hours success';
        constructor(public venue: VenueStateModel) {}
      }
      export class Failure {
        static readonly type = '[Venue] catch Read Venue Half Day Work Hours failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Venue] try Update Venue Half Day Work Hours';
        constructor(
          public id: VenueStateModel['id'],
          public data: Partial<VenueStateModel['workHoursHalfDay']>,
        ) {}
      }
      export class Success {
        static readonly type = '[Venue] then Update Venue Half Day Work Hours success';
        constructor(public venue: VenueStateModel) {}
      }
      export class Failure {
        static readonly type = '[Venue] catch Update Venue Half Day Work Hours failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace WorkHoursHourly {
    export namespace Read {
      export class Try {
        static readonly type = '[Venue] try Read Venue Hourly Work Hours';
        constructor(public id: VenueStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Venue] then Read Venue Hourly Work Hours success';
        constructor(public venue: VenueStateModel) {}
      }
      export class Failure {
        static readonly type = '[Venue] catch Read Venue Hourly Work Hours failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Venue] try Update Venue Hourly Work Hours';
        constructor(
          public id: VenueStateModel['id'],
          public data: Partial<VenueStateModel['workHoursHourly']>,
        ) {}
      }
      export class Success {
        static readonly type = '[Venue] then Update Venue Hourly Work Hours success';
        constructor(public venue: VenueStateModel) {}
      }
      export class Failure {
        static readonly type = '[Venue] catch Update Venue Hourly Work Hours failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }
}
