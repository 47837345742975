import { Injectable } from '@angular/core';
import { BuildingService } from '@marketplace/api/building';
import { Action, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { catchError, tap } from 'rxjs/operators';
import { CompanyStateAction } from './company.actions';
import { mapCompanyToApi, mapCompanyToClient } from './company.helper';
import { CompanyStateModel } from './company.model';

@State<CompanyStateModel>({
  name: 'company',
})
@Injectable()
export class CompanyState {
  constructor(private readonly buildingService: BuildingService) {}

  @Action(CompanyStateAction.Read.Try)
  readCompany(
    { dispatch, getState, setState }: StateContext<CompanyStateModel>,
    { buildingId }: CompanyStateAction.Read.Try,
  ) {
    return this.buildingService.readCompany({ building: buildingId }).pipe(
      catchError(error => {
        dispatch(new CompanyStateAction.Read.Failure(error));

        throw error;
      }),
      tap(company => {
        setState(patch(mapCompanyToClient(company)));

        dispatch(new CompanyStateAction.Read.Success(getState(), buildingId));
      }),
    );
  }

  @Action(CompanyStateAction.Update.Try)
  updateCompany(
    { dispatch, getState, setState }: StateContext<CompanyStateModel>,
    { buildingId, data }: CompanyStateAction.Update.Try,
  ) {
    return this.buildingService
      .updateCompany({ building: buildingId, data: mapCompanyToApi(data) })
      .pipe(
        catchError(error => {
          dispatch(new CompanyStateAction.Update.Failure(error));

          throw error;
        }),
        tap(response => {
          if (response.status) {
            setState(patch(data));

            dispatch(new CompanyStateAction.Update.Success(getState(), buildingId));
          } else {
            dispatch(new CompanyStateAction.Update.Failure(response.status));

            throw response.status;
          }
        }),
      );
  }
}
