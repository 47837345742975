// tslint:disable: max-classes-per-file

import { SelectOption } from '../select/select-option.type';

export class ItemNode {
  children!: ItemNode[];
  item!: SelectOption;
}

export class ItemFlatNode {
  expandable!: boolean;
  item!: SelectOption;
  level!: number;
}

export type TreeSelectOption = SelectOption & {
  children?: TreeSelectOption[];
};
