// tslint:disable: no-namespace

import { DeepPartial, User } from '@nibol/shared';
import { Venue } from '../venue';

export namespace Building {
  export namespace Company {
    export namespace Read {
      export type Params = {
        building: Item.Read.Response['id'];
      };
      export type Response = {
        building: Item.Read.Response['id'];
        iban: string;
        info: {
          address: string;
          billing_email: string;
          city: string;
          country: string;
          einvoice: string;
          extra: string;
          name: string;
          pec: string;
          postal: string;
          state: string;
          vat: string;
        };
      };
    }
    export namespace Update {
      export type Params = {
        building: Item.Read.Response['id'];
        data: DeepPartial<Pick<Read.Response, 'iban' | 'info'>>;
      };
      export type Response = { status: boolean };
    }
  }

  export namespace Item {
    export namespace Read {
      export type Params = { id: Response['id'] };
      export type Response = {
        id: string;
        name: string;
        fees: {
          fc: number;
          hp: number;
          ppp: number;
        } | null;
        status: Venue.Item.Read.Response['status'];
        company?: Company.Read.Response['info'];
        reservations?: Reservations.Read.Response['reservations'];
        venues?: Venues.Read.Response;
      };
    }
    export namespace Update {
      export type Params = {
        id: Read.Response['id'];
        data: Partial<Pick<Read.Response, 'name' | 'fees'>>;
      };
      export type Response = { status: boolean };
    }
  }

  export namespace List {
    export namespace Read {
      export type Response = Item.Read.Response[];
    }
  }

  export namespace Reservations {
    export namespace Read {
      export type Params = {
        building: Item.Read.Response['id'];
        fromDay: ReturnType<Response['reservations'][number]['time']['day']['toString']>;
        toDay?: ReturnType<Response['reservations'][number]['time']['day']['toString']>;
      };
      export type Response = {
        count: Response['reservations']['length'];
        reservations: Array<{
          status: Array<
            | 'inactive'
            | 'cancel'
            | 'cancelled'
            | 'check_in'
            | 'checked_in'
            | 'external'
            | 'lost'
            | 'rate'
            | 'rated'
          >;
          time: {
            day: number;
            from: number;
            mode: 'daily' | 'halfday' | 'hourly';
            to: number;
          };
          venue: Venues.Read.Response[number]['id'];
          id?: string;
          discount?: number;
          mode: 'private' | 'shared';
          payment?: {
            total: number;
            work: {
              hours: number;
              price: number;
              total: number;
            };
          };
          seats?: number;
          summary?: string;
          user?: {
            data: Pick<User.Item.Read.Response['user']['data'], 'first_name' | 'last_name' | 'pic'>;
            info: User.Item.Read.Response['user']['info'];
          };
          visible?: boolean;
        }>;
      };
    }
  }

  export namespace Venues {
    export namespace Create {
      export type Params = {
        id: Item.Read.Response['id'];
        data: Pick<Venue.Item.Read.Response, 'mode' | 'name'>;
      };
      export type Response = { status: boolean };
    }
    export namespace Read {
      export type Params = {
        id: Item.Read.Response['id'];
      };
      export type Response = Venue.Item.Read.Response[];
    }
  }
}
