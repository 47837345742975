<nib-app-shell
  [showHeader]="true"
  [showMenu]="false"
  [showTitle]="false"
  class="nib-app-shell-wizard"
>
  <nib-app-shell-logo
    [fxFlex]="100"
    [fxLayout]="'row-reverse'"
    [fxLayout.gt-sm]="'row'"
    [fxLayoutAlign]="'space-between center'"
    [fxLayoutAlign.gt-sm]="'start center'"
  >
    <div [inlineSVG]="logo"></div>
  </nib-app-shell-logo>
  <nib-app-shell-main>
    <nib-progress-bar
      [color]="'positive'"
      [size]="'sm'"
      [value]="completionPercentage"
      class="nib-app-shell-wizard-progress-bar"
    ></nib-progress-bar>
    <div [fxLayout]="'row'">
      <div
        [fxFlex]="100"
        [fxFlex.gt-sm]="(100 / 8) * 5"
        [fxLayoutAlign]="'end'"
        class="nib-app-shell-wizard-main"
      >
        <div [fxFlex]="100" [fxFlex.gt-sm]="(100 / 5) * 4" class="nib-app-shell-wizard-wrapper">
          <router-outlet></router-outlet>
        </div>
      </div>
      <div
        *nibIfBreakpointIs="'gt-sm'"
        [fxFlex]="100"
        [fxFlex.gt-sm]="(100 / 8) * 3"
        class="nib-app-shell-wizard-help"
      >
        <div class="nib-app-shell-wizard-wrapper">
          <router-outlet #helpOutlet name="help"></router-outlet>
        </div>
      </div>
    </div>
  </nib-app-shell-main>
</nib-app-shell>
