// tslint:disable: no-namespace max-classes-per-file

import { VersionStateModelType } from './version-state-model.type';

export namespace VersionStateAction {
  export namespace Change {
    export class Try {
      static readonly type = '[Version] try Change Version';
      constructor(public version: VersionStateModelType) {}
    }
    export class Success {
      static readonly type = '[Version] then Change Version success';
      constructor(public version: VersionStateModelType) {}
    }
    export class Failure {
      static readonly type = '[Version] catch Change Version failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }
}
