import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
} from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
import { PermissionsState } from '../store/permissions/permissions.state';
import { PermissionsRouterData } from './permissions-router-data.type';

@Injectable({ providedIn: 'root' })
export class PermissionsGuard<Roles extends string[]>
  implements CanActivate, CanActivateChild, CanLoad {
  @Select(PermissionsState) roles$!: Observable<Roles | undefined>;

  constructor(private readonly router: Router) {}

  canActivate({ data }: ActivatedRouteSnapshot): Observable<boolean> {
    return data?.permissions ? this.isAuthorized(data?.permissions) : of(true);
  }

  canActivateChild({ data }: ActivatedRouteSnapshot): Observable<boolean> {
    return data?.permissions ? this.isAuthorized(data?.permissions) : of(true);
  }

  canLoad({ data }: Route): Observable<boolean> {
    return data?.permissions ? this.isAuthorized(data?.permissions) : of(true);
  }

  private isAuthorized({ only, redirectTo }: PermissionsRouterData<Roles>): Observable<boolean> {
    return this.roles$.pipe(
      filter(roles => typeof roles !== 'undefined' && roles.length > 0),
      first(),
      map(
        roles =>
          typeof roles !== 'undefined' && roles.filter(role => only.includes(role)).length > 0,
      ),
      tap(isRoleAuthorized => {
        if (!isRoleAuthorized) {
          this.router.navigate([redirectTo]);
        }
      }),
    );
  }
}
