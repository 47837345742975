<nib-form [formGroup]="form">
  <nib-form-title>{{ 'sharedspaces_wifi_subtitle' | translate }}</nib-form-title>
  <nib-form-description>
    {{ 'sharedspaces_wifi_body' | translate }}
  </nib-form-description>
  <nib-form-field>
    <nib-input
      [formControlName]="'name'"
      [fullWidth]="true"
      [id]="'name'"
      [label]="'inputs_text_wifi_name_label' | translate"
    ></nib-input>
  </nib-form-field>
  <nib-form-field>
    <nib-select
      #passwordType
      [formControlName]="'passwordType'"
      [fullWidth]="true"
      [id]="'passwordType'"
      [label]="'inputs_text_wifi_type_label' | translate"
      [options]="passwordTypes"
    ></nib-select>
  </nib-form-field>
  <nib-form-field [hidden]="passwordType?.value !== 'password'">
    <nib-input
      [formControlName]="'password'"
      [fullWidth]="true"
      [id]="'password'"
      [label]="'inputs_text_wifi_psw_label' | translate"
    ></nib-input>
  </nib-form-field>
  <ng-container *ngIf="showSpeed">
    <nib-form-field *ngxPermissionsOnly="'admin'">
      <nib-select
        [formControlName]="'speed'"
        [fullWidth]="true"
        [id]="'speed'"
        [label]="'inputs_text_wifi_speed_label' | translate"
        [options]="speeds"
      ></nib-select>
    </nib-form-field>
  </ng-container>
</nib-form>
