import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoginFooterContentComponent } from './login-route/login-footer-content/login-footer-content.component';
import { LoginRouteComponent } from './login-route/login-route.component';

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forChild([
      {
        // root is '/login'
        path: '',
        data: { title: 'login_title' },
        children: [
          { path: '', component: LoginRouteComponent },
          { path: '', component: LoginFooterContentComponent, outlet: 'footer-content' },
        ],
      },
    ]),
  ],
})
export class LoginRoutingModule {}
