import { Injectable, TemplateRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastDataModel } from './toast-data.model';
import { ToastComponent } from './toast.component';

@Injectable({ providedIn: 'root' })
export class ToastService {
  constructor(private readonly matSnackBar: MatSnackBar) {}

  /** Close the currently visible toast */
  dismiss(): void {
    this.matSnackBar.dismiss();
  }

  /**
   * Open a new toast
   * @param title Short text which describe the message
   * @param content Long text to show in body
   * @param [severity=informative] Severity level of the message
   * @param [duration=5000] How many times in ms the toast should be visible
   */
  open(
    title: ToastDataModel['title'],
    content: ToastDataModel['content'],
    severity = new ToastDataModel().severity,
    duration = 5000,
  ): void {
    this.openFromMatSnackBar({ content, customAction: undefined, severity, title }, duration);
  }

  /**
   * Open a new toast with custom action instead the default one
   * @param title Short text which describe the message
   * @param content Long text to show in body
   * @param customAction A template to use to dismiss toast instead of the default one
   * @param [severity=informative] Severity level of the message
   * @param [duration=5000] How many times in ms the toast should be visible
   */
  openWithCustomAction(
    title: ToastDataModel['title'],
    content: ToastDataModel['content'],
    customAction: TemplateRef<unknown>,
    severity = new ToastDataModel().severity,
    duration = 5000,
  ): void {
    this.openFromMatSnackBar({ content, customAction, severity, title }, duration);
  }

  /**
   * Use MatSnackBar to show toast
   * @param data Data to use in toast component
   * @param duration How many times in ms the toast should be visible
   */
  private openFromMatSnackBar(data: ToastDataModel, duration?: number): void {
    this.matSnackBar.openFromComponent(ToastComponent, {
      data: {
        content: data.content,
        customAction: data.customAction,
        severity: data.severity,
        title: data.title,
      },
      duration: duration ? duration : undefined,
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
    });
  }
}
