import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { CalendarNativeDateFormatter, DateFormatterParams } from 'angular-calendar';

@Injectable()
export class CustomDateFormatter extends CalendarNativeDateFormatter {
  dayViewHour({ date, locale }: DateFormatterParams): string {
    return new DatePipe(locale as string).transform(date, 'HH:mm', locale) as string;
  }

  weekViewHour({ date, locale }: DateFormatterParams): string {
    return this.dayViewHour({ date, locale });
  }
}
