<nib-app-shell
  [showHeader]="true"
  [showMenu]="false"
  [showTitle]="false"
  class="nib-app-shell-public"
>
  <nib-app-shell-logo
    [fxFlex]="100"
    [fxLayout]="'row-reverse'"
    [fxLayout.gt-sm]="'row'"
    [fxLayoutAlign]="'space-between center'"
    [fxLayoutAlign.gt-sm]="'start center'"
  >
    <div *ngIf="logo" [inlineSVG]="logo"></div>
  </nib-app-shell-logo>
  <nib-app-shell-main class="nib-app-shell-public-main">
    <nib-card class="nib-app-shell-public-content">
      <router-outlet></router-outlet>
    </nib-card>
    <div class="nib-app-shell-public-footer-content">
      <router-outlet #footerContentOutlet="outlet" name="footer-content"></router-outlet>
    </div>
  </nib-app-shell-main>
</nib-app-shell>
