<div [fxLayout]="'column'" [fxLayoutAlign]="'space-between stretch'" class="nib-app-shell-menu">
  <div class="nib-app-shell-menu-nav-container">
    <nav>
      <ng-content
        select="ng-container:not([position='end']), nib-app-shell-menu-item:not([position='end']),
      nib-app-shell-menu-divider:not([position='end'])"
      ></ng-content>
    </nav>
  </div>
  <nav class="nib-app-shell-menu-items-position-end">
    <ng-content
      select="ng-container[position='end'], nib-app-shell-menu-item[position='end'],
        nib-app-shell-menu-divider[position='end']"
    ></ng-content>
  </nav>
</div>
