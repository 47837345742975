import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { AppStateAction } from '@nibol/store';
import { TranslationStateAction } from '@nibol/translation';

@State({
  name: 'languagesaga',
})
@Injectable()
export class LanguageSaga {
  @Action(TranslationStateAction.CurrentLanguage.Change.Cancel)
  changeCurrentLanguageCancel({ dispatch }: StateContext<unknown>) {
    dispatch([
      new AppStateAction.State.Change.Try('ready', 1000),
      new TranslationStateAction.State.Change.Try('ready', 1000),
    ]);
  }

  @Action(TranslationStateAction.CurrentLanguage.Change.Success)
  changeCurrentLanguageSuccess({ dispatch }: StateContext<unknown>) {
    dispatch([
      new AppStateAction.State.Change.Try('ready', 1000),
      new TranslationStateAction.State.Change.Try('ready', 1000),
    ]);
  }

  @Action(TranslationStateAction.CurrentLanguage.Change.Try)
  changeCurrentLanguageTry({ dispatch }: StateContext<unknown>) {
    dispatch(new AppStateAction.State.Change.Try('in-progress'));
  }
}
