import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ProxyValueAccessor } from '../form/proxy-value-accessor';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'nib-textarea',
  styleUrls: ['textarea.component.scss'],
  templateUrl: 'textarea.component.html',
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    class: 'nib-textarea',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextareaComponent,
      multi: true,
    },
  ],
})
export class TextareaComponent extends ProxyValueAccessor {
  proxiedControl = new FormControl('');

  /** Whether input should be disabled. */
  @Input()
  get disabled(): boolean {
    return this.proxiedControl.disabled;
  }
  set disabled(value: boolean) {
    const disabled = coerceBooleanProperty(value);
    if (disabled) {
      this.proxiedControl.disable();
    } else {
      this.proxiedControl.enable();
    }
  }

  /** Whether the input should be in full-width mode. */
  @Input()
  get fullWidth(): boolean {
    return this._fullWidth;
  }
  set fullWidth(value: boolean) {
    this._fullWidth = coerceBooleanProperty(value);
  }
  private _fullWidth = false;

  /** Input id and name. */
  @Input() id = '';

  /** Input label. */
  @Input() label = '';

  /** Text to show in empty field. */
  @Input() placeholder = '';

  /** Whether the input should be readonly. */
  @Input()
  get readonly(): boolean {
    return this._readonly;
  }
  set readonly(value: boolean) {
    this._readonly = coerceBooleanProperty(value);
  }
  private _readonly = false;

  /** Whether the input should be required. */
  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }
  private _required = false;

  /** Input value. */
  @Input()
  get value(): string | number {
    return this.proxiedControl.value;
  }
  set value(value: string | number) {
    this.proxiedControl.setValue(value);
  }

  /** Emit everytime value change */
  @Output() onChange = new EventEmitter<{
    value: string | number;
  }>();

  /** Class to apply to input. */
  get ngClass(): { [key: string]: boolean } {
    return {
      'nib-textarea-disabled': this.disabled,
      'nib-textarea-full-width': this.fullWidth,
      'nib-textarea-readonly': this.readonly,
    };
  }
}
