// tslint:disable: no-namespace max-classes-per-file

import { BuildingStateModel } from '../building';

export namespace CurrentBuildingStateAction {
  export namespace Change {
    export class Try {
      static readonly type = '[CurrentBuilding] try Change Current Building';
      constructor(public id: BuildingStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[CurrentBuilding] then Change Current Building success';
      constructor(public id: BuildingStateModel['id']) {}
    }
    export class Failure {
      static readonly type = '[CurrentBuilding] catch Change Current Building failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }
  export namespace Reset {
    export class Try {
      static readonly type = '[CurrentBuilding] try Reset Current Building';
    }
    export class Success {
      static readonly type = '[CurrentBuilding] then Reset Current Building success';
      constructor(public id: null) {}
    }
    export class Failure {
      static readonly type = '[CurrentBuilding] catch Reset Current Building failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }
}
