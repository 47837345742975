import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormValueAccessor } from '@nibol/ui';
import { ServiceFormValue } from './service-form-value.type';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ServiceFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ServiceFormComponent),
      multi: true,
    },
  ],
  selector: 'nib-service-form',
  styleUrls: ['service-form.component.scss'],
  templateUrl: 'service-form.component.html',
})
export class ServiceFormComponent extends FormValueAccessor<ServiceFormValue> {
  form = new FormGroup({
    serviceEmail: new FormControl(true),
    servicePush: new FormControl(true),
    servicePushWeb: new FormControl(true),
    serviceSms: new FormControl(true),
  });
}
