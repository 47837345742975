import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ProxyValueAccessor } from '../form/proxy-value-accessor';
import { ChecklistDatabase } from './tree-selection-database.service';
import { ItemFlatNode, ItemNode, TreeSelectOption } from './tree-selection.type';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'nib-tree-selection' },
  providers: [
    ChecklistDatabase,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TreeSelectionComponent,
    },
  ],
  selector: 'nib-tree-selection',
  styleUrls: ['tree-selection.component.scss'],
  templateUrl: 'tree-selection.component.html',
})
export class TreeSelectionComponent extends ProxyValueAccessor implements AfterContentInit, OnInit {
  // tslint:disable-next-line: no-any
  @Input() options: TreeSelectOption[] = [];

  checklistSelection = new SelectionModel<ItemFlatNode>(true);
  dataSource: MatTreeFlatDataSource<ItemNode, ItemFlatNode>;
  treeControl: FlatTreeControl<ItemFlatNode>;

  /** The control with list of selected values */
  readonly proxiedControl = new FormControl('');

  hasChild = (_index: number, nodeData: ItemFlatNode) => nodeData.expandable;

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  private readonly flatNodeMap = new Map<ItemFlatNode, ItemNode>();
  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  private readonly nestedNodeMap = new Map<ItemNode, ItemFlatNode>();
  private readonly treeFlattener: MatTreeFlattener<ItemNode, ItemFlatNode>;
  private readonly getChildren = (node: ItemNode): ItemNode[] => node.children;
  private readonly getLevel = (node: ItemFlatNode) => node.level;
  private readonly isExpandable = (node: ItemFlatNode) => node.expandable;
  /** Transformer to convert nested node to flat node. Record the nodes in maps for later use. */
  private readonly transformer = (node: ItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item ? existingNode : new ItemFlatNode();

    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  constructor(private readonly database: ChecklistDatabase) {
    super();

    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren,
    );
    this.treeControl = new FlatTreeControl<ItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
  }

  ngAfterContentInit(): void {
    this.updateSelection(this.proxiedControl.value);
  }

  ngOnInit(): void {
    this.database.initialize(this.options);
  }

  /** Whether all the descendants of the node are selected. */
  areAllDescendantsSelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const isAllDescendantsSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });

    return isAllDescendantsSelected;
  }

  /** Whether part of the descendants are selected */
  areDescendantsPartiallySelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));

    return result && !this.areAllDescendantsSelected(node);
  }

  /** Toggle a leaf item selection. Check all the parents to see if they changed */
  leafItemSelectionToggle(node: ItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
    this.updateProxiedControl();
  }

  /** Toggle the item selection. Select/deselect all the descendants node */
  toggleItemSelection(node: ItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.treeControl.expandDescendants(node);

    const descendants = this.treeControl.getDescendants(node);

    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => {
      this.checklistSelection.isSelected(child);
    });
    this.checkAllParentsSelection(node);

    this.updateProxiedControl();
  }

  writeValue(value: string): void {
    this.updateSelection(value);
    super.writeValue(value);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  private checkAllParentsSelection(node: ItemFlatNode): void {
    let parent: ItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  // tslint:disable-next-line: cyclomatic-complexity
  private checkRootNodeSelection(node: ItemFlatNode): void {
    const isNodeSelected = this.checklistSelection.isSelected(node);

    if (isNodeSelected && !this.areAllDescendantsSelected(node)) {
      this.checklistSelection.deselect(node);
    } else if (!isNodeSelected && this.areAllDescendantsSelected(node)) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  private getParentNode(node: ItemFlatNode): ItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    for (let i = this.treeControl.dataNodes.indexOf(node) - 1; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        this.treeControl.expand(currentNode);

        return currentNode;
      }
    }

    return null;
  }

  private updateProxiedControl(): void {
    this.proxiedControl.setValue(
      this.checklistSelection.selected
        .filter(item => !item.expandable)
        .map(item => item.item.value)
        .join(','),
    );
  }

  private updateSelection(value: string): void {
    this.checklistSelection.clear();

    value.split(',').forEach(item => {
      const node = [...this.nestedNodeMap.values()].find(
        nestedNode => nestedNode.item.value === item,
      );

      if (node) {
        this.checklistSelection.select(node);
        this.checkAllParentsSelection(node);
      }
    });
  }
}
