import { Selector } from '@ngxs/store';
import {
  NameAndAddressStateModel,
  SeatsAndAmenitiesStateModel,
  SettingsStateModel,
  VenueStateModel,
} from './venue.model';
import { VenueState } from './venue.state';
import { Modes, Types } from './venue.type';

export class VenueSelectors {
  @Selector([VenueState])
  static creatingStatus({ creatingStatus }: VenueStateModel): VenueStateModel['creatingStatus'] {
    return creatingStatus;
  }

  @Selector([VenueState])
  static exportCalendarUrl({
    exportCalendarUrl,
  }: VenueStateModel): VenueStateModel['exportCalendarUrl'] {
    return exportCalendarUrl;
  }

  @Selector([VenueState])
  static fullname({ name }: VenueStateModel): VenueStateModel['name'] {
    return name;
  }

  @Selector([VenueState])
  static id({ id }: VenueStateModel): VenueStateModel['id'] {
    return id;
  }

  @Selector([VenueState])
  static importedCalendars({
    importedCalendars,
  }: VenueStateModel): VenueStateModel['importedCalendars'] {
    return importedCalendars;
  }

  @Selector([VenueState])
  static isCoworking({ mode, type }: VenueStateModel): boolean {
    return mode === Modes.shared && type === Types.coworking;
  }

  @Selector([VenueState])
  static isPrivate({ mode }: VenueStateModel): boolean {
    return mode === Modes.private;
  }

  @Selector([VenueState])
  static isShared({ mode }: VenueStateModel): boolean {
    return mode === Modes.shared;
  }

  @Selector([VenueState])
  static mode({ mode }: VenueStateModel): VenueStateModel['mode'] {
    return mode;
  }

  @Selector([VenueState])
  static nameAndAddress({
    additionalInfo,
    address,
    area,
    coordinates,
    description,
    name,
  }: VenueStateModel): NameAndAddressStateModel {
    return { additionalInfo, address, area, coordinates, description, name };
  }

  @Selector([VenueState])
  static photos({ photos }: VenueStateModel): VenueStateModel['photos'] {
    return photos;
  }

  @Selector([VenueState])
  static photosLink({ photosLink }: VenueStateModel): VenueStateModel['photosLink'] {
    return photosLink || '';
  }

  @Selector([VenueState])
  static seatsAndAmenities({
    amenities,
    info,
    privacyLevel,
    seats,
    toilette,
    wifi,
  }: VenueStateModel): SeatsAndAmenitiesStateModel {
    return { amenities, info, privacyLevel, seats, toilette, wifi };
  }

  @Selector([VenueState])
  static settings({
    activities,
    allowedGuests,
    discount,
    instantBooking,
    sanitized,
    type,
    status,
  }: VenueStateModel): SettingsStateModel {
    return { activities, allowedGuests, discount, instantBooking, sanitized, type, status };
  }

  @Selector([VenueState])
  static specialClosingTimes({
    specialClosingTimes,
  }: VenueStateModel): VenueStateModel['specialClosingTimes'] {
    return specialClosingTimes;
  }

  @Selector([VenueSelectors.settings])
  static status({ status }: SettingsStateModel): SettingsStateModel['status'] {
    return status;
  }

  @Selector([VenueState])
  static workHoursFullDay({
    workHoursFullDay,
  }: VenueStateModel): VenueStateModel['workHoursFullDay'] {
    return workHoursFullDay;
  }

  @Selector([VenueState])
  static workHoursHalfDay({
    workHoursHalfDay,
  }: VenueStateModel): VenueStateModel['workHoursHalfDay'] {
    return workHoursHalfDay;
  }

  @Selector([VenueState])
  static workHoursHourly({ workHoursHourly }: VenueStateModel): VenueStateModel['workHoursHourly'] {
    return workHoursHourly;
  }
}
