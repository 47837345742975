import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslationModule } from '@nibol/translation';
import { ButtonModule, FormModule, InputModule } from '@nibol/ui';
import { InlineSVGModule } from 'ng-inline-svg';
import { RecaptchaV3Module } from 'ng-recaptcha';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { SignupFooterContentComponent } from './signup-route/signup-footer-content/signup-footer-content.component';
import { SignupFormComponent } from './signup-route/signup-form/signup-form.component';
import { SignupRouteComponent } from './signup-route/signup-route.component';
import { SignupRoutingModule } from './signup-routing.module';

@NgModule({
  imports: [
    ButtonModule,
    CommonModule,
    FeatureToggleModule,
    FlexModule,
    FormModule,
    InlineSVGModule,
    InputModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    SignupRoutingModule,
    TranslationModule,
  ],
  declarations: [SignupFooterContentComponent, SignupFormComponent, SignupRouteComponent],
})
export class SignupModule {}
