<nib-button
  *nibLet="inProgress$ | async as inProgress"
  [disabled]="inProgress"
  [fullWidth]="true"
  (onClick)="loginWithOkta()"
  class="nib-okta-button"
>
  <nib-icon *ngIf="inProgress" [name]="'progress'"></nib-icon>
  {{ 'login_continuewith' | translate: { sso: 'integrations_okta' | translate } }}
</nib-button>
