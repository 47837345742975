<nib-form-group>
  <div [fxLayout]="'column'" [fxLayoutGap]="'8px'">
    <div [fxLayout]="'row'" [fxLayoutAlign]="'space-between center'">
      <nib-checkbox
        #interval
        [formControl]="isInterval"
        [label]="
          ('inputs_text_specialclose_checkbox_label' | translate) +
          '<br /><span class=nib-small>' +
          ('inputs_text_specialclose_checkbox_caption' | translate) +
          '</span>'
        "
      ></nib-checkbox>
      <nib-button
        [clean]="true"
        (onClick)="onCloseClick.emit()"
        class="nib-date-slots-form-remove-button"
      >
        <nib-icon [name]="'close'"></nib-icon>
      </nib-button>
    </div>
    <ng-container [formGroup]="form">
      <div
        *ngIf="interval.checked; else noInterval"
        [fxLayout]="'column'"
        [fxLayout.gt-md]="'row'"
        [fxLayoutGap]="'16px'"
      >
        <nib-form-field
          [class.nib-date-slot-form-control-field-invalid]="fromControlError || rangeError"
          [fxFlex]="100"
          [fxFlex.gt-md]="50"
        >
          <nib-input
            #from
            [formControlName]="'from'"
            [fullWidth]="true"
            [label]="'inputs_text_specialclose_fromday_label' | translate"
            [type]="'date'"
          ></nib-input>
        </nib-form-field>
        <nib-form-field
          [class.nib-date-slot-form-control-field-invalid]="toControlError || rangeError"
          [fxFlex]="100"
          [fxFlex.gt-md]="50"
        >
          <nib-input
            #to
            [formControlName]="'to'"
            [fullWidth]="true"
            [label]="'inputs_text_specialclose_today_label' | translate"
            [type]="'date'"
            [startAt]="to.value ? to.value : from.value"
          ></nib-input>
        </nib-form-field>
      </div>
      <ng-template #noInterval>
        <nib-form-field
          [class.nib-date-slot-form-control-field-invalid]="onControlError"
          [fxFlex]="100"
          [fxFlex.gt-md]="50"
        >
          <nib-input
            [formControlName]="'on'"
            [fullWidth]="true"
            [label]="'inputs_text_specialclose_singleday_label' | translate"
            [type]="'date'"
          ></nib-input>
        </nib-form-field>
      </ng-template>
    </ng-container>
  </div>
</nib-form-group>
