import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormValueAccessor } from '@nibol/ui';
import { AccountFormValue } from './account-form-value.type';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AccountFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AccountFormComponent),
      multi: true,
    },
  ],
  selector: 'nib-account-form',
  styleUrls: ['account-form.component.scss'],
  templateUrl: 'account-form.component.html',
})
export class AccountFormComponent extends FormValueAccessor<AccountFormValue> {
  form = new FormGroup({
    accountEmail: new FormControl(true),
    accountPush: new FormControl(true),
    accountPushWeb: new FormControl(true),
    accountSms: new FormControl(true),
  });
}
