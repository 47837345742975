import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { AppState } from './app/app.state';
import { FeatureTogglesState } from './feature-toggles/feature-toggles.state';
import { VersionState } from './version/version.state';
import { VersionsState } from './versions/versions.state';

@NgModule({
  imports: [NgxsModule.forFeature([AppState, FeatureTogglesState, VersionsState, VersionState])],
})
export class StoreModule {}
