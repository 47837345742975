// tslint:disable: no-namespace

export namespace Venue {
  export namespace CreatingStatus {
    export namespace Read {
      export type Params = {
        id: Item.Read.Response['id'];
      };
      export type Response = {
        id: Item.Read.Response['id'];
        creation_status: {
          accountCompleted: boolean;
          companyCompleted: boolean;
          yourSpaceCompleted: boolean;
        };
      };
    }
  }

  export namespace ExportCalendar {
    export namespace Read {
      export type Params = {
        id: Item.Read.Response['id'];
      };
      export type Response = {
        url: string;
      };
    }
  }

  export namespace ImportedCalendar {
    export namespace Create {
      export type Params = {
        id: Item.Read.Response['id'];
        calendar: {
          name: string;
          url: string;
        };
      };
      export type Response = {
        _id: string;
        health: boolean;
        name: Params['calendar']['name'];
        url: Params['calendar']['url'];
      };
    }
    export namespace Delete {
      export type Params = {
        id: Item.Read.Response['id'];
        calendar: Read.Response['calendars'][number]['_id'];
      };
      export type Response = {
        status: boolean;
      };
    }
    export namespace Read {
      export type Params = {
        id: Item.Read.Response['id'];
      };
      export type Response = {
        calendars: Array<{
          _id: string;
          health: boolean;
          name: string;
          url: string;
        }>;
        id: Item.Read.Response['id'];
      };
    }
    export namespace Update {
      export type Params = {
        id: Item.Read.Response['id'];
        calendar: Read.Response['calendars'][number]['_id'];
      };
      export type Response = Read.Response['calendars'][number];
    }
  }

  export namespace Item {
    export namespace Delete {
      export type Params = {
        id: Read.Response['id'];
      };
      export type Response = {
        status: boolean;
      };
    }
    export namespace Read {
      export type Params = {
        id: Response['id'];
      };
      export type Response = {
        id: string;
        name: string;
        mode: Mode;
        promoted: boolean;
        status: Status;
        type: Type;
        map_filter?: MapFilter;
      };
    }
    export namespace Update {
      export type Params = {
        id: Read.Response['id'];
        data: Partial<
          Pick<NameAndAddress.Read.Response, 'area_key' | 'name' | 'description' | 'mode'> & {
            position?: Partial<
              Pick<NonNullable<NameAndAddress.Read.Response['position']>, 'additional' | 'address'>
            > & {
              coordinates?: Partial<
                NonNullable<NameAndAddress.Read.Response['position']>['coordinates']
              >;
            };
          } & Pick<Photos.Read.Response, 'pictures' | 'picture_link'> &
            Pick<
              SeatsAndAmenities.Read.Response,
              'amenities' | 'info' | 'seats' | 'privacy_level' | 'toilet_clean' | 'wifi_fast'
            > & {
              wifi?: Partial<SeatsAndAmenities.Read.Response['wifi']>;
            } & Pick<
              Settings.Read.Response,
              'activities' | 'allow' | 'discount' | 'instant' | 'sanitized' | 'status' | 'type'
            > &
            Pick<SpecialClosingTimes.Read.Response, 'closings'> & {
              creation_status?: Partial<CreatingStatus.Read.Response['creation_status']>;
            } & {
              work?: Partial<WorkHours.Read.Response['work']>;
              time_mode?: WorkHours.Read.Response['time_mode'];
            }
        >;
      };
      export type Response = {
        status: boolean;
      };
    }
  }

  export namespace NameAndAddress {
    export namespace Read {
      export type Params = {
        id: Item.Read.Response['id'];
      };
      export type Response = {
        area_key: string;
        id: Item.Read.Response['id'];
        mode: Item.Read.Response['mode'];
        name: Item.Read.Response['name'];
        position: {
          coordinates: {
            lat?: number;
            lng?: number;
          };
          additional?: string;
          address?: string;
        } | null;
        description?: string;
      };
    }
  }

  export namespace Photos {
    export namespace Read {
      export type Params = {
        id: Item.Read.Response['id'];
      };
      export type Response = {
        id: Item.Read.Response['id'];
        pictures: string[];
        picture_link?: string;
      };
    }
  }

  export namespace RequestApproval {
    export type Params = {
      id: Item.Read.Response['id'];
    };
    export type Response = {
      status: boolean;
    };
  }

  export namespace SeatsAndAmenities {
    export namespace Read {
      export type Params = {
        id: Item.Read.Response['id'];
      };
      export type Response = {
        amenities: { [key: string]: boolean };
        id: Item.Read.Response['id'];
        info: { [key: string]: boolean };
        seats: number;
        wifi: { mode?: WifiMode; password?: string | null; ssid?: string } | null;
        privacy_level?: number;
        toilet_clean?: boolean;
        wifi_fast?: boolean;
      };
    }
  }

  export namespace Settings {
    export namespace Read {
      export type Params = {
        id: Item.Read.Response['id'];
      };
      export type Response = {
        activities: { [key: string]: boolean };
        discount: number;
        id: Item.Read.Response['id'];
        instant: boolean;
        sanitized: boolean;
        status: Status;
        type: Type | null;
        allow?: { [key: string]: boolean };
      };
    }
  }

  export namespace SpecialClosingTimes {
    export namespace Read {
      export type Params = {
        id: Item.Read.Response['id'];
      };
      export type Response = {
        id: Item.Read.Response['id'];
        closings: Array<{
          from: number;
          to?: number;
        }>;
      };
    }
  }

  export namespace WorkHours {
    export namespace Read {
      export type Params = {
        id: Item.Read.Response['id'];
        time_mode?: Response['time_mode'];
      };
      export type Response =
        | WorkHoursDailyResponse
        | WorkHoursHalfDayResponse
        | WorkHoursHourlyResponse;
    }
  }
}

export type MapFilter = keyof typeof MapFilters;

export const enum MapFilters {
  coworking = 'coworking',
  private = 'private',
  promoted = 'promoted',
  shared = 'shared',
}

export type Mode = keyof typeof Modes;

export const enum Modes {
  private = 'private',
  shared = 'shared',
}

export type Status = keyof typeof Statuses;

export const enum Statuses {
  active = 'active',
  creating = 'creating',
  inactive = 'inactive',
  promoted = 'promoted',
  promoted_visible = 'promoted_visible',
  pending = 'pending',
  refused = 'refused',
  waiting = 'waiting',
}

export type TimeMode = keyof typeof TimeModes;

export const enum TimeModes {
  daily = 'daily',
  halfday = 'halfday',
  hourly = 'hourly',
}

export type Type = keyof typeof Types;

export const enum Types {
  cafeteria = 'cafeteria',
  coworking = 'coworking',
  home = 'home',
  hotel = 'hotel',
  office = 'office',
  restaurant = 'restaurant',
}

export type WeekDay = keyof typeof WeekDays;

export const enum WeekDays {
  mon = 'mon',
  tue = 'tue',
  wed = 'wed',
  thu = 'thu',
  fri = 'fri',
  sat = 'sat',
  sun = 'sun',
}

export type WifiMode = keyof typeof WifiModes;

export const enum WifiModes {
  code = 'code',
  password = 'password',
  phone = 'phone',
  social = 'social',
}

export type WorkHoursDailyResponse = {
  id: Venue.Item.Read.Response['id'];
  time_mode: 'daily';
  work: {
    price: number | null;
    slots: {
      days: WeekDay[];
      times: Array<{
        accept: boolean;
        from: number;
        to: number;
      }>;
    };
  };
};

export type WorkHoursHalfDayResponse = {
  id: Venue.Item.Read.Response['id'];
  time_mode: 'halfday';
  work: {
    price: number | null;
    slots: {
      days: WeekDay[];
      times: Array<{
        accept: boolean;
        from: number;
        to: number;
      }>;
    };
  };
};

export type WorkHoursHourlyResponse = {
  id: Venue.Item.Read.Response['id'];
  time_mode: 'hourly';
  work: {
    price: number | null;
    slots: {
      [weekDay in WeekDay]?: Array<{
        from: number;
        to: number;
        accept?: boolean;
      }>;
    };
  };
};
