<nib-card class="nib-create-password-route-content">
  <div *ngIf="isCreatePasswordRequested$ | async; else createPasswordForm">
    <h1>{{ 'createpassword_title' | translate }}</h1>
    <p [innerHTML]="'createpassword_gointo' | translate"></p>
  </div>
  <ng-template #createPasswordForm>
    <h1>{{ 'createpassword_title' | translate }}</h1>
    <p>{{ 'createpassword_description' | translate }}</p>
    <form [formGroup]="form" (ngSubmit)="createPassword()">
      <nib-create-password-form
        [disabled]="isCreatePasswordFormDisabled$ | async"
        [formControlName]="'createPassword'"
      ></nib-create-password-form>
    </form>
  </ng-template>
</nib-card>
