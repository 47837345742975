// tslint:enable: match-default-export-name
import { ModuleWithProviders, NgModule } from '@angular/core';
import { PrismicServiceConfigProvider } from '@ngx-prismic/client';
import { NgxsModule } from '@ngxs/store';
import { DEBUG_MODE } from './core/debug-mode.token';
import { DEFAULT_LANGUAGE } from './core/default-language.token';
import { TRANSLATIONS_ENDPOINT } from './core/translations-endpoint.token';
import { TranslationState } from './store/translation.state';
import { TranslationModuleOptions } from './translation-module-options.type';
import { TranslationPipe } from './translation.pipe';

@NgModule({
  declarations: [TranslationPipe],
  exports: [TranslationPipe],
  imports: [NgxsModule.forFeature([TranslationState])],
  providers: [
    {
      provide: PrismicServiceConfigProvider,
      useExisting: TRANSLATIONS_ENDPOINT,
    },
  ],
})
export class TranslationModule {
  static forRoot(options: TranslationModuleOptions): ModuleWithProviders<TranslationModule> {
    return {
      ngModule: TranslationModule,
      providers: [
        {
          provide: DEBUG_MODE,
          useValue: options.debugMode,
        },
        {
          provide: DEFAULT_LANGUAGE,
          useValue: options.defaultLanguage,
        },
        {
          provide: TRANSLATIONS_ENDPOINT,
          useValue: options.translationsEndpoint,
        },
      ],
    };
  }
}
