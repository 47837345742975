// tslint:disable: no-namespace

import { Building } from '../building';
import { Venue } from '../venue';

export namespace User {
  export namespace AssociatedVenueAndBuilding {
    export namespace Create {
      export type Params = {
        building: Building.Item.Read.Response['name'];
        venue: {
          mode: Venue.Item.Read.Response['mode'];
          name: Venue.Item.Read.Response['name'];
          position?: Venue.NameAndAddress.Read.Response['position'];
          type?: Venue.Settings.Read.Response['type'];
        };
      };
      export type Response = {
        status: boolean;
      };
    }
  }
}
