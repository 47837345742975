import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QuillEditorBase } from 'ngx-quill';
import Quill from 'quill';
import { BehaviorSubject, fromEvent } from 'rxjs';

@UntilDestroy()
export class RichTextEditorBaseComponent {
  hasBold$ = new BehaviorSubject(false);
  hasItalic$ = new BehaviorSubject(false);
  isHeader1$ = new BehaviorSubject(false);
  isHeader2$ = new BehaviorSubject(false);
  isLink$ = new BehaviorSubject(false);
  modules: QuillEditorBase['modules'] = { clipboard: { matchVisual: false } };
  quill?: Quill;

  changeToolbarButtonStatus() {
    const range = this.quill?.getSelection();

    if (range) {
      const formats = this.quill?.getFormat(range);

      this.hasBold$.next(formats?.bold === true);
      this.hasItalic$.next(formats?.italic === true);
      this.isHeader1$.next(formats?.header === 1);
      this.isHeader2$.next(formats?.header === 2);
      this.isLink$.next(typeof formats?.link === 'string');
    } else {
      this.resetToolbarButtonStatus();
    }
  }

  cleanStyle(): void {
    const range = this.quill?.getSelection();

    if (range && range.length > 0) {
      this.quill?.removeFormat(range.index, range.length, 'user');
    } else {
      const formats = this.quill?.getFormat();

      if (formats) {
        Object.keys(formats).forEach(name => {
          this.quill?.format(name, false, 'user');
        });
      }
    }
  }

  createOrUpdateLink(): void {
    const currentLink = this.quill?.getFormat().link;

    if (currentLink) {
      this.updateLink();
    } else if (this.quill?.getSelection()?.length) {
      const newURL = prompt('URL');

      if (newURL) {
        this.quill?.format('link', newURL, 'user');
      }
    }
  }

  initializeQuill(quill: Quill) {
    this.quill = quill;

    fromEvent(this.quill.root, 'blur')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.resetToolbarButtonStatus();
      });

    this.manageKeyBinding();
  }

  resetToolbarButtonStatus() {
    this.hasBold$.next(false);
    this.hasItalic$.next(false);
    this.isHeader1$.next(false);
    this.isHeader2$.next(false);
    this.isLink$.next(false);
  }

  toggleBold(): void {
    this.toggleFormatText('bold');
  }

  toggleH1(): void {
    this.toggleFormatLine('header', 1);
  }

  toggleH2(): void {
    this.toggleFormatLine('header', 2);
  }

  toggleItalic(): void {
    this.toggleFormatText('italic');
  }

  private manageKeyBinding(): void {
    const shortKey = /Mac/i.test(navigator.platform) ? 'metaKey' : 'ctrlKey';

    if (this.quill?.root) {
      fromEvent<KeyboardEvent>(this.quill.root, 'keydown')
        .pipe(untilDestroyed(this))
        .subscribe(event => {
          switch (event.key) {
            case 'b':
              if (event[shortKey]) {
                this.hasBold$.next(!this.hasBold$.getValue());
              }
              break;

            case 'i':
              if (event[shortKey]) {
                this.hasItalic$.next(!this.hasItalic$.getValue());
              }
              break;

            default:
              this.changeToolbarButtonStatus();
              break;
          }
        });
    }
  }

  private toggleFormatLine(format: string, value: number): void {
    const range = this.quill?.getSelection();

    if (range && this.quill?.getFormat()[format] !== value) {
      this.quill?.formatLine(range?.index, range?.length, format, value, 'user');
    } else {
      this.quill?.format(format, false, 'user');
    }

    this.changeToolbarButtonStatus();
  }

  private toggleFormatText(format: string): void {
    const range = this.quill?.getSelection();

    if (range && range.length > 0) {
      this.quill?.formatText(range, format, !this.quill?.getFormat(range)[format], 'user');
    } else {
      this.quill?.format(format, !this.quill.getFormat()[format], 'user');
    }

    this.changeToolbarButtonStatus();
  }

  private updateLink(): void {
    const selectionIndex = this.quill?.getSelection()?.index;
    const selectionLength = this.quill?.getSelection()?.length;

    if (typeof selectionIndex !== 'undefined' && typeof selectionLength !== 'undefined') {
      const leaf: [{ parent: { domNode: HTMLAnchorElement } }, number] = this.quill?.getLeaf(
        selectionIndex + (selectionLength > 0 ? 1 : 0),
      );
      const newURL = prompt('URL', leaf[0].parent.domNode.href);

      leaf[0].parent.domNode.setAttribute('href', newURL ?? leaf[0].parent.domNode.href);
    }
  }
}
