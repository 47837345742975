<div class="nib-drawer-backdrop" (click)="onBackdropClick.emit($event)"></div>
<div
  [@slideInOut]="true"
  (@slideInOut.done)="manageAnimationDone($event)"
  (@slideInOut.start)="manageAnimationStart($event)"
  class="nib-drawer-container"
>
  <div
    *ngIf="enableCdkFocus$ | async"
    [cdkTrapFocus]="true"
    [cdkTrapFocusAutoCapture]="true"
    class="nib-drawer-wrapper"
  >
    <ng-content></ng-content>
  </div>
</div>
