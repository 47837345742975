import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnChanges,
  ViewEncapsulation,
} from '@angular/core';
import { AppShellService } from './app-shell.service';

/** @dynamic */
// tslint:disable-next-line: validate-decorators
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'nib-app-shell',
  },
  selector: 'nib-app-shell',
  styleUrls: ['app-shell.component.scss'],
  templateUrl: 'app-shell.component.html',
})
export class AppShellComponent implements OnChanges {
  /** Whether the main should be full height */
  @Input() fullHeight = true;

  /** Whether the main should be full width */
  @Input() fullWidth = true;

  /** Whether the header should be shown */
  @Input() showHeader = true;

  /** Whether the menu should be shown */
  @Input() showMenu = true;

  /** Whether the title should be shown */
  @Input() showTitle = true;

  /** Whether the main should show tabs */
  @Input() withTabs = true;

  /** Define if menu should be shown in grid */
  get containerColumns(): string {
    if (this.showMenu) {
      return this.appShellService.isMenuOpen.value ? 'auto 56px' : '0 auto';
    } else {
      return '0 auto';
    }
  }

  /** Define if menu should be shown in grid in viewport gt-sm */
  get containerColumnsGtSm(): string {
    return this.showMenu ? '240px auto' : '0 auto';
  }

  /** Define if header should be shown in grid */
  get containerRows(): string {
    if (this.showHeader) {
      return this.appShellService.isMenuOpen.value
        ? '72px calc(100% - 72px)'
        : '56px calc(100% - 56px)';
    } else {
      return '0 100%';
    }
  }

  /** Define if header should be shown in grid in viewport gt-sm */
  get containerRowsGtSm(): string {
    return this.showHeader ? '56px calc(100% - 56px)' : '0 100%';
  }

  /** Define if main should show its header */
  get mainRows(): string {
    if (this.showTitle) {
      return this.showHeader ? '97.2px calc(100% - 97.2px)' : '97.2px calc(100% - 97.2px)';
    } else {
      return '100%';
    }
  }

  /** Define if main should show its header in viewport gt-sm */
  get mainRowsGtXs(): string {
    return this.showTitle ? '97.2px calc(100% - 97.2px)' : '100%';
  }

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    readonly appShellService: AppShellService,
  ) {}

  ngOnChanges(): void {
    this.addClassToBody();
  }

  private addClassToBody(): void {
    if (this.showMenu) {
      this.document.body.classList.add('nib-body-with-menu');
    } else {
      this.document.body.classList.remove('nib-body-with-menu');
    }
  }
}
