import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { CardModule } from '../card/public-api';
import { FormActionComponent } from './form-action.component';
import { FormDescriptionComponent } from './form-description.component';
import { FormDividerComponent } from './form-divider.component';
import { FormFieldErrorComponent } from './form-field-error.component';
import { FormFieldHintComponent } from './form-field-hint.component';
import { FormFieldComponent } from './form-field.component';
import { FormGroupComponent } from './form-group.component';
import { FormTextComponent } from './form-text.component';
import { FormTitleComponent } from './form-title.component';
import { FormComponent } from './form.component';

@NgModule({
  imports: [CardModule, CommonModule, FlexModule],
  exports: [
    FormActionComponent,
    FormComponent,
    FormDescriptionComponent,
    FormDividerComponent,
    FormFieldComponent,
    FormFieldErrorComponent,
    FormFieldHintComponent,
    FormGroupComponent,
    FormTextComponent,
    FormTitleComponent,
  ],
  declarations: [
    FormActionComponent,
    FormComponent,
    FormDescriptionComponent,
    FormDividerComponent,
    FormFieldComponent,
    FormFieldErrorComponent,
    FormFieldHintComponent,
    FormGroupComponent,
    FormTextComponent,
    FormTitleComponent,
  ],
})
export class FormModule {}
