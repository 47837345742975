<div [formGroup]="form">
  <nib-form-group
    *ngFor="let formControlName of formControlsNames; trackBy: trackByFormControlName"
    class="nib-section nib-weekday-time-slots-form-field-section"
  >
    <nib-weekday-form-control
      [formControlName]="formControlName"
      [timeSteps]="timeSteps"
    ></nib-weekday-form-control>
  </nib-form-group>
</div>
<nib-datalist [name]="'timelist'" [timeSteps]="timeSteps" [type]="'time'"></nib-datalist>
