import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { FormSaverSnackbarService } from '../../../components/app-shell/app-shell-auth/form-saver-snackbar/form-saver-snackbar.service';
import { UserStateAction } from '../../../store/user/user.actions';
import { UserNotifications } from '../../../store/user/user.model';
import { UserSelectors } from '../../../store/user/user.selectors';
import { AccountNotificationsRouteFormValue } from './account-notifications-route-form-value.type';
import {
  mapAccountNotificationsToForm,
  mapAccountNotificationsToStore,
} from './account-notifications-route.helper';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nib-account-notifications-route',
  styleUrls: ['account-notifications-route.component.scss'],
  templateUrl: 'account-notifications-route.component.html',
})
export class AccountNotificationsRouteComponent implements OnDestroy, OnInit {
  @Dispatch() updateUserNotifications = (notifications: UserNotifications) =>
    new UserStateAction.Notifications.Update.Try(notifications);

  @Select(UserSelectors.notifications) notifications$!: Observable<
    UserNotifications['notifications']
  >;

  get form(): FormGroup {
    return this.formSaverSnackbarService.form;
  }

  constructor(
    // tslint:disable-next-line: max-line-length
    private readonly formSaverSnackbarService: FormSaverSnackbarService<AccountNotificationsRouteFormValue>,
  ) {}

  ngOnDestroy(): void {
    this.formSaverSnackbarService.cancel();
  }

  ngOnInit(): void {
    this.createForm();
    this.manageFormSavings();
    this.setInitialValues();
  }

  private createForm(): void {
    this.formSaverSnackbarService.initialize({
      account: new FormControl(
        { accountEmail: true, accountPush: true, accountPushWeb: true, accountSms: true },
        Validators.required,
      ),
      announcements: new FormControl(
        {
          announcementsEmail: true,
          announcementsPush: true,
          announcementsPushWeb: true,
          announcementsSms: true,
        },
        Validators.required,
      ),
      newsletter: new FormControl(
        {
          newsletterEmail: true,
          newsletterPush: true,
          newsletterPushWeb: true,
          newsletterSms: true,
        },
        Validators.required,
      ),
      service: new FormControl(
        { serviceEmail: true, servicePush: true, servicePushWeb: true, serviceSms: true },
        Validators.required,
      ),
    });
  }

  private manageFormSavings(): void {
    this.formSaverSnackbarService.onSave$.pipe(untilDestroyed(this)).subscribe(({ next }) => {
      this.updateUserNotifications(mapAccountNotificationsToStore(next));
    });
  }

  private setInitialValues(): void {
    this.notifications$.pipe(untilDestroyed(this)).subscribe(notifications => {
      this.formSaverSnackbarService.update(mapAccountNotificationsToForm(notifications));
    });
  }
}
