import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Actions, ofActionDispatched, ofActionSuccessful, Select } from '@ngxs/store';
import {
  AuthStateAction,
  PermissionsSelectors,
  PermissionsStateAction,
  RegistrationStatuses as Statuses,
  Roles,
} from '@nibol/auth';
import { AppSelectors } from '@nibol/store';
import { FormValueAccessor } from '@nibol/ui';
import { StateReset } from 'ngxs-reset-plugin';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { arrayContainsExactValues } from '../../../../helpers/array-contains-exact-values.helper';
import { UserState } from '../../../../store/user/user.state';
import { LoginRouteSelectors } from '../login-route.selectors';
import { LoginFormValue } from './login-form-value.type';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LoginFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LoginFormComponent),
      multi: true,
    },
  ],
  selector: 'nib-login-form',
  styleUrls: ['login-form.component.scss'],
  templateUrl: 'login-form.component.html',
})
export class LoginFormComponent
  extends FormValueAccessor<LoginFormValue>
  implements AfterContentInit, OnInit {
  @Dispatch() checkIdentity = (email: string) =>
    new AuthStateAction.CheckIdentity.Try({ method: 'email', token: email });
  @Dispatch() resetLoginProcess = () => [
    new AuthStateAction.Tenant.Change.Try('api'),
    new PermissionsStateAction.List.Remove.Try([Roles.couldLogIn]),
    new StateReset(UserState),
  ];

  @Select(PermissionsSelectors.couldLogIn) couldLogIn$!: Observable<boolean>;
  @Select(LoginRouteSelectors.isLoginWithGoogleEnabled)
  isLoginWithGoogleEnabled$!: Observable<boolean>;
  @Select(LoginRouteSelectors.isLoginWithMicrosoftEnabled)
  isLoginWithMicrosoftEnabled$!: Observable<boolean>;
  @Select(LoginRouteSelectors.isLoginWithOktaEnabled) isLoginWithOktaEnabled$!: Observable<boolean>;
  @Select(LoginRouteSelectors.isLoginWithPasswordEnabled)
  isLoginWithPasswordEnabled$!: Observable<boolean>;

  @SelectSnapshot(AppSelectors.platform) platform!: string;

  ssoErrorMessage$ = new BehaviorSubject<string | null>(null);
  form = new FormGroup({
    password: new FormControl(null, [Validators.required]),
    staySignedIn: new FormControl(false),
    username: new FormControl(null, [Validators.required, Validators.email]),
  });
  inProgress$ = new BehaviorSubject(false);

  get emailErrorMessage(): string | undefined {
    const errors = this.form.get('username')?.errors || {};

    return {
      ['email' as keyof ValidationErrors]: 'inputs_text_email_error_format',
      ['usernameNotExist' as keyof ValidationErrors]: 'inputs_text_email_error_usernamenotexist',
    }[Object.keys(errors)[0]];
  }

  get passwordErrorMessage(): string | undefined {
    const errors = this.form.get('password')?.errors || {};

    return {
      ['invalidUsernamePassword' as keyof ValidationErrors]: 'inputs_text_password_error_invalidusernamepassword',
    }[Object.keys(errors)[0]];
  }

  constructor(
    private readonly actions$: Actions,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  ngAfterContentInit(): void {
    this.forceEmailChecking();
  }

  ngOnInit(): void {
    this.manageEmailChecking();
    this.manageInvalidUsernameOrPassword();
    this.manageLogin();
  }

  changeEmail(): void {
    this.form.get('username')?.reset();
    this.ssoErrorMessage$.next(null);
    this.resetLoginProcess();
  }

  manageErrorMessage(message?: string) {
    switch (message) {
      case 'azure:window-closed':
        setTimeout(() => {
          this.ssoErrorMessage$.next('login_sso_errorwindowclosed_text');
        }, 2000);
        break;

      case 'google:window-closed':
        setTimeout(() => {
          this.ssoErrorMessage$.next('login_sso_errorwindowclosed_text');
        }, 2000);
        break;

      case 'okta:window-closed':
        setTimeout(() => {
          this.ssoErrorMessage$.next('login_sso_errorwindowclosed_text');
        }, 2000);
        break;

      default:
        this.ssoErrorMessage$.next(message ? 'login_sso_error_text' : null);
        break;
    }
  }

  verifyEmail(): void {
    this.inProgress$.next(true);
    this.checkIdentity(this.form.get('username')?.value);
  }

  private manageEmailChecking(): void {
    this.actions$
      .pipe(
        ofActionSuccessful(AuthStateAction.CheckIdentity.Success),
        tap(() => {
          this.inProgress$.next(false);
        }),
        untilDestroyed(this),
      )
      .subscribe(
        ({
          response: { registration_statuses: status },
        }: AuthStateAction.CheckIdentity.Success) => {
          if (arrayContainsExactValues(status, [Statuses.not_registered])) {
            this.form.get('username')?.setErrors({ usernameNotExist: true });
            this.changeDetectorRef.markForCheck();
          }
        },
      );

    this.actions$
      .pipe(
        ofActionSuccessful(AuthStateAction.CheckIdentity.Failure),
        tap(() => {
          this.inProgress$.next(false);
        }),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.inProgress$.next(false);
      });
  }

  private forceEmailChecking(): void {
    if (this.form.get('username')?.valid) {
      this.verifyEmail();
    }
  }

  private manageInvalidUsernameOrPassword(): void {
    this.actions$
      .pipe(
        ofActionSuccessful(AuthStateAction.Login.Failure),
        filter(payload =>
          ['mail-not-found', 'wrong-password', 'no-valid-auth'].includes(payload.error.error),
        ),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.form.get('password')?.setErrors({ invalidUsernamePassword: true });
        this.changeDetectorRef.markForCheck();
      });
  }

  private manageLogin(): void {
    this.actions$
      .pipe(ofActionDispatched(AuthStateAction.Login.Try), untilDestroyed(this))
      .subscribe(() => {
        this.inProgress$.next(true);
      });

    this.actions$
      .pipe(ofActionSuccessful(AuthStateAction.Login.Failure), untilDestroyed(this))
      .subscribe(() => {
        this.inProgress$.next(false);
      });
  }
}
