import { Injectable } from '@angular/core';
import { environment } from '@marketplace/environment';
import { FeatureToggle } from '@marketplace/feature-toggles';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Navigate } from '@ngxs/router-plugin';
import { Action, NgxsAfterBootstrap, State, StateContext } from '@ngxs/store';
import {
  AuthSelectors,
  AuthStateAction,
  PermissionsStateAction,
  RegistrationStatuses,
  Roles,
} from '@nibol/auth';
import { UserStateAction as NibolUserStateAction } from '@nibol/shared';
import { AppStateAction, FeatureTogglesState, States } from '@nibol/store';
import { addDays, startOfToday } from 'date-fns';
import { StateReset } from 'ngxs-reset-plugin';
import { AreasState, AreasStateAction } from './areas';
import { BuildingState, BuildingStateAction } from './building';
import { BuildingsState, BuildingsStateAction } from './buildings';
import { CompanyState, CompanyStateAction } from './company';
import { CurrentBuildingState, CurrentBuildingStateAction } from './current-building';
import { ReservationsState, ReservationsStateAction } from './reservations';
import { UserState, UserStateAction } from './user';
import { VenueState, VenueStateAction } from './venue';
import { VenuesState, VenuesStateAction } from './venues';

@State({
  name: 'saga',
})
@Injectable()
export class Saga implements NgxsAfterBootstrap {
  @SelectSnapshot(CurrentBuildingState)
  currentBuildingId!: string;
  @SelectSnapshot(AuthSelectors.isAuthenticated) isAuthenticated!: boolean;
  @SelectSnapshot(FeatureTogglesState) featureToggles!: {
    [name in FeatureToggle]: boolean;
  };

  ngxsAfterBootstrap({ dispatch }: StateContext<unknown>): void {
    dispatch(new AppStateAction.Init.Try());
  }

  @Action(BuildingStateAction.Item.Read.Success)
  buildingItemReadSuccess(
    { dispatch }: StateContext<unknown>,
    { building }: BuildingStateAction.Item.Read.Success,
  ) {
    dispatch(new BuildingsStateAction.List.Update.Try(building));
  }

  @Action(BuildingStateAction.Item.Update.Success)
  buildingItemUpdateSuccess(
    { dispatch }: StateContext<unknown>,
    { building }: BuildingStateAction.Item.Update.Success,
  ) {
    dispatch(new BuildingsStateAction.List.Update.Try(building));
  }

  @Action(BuildingStateAction.Venue.Create.Success)
  buildingVenuesCreateSuccess(
    { dispatch }: StateContext<unknown>,
    { building }: BuildingStateAction.Item.Update.Success,
  ) {
    dispatch(new VenuesStateAction.List.Read.Try(building.id));
  }

  @Action(BuildingsStateAction.List.Read.Success)
  buildingsListReadSuccess(
    { dispatch }: StateContext<unknown>,
    { buildings }: BuildingsStateAction.List.Read.Success,
  ) {
    if (typeof buildings.find(building => building.id === this.currentBuildingId) === 'undefined') {
      dispatch(new CurrentBuildingStateAction.Change.Try(buildings[0]?.id));
    } else {
      dispatch(new CurrentBuildingStateAction.Change.Try(this.currentBuildingId));
    }
  }

  @Action(CurrentBuildingStateAction.Change.Success)
  async changeCurrentBuildingSuccess(
    { dispatch }: StateContext<unknown>,
    { id: currentBuildingId }: CurrentBuildingStateAction.Change.Success,
  ) {
    if (currentBuildingId) {
      dispatch(new AppStateAction.State.Change.Try(States['in-progress']));
      await dispatch([
        new CompanyStateAction.Read.Try(currentBuildingId),
        new BuildingStateAction.Item.Read.Try(currentBuildingId),
        new VenuesStateAction.List.Read.Try(currentBuildingId),
        new AreasStateAction.List.Read.Try(),
      ]).toPromise();

      await dispatch([
        new ReservationsStateAction.List.Read.Try(
          currentBuildingId,
          addDays(startOfToday(), -90),
          addDays(startOfToday(), 42),
        ),
      ]).toPromise();

      dispatch(new AppStateAction.State.Change.Try(States.ready, 1000));
    } else {
      dispatch([
        new CompanyStateAction.Read.Failure('empty currentBuildingId'),
        new BuildingStateAction.Item.Read.Failure('empty currentBuildingId'),
        new VenuesStateAction.List.Read.Failure('empty currentBuildingId'),
        new ReservationsStateAction.List.Read.Failure('empty currentBuildingId'),
      ]);
    }
  }

  // tslint:disable-next-line: cyclomatic-complexity
  @Action(AuthStateAction.CheckIdentity.Success)
  checkIdentitySuccess(
    { dispatch }: StateContext<unknown>,
    {
      body: { token: email },
      response: { registration_statuses },
    }: AuthStateAction.CheckIdentity.Success,
  ) {
    if (registration_statuses.includes(RegistrationStatuses.mkt_manager)) {
      dispatch(new PermissionsStateAction.List.Add.Try([Roles.couldLogIn]));
    } else if (registration_statuses.includes(RegistrationStatuses.mkt_user)) {
      dispatch(new Navigate(['/access-denied'], { email, error: 'marketplace-user' }));
    } else if (registration_statuses.includes(RegistrationStatuses.bus_employee)) {
      dispatch(new Navigate(['/access-denied'], { email, error: 'business-employee' }));
    } else if (registration_statuses.includes(RegistrationStatuses.bus_manager)) {
      dispatch(new Navigate(['/access-denied'], { email, error: 'business-manager' }));
    } else if (!registration_statuses.includes(RegistrationStatuses.not_registered)) {
      dispatch(new PermissionsStateAction.List.Add.Try([Roles.couldLogIn]));
    }
  }

  @Action(CompanyStateAction.Read.Success)
  companyReadSuccess(
    { dispatch }: StateContext<unknown>,
    { company, buildingId }: CompanyStateAction.Read.Success,
  ) {
    dispatch(new BuildingsStateAction.List.Update.Try({ company, id: buildingId }));
  }

  @Action(AppStateAction.Init.Success)
  async initSuccess({ dispatch }: StateContext<unknown>) {
    if (this.isAuthenticated) {
      dispatch(new BuildingsStateAction.List.Read.Try());
    }
  }

  @Action(AuthStateAction.Login.Success)
  @Action(AuthStateAction.LoginTemporary.Success)
  async loginSuccess({ dispatch }: StateContext<unknown>) {
    await dispatch([new BuildingsStateAction.List.Read.Try()]).toPromise();

    dispatch(new Navigate([environment.homepage]));
  }

  @Action(AuthStateAction.Logout.Success)
  logoutSuccess({ dispatch }: StateContext<unknown>) {
    dispatch(
      new StateReset(
        AreasState,
        BuildingsState,
        BuildingState,
        CompanyState,
        CurrentBuildingState,
        ReservationsState,
        UserState,
        VenueState,
        VenuesState,
      ),
    );
  }

  @Action(AuthStateAction.Registration.Success)
  async registrationSuccess({ dispatch }: StateContext<unknown>) {
    await dispatch([
      new PermissionsStateAction.List.Change.Try([Roles.manager]),
      new NibolUserStateAction.Item.Read.Try(),
      new BuildingsStateAction.List.Read.Try(),
    ]).toPromise();

    dispatch([new Navigate(['/become-a-host/space/mode'])]);
  }

  @Action(ReservationsStateAction.List.Read.Success)
  reservationsReadSuccess(
    { dispatch }: StateContext<unknown>,
    { buildingId, reservations }: ReservationsStateAction.List.Read.Success,
  ) {
    dispatch(new BuildingsStateAction.List.Update.Try({ id: buildingId, reservations }));
  }

  @Action(UserStateAction.AssociatedVenueAndBuilding.Create.Success)
  async userAssociatedVenueAndBuildingCreateSuccess({ dispatch }: StateContext<unknown>) {
    await dispatch(new BuildingsStateAction.List.Read.Try()).toPromise();

    if (this.currentBuildingId) {
      dispatch(new VenuesStateAction.List.Read.Try(this.currentBuildingId));
    }
  }

  @Action(VenuesStateAction.List.Read.Success)
  venuesReadSuccess(
    { dispatch }: StateContext<unknown>,
    { buildingId, venues }: VenuesStateAction.List.Read.Success,
  ) {
    dispatch(new BuildingsStateAction.List.Update.Try({ id: buildingId, venues }));
  }

  @Action(VenuesStateAction.List.Update.Success)
  venuesUpdateSuccess(
    { dispatch }: StateContext<unknown>,
    { buildingId, venues }: VenuesStateAction.List.Update.Success,
  ) {
    dispatch(new BuildingsStateAction.List.Update.Try({ id: buildingId, venues }));
  }

  @Action(VenueStateAction.ExportCalendarUrl.Read.Success)
  venueExportCalendarUrlReadSuccess(
    { dispatch }: StateContext<unknown>,
    { venue }: VenueStateAction.ExportCalendarUrl.Read.Success,
  ) {
    dispatch(new VenuesStateAction.List.Update.Try(venue));
  }

  @Action(VenueStateAction.ImportedCalendars.Read.Success)
  venueImportedCalendarUrlReadSuccess(
    { dispatch }: StateContext<unknown>,
    { venue }: VenueStateAction.ExportCalendarUrl.Read.Success,
  ) {
    dispatch(new VenuesStateAction.List.Update.Try(venue));
  }

  @Action(VenueStateAction.Item.Delete.Success)
  venueItemDeleteSuccess(
    { dispatch }: StateContext<unknown>,
    { venue: { id } }: VenueStateAction.Item.Delete.Success,
  ) {
    dispatch([new VenuesStateAction.List.Remove.Try(id), new Navigate([environment.homepage])]);
  }

  @Action(VenueStateAction.Item.Read.Failure)
  venueItemReadFailure({ dispatch }: StateContext<unknown>) {
    dispatch(new AppStateAction.State.Change.Try(States.ready, 1000));
  }

  @Action(VenueStateAction.Item.Read.Success)
  venueItemReadSuccess({ dispatch }: StateContext<unknown>) {
    dispatch(new AppStateAction.State.Change.Try(States.ready, 1000));
  }

  @Action(VenueStateAction.Item.Read.Try)
  venueItemReadTry({ dispatch }: StateContext<unknown>) {
    dispatch(new AppStateAction.State.Change.Try(States['waiting-for-response'], 1000));
  }

  @Action(VenueStateAction.Item.Update.Success)
  venueItemUpdateSuccess(
    { dispatch }: StateContext<unknown>,
    { venue }: VenueStateAction.Item.Update.Success,
  ) {
    dispatch(new VenuesStateAction.List.Update.Try(venue));
  }

  @Action(VenueStateAction.NameAndAddress.Read.Success)
  venueNameAndAddressReadSuccess(
    { dispatch }: StateContext<unknown>,
    { venue }: VenueStateAction.NameAndAddress.Read.Success,
  ) {
    dispatch(new VenuesStateAction.List.Update.Try(venue));
  }

  @Action(VenueStateAction.Photos.Read.Success)
  venuePhotosReadSuccess(
    { dispatch }: StateContext<unknown>,
    { venue }: VenueStateAction.Photos.Read.Success,
  ) {
    dispatch(new VenuesStateAction.List.Update.Try(venue));
  }

  @Action(VenueStateAction.SeatsAndAmenities.Read.Success)
  venueSeatsAndAmenitiesReadSuccess(
    { dispatch }: StateContext<unknown>,
    { venue }: VenueStateAction.SeatsAndAmenities.Read.Success,
  ) {
    dispatch(new VenuesStateAction.List.Update.Try(venue));
  }

  @Action(VenueStateAction.Settings.Read.Success)
  venueSettingsReadSuccess(
    { dispatch }: StateContext<unknown>,
    { venue }: VenueStateAction.Settings.Read.Success,
  ) {
    dispatch(new VenuesStateAction.List.Update.Try(venue));
  }

  @Action(VenueStateAction.SpecialClosingTimes.Read.Success)
  venueSpecialClosingTimesReadSuccess(
    { dispatch }: StateContext<unknown>,
    { venue }: VenueStateAction.SpecialClosingTimes.Read.Success,
  ) {
    dispatch(new VenuesStateAction.List.Update.Try(venue));
  }

  @Action(VenueStateAction.WorkHoursHourly.Read.Success)
  venueWorkHoursReadSuccess(
    { dispatch }: StateContext<unknown>,
    { venue }: VenueStateAction.WorkHoursHourly.Read.Success,
  ) {
    dispatch(new VenuesStateAction.List.Update.Try(venue));
  }
}
