import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Select } from '@ngxs/store';
import { AuthStateAction } from '@nibol/auth';
import { AppSelectors, AppStateModel, RouterSelectors } from '@nibol/store';
import { Observable } from 'rxjs';
import { UserSelectors } from '../../../store/public-api';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nib-permission-denied-route',
  styleUrls: ['permission-denied-route.component.scss'],
  templateUrl: 'permission-denied-route.component.html',
})
export class PermissionDeniedRouteComponent {
  @Dispatch() logout = () => new AuthStateAction.Logout.Try();

  @Select(AppSelectors.supportContacts)
  supportContacts$!: Observable<AppStateModel['supportContacts']>;

  @SelectSnapshot(UserSelectors.email) email!: string;
  @SelectSnapshot(RouterSelectors.failureMessage) failureMessage!: string;

  constructor(private readonly sanitizer: DomSanitizer) {}

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  trackBySupportContactName(
    _index: number,
    supportContact: AppStateModel['supportContacts'][number],
  ): string {
    return supportContact.name;
  }
}
