<h1>{{ 'signup_title' | translate }}</h1>
<form [formGroup]="form" (ngSubmit)="registration()">
  <nib-signup-form
    [disabled]="isSignupFormDisabled$ | async"
    [formControlName]="'registration'"
  ></nib-signup-form>
  <aside
    class="nib-signup-route-google-recaptcha-disclaimer nib-heading-5"
    [innerHTML]="'signup_recaptcha_disclaimer' | translate"
  ></aside>
</form>
