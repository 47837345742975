import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormValueAccessor, SelectOption } from '@nibol/ui';
import { roundToNearestMinutes } from 'time-turner-js';
import {
  Availability,
  TimeSlot,
  TimeSlotsFormFieldValue,
} from './time-slots-form-field-value.type';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeSlotsFormFieldComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TimeSlotsFormFieldComponent),
      multi: true,
    },
  ],
  selector: 'nib-time-slots-form-field',
  styleUrls: ['time-slots-form-field.component.scss'],
  templateUrl: 'time-slots-form-field.component.html',
})
export class TimeSlotsFormFieldComponent
  extends FormValueAccessor<TimeSlotsFormFieldValue>
  implements OnInit {
  @Input() enableAvailabilityStatus = true;
  @Input() enableAddingButton = true;
  @Input() states: Array<SelectOption<Availability>> = [];
  @Input() timeSteps = 15;

  form = new FormGroup(
    {
      availability: new FormControl(''),
      timeSlots: new FormControl({}),
    },
    (control: AbstractControl) => {
      return this.validator(control);
    },
  );

  timeSlotsControls: FormGroup[] = [];

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.addTimeSlotOnActivateAvailabilityStatus();
  }

  addNewTimeSlot(event?: MouseEvent): void {
    if (event) {
      event.preventDefault();
    }

    this.timeSlotsControls = [
      ...this.timeSlotsControls,
      this.buildTimeslotControls({ from: '', to: '' }),
    ];

    this.updateFormValue();
  }

  removeTimeSlot(index: number): void {
    this.timeSlotsControls = this.timeSlotsControls.filter(
      (_formGroup, formGroupIndex) => formGroupIndex !== index,
    );

    this.updateFormValue();
  }

  trackByTimeSlotIndex(index: number): number {
    return index;
  }

  writeValue(value: TimeSlotsFormFieldValue): void {
    if (value && value.timeSlots) {
      this.timeSlotsControls = Object.values(value.timeSlots).map(slot =>
        this.buildTimeslotControls(slot),
      );
    }

    super.writeValue({
      availability: value.availability || 'reservation-not-accepted',
      timeSlots: value.timeSlots || {},
    });

    this.changeDetectorRef.markForCheck();
  }

  private addTimeSlotOnActivateAvailabilityStatus(): void {
    const availabilityControl = this.form.get('availability');

    if (availabilityControl) {
      availabilityControl.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
        if (value === 'reservation-not-accepted') {
          this.writeValue({
            availability: value,
            timeSlots: {},
          });
        }

        if (value === 'reservation-accepted') {
          this.addNewTimeSlot();
        }
      });
    }
  }

  private buildTimeslotControls(slot?: TimeSlot): FormGroup {
    const fromControl = new FormControl(slot?.from, Validators.required);
    const toControl = new FormControl(slot?.to, Validators.required);
    const formGroup = new FormGroup(
      {
        from: fromControl,
        to: toControl,
      },
      (control: AbstractControl) => {
        if (fromControl.errors) {
          return fromControl.errors;
        }

        if (toControl.errors) {
          return toControl.errors;
        }

        const value = control.value as TimeSlot;

        return value.from < value.to ? null : { error: 'invalid range' };
      },
    );

    formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      fromControl.setValue(roundToNearestMinutes(value.from, this.timeSteps), { emitEvent: false });
      toControl.setValue(roundToNearestMinutes(value.to, this.timeSteps), { emitEvent: false });

      this.updateFormValue();
    });

    return formGroup;
  }

  private updateFormValue(): void {
    const updatedTimeslots: { [index: number]: TimeSlot } = {};

    this.timeSlotsControls
      .map(timeSlot => timeSlot.value as TimeSlot)
      .forEach((value, index) => {
        updatedTimeslots[index] = value;
      });

    this.form.patchValue({ timeSlots: updatedTimeslots });
  }

  private validator(control: AbstractControl): ValidationErrors | null {
    const value: TimeSlotsFormFieldValue = control.value;

    if (value.availability === 'reservation-accepted' && Object.keys(value.timeSlots).length > 0) {
      return (
        this.timeSlotsControls.map(formGroup => formGroup.errors).find(error => error !== null) ||
        null
      );
    }

    if (value.availability === 'reservation-not-accepted') {
      return null;
    }

    return { error: 'invalid availability' };
  }
}
