import { Injectable } from '@angular/core';
import { UserService } from '@marketplace/api/user';
import { Action, State, StateContext } from '@ngxs/store';
import { UserState as NibolUserState, UserStateModel } from '@nibol/shared';
import { catchError, tap } from 'rxjs/operators';
import { UserStateAction } from './user.actions';
import { mapAssociatedBuildingToApi, mapAssociatedVenueToApi } from './user.helper';

@State<UserStateModel>({
  name: 'usermarketplace',
})
@Injectable()
export class UserState extends NibolUserState {
  constructor(protected readonly userService: UserService) {
    super(userService);
  }

  @Action(UserStateAction.AssociatedVenueAndBuilding.Create.Try)
  createAssociatedVenueAndBuilding(
    { dispatch, getState }: StateContext<UserStateModel>,
    { building, venue }: UserStateAction.AssociatedVenueAndBuilding.Create.Try,
  ) {
    return this.userService
      .createAssociatedVenueAndBuilding({
        building: mapAssociatedBuildingToApi(building),
        venue: mapAssociatedVenueToApi(venue),
      })
      .pipe(
        catchError(error => {
          dispatch(new UserStateAction.AssociatedVenueAndBuilding.Create.Failure(error));

          throw error;
        }),
        tap(response => {
          if (response.status) {
            dispatch(new UserStateAction.AssociatedVenueAndBuilding.Create.Success(getState()));
          } else {
            dispatch(
              new UserStateAction.AssociatedVenueAndBuilding.Create.Failure(response.status),
            );

            throw response.status;
          }
        }),
      );
  }
}
