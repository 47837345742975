import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'nib-floating-panel',
  styleUrls: ['floating-panel.component.scss'],
  templateUrl: 'floating-panel.component.html',
})
export class FloatingPanelComponent {}
