import { Injectable } from '@angular/core';
import { MatDateFormats, NativeDateAdapter } from '@angular/material/core';

@Injectable({ providedIn: 'root' })
export class NibDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: string): string {
    if (displayFormat === 'customInput') {
      const year = date.getFullYear();
      let day: string = date.getDate().toString();
      let month: string = (date.getMonth() + 1).toString();

      day = +day < 10 ? '0' + day : day;
      month = +month < 10 ? '0' + month : month;

      return `${year}-${month}-${day}`;
    }

    return super.format(date, displayFormat);
  }

  getFirstDayOfWeek(): number {
    return 1;
  }
}

export const NIB_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'customInput',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};
