import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TermsAndPrivacyPolicyRouteComponent } from './terms-and-privacy-policy-route/terms-and-privacy-policy-route.component';
import { TermsAndPrivacyPolicyTitleResolver } from './terms-and-privacy-policy-title.resolver';
import { TermsAndPrivacyPolicyResolver } from './terms-and-privacy-policy.resolver';

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forChild([
      {
        // root is '/terms-and-privacy-policy'
        path: '',
        component: TermsAndPrivacyPolicyRouteComponent,
        resolve: { TermsAndPrivacyPolicyResolver, title: TermsAndPrivacyPolicyTitleResolver },
      },
    ]),
  ],
})
export class TermsAndPrivacyPolicyRoutingModule {}
