import { Inject, Injectable } from '@angular/core';
import { STRIPE_ELEMENTS_OPTIONS } from './core/stripe-elements-options.token';
import { STRIPE_KEY } from './core/stripe-key.token';

/**
 * Centralize using of Stripe methods
 */
/** @dynamic */
@Injectable({ providedIn: 'root' })
export class StripeService {
  /** Stripe instance */
  private readonly instance = Stripe(this.stripeKey);

  constructor(
    @Inject(STRIPE_KEY) private readonly stripeKey: string,
    @Inject(STRIPE_ELEMENTS_OPTIONS)
    private readonly stripeElementsOptions: stripe.elements.ElementsCreateOptions,
  ) {}

  /**
   * Create stripe element
   * @param type Stripe element to create
   * @param styleOptions Style options to apply
   */
  create(
    type: stripe.elements.elementsType,
    styleOptions: stripe.elements.ElementsOptions,
  ): stripe.elements.Element {
    return this.instance.elements(this.stripeElementsOptions).create(type, styleOptions);
  }

  /**
   * Create a new source
   * @param element Stripe element
   * @param sourceData Data to use to connect element to Stripe
   */
  async createSource(
    element: stripe.elements.Element,
    sourceData: {
      currency: 'eur' | 'usd';
      owner: { name: string; email: string };
      type: 'sepa_debit';
    },
  ): Promise<stripe.SourceResponse> {
    return this.instance.createSource(element, sourceData);
  }
}
