<nib-form>
  <nib-form-title>{{ 'account_logout_subtitle' | translate }}</nib-form-title>
  <nib-form-description>
    {{ 'account_logout_body' | translate }}
  </nib-form-description>
  <div [fxLayoutAlign]="'end'">
    <nib-button
      [fullWidth]="true"
      [fxFlex]="100"
      [fxFlex.gt-md]="'fit-content'"
      (onClick)="doLogout()"
    >
      {{ 'account_logout_button' | translate }}
    </nib-button>
  </div>
</nib-form>
