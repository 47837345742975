import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Observable } from 'rxjs';
import { AuthSelectors } from '../store/auth.selectors';
import { API_ROOT_URL } from '../tokens/api-root-url.token';
import { AUTH_ROOT_URL } from '../tokens/auth-root-url.token';

@Injectable()
export class AppendTenantToApiEndpointHeaderInterceptor implements HttpInterceptor {
  @SelectSnapshot(AuthSelectors.tenant) tenant!: string;

  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    @Inject(AUTH_ROOT_URL) private readonly authRootUrl: string,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.startsWith(this.apiRootUrl) || request.url.startsWith(this.authRootUrl)) {
      return next.handle(
        request.clone({
          url: request.url.replace('https://api', `https://${this.tenant}`),
        }),
      );
    }

    return next.handle(request);
  }
}
