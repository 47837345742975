<section [formGroup]="form" [fxLayout]="'column'" [fxLayoutGap]="'16px'">
  <nib-form-field *ngIf="enableAvailabilityStatus" [fxFlex]="100">
    <nib-select
      [formControlName]="'availability'"
      [fullWidth]="true"
      [label]="'inputs_text_times_status_label' | translate"
      [options]="states"
    ></nib-select>
  </nib-form-field>
  <ng-container *ngIf="form.value.availability === 'reservation-accepted'">
    <div
      *ngFor="let timeslot of timeSlotsControls; let i = index; trackBy: trackByTimeSlotIndex"
      class="nib-time-slots-form-field-container"
    >
      <div
        [class.nib-time-slots-form-field-invalid]="
          timeslot.controls.from.value && timeslot.controls.to.value && timeslot.invalid
        "
        [fxFlex]="100"
        [fxLayout]="'column'"
        [fxLayout.gt-sm]="'row'"
        [fxLayoutGap]="'16px'"
      >
        <nib-form-field [fxFlex]="100" [fxFlex.gt-sm]="50">
          <nib-input
            [formControl]="timeslot.controls.from"
            [fullWidth]="true"
            [label]="'inputs_text_times_from_label' | translate"
            [list]="'timelist'"
            [step]="timeSteps * 60"
            [type]="'time'"
          ></nib-input>
        </nib-form-field>
        <nib-form-field [fxFlex]="100" [fxFlex.gt-sm]="50">
          <nib-input
            [formControl]="timeslot.controls.to"
            [fullWidth]="true"
            [label]="'inputs_text_times_to_label' | translate"
            [list]="'timelist'"
            [step]="timeSteps * 60"
            [type]="'time'"
          ></nib-input>
        </nib-form-field>
      </div>
      <nib-button
        *ngIf="enableAddingButton && i > 0"
        [color]="'tertiary'"
        (onClick)="removeTimeSlot(i)"
        class="nib-time-slots-form-field-remove-timeslot"
      >
        <nib-icon [color]="'var(--color-gray-4)'" [name]="'close'"></nib-icon>
      </nib-button>
    </div>
    <nib-form-action *ngIf="enableAddingButton" [fxLayoutAlign]="'end'">
      <nib-button
        [fullWidth]="true"
        [fxFlex]="100"
        [fxFlex.gt-md]="'fit-content'"
        (onClick)="addNewTimeSlot($event)"
      >
        {{ 'main_button_add_time_slots' | translate }}
      </nib-button>
    </nib-form-action>
  </ng-container>
</section>
<nib-datalist [name]="'timelist'" [timeSteps]="timeSteps" [type]="'time'"></nib-datalist>
