<div class="nib-file-uploader-wrapper">
  <ng-container *ngIf="value">
    <div [style.background-image]="'url(' + value! + ')'" class="nib-file-uploader-preview"></div>
  </ng-container>
  <nib-progress-spinner *ngIf="progress; else dropZone" [value]="progress"></nib-progress-spinner>
  <ng-template #dropZone>
    <label
      (dragleave)="onDragLeave($event)"
      (dragover)="onDragOver($event)"
      (drop)="onDrop($event)"
      [ngClass]="{
        'nib-file-uploader-dragover': isDraggingOver | async,
        'nib-file-uploader-dragover-error': isDraggingOverError | async
      }"
      class="nib-file-uploader-drop-zone"
    >
      <nib-icon
        [class.nib-file-uploader-icon-with-preview]="value"
        [color]="'var(--color-gray-4)'"
        [name]="icon"
        class="nib-file-uploader-icon"
      ></nib-icon>
      <div class="nib-file-uploader-title">{{ translations.title }}</div>
      <div class="nib-file-uploader-description">{{ translations.description }}</div>
      <input
        #fileInput
        [accept]="accept"
        (change)="onInputChange(fileInput)"
        class="nib-file-uploader-file-input"
        type="file"
      />
    </label>
  </ng-template>
</div>
