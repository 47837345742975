import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { ButtonModule } from '../button/button.module';
import { IconModule } from '../icon/icon.module';
import { RichTextEditorComponent } from './rich-text-editor.component';

@NgModule({
  imports: [ButtonModule, CommonModule, IconModule, FormsModule, QuillModule.forRoot()],
  exports: [RichTextEditorComponent],
  declarations: [RichTextEditorComponent],
})
export class RichTextEditorModule {}
