import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SwUpdate } from '@angular/service-worker';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Action, State, StateContext } from '@ngxs/store';
import {
  AuthSelectors,
  AuthStateAction,
  PermissionsStateAction,
  PLATFORM,
  Roles,
} from '@nibol/auth';
import {
  AppSelectors,
  AppStateAction,
  Breadcrumb,
  Platform,
  RouterSelectors,
  VersionsStateAction,
  VersionsStateModelType,
  VersionStateAction,
  VersionStateModelType,
} from '@nibol/store';
import {
  TranslationSelectors,
  TranslationService,
  TranslationStateAction,
  TranslationStateModel,
} from '@nibol/translation';
import { ToastService } from '@nibol/ui';
import { isObservable } from 'rxjs';
import { filter, first, takeWhile } from 'rxjs/operators';
import { PLATFORM_NAME } from '../core/tokens/platform-name.token';
import { VERSION } from '../core/tokens/version.token';
import { VERSIONS } from '../core/tokens/versions.token';
import { FrontendySelectors } from './frontendy.selectors';

@State({
  name: 'appsaga',
})
@Injectable()
export class AppSaga {
  @SelectSnapshot(RouterSelectors.failureMessage) failureMessage!: string;
  @SelectSnapshot(AuthSelectors.isAuthenticated) isAuthenticated!: boolean;
  @SelectSnapshot(AppSelectors.notifications) notifications!: boolean;
  @SelectSnapshot(FrontendySelectors.title) screenTitle!: Breadcrumb['name'];
  @SelectSnapshot(TranslationSelectors.state) translationState!: TranslationStateModel['state'];

  constructor(
    @Inject(PLATFORM_NAME) private readonly platformName: string,
    @Inject(PLATFORM) private readonly platform: Platform,
    @Inject(VERSION) private readonly version: VersionStateModelType,
    @Inject(VERSIONS) private readonly versions: VersionsStateModelType,
    private readonly title: Title,
    private readonly swUpdate: SwUpdate,
    private readonly toastService: ToastService,
    private readonly translationService: TranslationService,
  ) {}

  @Action(AppStateAction.Init.Success)
  initSuccess({ dispatch }: StateContext<unknown>) {
    dispatch([
      new AppStateAction.Platform.Change.Try(this.platform),
      new VersionStateAction.Change.Try(this.version),
      new TranslationStateAction.Init.Try(),
    ]);

    this.initPWAChangesChecking();

    if (this.isAuthenticated) {
      dispatch(new AuthStateAction.RefreshToken.Try());
    } else {
      const code = new URLSearchParams(document.location.search).get('code');

      if (code) {
        dispatch(new AuthStateAction.LoginTemporary.Try({ code }));
      } else {
        dispatch(new PermissionsStateAction.List.Change.Try([Roles.guest]));
      }
    }
  }

  @Action(AppStateAction.Init.Try)
  initTry({ dispatch }: StateContext<unknown>) {
    dispatch(new VersionsStateAction.Init.Try(this.versions));
  }

  @Action(AppStateAction.State.Ready.Success)
  stateReadySuccess() {
    if (this.notifications && this.failureMessage) {
      switch (this.failureMessage) {
        default:
          this.toastService.open(
            this.translationService.translate('main_genericerror_title'),
            this.translationService.translate('main_genericerror_text'),
            'negative',
          );
          break;
      }
    }
  }

  @Action(RouterNavigation)
  @Action(TranslationStateAction.State.Change.Success)
  async changeHTMLTitle() {
    if (this.translationState === 'ready') {
      if (isObservable(this.screenTitle)) {
        this.screenTitle
          .pipe(
            filter(screenTitle => typeof screenTitle !== 'undefined'),
            first(),
          )
          .subscribe(screenTitle => {
            this.title.setTitle(`${screenTitle} - ${this.platformName}`);
          });
      } else {
        this.title.setTitle(
          `${this.translationService.translate(this.screenTitle)} - ${this.platformName}`,
        );
      }
    }
  }

  private initPWAChangesChecking(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.pipe(takeWhile(() => this.swUpdate.isEnabled)).subscribe(() => {
        if (confirm(this.translationService.translate('main_pwa_ask_to_update'))) {
          window.location.reload();
        }
      });

      this.swUpdate.activated
        .pipe(
          filter(event => !!event),
          first(),
        )
        .subscribe(() => {
          if (this.notifications) {
            this.toastService.open(
              this.translationService.translate('main_pwa_new_version_title'),
              this.translationService.translate('main_pwa_new_version_text'),
            );
          }
        });
    }
  }
}
