// tslint:disable: no-namespace max-classes-per-file

import { TranslationStateModel } from './translation.model';

export namespace TranslationStateAction {
  export namespace CurrentLanguage {
    export namespace Change {
      export class Try {
        static readonly type = '[Translation] try Change Current Language';
        constructor(public currentLanguage: TranslationStateModel['currentLanguage']) {}
      }
      export class Success {
        static readonly type = '[Translation] then Change Current Language success';
        constructor(public translation: TranslationStateModel) {}
      }
      export class Failure {
        static readonly type = '[Translation] catch Change Current Language failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
      export class Cancel {
        static readonly type = '[Translation] catch Change Current Language cancel';
        constructor(public reason: string) {}
      }
    }
  }

  export namespace Init {
    export class Try {
      static readonly type = '[Translation] try Init';
    }
    export class Success {
      static readonly type = '[Translation] then Init success';
      constructor(public translation: TranslationStateModel) {}
    }
    export class Failure {
      static readonly type = '[Translation] catch Init failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace State {
    export namespace Change {
      export class Try {
        static readonly type = '[Translation] try Change State';
        constructor(public state: TranslationStateModel['state'], public delay = 0) {}
      }
      export class Success {
        static readonly type = '[Translation] then Change State success';
        constructor(public translation: TranslationStateModel) {}
      }
      export class Failure {
        static readonly type = '[Translation] catch Change State failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }
}
