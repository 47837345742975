import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxFeatureToggleCanActivateGuard } from 'ngx-feature-toggle';
import { ResetPasswordRouteComponent } from './reset-password-route/reset-password-route.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        // root is '/reset-password'
        path: '',
        component: ResetPasswordRouteComponent,
        canActivate: [NgxFeatureToggleCanActivateGuard],
        data: {
          featureToggle: ['TOGGLE_ENABLE_RESET_PASSWORD'],
          title: 'resetpassword_title',
        },
      },
    ]),
  ],
})
export class ResetPasswordRoutingModule {}
