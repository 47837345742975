import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core';
import {
  BottomSheetPosition,
  BottomSheetService,
  ButtonComponent,
  DialogService,
  SelectOption,
  ToastService,
  TreeSelectOption,
} from '@nibol/ui';
import { BehaviorSubject } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nib-demo-route',
  styleUrls: ['demo-route.component.scss'],
  templateUrl: 'demo-route.component.html',
})
export class DemoRouteComponent {
  buildingOptions: TreeSelectOption[] = [
    {
      children: [
        {
          children: [
            { label: 'Space 1 1 1', value: 'space-1-1-1' },
            { label: 'Space 1 1 2', value: 'space-1-1-2' },
            { label: 'Space 1 1 3', value: 'space-1-1-3' },
          ],
          label: 'Office 1 1',
          value: 'office-1-1',
        },
        {
          children: [
            { label: 'Space 1 2 1', value: 'space-1-2-1' },
            { label: 'Space 1 2 2', value: 'space-1-2-2' },
          ],
          label: 'Office 1 2',
          value: 'office-1-2',
        },
      ],
      label: 'Building 1',
      value: 'building-1',
    },
    {
      children: [
        {
          children: [
            { label: 'Space 2 1 1', value: 'space-2-1-1' },
            { label: 'Space 2 1 2', value: 'space-2-1-2' },
          ],
          label: 'Office 2 1',
          value: 'office-2-1',
        },
        {
          children: [{ label: 'Space 2 2 1', value: 'space-1-2-1' }],
          label: 'Office 2 2',
          value: 'office-2-2',
        },
      ],
      label: 'Building 2',
      value: 'building-2',
    },
  ];
  images = [
    'https://cdn.nibol.co/places/nibolcover1.jpg',
    'https://cdn.nibol.co/places/nibolcover2.jpg',
    'https://cdn.nibol.co/places/nibolcover3.jpg',
    'https://cdn.nibol.co/places/nibolcover4.jpg',
  ];
  markers = [
    { name: 'M1', x: 0, y: 0 },
    { name: 'M2', x: 100, y: 50 },
    { name: 'M3', x: 300, y: 400 },
    { name: 'M4', x: 800, y: 500 },
  ];
  options: SelectOption[] = [
    { label: 'Default', value: 'default' },
    { label: 'Cafeteria', value: 'cafeteria' },
    { label: 'Restaurant', value: 'restaurant' },
    { label: 'Home', value: 'home' },
    { label: 'Office', value: 'office' },
  ];
  showDrawer$ = new BehaviorSubject(false);

  constructor(
    private readonly bottomSheetService: BottomSheetService,
    private readonly dialogService: DialogService,
    private readonly toastService: ToastService,
  ) {}

  closeDrawer(): void {
    this.showDrawer$.next(false);
  }

  dismissDialogWithCustomAction(): void {
    this.dialogService.dismiss();
  }

  dismissToastWithCustomAction(): void {
    this.toastService.dismiss();
  }

  showBottomSheet(
    customAction?: TemplateRef<ButtonComponent>,
    position: BottomSheetPosition = 'right',
  ): void {
    if (customAction) {
      this.bottomSheetService.openWithCustomAction(
        'Bottom Sheet',
        'Show bottom sheet in application shell',
        customAction,
        position,
      );
    } else {
      this.bottomSheetService.open(
        'Bottom Sheet',
        'Show bottom sheet in application shell',
        position,
      );
    }
  }

  showDialog(customAction?: TemplateRef<ButtonComponent>): void {
    if (customAction) {
      this.dialogService.openWithCustomAction(
        'Dialog',
        'Show dialog in application shell',
        customAction,
      );
    } else {
      this.dialogService.open('Dialog', 'Show dialog in application shell');
    }
  }

  showDrawer(): void {
    this.showDrawer$.next(true);
  }

  showToast(customAction?: TemplateRef<ButtonComponent>): void {
    if (customAction) {
      this.toastService.openWithCustomAction(
        'Toast',
        'Show toast',
        customAction,
        'positive',
        50000,
      );
    } else {
      this.toastService.open('Toast', 'Show toast in application shell', 'positive');
    }
  }
}
