/**
 * Calculate member fullname
 * @param firstname Member name
 * @param lastname Member lastname
 * @returns Member fullname
 */
export function fullname(firstname?: string, lastname?: string): string {
  return `${firstname ?? ''} ${lastname ?? ''}`.trim();
}

/**
 * Calculate member contact name
 * @param email Member email
 * @param firstname Member firstname
 * @param lastname Member lastname
 * @returns Contact name
 */
export function membername(email: string, firstname?: string, lastname?: string): string {
  if (firstname || lastname) {
    return `${firstname ?? ''} ${lastname ?? ''} <${email}>`.trim();
  }

  return email;
}
