import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTreeModule } from '@angular/material/tree';
import { ButtonModule } from '../button/button.module';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { IconModule } from '../icon/icon.module';
import { TreeSelectionComponent } from './tree-selection.component';

@NgModule({
  declarations: [TreeSelectionComponent],
  exports: [TreeSelectionComponent],
  imports: [ButtonModule, CheckboxModule, CommonModule, IconModule, MatTreeModule],
})
export class TreeSelectionModule {}
