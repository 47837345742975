import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Breadcrumb } from '@nibol/store';
import { isObservable, of } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nib-breadcrumbs',
  styleUrls: ['breadcrumbs.component.scss'],
  templateUrl: 'breadcrumbs.component.html',
})
export class BreadcrumbsComponent {
  @Input()
  get breadcrumbs(): Breadcrumb[] {
    return this._breadcrumbs;
  }
  set breadcrumbs(value: Breadcrumb[]) {
    this._breadcrumbs = value.map(breadcrumb => ({
      ...breadcrumb,
      name: isObservable(breadcrumb.name) ? breadcrumb.name : of(breadcrumb.name),
    }));
  }
  private _breadcrumbs: Breadcrumb[] = [];
}
