import { Injectable } from '@angular/core';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Select, State, StateContext } from '@ngxs/store';
import { AuthStateAction, PermissionsSelectors, PermissionsStateAction, Roles } from '@nibol/auth';
import { AppSelectors, AppStateAction, States } from '@nibol/store';
import { TranslationService } from '@nibol/translation';
import { ToastService } from '@nibol/ui';
import { isOn } from 'feature-toggle-service';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { UserStateAction } from './user/public-api';

@State({
  name: 'authsaga',
})
@Injectable()
export class AuthSaga {
  @Select(PermissionsSelectors.hasToChangePassword)
  hasToChangePasswordRole$!: Observable<boolean>;

  @SelectSnapshot(AppSelectors.notifications) notifications!: boolean;

  constructor(
    private readonly toastService: ToastService,
    private readonly translationService: TranslationService,
  ) {}

  @Action(AuthStateAction.CheckIdentity.Failure)
  checkIdentityFailure() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('login_toast_application_error_title'),
        this.translationService.translate('login_toast_application_error_text'),
        'negative',
      );
    }
  }

  @Action(AuthStateAction.CheckIdentity.Success)
  checkIdentitySuccess(
    { dispatch }: StateContext<unknown>,
    {
      body: { token: email },
      response: { active_sso, company, last_password_change, login_link_sso },
    }: AuthStateAction.CheckIdentity.Success,
  ) {
    dispatch([
      new AuthStateAction.Sso.Change.Try(
        // tslint:disable-next-line: strict-type-predicates
        typeof active_sso === 'undefined' || active_sso.length === 0
          ? [{ sso: 'password' }]
          : active_sso.map(sso => ({
              sso,
              loginLink: sso === 'password' ? undefined : login_link_sso?.[sso],
            })),
      ),
      new UserStateAction.Item.Store.Try({
        avatar: company?.info.logo,
        email,
        lastPasswordChange:
          typeof last_password_change !== 'undefined' ? new Date(last_password_change) : undefined,
      }),
    ]);
  }

  @Action(AuthStateAction.CreatePassword.Success)
  createPasswordSuccess({ dispatch }: StateContext<unknown>) {
    setTimeout(async () => {
      await dispatch(new UserStateAction.Item.Read.Try()).toPromise();
      dispatch(new Navigate(['/']));
    }, 5000);
  }

  // tslint:disable-next-line: cyclomatic-complexity
  @Action(AuthStateAction.Login.Success)
  @Action(AuthStateAction.LoginTemporary.Success)
  @Action(AuthStateAction.RefreshToken.Success)
  async loginAndRefreshTokenSuccess(
    { dispatch }: StateContext<unknown>,
    {
      response: { need_password_change, terms_privacy },
    }:
      | AuthStateAction.Login.Success
      | AuthStateAction.LoginTemporary.Success
      | AuthStateAction.RefreshToken.Success,
  ) {
    dispatch(new AppStateAction.State.Change.Try(States['in-progress']));
    await dispatch(new UserStateAction.Item.Read.Try()).toPromise();

    // @todo(heavybeard): enhance performance of this check
    if (
      (terms_privacy && isOn('TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY')) ||
      (need_password_change && isOn('TOGGLE_ENABLE_CREATE_PASSWORD'))
    ) {
      dispatch([
        new PermissionsStateAction.List.Change.Try([
          ...(terms_privacy && isOn('TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY')
            ? [Roles.hasToAcceptTermsAndPrivacyPolicy]
            : []),
          ...(need_password_change && isOn('TOGGLE_ENABLE_CREATE_PASSWORD')
            ? [Roles.hasToChangePassword]
            : []),
        ]),
      ]);
    }
  }

  @Action(AuthStateAction.Login.Try)
  @Action(AuthStateAction.LoginTemporary.Try)
  loginAndRefreshTokenTry({ dispatch }: StateContext<unknown>) {
    dispatch(new PermissionsStateAction.List.Remove.Try([Roles.guest, Roles.couldLogIn]));
  }

  @Action(AuthStateAction.Login.Failure)
  loginFailure(
    { dispatch }: StateContext<unknown>,
    { body, error }: AuthStateAction.Login.Failure,
  ) {
    if (!['wrong-password', 'mail-not-found', 'no-valid-auth'].includes(error.error)) {
      dispatch([new Navigate(['/access-denied'], { email: body.email, error: error.error })]);
    }
  }

  @Action(AuthStateAction.Logout.Success)
  logoutSuccess({ dispatch }: StateContext<unknown>) {
    dispatch([
      new AppStateAction.State.Change.Try(States['in-progress']),
      new PermissionsStateAction.List.Change.Try([Roles.guest]),
      new Navigate(['/login']),
    ]);
  }

  @Action(AuthStateAction.LoginTemporary.Failure)
  loginTemporaryFailure({ dispatch }: StateContext<unknown>) {
    dispatch(new AuthStateAction.Logout.Try());
  }

  @Action(AuthStateAction.Logout.Try)
  logoutTry({ dispatch }: StateContext<unknown>) {
    dispatch(new AppStateAction.State.Change.Try(States['in-progress']));
  }

  @Action(AuthStateAction.TermsAndPrivacyPolicy.Read.Failure)
  async readTermsAndPrivacyPolicyFailure(
    { dispatch }: StateContext<unknown>,
    { error }: AuthStateAction.TermsAndPrivacyPolicy.Read.Failure,
  ) {
    dispatch(new Navigate(['/permission-denied'], { error: error.error }));
  }

  @Action(AuthStateAction.RefreshToken.Failure)
  refreshTokenFailure({ dispatch }: StateContext<unknown>) {
    dispatch(new AuthStateAction.Logout.Try());
  }

  @Action(AuthStateAction.Registration.Success)
  registrationSuccess({ dispatch }: StateContext<unknown>) {
    dispatch([
      new AppStateAction.State.Change.Try(States['in-progress']),
      new PermissionsStateAction.List.Change.Try([Roles.user]),
    ]);
  }

  @Action(AuthStateAction.SupportContacts.Success)
  supportContactsSuccess(
    { dispatch }: StateContext<unknown>,
    { response: supportContacts }: AuthStateAction.SupportContacts.Success,
  ) {
    dispatch(
      new AppStateAction.SupportContacts.Change.Try(
        supportContacts.map(supportContact => ({
          contacts: supportContact.support_contacts,
          name: supportContact.name,
        })),
      ),
    );
  }

  @Action(AuthStateAction.TermsAndPrivacyPolicy.Update.Success)
  updateTermsAndPrivacyPolicySuccess({ dispatch }: StateContext<unknown>) {
    return this.hasToChangePasswordRole$.pipe(
      first(),
      map(async hasToChangePasswordRole => {
        if (!hasToChangePasswordRole) {
          await dispatch(new UserStateAction.Item.Read.Try()).toPromise();
        }

        return dispatch(new Navigate(['/create-password']));
      }),
    );
  }
}
