import { Selector } from '@ngxs/store';
import { AuthSelectors, AuthStateModel, SsoList } from '@nibol/auth';

/** @dynamic */
export class LoginRouteSelectors {
  @Selector([AuthSelectors.sso])
  static isLoginWithGoogleEnabled(sso: AuthStateModel['sso']): boolean {
    return sso.some(ssoSetting => ssoSetting.sso === SsoList.google);
  }

  @Selector([AuthSelectors.sso])
  static isLoginWithMicrosoftEnabled(sso: AuthStateModel['sso']): boolean {
    return sso.some(ssoSetting => ssoSetting.sso === SsoList.azure);
  }

  @Selector([AuthSelectors.sso])
  static isLoginWithOktaEnabled(sso: AuthStateModel['sso']): boolean {
    return sso.some(ssoSetting => ssoSetting.sso === SsoList.okta);
  }

  @Selector([AuthSelectors.sso])
  static isLoginWithPasswordEnabled(sso: AuthStateModel['sso']): boolean {
    return sso.some(ssoSetting => ssoSetting.sso === SsoList.password);
  }

  @Selector([
    LoginRouteSelectors.isLoginWithGoogleEnabled,
    LoginRouteSelectors.isLoginWithMicrosoftEnabled,
    LoginRouteSelectors.isLoginWithOktaEnabled,
  ])
  static isLoginWithSsoEnabled(...ssoEnabledList: boolean[]): boolean {
    return ssoEnabledList.some(ssoEnabledItem => ssoEnabledItem);
  }
}
