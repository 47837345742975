// tslint:disable: no-namespace

export namespace Subscription {
  export namespace Create {
    export type Params = {
      place?: string;
      user?: string;
    } & PushSubscriptionJSON;
    export type Response = {
      data: {
        place?: string;
        user?: string;
      } & PushSubscriptionJSON;
    };
  }
}
