<mat-checkbox
  [id]="id"
  [indeterminate]="indeterminate"
  [name]="id"
  [ngClass]="ngClass"
  [required]="required"
  [formControl]="proxiedControl"
  (change)="checkedChange.emit($event.checked)"
>
  <span *ngIf="label; else ngContent" [innerHTML]="label"></span>
  <ng-template #ngContent><ng-content></ng-content></ng-template>
</mat-checkbox>
