<div class="nib-dialog" cdkTrapFocus>
  <div class="nib-dialog-body">
    <div *ngIf="title" class="nib-dialog-title nib-heading-1">
      <ng-container *ngIf="isTitleString; else titleTemplate">{{ title }}</ng-container>
      <ng-template #titleTemplate>
        <ng-template [ngTemplateOutlet]="title"></ng-template>
      </ng-template>
    </div>
    <div class="nib-dialog-content nib-body">
      <ng-container *ngIf="isContentString; else contentTemplate">{{ content }}</ng-container>
      <ng-template #contentTemplate>
        <ng-template [ngTemplateOutlet]="content"></ng-template>
      </ng-template>
    </div>
  </div>
  <div class="nib-dialog-actions" *ngIf="customAction">
    <ng-template [ngTemplateOutlet]="customAction" [ngTemplateOutletContext]="data"></ng-template>
  </div>
  <nib-button
    *ngIf="showCloseButton"
    [clean]="true"
    [size]="'sm'"
    (onClick)="dismiss()"
    class="nib-dialog-close-button"
  >
    <nib-icon [color]="'var(--color-gray-4)'" [name]="'close'"></nib-icon>
  </nib-button>
</div>
