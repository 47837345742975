// tslint:disable: no-namespace max-classes-per-file

import { BuildingStateModel } from '../building';
import { ReservationStateModel } from './reservations.model';

export namespace ReservationsStateAction {
  export namespace List {
    export namespace Read {
      export class Try {
        static readonly type = '[Reservations] try Read Reservations';
        constructor(
          public buildingId: BuildingStateModel['id'],
          public fromDay: ReservationStateModel['start'],
          public toDay?: ReservationStateModel['end'],
        ) {}
      }
      export class Success {
        static readonly type = '[Reservations] then Read Reservations success';
        constructor(
          public reservations: ReservationStateModel[],
          public buildingId: BuildingStateModel['id'],
        ) {}
      }
      export class Failure {
        static readonly type = '[Reservations] catch Read Reservations failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }
}
