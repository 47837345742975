import { LanguageCodes } from '@nibol/translation';
import { User } from '../../api/user/user.type';
import { DeepPartial } from '../../helpers/deep-partial.type';
import { mapRolesToClient } from '../../permissions/role.helper';
import { UserStateModel } from './user.model';

export function mapUserInfoToApi(user: Partial<UserStateModel>): User.Info.Update.Params {
  return {
    user: {
      auth: mapUserInfoAuthToApi(user),
      data: mapUserInfoDataToApi(user),
      info: mapUserInfoInfoToApi(user),
    },
  };
}

// tslint:disable-next-line: cyclomatic-complexity
export function mapUserNotificationsToApi(
  notification: Partial<UserStateModel['notifications']>,
): User.Notification.Update.Params {
  return {
    notification: {
      email: {
        account: notification.account?.email || false,
        announcements: notification.announcements?.email || false,
        newsletter: notification.newsletter?.email || false,
        service: notification.service?.email || false,
      },
      push: {
        account: notification.account?.pushweb || false,
        announcements: notification.announcements?.pushweb || false,
        newsletter: notification.newsletter?.pushweb || false,
        service: notification.service?.pushweb || false,
      },
      sms: {
        account: notification.account?.sms || false,
        announcements: notification.announcements?.sms || false,
        newsletter: notification.newsletter?.sms || false,
        service: notification.service?.sms || false,
      },
    },
  };
}

// tslint:disable-next-line: cyclomatic-complexity
export function mapUserToClient({
  user,
  notification,
}: User.Item.Read.Response): DeepPartial<UserStateModel> {
  return {
    email: user.auth.email,
    firstname: user.data.first_name,
    id: user.id,
    language: {
      en: LanguageCodes['en-gb'],
      es: LanguageCodes['es-es'],
      fr: LanguageCodes['fr-fr'],
      it: LanguageCodes['it-it'],
    }[user.info.language],
    lastname: user.data.last_name,
    notifications: {
      account: {
        email: notification.email?.announcements || notification.email?.account || false,
        pushweb: notification.push?.announcements || notification.push?.account || false,
        sms: notification.sms?.announcements || notification.sms?.account || false,
      },
      announcements: {
        email: notification.email?.announcements || notification.email?.announcements || false,
        pushweb: notification.push?.announcements || notification.push?.announcements || false,
        sms: notification.sms?.announcements || notification.sms?.announcements || false,
      },
      newsletter: {
        email: notification.email?.announcements || notification.email?.newsletter || false,
        pushweb: notification.push?.announcements || notification.push?.newsletter || false,
        sms: notification.sms?.announcements || notification.sms?.newsletter || false,
      },
      service: {
        email: notification.email?.newsletter || notification.email?.service || false,
        pushweb: notification.push?.newsletter || notification.push?.service || false,
        sms: notification.sms?.newsletter || notification.sms?.service || false,
      },
    },
    permissions: user.permissions ?? [],
    phone: {
      countryCode: user.data.phone?.country,
      phoneNumber: user.data.phone?.number,
    },
    roles: mapRolesToClient(user.roles),
  };
}

function mapUserInfoDataToApi(
  user: Partial<UserStateModel>,
): Partial<User.Info.Update.Params['user']['data']> {
  return {
    first_name: user.firstname,
    last_name: user.lastname,
    ...(user.phone
      ? {
          phone: {
            country: user.phone?.countryCode || '+39',
            number: user.phone?.phoneNumber || '',
          },
        }
      : {}),
  };
}

function mapUserInfoAuthToApi(
  user: Partial<UserStateModel>,
): Partial<User.Info.Update.Params['user']['auth']> {
  return {
    email: user.email,
  };
}

function mapUserInfoInfoToApi(
  user: Partial<UserStateModel>,
): Partial<User.Info.Update.Params['user']['info']> {
  return {
    language: user.language
      ? {
          [LanguageCodes['en-gb']]: 'en' as 'en',
          [LanguageCodes['es-es']]: 'es' as 'es',
          [LanguageCodes['fr-fr']]: 'fr' as 'fr',
          [LanguageCodes['it-it']]: 'it' as 'it',
        }[user.language]
      : user.language,
  };
}
