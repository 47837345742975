import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { Auth, AuthStateAction, PermissionsSelectors } from '@nibol/auth';
import { RouteParams, RouterSelectors } from '@nibol/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UserSelectors, UserStateModel } from '../../../store/public-api';
import { LoginFormValue } from './login-form/login-form-value.type';
import { LoginFormComponent } from './login-form/login-form.component';
import { LoginRouteSelectors } from './login-route.selectors';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nib-login-route',
  styleUrls: ['login-route.component.scss'],
  templateUrl: 'login-route.component.html',
})
export class LoginRouteComponent implements OnInit {
  @Dispatch()
  loginTry = (
    password: Auth.Login.Body['token'],
    staySignedIn: Auth.Login.Body['keep'],
    username: Auth.Login.Body['email'],
  ) =>
    new AuthStateAction.Login.Try({
      email: username,
      method: 'password',
      token: password,
      keep: staySignedIn,
    });

  @Select(UserSelectors.avatar) avatar$!: Observable<UserStateModel['avatar']>;
  @Select(PermissionsSelectors.couldLogIn) couldLogIn$!: Observable<boolean>;
  @Select(RouterSelectors.email) email$!: Observable<RouteParams['email']>;
  @Select(UserSelectors.lastPasswordChange)
  lastPasswordChange$!: Observable<UserStateModel['lastPasswordChange']>;
  @Select(LoginRouteSelectors.isLoginWithPasswordEnabled)
  isLoginWithPasswordEnabled$!: Observable<boolean>;
  @Select(LoginRouteSelectors.isLoginWithSsoEnabled)
  isLoginWithSsoEnabled$!: Observable<boolean>;

  @ViewChild('loginForm') loginForm?: LoginFormComponent;

  form = new FormGroup({
    login: new FormControl({
      password: '',
      staySignedIn: false,
      username: '',
    }),
  });

  ngOnInit(): void {
    this.setUsernameValueFromRouteParams();
  }

  login(): void {
    if (this.form.valid) {
      const { password, staySignedIn, username } = this.form.value.login as LoginFormValue;

      this.loginTry(password, staySignedIn, username);
    }
  }

  private setUsernameValueFromRouteParams(): void {
    this.email$
      .pipe(
        filter(email => typeof email !== 'undefined'),
        untilDestroyed(this),
      )
      .subscribe(email => {
        this.form.get('login')?.setValue({ username: email });
      });
  }
}
