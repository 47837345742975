import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslationModule } from '@nibol/translation';
import { ButtonModule, FormModule, IconModule, InputModule, SelectModule } from '@nibol/ui';
import { TimeSlotsFormFieldComponent } from './time-slots-form-field.component';

@NgModule({
  declarations: [TimeSlotsFormFieldComponent],
  exports: [TimeSlotsFormFieldComponent],
  imports: [
    ButtonModule,
    CommonModule,
    FlexModule,
    FormModule,
    IconModule,
    InputModule,
    ReactiveFormsModule,
    SelectModule,
    TranslationModule,
  ],
})
export class TimeSlotsFormFieldModule {}
