import { ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from '@marketplace/environment';
import { FeatureToggle } from '@marketplace/feature-toggles';
import {
  BuildingSelectors,
  ReservationsSelectors,
  VenuesState,
  VenueStateModel,
} from '@marketplace/store';
import { Version } from '@marketplace/version';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { AuthSelectors } from '@nibol/auth';
import { FrontendySelectors, UserSelectors } from '@nibol/shared';
import { Breadcrumb, FeatureTogglesState, RouterSelectors, VersionState } from '@nibol/store';
import { TranslationService } from '@nibol/translation';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'mkt-app-shell',
  styleUrls: ['app-shell.component.scss'],
  templateUrl: 'app-shell.component.html',
})
export class AppShellComponent {
  @Select(RouterSelectors.breadcrumbs) breadcrumbs$!: Observable<Breadcrumb[]>;
  @Select(BuildingSelectors.fullname) buildingFullname$!: Observable<string>;
  @Select(RouterSelectors.completionPercentage) completionPercentage$!: Observable<number>;
  @Select(FeatureTogglesState) featuretoggles$!: Observable<Record<FeatureToggle, boolean>>;
  @Select(FrontendySelectors.fullHeight) fullHeight$!: Observable<boolean>;
  @Select(FrontendySelectors.fullWidth) fullWidth$!: Observable<boolean>;
  @Select(AuthSelectors.isAuthenticated) isAuthenticated$!: Observable<boolean>;
  @Select(FrontendySelectors.isInProgress) isInProgress$!: Observable<boolean>;
  @Select(FrontendySelectors.isReady) isReady$!: Observable<boolean>;
  @Select(RouterSelectors.isWizard) isWizard$!: Observable<boolean>;
  @Select(FrontendySelectors.showHeader) showHeader$!: Observable<boolean>;
  @Select(FrontendySelectors.showMenu) showMenu$!: Observable<boolean>;
  @Select(ReservationsSelectors.amount) reservationsAmount$!: Observable<number>;
  @Select(FrontendySelectors.showTitle) showTitle$!: Observable<boolean>;
  @Select(VenuesState) spaces$!: Observable<VenueStateModel[]>;
  @Select(FrontendySelectors.title) title$!: Observable<string>;
  @Select(UserSelectors.fullname) userFullname$!: Observable<string>;
  @Select(VersionState) version$!: Observable<Version>;
  @Select(FrontendySelectors.withTabs) withTabs$!: Observable<boolean>;

  homepage = environment.homepage;
  logo = environment.logo;

  constructor(private readonly translationService: TranslationService) {}

  openHelpCenterOnNewTab(): void {
    window.open(this.translationService.translate('signup_support_link'), '_blank', 'noopener');
  }

  trackByVenueId(_index: number, venue: VenueStateModel): string {
    return venue.id;
  }
}
