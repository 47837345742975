<label *ngIf="label" [for]="formControlName || id" class="nib-body">{{ label }}</label>
<mat-form-field class="nib-form-field-type-nib-native-input nib-input-full-width">
  <mat-chip-list #chipList [attr.aria-label]="'label'" [selectable]="false">
    <mat-chip
      *ngFor="let selectedValue of selectedValues$ | async"
      [removable]="true"
      (removed)="remove(selectedValue)"
      class="nib-multi-select-chip"
    >
      <nib-icon
        [color]="'var(--color-gray-3)'"
        [name]="'close-small'"
        matChipRemove
        class="nib-multi-select-close"
      ></nib-icon>
      {{ selectedValue.label }}
    </mat-chip>
    <div class="nib-multi-select-input-container">
      <input
        #valueInput
        *ngIf="hasNotReachedMaxSelectedValues"
        [formControl]="typingControl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)"
        (ngModelChange)="onTyping.emit($event)"
        class="nib-multi-select-input"
      />
      <span
        *ngIf="valueInput?.nativeElement.value === ''"
        class="nib-multi-select-input-placeholder"
      >
        {{ placeholder }}
      </span>
    </div>
  </mat-chip-list>
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="selected($event); onChange.emit({ value: $event.option.value })"
    class="nib-multi-options"
  >
    <cdk-virtual-scroll-viewport [itemSize]="35" [ngStyle]="{ height: optionsHeight }">
      <mat-option
        *cdkVirtualFor="let option of filteredOptions$ | async"
        [disabled]="option.disabled"
        [value]="option.value"
      >
        {{ option.label }}
      </mat-option>
    </cdk-virtual-scroll-viewport>
  </mat-autocomplete>
</mat-form-field>
