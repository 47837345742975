import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { TranslationModule } from '@nibol/translation';
import { InputModule } from '@nibol/ui';
import { PermissionDeniedFooterContentComponent } from './permission-denied-footer-content/permission-denied-footer-content.component';
import { PermissionDeniedRouteComponent } from './permission-denied-route/permission-denied-route.component';
import { PermissionDeniedRoutingModule } from './permission-denied-routing.module';

@NgModule({
  declarations: [PermissionDeniedFooterContentComponent, PermissionDeniedRouteComponent],
  imports: [
    PermissionDeniedRoutingModule,
    CommonModule,
    FlexModule,
    InputModule,
    TranslationModule,
  ],
})
export class PermissionDeniedModule {}
