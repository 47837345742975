import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from './translation.service';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'translate' })
export class TranslationPipe implements PipeTransform {
  constructor(private readonly translationService: TranslationService) {}

  // tslint:disable-next-line: no-any
  transform(name?: string | null, params?: { [key: string]: any }): string {
    return name ? this.translationService.translate(name, params) : '';
  }
}
