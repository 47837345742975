import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslationModule } from '@nibol/translation';
import {
  ButtonModule,
  CardModule,
  FormModule,
  IconModule,
  InputModule,
  SwitchModule,
} from '@nibol/ui';
import { WeekTimeSlotsFormFieldComponent } from './week-time-slots-form-field.component';
import { WeekdayFormControlComponent } from './weekday-form-control/weekday-form-control.component';

@NgModule({
  declarations: [WeekdayFormControlComponent, WeekTimeSlotsFormFieldComponent],
  exports: [WeekTimeSlotsFormFieldComponent],
  imports: [
    ButtonModule,
    CardModule,
    CommonModule,
    FlexModule,
    FormModule,
    IconModule,
    InputModule,
    ReactiveFormsModule,
    SwitchModule,
    TranslationModule,
  ],
})
export class WeekTimeSlotsFormFieldModule {}
