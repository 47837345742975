import { Inject, Injectable } from '@angular/core';
import { PrismicService } from '@ngx-prismic/client';
// Waiting for https://github.com/prismicio/prismic-javascript/issues/27
// tslint:disable-next-line: match-default-export-name - Waiting for above issue resolving
import Prismic from 'prismic-javascript';
import { map } from 'rxjs/operators';
import { DOCUMENT_TYPES } from '../core/document-types.token';
import { LanguageCode } from '../core/language-code.type';
import { TranslationsFromPrismic } from './translations-from-prismic.type';

@Injectable({ providedIn: 'root' })
export class TranslationPrismicService {
  constructor(
    @Inject(DOCUMENT_TYPES) private readonly documentTypes: string[],
    private readonly prismicService: PrismicService,
  ) {}

  async getDictionary(language: LanguageCode | null): Promise<TranslationsFromPrismic> {
    return this.prismicService
      .query<TranslationsFromPrismic>(
        [Prismic.Predicates.any('document.type', this.documentTypes)],
        { ...(language && { lang: language }) },
      )
      .pipe(
        map(document =>
          document.results.map(result => result.data).reduce((r, c) => Object.assign(r, c), {}),
        ),
      )
      .toPromise<TranslationsFromPrismic>();
  }
}
