import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslationModule } from '@nibol/translation';
import { ButtonModule, IconModule, LetModule } from '@nibol/ui';
import { GoogleButtonComponent } from './google-button.component';

@NgModule({
  declarations: [GoogleButtonComponent],
  exports: [GoogleButtonComponent],
  imports: [ButtonModule, CommonModule, IconModule, LetModule, TranslationModule],
})
export class GoogleButtonModule {}
