<ng-container [formGroup]="form">
  <nib-form-field>
    <nib-input
      [autocomplete]="'email'"
      [formControlName]="'username'"
      [fullWidth]="true"
      [label]="'inputs_text_email_label' | translate"
      [type]="'email'"
    ></nib-input>
  </nib-form-field>
  <nib-form-action>
    <nib-button
      [color]="'primary'"
      [disabled]="!form.valid || disabled"
      [fullWidth]="true"
      [submit]="true"
    >
      {{ 'recoverpassword_button' | translate }}
    </nib-button>
  </nib-form-action>
</ng-container>
