import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { NpsModule } from '@nibol/nps';
import { TranslationModule } from '@nibol/translation';
import {
  AppShellModule,
  AvatarModule,
  ButtonModule,
  ChipModule,
  IconModule,
  IfBreakpointIsModule,
  InputModule,
  LetModule,
  SelectModule,
} from '@nibol/ui';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AppShellAuthComponent } from './app-shell-auth.component';
import { AppStatusResumeComponent } from './app-status-resume/app-status-resume.component';
import { BreadcrumbsModule } from './breadcrumbs/breadcrumbs.module';
import { FormSaverSnackbarComponent } from './form-saver-snackbar/form-saver-snackbar.component';

@NgModule({
  declarations: [AppShellAuthComponent, AppStatusResumeComponent, FormSaverSnackbarComponent],
  exports: [AppShellAuthComponent],
  imports: [
    AppShellModule,
    AvatarModule,
    BreadcrumbsModule,
    ButtonModule,
    ChipModule,
    CommonModule,
    ExtendedModule,
    FlexModule,
    IconModule,
    IfBreakpointIsModule,
    InlineSVGModule,
    InputModule,
    LetModule,
    NgxPermissionsModule,
    NpsModule,
    RouterModule,
    SelectModule,
    TranslationModule,
  ],
})
export class AppShellAuthModule {}
