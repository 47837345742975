<ng-container [formGroup]="form">
  <nib-form-field [hint]="'inputs_text_psw_caption' | translate">
    <nib-input
      [formControlName]="'password'"
      [fullWidth]="true"
      [label]="'inputs_text_newpassword_label' | translate"
      [type]="'password'"
      [visibilityToggle]="true"
    ></nib-input>
  </nib-form-field>
  <nib-form-action>
    <nib-button
      [color]="'primary'"
      [disabled]="!form.valid || disabled"
      [fullWidth]="true"
      [submit]="true"
    >
      {{ 'createpassword_button' | translate }}
    </nib-button>
  </nib-form-action>
</ng-container>
