<label *ngIf="label" [for]="id" class="nib-body">{{ label }}</label>
<mat-form-field [ngClass]="ngClass" class="nib-form-field-type-nib-native-textarea">
  <textarea
    [formControl]="proxiedControl"
    [id]="id"
    [name]="id"
    [placeholder]="placeholder"
    [readonly]="readonly"
    [required]="required"
    matInput
  ></textarea>
</mat-form-field>
