import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_ROOT_URL } from '../tokens/api-root-url.token';
import { AUTH_ROOT_URL } from '../tokens/auth-root-url.token';

@Injectable()
export class AppendClientToApiEndpointHeaderInterceptor implements HttpInterceptor {
  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    @Inject(AUTH_ROOT_URL) private readonly authRootUrl: string,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.startsWith(this.apiRootUrl) || request.url.startsWith(this.authRootUrl)) {
      return next.handle(request.clone({ headers: request.headers.set('nib_client', 'man') }));
    }

    return next.handle(request);
  }
}
