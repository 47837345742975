import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject } from 'rxjs';
import { FormSaverSnackbarService } from './form-saver-snackbar.service';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nib-form-saver-snackbar',
  styleUrls: ['form-saver-snackbar.component.scss'],
  templateUrl: 'form-saver-snackbar.component.html',
})
export class FormSaverSnackbarComponent implements OnInit {
  isSaveDisabled$ = new ReplaySubject<boolean>(1);
  isFormSaverSnackbarVisible$ = new ReplaySubject<boolean>(1);

  constructor(private readonly formSaverSnackbarService: FormSaverSnackbarService) {}

  ngOnInit(): void {
    this.manageActions();
  }

  cancel(): void {
    this.formSaverSnackbarService.cancel();
  }

  save(): void {
    this.formSaverSnackbarService.save();
  }

  private manageActions(): void {
    this.formSaverSnackbarService.statusChanges$.pipe(untilDestroyed(this)).subscribe(isValid => {
      this.isSaveDisabled$.next(!isValid);
    });

    this.formSaverSnackbarService.visibleChanges$
      .pipe(untilDestroyed(this))
      .subscribe(isVisible => {
        this.isFormSaverSnackbarVisible$.next(isVisible);
      });
  }
}
