import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { CurrentBuildingStateAction } from './current-building.actions';

@State<string | null>({
  name: 'currentbuilding',
  defaults: null,
})
@Injectable()
export class CurrentBuildingState {
  @Action(CurrentBuildingStateAction.Change.Try)
  changeCurrentBuildingId(
    { dispatch, getState, setState }: StateContext<string>,
    { id }: CurrentBuildingStateAction.Change.Try,
  ) {
    try {
      setState(id);

      dispatch(new CurrentBuildingStateAction.Change.Success(getState()));
    } catch (error) {
      dispatch(new CurrentBuildingStateAction.Change.Failure(error));

      throw error;
    }
  }

  @Action(CurrentBuildingStateAction.Reset.Try)
  resetCurrentBuildingId({ dispatch, getState, setState }: StateContext<null>) {
    try {
      setState(null);

      dispatch(new CurrentBuildingStateAction.Reset.Success(getState()));
    } catch (error) {
      dispatch(new CurrentBuildingStateAction.Reset.Failure(error));

      throw error;
    }
  }
}
