<div
  *ngIf="isFormSaverSnackbarVisible$ | async"
  [fxLayout]="'row'"
  [fxLayoutAlign]="'space-between center'"
  class="nib-form-saver-snackbar-container"
>
  <div
    [fxFlex]="100"
    [fxHide]="true"
    [fxLayout]="'row'"
    [fxLayoutAlign]="'start center'"
    [fxShow.gt-xs]="true"
  >
    <nib-icon
      [color]="'var(--color-semantic-notice)'"
      [name]="'notice'"
      class="nib-form-saver-icon"
    ></nib-icon>
    <span class="nib-form-saver-text">{{ 'save_text' | translate }}</span>
  </div>
  <div [fxFlex]="100" [fxFlex.gt-sm]="'25%'" [fxLayout]="'row'" [fxLayoutGap]="'16px'">
    <nib-button [clean]="true" [fullWidth]="true" [fxFlex]="100" (onClick)="cancel()">
      {{ 'save_button2' | translate }}
    </nib-button>
    <nib-button
      [color]="'positive'"
      [disabled]="isSaveDisabled$ | async"
      [fullWidth]="true"
      [fxFlex]="100"
      (onClick)="save()"
    >
      {{ 'save_button1' | translate }}
    </nib-button>
  </div>
</div>
