<h1>{{ 'accessdenied_title' | translate }}</h1>
<p [ngSwitch]="failureMessage" class="nib-access-denied-route-body">
  <span
    *ngSwitchCase="'business-employee'"
    [innerHTML]="'accessdenied_businessemployeeuser' | translate"
  ></span>
  <span
    *ngSwitchCase="'business-manager'"
    [innerHTML]="'accessdenied_businessmanager' | translate"
  ></span>
  <span *ngSwitchCase="'not-invited'" [innerHTML]="'accessdenied_notinvited' | translate"></span>
  <span
    *ngSwitchCase="'not-registered'"
    [innerHTML]="'accessdenied_notregistered' | translate"
  ></span>
  <span
    *ngSwitchCase="'marketplace-manager'"
    [innerHTML]="'accessdenied_marketplacemanager' | translate"
  ></span>
  <ng-container *ngSwitchCase="'marketplace-user'" [ngSwitch]="platform">
    <span *ngSwitchCase="'bus'" [innerHTML]="'accessdenied_frommarketplace' | translate"></span>
    <span *ngSwitchCase="'mkt'" [innerHTML]="'accessdenied_marketplaceuser' | translate"></span>
  </ng-container>
  <span *ngSwitchCase="'no-valid-auth'" [innerHTML]="'accessdenied_novalidauth' | translate"></span>
</p>
<nib-input
  [fullWidth]="true"
  [id]="'username'"
  [label]="'inputs_text_email_label' | translate"
  [readonly]="true"
  [type]="'email'"
  [value]="email"
  class="nib-input-disabled"
></nib-input>
<p class="nib-access-denied-route-change-email">
  <a [routerLink]="'/login'">{{ 'login_changeemail' | translate }}</a>
</p>
<div
  [fxLayout]="'column'"
  [fxLayout.gt-sm]="'row'"
  [fxLayoutGap]="'16px'"
  [ngSwitch]="failureMessage"
>
  <div
    *ngSwitchCase="'business-employee'"
    [fxLayout]="'column'"
    [fxLayoutGap]="'16px'"
    class="nib-access-denied-route-business-employee"
  >
    <a
      *ngFor="
        let supportContact of supportContacts$ | async as supportContacts;
        trackBy: trackBySupportContactName
      "
      [fxFlex]="'100%'"
      [href]="
        sanitize(
          'accessdenied_contactyouradmin_link' | translate: { emails: supportContact.contacts }
        )
      "
      class="nib-button"
    >
      <ng-container *ngIf="supportContacts.length > 1">{{ supportContact.name }} - </ng-container>
      {{ 'accessdenied_contactyouradmin' | translate }}
    </a>
    <a
      [fxFlex]="'100%'"
      [href]="'https://app.nibol.co/user/login?email=' + email"
      class="nib-button nib-button-primary"
    >
      {{ 'accessdenied_gotoemployeeapp' | translate }}
    </a>
  </div>
  <ng-container *ngSwitchCase="'business-manager'">
    <a
      [fxFlex]="'100%'"
      [href]="'https://business.nibol.co/login?email=' + email"
      class="nib-button"
    >
      {{ 'accessdenied_manageoffices' | translate }}
    </a>
    <a
      [fxFlex]="'100%'"
      [href]="sanitize('accessdenied_checkvenues_link' | translate)"
      class="nib-button nib-button-primary"
    >
      {{ 'accessdenied_checkvenues' | translate }}
    </a>
  </ng-container>
  <div
    *ngSwitchCase="'not-invited'"
    [fxLayout]="'column'"
    [fxLayoutGap]="'16px'"
    class="nib-access-denied-route-not-invited"
  >
    <a
      *ngFor="
        let supportContact of supportContacts$ | async as supportContacts;
        trackBy: trackBySupportContactName
      "
      [fxFlex]="'100%'"
      [href]="
        sanitize(
          'accessdenied_contactyouradmin_link' | translate: { emails: supportContact.contacts }
        )
      "
      class="nib-button nib-button-primary"
    >
      <ng-container *ngIf="supportContacts.length > 1">{{ supportContact.name }} - </ng-container>
      {{ 'accessdenied_contactyouradmin' | translate }}
    </a>
  </div>
  <ng-container *ngSwitchCase="'not-registered'">
    <a
      [fxFlex]="'100%'"
      [href]="sanitize('accessdenied_receivemoreinformation_link' | translate)"
      class="nib-button nib-button-primary"
    >
      {{ 'accessdenied_receivemoreinformation' | translate }}
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'marketplace-manager'">
    <a
      [fxFlex]="'100%'"
      [href]="'https://manager.nibol.co/login?email=' + email"
      class="nib-button"
    >
      {{ 'accessdenied_managevenue' | translate }}
    </a>
    <a
      [fxFlex]="'100%'"
      [href]="sanitize('accessdenied_checkvenues_link' | translate)"
      class="nib-button nib-button-primary"
    >
      {{ 'accessdenied_checkvenues' | translate }}
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'marketplace-user'" [ngSwitch]="platform">
    <ng-container *ngSwitchCase="'bus'">
      <a
        [fxFlex]="'100%'"
        [href]="'https://manager.nibol.co/signup?email=' + email"
        class="nib-button"
      >
        {{ 'accessdenied_addvenue' | translate }}
      </a>
      <a
        [fxFlex]="'100%'"
        [href]="sanitize('accessdenied_checkvenues_link' | translate)"
        class="nib-button nib-button-primary"
      >
        {{ 'accessdenied_checkvenues' | translate }}
      </a>
    </ng-container>
    <ng-container *ngSwitchCase="'mkt'">
      <a
        [fxFlex]="'100%'"
        [href]="sanitize('accessdenied_checkvenues_link' | translate)"
        class="nib-button"
      >
        {{ 'accessdenied_checkvenues' | translate }}
      </a>
      <a
        [fxFlex]="'100%'"
        [href]="'https://manager.nibol.co/signup?email=' + email"
        class="nib-button nib-button-primary"
      >
        {{ 'accessdenied_addvenue' | translate }}
      </a>
    </ng-container>
  </ng-container>
</div>
