<div [fxLayout]="'column'" [fxLayout.gt-md]="'row'" [fxLayoutGap]="'16px'">
  <div [fxFlex]="100" [fxFlex.gt-md]="'33'" class="nib-form-legend">
    <p class="nib-heading-3">{{ 'privatespaces_specialclose_subtitle' | translate }}</p>
    <p>
      {{ 'privatespaces_specialclose_body' | translate }}
    </p>
  </div>
  <div [fxFlex]="100" [fxFlex.gt-md]="'66'" [fxLayout]="'column'" [fxLayoutGap]="'16px'">
    <ng-container *ngFor="let dateSlot of dateSlots; let i = index; trackBy: dateSlotIndex">
      <nib-card>
        <nib-date-slot-form
          [formControl]="dateSlot"
          (onCloseClick)="removeDateSlot(i)"
        ></nib-date-slot-form>
      </nib-card>
    </ng-container>
    <nib-card [fxFlex]="100" [fxLayoutAlign]="'end'">
      <nib-button
        [color]="'primary'"
        [fullWidth]="true"
        [fxFlex]="100"
        [fxFlex.gt-md]="'fit-content'"
        (onClick)="addDateSlot()"
      >
        {{ 'privatespaces_specialclose_button' | translate }}
      </nib-button>
    </nib-card>
  </div>
</div>
