import { VersionsStateModelType } from '@nibol/store';
import { Versions } from '../version';
import { FeatureToggle } from './feature-toggles.type';

export const versions: VersionsStateModelType<Versions, FeatureToggle> = {
  [Versions['2.0.0']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: false,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: false,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: false,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: false,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: true,
    TOGGLE_SHOW_PAYMENTS: true,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: true,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: true,
  },
  [Versions['2.1.0']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: false,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: false,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: false,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: false,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: true,
    TOGGLE_SHOW_PAYMENTS: true,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: true,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: true,
  },
  [Versions['2.2.0']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: false,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: false,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: false,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: true,
    TOGGLE_SHOW_PAYMENTS: true,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: true,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: true,
  },
  [Versions['2.3.0']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: false,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: false,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: true,
    TOGGLE_SHOW_PAYMENTS: true,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: true,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: true,
  },
  [Versions['2.4.0']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: false,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: false,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: true,
    TOGGLE_SHOW_PAYMENTS: true,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: true,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: true,
  },
  [Versions['2.5.0']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: false,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: true,
    TOGGLE_SHOW_PAYMENTS: true,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: true,
  },
  [Versions['2.6.0']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: true,
    TOGGLE_SHOW_PAYMENTS: true,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: true,
  },
  [Versions['2.7.0']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: true,
    TOGGLE_SHOW_PAYMENTS: true,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: true,
  },
  [Versions['2.7.2']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['2.8.0']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['2.9.0']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['2.9.1']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['2.9.2']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['2.10.0']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['3.0.0']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['3.1.0']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['3.1.1']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['3.2.0']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['3.2.1']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['3.2.2']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['3.2.3']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['3.3.0']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['3.3.1']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['3.4.0']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['3.5.0']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['3.5.1']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['3.6.0']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['3.6.1']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['3.7.0']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['3.7.1']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['3.8.0']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['3.9.0']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['3.10.0']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['3.11.0']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['3.12.0']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['3.13.0']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['3.14.0']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['3.15.0']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['4.0.0']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['4.0.1']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['4.1.0']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
  [Versions['4.2.0']]: {
    TOGGLE_ENABLE_ALLOWED_GUESTS: false,
    TOGGLE_ENABLE_ANALYTICS: false,
    TOGGLE_ENABLE_AREA: false,
    TOGGLE_ENABLE_BILLING: true,
    TOGGLE_ENABLE_CREATE_NEW_VENUE: false,
    TOGGLE_ENABLE_CREATE_PASSWORD: false,
    TOGGLE_ENABLE_DOLLAR_CURRENCY: false,
    TOGGLE_ENABLE_EMAIL_EDITING: true,
    TOGGLE_ENABLE_HELP: true,
    TOGGLE_ENABLE_IMPORT_EXPORT_CALENDAR: true,
    TOGGLE_ENABLE_INCOMES: false,
    TOGGLE_ENABLE_LANGUAGE_SELECTION: true,
    TOGGLE_ENABLE_LANGUAGE_SPANISH: true,
    TOGGLE_ENABLE_MILES_UNIT: false,
    TOGGLE_ENABLE_NOTIFICATION_PUSH: false,
    TOGGLE_ENABLE_PAYMENTS: false,
    TOGGLE_ENABLE_PHONE_PREFIX: false,
    TOGGLE_ENABLE_PHOTOS: true,
    TOGGLE_ENABLE_RESERVATIONS_LIST: false,
    TOGGLE_ENABLE_RESET_PASSWORD: true,
    TOGGLE_ENABLE_SEPA: false,
    TOGGLE_ENABLE_SIGNUP: true,
    TOGGLE_ENABLE_SPACES_DELETION: true,
    TOGGLE_ENABLE_SUPPORT: true,
    TOGGLE_ENABLE_TERMS_AND_PRIVACY_POLICY: false,
    TOGGLE_SHOW_ANALYTICS: false,
    TOGGLE_SHOW_PAYMENTS: false,
    TOGGLE_SHOW_PHONE: true,
    TOGGLE_SHOW_PUSH_MOBILE: false,
    TOGGLE_SHOW_SEPA: false,
    TOGGLE_SHOW_SHARED_SPACE_WORK_PRICE: false,
  },
};
