import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormSaverSnackbarCanDeactivateGuard } from '../../components/app-shell/app-shell-auth/form-saver-snackbar/form-saver-snackbar-can-deactivate.guard';
import { AccountNotificationsRouteComponent } from './account-notifications-route/account-notifications-route.component';
import { AccountProfileRouteComponent } from './account-profile-route/account-profile-route.component';
import { AccountRouteComponent } from './account-route/account-route.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        // root is 'account'
        path: '',
        component: AccountRouteComponent,
        data: {
          breadcrumbs: [],
          fullWidth: true,
          title: 'account_account_title',
        },
      },
      {
        path: 'notifications',
        component: AccountNotificationsRouteComponent,
        canDeactivate: [FormSaverSnackbarCanDeactivateGuard],
        data: {
          breadcrumbs: [{ name: 'account_account_title', path: '/account' }],
          title: 'account_notifications_title',
        },
      },
      {
        path: 'profile',
        component: AccountProfileRouteComponent,
        canDeactivate: [FormSaverSnackbarCanDeactivateGuard],
        data: {
          breadcrumbs: [{ name: 'account_account_title', path: '/account' }],
          title: 'account_profile_title',
        },
      },
    ]),
  ],
})
export class AccountRoutingModule {}
