import { Injectable } from '@angular/core';
import {
  PhotoService,
  VenueService,
  WorkHoursDailyResponse,
  WorkHoursHalfDayResponse,
  WorkHoursHourlyResponse,
} from '@marketplace/api';
import { Action, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { StateReset } from 'ngxs-reset-plugin';
import { catchError, tap } from 'rxjs/operators';
import { VenueStateAction } from './venue.actions';
import {
  mapImportedCalendarsToClient,
  mapImportedCalendarToClient,
  mapNameAndAddressToClient,
  mapPhotosToClient,
  mapSeatsAndAmenitiesToClient,
  mapSpecialClosingTimesToClient,
  mapVenueSettingsToClient,
  mapVenueToApi,
  mapWorkHoursFullDayToClient,
  mapWorkHoursHalfDayToClient,
  mapWorkHoursHourlyToClient,
} from './venue.helper';
import { VenueStateModel } from './venue.model';
import { ImportedCalendar, TimeModes } from './venue.type';

@State<VenueStateModel>({
  name: 'venue',
})
@Injectable()
export class VenueState {
  constructor(
    private readonly photoService: PhotoService,
    private readonly venueService: VenueService,
  ) {}

  @Action(VenueStateAction.ImportedCalendars.Create.Try)
  createImportedCalendar(
    { dispatch, getState, setState }: StateContext<VenueStateModel>,
    { id, calendar: { name, url } }: VenueStateAction.ImportedCalendars.Create.Try,
  ) {
    return this.venueService.createImportedCalendar({ id, calendar: { name, url } }).pipe(
      catchError(error => {
        dispatch(new VenueStateAction.ImportedCalendars.Create.Failure(error));

        throw error;
      }),
      tap(importedCalendar => {
        setState(
          patch({
            importedCalendars: append([mapImportedCalendarToClient(importedCalendar)]),
          }),
        );

        dispatch(new VenueStateAction.ImportedCalendars.Create.Success(getState()));
      }),
    );
  }

  @Action(VenueStateAction.Photos.Create.Try)
  createPhotos(
    { dispatch, getState }: StateContext<VenueStateModel>,
    { data, id }: VenueStateAction.Photos.Create.Try,
  ) {
    const uploadData = new FormData();
    uploadData.append('id', id);
    data?.forEach((photo, index) => {
      uploadData.append(`image_${index + 1}`, photo, photo.name);
    });

    return this.photoService.upload(uploadData).pipe(
      catchError(error => {
        dispatch(new VenueStateAction.Photos.Create.Failure(error));

        throw error;
      }),
      tap(uploads => {
        dispatch(
          new VenueStateAction.Photos.Update.Try(id, [...(getState().photos || []), ...uploads]),
        );
      }),
      catchError(error => {
        dispatch(new VenueStateAction.Photos.Create.Failure(error));

        throw error;
      }),
      tap(() => {
        dispatch(new VenueStateAction.Photos.Create.Success(getState()));
      }),
    );
  }

  @Action(VenueStateAction.ImportedCalendars.Delete.Try)
  deleteImportedCalendar(
    { dispatch, getState, setState }: StateContext<VenueStateModel>,
    { id, calendarId }: VenueStateAction.ImportedCalendars.Delete.Try,
  ) {
    return this.venueService.deleteImportedCalendar({ id, calendar: calendarId }).pipe(
      catchError(error => {
        dispatch(new VenueStateAction.ImportedCalendars.Delete.Failure(error));

        throw error;
      }),
      tap(({ status }) => {
        if (status) {
          setState(
            patch({
              importedCalendars: removeItem<ImportedCalendar>(
                importedCalendar => importedCalendar?.id === calendarId,
              ),
            }),
          );

          dispatch(new VenueStateAction.ImportedCalendars.Delete.Success(getState()));
        } else {
          dispatch(new VenueStateAction.ImportedCalendars.Delete.Failure(status));

          throw status;
        }
      }),
    );
  }

  @Action(VenueStateAction.Item.Delete.Try)
  deleteItem(
    { dispatch, getState }: StateContext<VenueStateModel>,
    { id }: VenueStateAction.Item.Delete.Try,
  ) {
    return this.venueService.delete({ id }).pipe(
      catchError(error => {
        dispatch(new VenueStateAction.Item.Delete.Failure(error));

        throw error;
      }),
      tap(async () => {
        await dispatch(new VenueStateAction.Item.Delete.Success(getState())).toPromise();
        dispatch(new StateReset(VenueState));
      }),
    );
  }

  @Action(VenueStateAction.CreatingStatus.Read.Try)
  readCreatingStatus(
    { dispatch, getState, setState }: StateContext<VenueStateModel>,
    { id }: VenueStateAction.CreatingStatus.Read.Try,
  ) {
    return this.venueService.readCreatingStatus({ id }).pipe(
      catchError(error => {
        dispatch(new VenueStateAction.CreatingStatus.Read.Failure(error));

        throw error;
      }),
      tap(creatingStatus => {
        setState(
          patch({
            creatingStatus: creatingStatus.creation_status,
            id,
          }),
        );

        dispatch(new VenueStateAction.CreatingStatus.Read.Success(getState()));
      }),
    );
  }

  @Action(VenueStateAction.ExportCalendarUrl.Read.Try)
  readExportCalendarUrl(
    { dispatch, getState, setState }: StateContext<VenueStateModel>,
    { id }: VenueStateAction.ExportCalendarUrl.Read.Try,
  ) {
    return this.venueService.readExportCalendar({ id }).pipe(
      catchError(error => {
        dispatch(new VenueStateAction.ExportCalendarUrl.Read.Failure(error));

        throw error;
      }),
      tap(exportCalendar => {
        setState(
          patch({
            exportCalendarUrl: exportCalendar.url,
            id,
          }),
        );

        dispatch(new VenueStateAction.ExportCalendarUrl.Read.Success(getState()));
      }),
    );
  }

  @Action(VenueStateAction.ImportedCalendars.Read.Try)
  readImportedCalendars(
    { dispatch, getState, setState }: StateContext<VenueStateModel>,
    { id }: VenueStateAction.ImportedCalendars.Read.Try,
  ) {
    return this.venueService.readImportedCalendars({ id }).pipe(
      catchError(error => {
        dispatch(new VenueStateAction.ImportedCalendars.Read.Failure(error));

        throw error;
      }),
      tap(importedCalendars => {
        setState(
          patch({
            id,
            importedCalendars: mapImportedCalendarsToClient(importedCalendars),
          }),
        );

        dispatch(new VenueStateAction.ImportedCalendars.Read.Success(getState()));
      }),
    );
  }

  // tslint:disable-next-line: cyclomatic-complexity
  @Action(VenueStateAction.Item.Read.Try)
  readItem(
    { dispatch, getState }: StateContext<VenueStateModel>,
    { id, mode, type }: VenueStateAction.Item.Read.Try,
  ) {
    return dispatch([
      ...(mode === 'private' || type === 'coworking'
        ? [new VenueStateAction.WorkHoursFullDay.Read.Try(id)]
        : []),
      ...(mode === 'private' || type === 'coworking'
        ? [new VenueStateAction.WorkHoursHalfDay.Read.Try(id)]
        : []),
      new VenueStateAction.CreatingStatus.Read.Try(id),
      new VenueStateAction.ExportCalendarUrl.Read.Try(id),
      new VenueStateAction.ImportedCalendars.Read.Try(id),
      new VenueStateAction.NameAndAddress.Read.Try(id),
      new VenueStateAction.Photos.Read.Try(id),
      new VenueStateAction.SeatsAndAmenities.Read.Try(id),
      new VenueStateAction.Settings.Read.Try(id),
      new VenueStateAction.SpecialClosingTimes.Read.Try(id),
      new VenueStateAction.WorkHoursHourly.Read.Try(id),
    ]).pipe(
      catchError(error => {
        dispatch(new VenueStateAction.Item.Read.Failure(error));

        throw error;
      }),
      tap(() => {
        dispatch(new VenueStateAction.Item.Read.Success(getState()));
      }),
    );
  }

  @Action(VenueStateAction.NameAndAddress.Read.Try)
  readNameAndAddress(
    { dispatch, getState, setState }: StateContext<VenueStateModel>,
    { id }: VenueStateAction.NameAndAddress.Read.Try,
  ) {
    return this.venueService.readNameAndAddress({ id }).pipe(
      catchError(error => {
        dispatch(new VenueStateAction.NameAndAddress.Read.Failure(error));

        throw error;
      }),
      tap(nameAndAddress => {
        setState(
          patch({
            ...mapNameAndAddressToClient(nameAndAddress),
            id,
          }),
        );

        dispatch(new VenueStateAction.NameAndAddress.Read.Success(getState()));
      }),
    );
  }

  @Action(VenueStateAction.Photos.Read.Try)
  readPhotos(
    { dispatch, getState, setState }: StateContext<VenueStateModel>,
    { id }: VenueStateAction.Photos.Read.Try,
  ) {
    return this.venueService.readPhotos({ id }).pipe(
      catchError(error => {
        dispatch(new VenueStateAction.Photos.Read.Failure(error));

        throw error;
      }),
      tap(photos => {
        setState(
          patch({
            id,
            photos: mapPhotosToClient(photos),
            photosLink: photos.picture_link,
          }),
        );

        dispatch(new VenueStateAction.Photos.Read.Success(getState()));
      }),
    );
  }

  @Action(VenueStateAction.SeatsAndAmenities.Read.Try)
  readSeatsAndAmenities(
    { dispatch, getState, setState }: StateContext<VenueStateModel>,
    { id }: VenueStateAction.SeatsAndAmenities.Read.Try,
  ) {
    return this.venueService.readSeatsAndAmenities({ id }).pipe(
      catchError(error => {
        dispatch(new VenueStateAction.SeatsAndAmenities.Read.Failure(error));

        throw error;
      }),
      tap(seatsAndAmenities => {
        setState(
          patch({
            ...mapSeatsAndAmenitiesToClient(seatsAndAmenities),
            id,
          }),
        );

        dispatch(new VenueStateAction.SeatsAndAmenities.Read.Success(getState()));
      }),
    );
  }

  @Action(VenueStateAction.Settings.Read.Try)
  readSettings(
    { dispatch, getState, setState }: StateContext<VenueStateModel>,
    { id }: VenueStateAction.Settings.Read.Try,
  ) {
    return this.venueService.readSettings({ id }).pipe(
      catchError(error => {
        dispatch(new VenueStateAction.Settings.Read.Failure(error));

        throw error;
      }),
      tap(settings => {
        setState(
          patch({
            ...mapVenueSettingsToClient(settings),
            id,
          }),
        );

        dispatch(new VenueStateAction.Settings.Read.Success(getState()));
      }),
    );
  }

  @Action(VenueStateAction.SpecialClosingTimes.Read.Try)
  readSpecialClosingTimes(
    { dispatch, getState, setState }: StateContext<VenueStateModel>,
    { id }: VenueStateAction.SpecialClosingTimes.Read.Try,
  ) {
    return this.venueService.readSpecialClosingTimes({ id }).pipe(
      catchError(error => {
        dispatch(new VenueStateAction.SpecialClosingTimes.Read.Failure(error));

        throw error;
      }),
      tap(({ closings }) => {
        setState(
          patch({
            id,
            specialClosingTimes: mapSpecialClosingTimesToClient(closings),
          }),
        );

        dispatch(new VenueStateAction.SpecialClosingTimes.Read.Success(getState()));
      }),
    );
  }

  @Action(VenueStateAction.WorkHoursFullDay.Read.Try)
  readWorkHoursFullDay(
    { dispatch, getState, setState }: StateContext<VenueStateModel>,
    { id }: VenueStateAction.WorkHoursFullDay.Read.Try,
  ) {
    return this.venueService.readWorkHours({ id, time_mode: TimeModes.daily }).pipe(
      catchError(error => {
        dispatch(new VenueStateAction.WorkHoursFullDay.Read.Failure(error));

        throw error;
      }),
      tap(({ work }) => {
        setState(
          patch({
            id,
            workHoursFullDay: mapWorkHoursFullDayToClient(work as WorkHoursDailyResponse['work']),
          }),
        );

        dispatch(new VenueStateAction.WorkHoursFullDay.Read.Success(getState()));
      }),
    );
  }

  @Action(VenueStateAction.WorkHoursHalfDay.Read.Try)
  readWorkHoursHalfDay(
    { dispatch, getState, setState }: StateContext<VenueStateModel>,
    { id }: VenueStateAction.WorkHoursHalfDay.Read.Try,
  ) {
    return this.venueService.readWorkHours({ id, time_mode: TimeModes.halfday }).pipe(
      catchError(error => {
        dispatch(new VenueStateAction.WorkHoursHalfDay.Read.Failure(error));

        throw error;
      }),
      tap(({ work }) => {
        setState(
          patch({
            id,
            workHoursHalfDay: mapWorkHoursHalfDayToClient(work as WorkHoursHalfDayResponse['work']),
          }),
        );

        dispatch(new VenueStateAction.WorkHoursHalfDay.Read.Success(getState()));
      }),
    );
  }

  @Action(VenueStateAction.WorkHoursHourly.Read.Try)
  readWorkHoursHourly(
    { dispatch, getState, setState }: StateContext<VenueStateModel>,
    { id }: VenueStateAction.WorkHoursHourly.Read.Try,
  ) {
    return this.venueService.readWorkHours({ id, time_mode: TimeModes.hourly }).pipe(
      catchError(error => {
        dispatch(new VenueStateAction.WorkHoursHourly.Read.Failure(error));

        throw error;
      }),
      tap(({ work }) => {
        setState(
          patch({
            id,
            workHoursHourly: mapWorkHoursHourlyToClient(work as WorkHoursHourlyResponse['work']),
          }),
        );

        dispatch(new VenueStateAction.WorkHoursHourly.Read.Success(getState()));
      }),
    );
  }

  @Action(VenueStateAction.CreatingStatus.Update.Try)
  updateCreatingStatus(
    { dispatch, getState }: StateContext<VenueStateModel>,
    { id, data }: VenueStateAction.CreatingStatus.Update.Try,
  ) {
    return dispatch(new VenueStateAction.Item.Update.Try(id, { creatingStatus: data })).pipe(
      catchError(error => {
        dispatch(new VenueStateAction.CreatingStatus.Update.Failure(error));

        throw error;
      }),
      tap(() => {
        dispatch(new VenueStateAction.CreatingStatus.Update.Success(getState()));
      }),
    );
  }

  @Action(VenueStateAction.ImportedCalendars.Update.Try)
  updateImportedCalendar(
    { dispatch, getState, setState }: StateContext<VenueStateModel>,
    { id, calendarId }: VenueStateAction.ImportedCalendars.Update.Try,
  ) {
    return this.venueService.updateImportedCalendar({ id, calendar: calendarId }).pipe(
      catchError(error => {
        dispatch(new VenueStateAction.ImportedCalendars.Update.Failure(error));

        throw error;
      }),
      tap(importedCalendar => {
        setState(
          patch({
            importedCalendars: updateItem<ImportedCalendar>(
              statusImportedCalendar => statusImportedCalendar?.id === importedCalendar._id,
              mapImportedCalendarToClient(importedCalendar),
            ),
          }),
        );

        dispatch(new VenueStateAction.ImportedCalendars.Create.Success(getState()));
      }),
    );
  }

  @Action(VenueStateAction.Item.Update.Try)
  updateItem(
    { dispatch, getState, setState }: StateContext<VenueStateModel>,
    { data, id }: VenueStateAction.Item.Update.Try,
  ) {
    return this.venueService.update({ data: mapVenueToApi(data), id }).pipe(
      catchError(error => {
        dispatch(new VenueStateAction.Item.Update.Failure(error));

        throw error;
      }),
      // tslint:disable-next-line: cyclomatic-complexity
      tap(response => {
        if (response.status) {
          setState(
            patch({
              ...data,
              ...(data.creatingStatus ? { creatingStatus: patch(data.creatingStatus) } : {}),
              ...(data.workHoursFullDay ? { workHoursFullDay: patch(data.workHoursFullDay) } : {}),
              ...(data.workHoursHalfDay ? { workHoursHalfDay: patch(data.workHoursHalfDay) } : {}),
              ...(data.workHoursHourly ? { workHoursHourly: patch(data.workHoursHourly) } : {}),
              id,
            }),
          );

          dispatch(new VenueStateAction.Item.Update.Success(getState()));
        } else {
          dispatch(new VenueStateAction.Item.Update.Failure(response.status));

          throw response.status;
        }
      }),
    );
  }

  @Action(VenueStateAction.Mode.Update.Try)
  updateMode(
    { dispatch, getState }: StateContext<VenueStateModel>,
    { mode, id }: VenueStateAction.Mode.Update.Try,
  ) {
    return dispatch(new VenueStateAction.Item.Update.Try(id, { mode })).pipe(
      catchError(error => {
        dispatch(new VenueStateAction.Mode.Update.Failure(error));

        throw error;
      }),
      tap(() => {
        dispatch(new VenueStateAction.Mode.Update.Success(getState()));
      }),
    );
  }

  @Action(VenueStateAction.NameAndAddress.Update.Try)
  updateNameAndAddress(
    { dispatch, getState }: StateContext<VenueStateModel>,
    { data, id }: VenueStateAction.NameAndAddress.Update.Try,
  ) {
    return dispatch(new VenueStateAction.Item.Update.Try(id, data)).pipe(
      catchError(error => {
        dispatch(new VenueStateAction.NameAndAddress.Update.Failure(error));

        throw error;
      }),
      tap(() => {
        dispatch(new VenueStateAction.NameAndAddress.Update.Success(getState()));
      }),
    );
  }

  @Action(VenueStateAction.Photos.Update.Try)
  updatePhotos(
    { dispatch, getState }: StateContext<VenueStateModel>,
    { data, id }: VenueStateAction.Photos.Update.Try,
  ) {
    return dispatch(new VenueStateAction.Item.Update.Try(id, { photos: data })).pipe(
      catchError(error => {
        dispatch(new VenueStateAction.Photos.Update.Failure(error));

        throw error;
      }),
      tap(() => {
        dispatch(new VenueStateAction.Photos.Update.Success(getState()));
      }),
    );
  }

  @Action(VenueStateAction.PhotosLink.Update.Try)
  updatePhotosLink(
    { dispatch, getState }: StateContext<VenueStateModel>,
    { link, id }: VenueStateAction.PhotosLink.Update.Try,
  ) {
    return dispatch(new VenueStateAction.Item.Update.Try(id, { photosLink: link })).pipe(
      catchError(error => {
        dispatch(new VenueStateAction.PhotosLink.Update.Failure(error));

        throw error;
      }),
      tap(() => {
        dispatch(new VenueStateAction.PhotosLink.Update.Success(getState()));
      }),
    );
  }

  @Action(VenueStateAction.SeatsAndAmenities.Update.Try)
  updateSeatsAndAmenities(
    { dispatch, getState }: StateContext<VenueStateModel>,
    { data, id }: VenueStateAction.SeatsAndAmenities.Update.Try,
  ) {
    return dispatch(new VenueStateAction.Item.Update.Try(id, data)).pipe(
      catchError(error => {
        dispatch(new VenueStateAction.SeatsAndAmenities.Update.Failure(error));

        throw error;
      }),
      tap(() => {
        dispatch(new VenueStateAction.SeatsAndAmenities.Update.Success(getState()));
      }),
    );
  }

  @Action(VenueStateAction.Settings.Update.Try)
  updateSettings(
    { dispatch, getState }: StateContext<VenueStateModel>,
    { data, id }: VenueStateAction.Settings.Update.Try,
  ) {
    return dispatch(new VenueStateAction.Item.Update.Try(id, data)).pipe(
      catchError(error => {
        dispatch(new VenueStateAction.Settings.Update.Failure(error));

        throw error;
      }),
      tap(() => {
        dispatch(new VenueStateAction.Settings.Update.Success(getState()));
      }),
    );
  }

  @Action(VenueStateAction.SpecialClosingTimes.Update.Try)
  updateSpecialClosingTimes(
    { dispatch, getState }: StateContext<VenueStateModel>,
    { data, id }: VenueStateAction.SpecialClosingTimes.Update.Try,
  ) {
    return dispatch(new VenueStateAction.Item.Update.Try(id, data)).pipe(
      catchError(error => {
        dispatch(new VenueStateAction.SpecialClosingTimes.Update.Failure(error));

        throw error;
      }),
      tap(() => {
        dispatch(new VenueStateAction.SpecialClosingTimes.Update.Success(getState()));
      }),
    );
  }

  @Action(VenueStateAction.WorkHoursFullDay.Update.Try)
  updateWorkHoursFullDay(
    { dispatch, getState }: StateContext<VenueStateModel>,
    { data, id }: VenueStateAction.WorkHoursFullDay.Update.Try,
  ) {
    return dispatch(new VenueStateAction.Item.Update.Try(id, { workHoursFullDay: data })).pipe(
      catchError(error => {
        dispatch(new VenueStateAction.WorkHoursFullDay.Update.Failure(error));

        throw error;
      }),
      tap(() => {
        dispatch(new VenueStateAction.WorkHoursFullDay.Update.Success(getState()));
      }),
    );
  }

  @Action(VenueStateAction.WorkHoursHalfDay.Update.Try)
  updateWorkHoursHalfDay(
    { dispatch, getState }: StateContext<VenueStateModel>,
    { data, id }: VenueStateAction.WorkHoursHalfDay.Update.Try,
  ) {
    return dispatch(new VenueStateAction.Item.Update.Try(id, { workHoursHalfDay: data })).pipe(
      catchError(error => {
        dispatch(new VenueStateAction.WorkHoursHalfDay.Update.Failure(error));

        throw error;
      }),
      tap(() => {
        dispatch(new VenueStateAction.WorkHoursHalfDay.Update.Success(getState()));
      }),
    );
  }

  @Action(VenueStateAction.WorkHoursHourly.Update.Try)
  updateWorkHoursHourly(
    { dispatch, getState }: StateContext<VenueStateModel>,
    { data, id }: VenueStateAction.WorkHoursHourly.Update.Try,
  ) {
    return dispatch(new VenueStateAction.Item.Update.Try(id, { workHoursHourly: data })).pipe(
      catchError(error => {
        dispatch(new VenueStateAction.WorkHoursHourly.Update.Failure(error));

        throw error;
      }),
      tap(() => {
        dispatch(new VenueStateAction.WorkHoursHourly.Update.Success(getState()));
      }),
    );
  }
}
