import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DrawerComponent } from './drawer.component';

@NgModule({
  declarations: [DrawerComponent],
  exports: [DrawerComponent],
  imports: [A11yModule, CommonModule, MatSidenavModule],
})
export class DrawerModule {}
