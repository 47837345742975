import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { compose, patch } from '@ngxs/store/operators';
import { FeatureTogglesStateAction } from './feature-toggles.actions';
import { FeatureTogglesStateModel } from './feature-toggles.model';

@State<FeatureTogglesStateModel>({
  name: 'featuretoggles',
})
@Injectable()
export class FeatureTogglesState<FeatureToggles extends FeatureTogglesStateModel> {
  @Action(FeatureTogglesStateAction.Update.Try)
  update(
    { dispatch, getState, setState }: StateContext<FeatureToggles>,
    { featureToggles }: FeatureTogglesStateAction.Update.Try<FeatureToggles>,
  ) {
    try {
      setState(
        compose(
          ...Object.keys(featureToggles).map(featuretoggleName =>
            patch({
              [featuretoggleName]: featureToggles[featuretoggleName],
            }),
          ),
        ),
      );

      dispatch(new FeatureTogglesStateAction.Update.Success(getState()));
    } catch (error) {
      dispatch(new FeatureTogglesStateAction.Update.Failure(error));

      throw error;
    }
  }
}
