import { AreasState } from './areas';
import { BuildingState } from './building';
import { BuildingsState } from './buildings';
import { CompanyState } from './company';
import { CurrentBuildingState } from './current-building';
import { FeedbackSaga } from './feedback-saga';
import { ReservationsState } from './reservations';
import { Saga } from './saga';
import { UserState } from './user';
import { VenueState } from './venue';
import { VenuesState } from './venues';

export * from './areas';
export * from './building';
export * from './buildings';
export * from './company';
export * from './current-building';
export * from './feedback-saga';
export * from './reservations';
export * from './saga';
export * from './user';
export * from './venue';
export * from './venues';

export const states = [
  AreasState,
  BuildingsState,
  BuildingState,
  CompanyState,
  CurrentBuildingState,
  FeedbackSaga,
  ReservationsState,
  Saga,
  UserState,
  VenueState,
  VenuesState,
];
