import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WeekdaysFormGroupControl } from './weekdays-form-group-control.type';
import { WeekdaysFormGroupValue } from './weekdays-form-group-value.type';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WeekdaysFormGroupComponent),
      multi: true,
    },
  ],
  selector: 'nib-weekdays-form-group',
  styleUrls: ['weekdays-form-group.component.scss'],
  templateUrl: 'weekdays-form-group.component.html',
})
export class WeekdaysFormGroupComponent implements ControlValueAccessor {
  controls: WeekdaysFormGroupControl[] = [
    { control: new FormControl('monday'), key: 'monday', label: 'list_dayweek_mon' },
    { control: new FormControl('tuesday'), key: 'tuesday', label: 'list_dayweek_tue' },
    { control: new FormControl('wednesday'), key: 'wednesday', label: 'list_dayweek_wed' },
    { control: new FormControl('thursday'), key: 'thursday', label: 'list_dayweek_thu' },
    { control: new FormControl('friday'), key: 'friday', label: 'list_dayweek_fri' },
    { control: new FormControl('saturday'), key: 'saturday', label: 'list_dayweek_sat' },
    { control: new FormControl('sunday'), key: 'sunday', label: 'list_dayweek_sun' },
  ];

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  controlKey(_index: number, control: { control: FormControl; key: string }): string {
    return control.key;
  }

  registerOnChange(callback: (serviceFormValue: WeekdaysFormGroupValue) => void): void {
    this.onChange = () => {
      callback(
        this.controls.reduce((previous, current) => {
          previous[current.key] = !!current.control.value;

          return previous;
        }, ({} as unknown) as WeekdaysFormGroupValue),
      );
    };
  }

  registerOnTouched(callback: () => void): void {
    this.onTouched = () => {
      callback();
    };
  }

  writeValue(value: WeekdaysFormGroupValue): void {
    this.controls = [];

    Object.keys(value).forEach(key => {
      const control = new FormControl(value[key]);

      this.controls = [...this.controls, { control, key, label: `list_dayweek_${key}` }];

      control.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
        this.onChange();
        this.onTouched();
      });
    });

    this.changeDetectorRef.markForCheck();
  }

  // tslint:disable-next-line: no-empty
  private onChange: () => void = () => {};

  // tslint:disable-next-line: no-empty
  private onTouched: () => void = () => {};
}
