import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ItemNode, TreeSelectOption } from './tree-selection.type';

@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<ItemNode[]>([]);

  get data(): ItemNode[] {
    return this.dataChange.value;
  }

  initialize(treeData: TreeSelectOption[]) {
    this.dataChange.next(this.buildTree(treeData, 0));
  }

  buildTree(dataTree: TreeSelectOption[], level: number): ItemNode[] {
    return dataTree.map(item => {
      const node = new ItemNode();
      node.item = item;

      if (item.children?.length) {
        node.children = this.buildTree(item.children, level + 1);
      }

      return node;
    });
  }
}
