import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CardModule } from '@nibol/ui';
import { HubRouteCardComponent } from './hub-route-card.component';

@NgModule({
  imports: [CardModule, CommonModule, RouterModule],
  exports: [HubRouteCardComponent],
  declarations: [HubRouteCardComponent],
})
export class HubRouteCardModule {}
