import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

// tslint:disable-next-line: validate-decorators
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'nib-form-field-error',
  template: '<ng-content></ng-content>',
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    class: 'nib-form-field-error',
  },
})
export class FormFieldErrorComponent {}
