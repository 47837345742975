import { TranslationService } from '@nibol/translation';
import { FileUploaderTranslationsConfig } from '@nibol/ui';

export function fileUploaderTranslationsFactory(
  translationService: TranslationService,
): FileUploaderTranslationsConfig {
  return {
    title: translationService.translate('space_addphoto_title'),
    description: translationService.translate('space_addphoto_description'),
  };
}
