<nib-demo-item>
  <h3>Bottom Sheet</h3>
  <p>Show a container with additional content from bottom in left, center o right position</p>
  <div class="nib-demo-route-item">
    <p>Right</p>
    <nib-button (onClick)="showBottomSheet()">Show Bottom Sheet</nib-button>
  </div>
</nib-demo-item>
<nib-demo-item>
  <h3>Buttons</h3>
  <p>List of available buttons</p>
  <div class="nib-demo-route-item">
    <p>Primary color</p>
    <nib-button [color]="'primary'">Primary</nib-button>
  </div>
  <div class="nib-demo-route-item">
    <p>Secondary color</p>
    <nib-button [color]="'secondary'">Secondary</nib-button>
  </div>
  <div class="nib-demo-route-item">
    <p>Tertiary color</p>
    <nib-button [color]="'tertiary'">Tertiary</nib-button>
  </div>
  <div class="nib-demo-route-item">
    <p>Negative color</p>
    <nib-button [color]="'negative'">Negative</nib-button>
  </div>
  <div class="nib-demo-route-item">
    <p>Positive color</p>
    <nib-button [color]="'positive'">Positive</nib-button>
  </div>
  <div class="nib-demo-route-item">
    <p>Dark color</p>
    <nib-button [color]="'dark'">Dark</nib-button>
  </div>
  <div class="nib-demo-route-item">
    <p>Disabled mode</p>
    <nib-button [disabled]="true">Disabled</nib-button>&nbsp;&nbsp;
    <nib-button [color]="'primary'" [disabled]="true">Primary</nib-button>&nbsp;&nbsp;
    <nib-button [color]="'tertiary'" [disabled]="true">Tertiary</nib-button>&nbsp;&nbsp;
    <nib-button [color]="'negative'" [disabled]="true">Negative</nib-button>&nbsp;&nbsp;
    <nib-button [color]="'positive'" [disabled]="true">Positive</nib-button>
  </div>
  <div class="nib-demo-route-item">
    <p>Full width mode</p>
    <nib-button [fullWidth]="true">Default full width</nib-button>
    <br />
    <br />
    <nib-button [color]="'primary'" [fullWidth]="true">Default full width</nib-button>
  </div>
  <div class="nib-demo-route-item">
    <p>Clean</p>
    <nib-button [clean]="true">Clean</nib-button>&nbsp;&nbsp;
    <nib-button [clean]="true" [color]="'primary'">Primary</nib-button>&nbsp;&nbsp;
    <nib-button [clean]="true" [color]="'tertiary'">Tertiary</nib-button>&nbsp;&nbsp;
    <nib-button [clean]="true" [color]="'negative'">Negative</nib-button>&nbsp;&nbsp;
    <nib-button [clean]="true" [color]="'positive'">Positive</nib-button>
  </div>
  <div class="nib-demo-route-item">
    <p>Link version</p>
    <a [routerLink]="'../demo'" class="nib-button nib-button-primary">Primary Link</a>&nbsp;&nbsp;
    <a [routerLink]="'../demo'" class="nib-button nib-button-secondary">Secondary Link</a>
    &nbsp;&nbsp;
    <a [routerLink]="'../demo'" class="nib-button nib-button-tertiary">Tertiary Link</a>&nbsp;&nbsp;
    <a [routerLink]="'../demo'" class="nib-button nib-button-negative">Negative Link</a>&nbsp;&nbsp;
    <a [routerLink]="'../demo'" class="nib-button nib-button-positive">Positive Link</a>
  </div>
  <div class="nib-demo-route-item">
    <p>With Icon</p>
    <nib-button><nib-icon [name]="'search'"></nib-icon></nib-button>&nbsp;&nbsp;
    <nib-button [color]="'primary'">
      <nib-icon [name]="'search'"></nib-icon> Search Primary
    </nib-button>
    &nbsp;&nbsp;
    <nib-button [clean]="true" [color]="'negative'">
      Search <nib-icon [name]="'search'"></nib-icon>
    </nib-button>
  </div>
  <div class="nib-demo-route-item">
    <p>Sizes</p>
    <nib-button [size]="'sm'">Default Small</nib-button>&nbsp;&nbsp;
    <nib-button [size]="'sm'">
      <nib-icon [name]="'search'"></nib-icon>
      Search
    </nib-button>
    &nbsp;&nbsp;
    <nib-button [size]="'sm'"><nib-icon [name]="'search'"></nib-icon></nib-button>
  </div>
</nib-demo-item>
<nib-demo-item>
  <h3>Checkboxes</h3>
  <p>List of available checkboxes</p>
  <div class="nib-demo-route-item">
    <nib-checkbox [checked]="true" [label]="'Simple'"></nib-checkbox>
  </div>
  <div class="nib-demo-route-item">
    <nib-checkbox [checked]="true" [disabled]="true" [label]="'Disabled mode'"></nib-checkbox>
  </div>
  <div class="nib-demo-route-item">
    <nib-checkbox [checked]="true" [label]="'Full width'"></nib-checkbox>
  </div>
  <div class="nib-demo-route-item">
    <nib-checkbox [indeterminate]="true" [label]="'Indeterminate'"></nib-checkbox>
  </div>
</nib-demo-item>
<nib-demo-item>
  <h3>Chips</h3>
  <p>List of available chips</p>
  <div class="nib-demo-route-item">
    <p>Default color</p>
    <nib-chip>Default</nib-chip>
  </div>
  <div class="nib-demo-route-item">
    <p>Primary color</p>
    <nib-chip [color]="'primary'">Long text for chip</nib-chip>
  </div>
  <div class="nib-demo-route-item">
    <p>Secondary color</p>
    <nib-chip [color]="'secondary'">100</nib-chip>
  </div>
  <div class="nib-demo-route-item">
    <p>Dot chip</p>
    <nib-chip [color]="'notice'"></nib-chip>
  </div>
</nib-demo-item>
<nib-demo-item>
  <h3>Dialog</h3>
  <p>Show a container in center of the screen with content</p>
  <div class="nib-demo-route-item">
    <p>Show dialog in application shell</p>
    <nib-button (onClick)="showDialog()">Show Dialog</nib-button>
  </div>
  <div class="nib-demo-route-item">
    <p>Show dialog in application shell with custom action</p>
    <nib-button (onClick)="showDialog(okDialogAction)">Show Dialog</nib-button>
    <ng-template #okDialogAction>
      <nib-button [color]="'tertiary'" (onClick)="dismissDialogWithCustomAction()">OK</nib-button>
    </ng-template>
  </div>
</nib-demo-item>
<nib-demo-item>
  <h3>Drawer</h3>
  <p>Show a container with collapsible side content</p>
  <div class="nib-demo-route-item">
    <nib-button (onClick)="showDrawer()">Show Drawer</nib-button>
    <nib-drawer *ngIf="showDrawer$ | async" (onBackdropClick)="closeDrawer()">
      Drawer content
    </nib-drawer>
  </div>
</nib-demo-item>
<nib-demo-item>
  <h3>File Uploader</h3>
  <p>Upload a file with drag and drop feature</p>
  <div class="nib-demo-route-item">
    <nib-file-uploader></nib-file-uploader>
  </div>
</nib-demo-item>
<nib-demo-item>
  <h3>Floating Panel</h3>
  <p>Show content in a floating panel</p>
  <div class="nib-demo-route-item">
    <p>Simple</p>
    <nib-button #trigger="nibFloatingPanelTriggerFor" [nibFloatingPanelTriggerFor]="floatingPanel">
      Show Floating Panel
    </nib-button>
    <ng-template #floatingPanel>
      <nib-floating-panel>
        <h3>Floating panel content</h3>
        <nib-button [size]="'sm'" (onClick)="trigger.close()">Close</nib-button>
      </nib-floating-panel>
    </ng-template>
  </div>
</nib-demo-item>
<nib-demo-item>
  <h3>Forms Big</h3>
  <p>How to build a big form</p>
  <nib-form class="nib-demo-route-item">
    <p class="nib-heading-1">Big title</p>
    <nib-form-field>
      <nib-input [fullWidth]="true" [label]="'Label'"></nib-input>
    </nib-form-field>
  </nib-form>
</nib-demo-item>
<nib-demo-item>
  <h3>Forms</h3>
  <p>How to build a form</p>
  <nib-form class="nib-demo-route-item">
    <nib-form-title>Title</nib-form-title>
    <nib-form-description>Type your form description here</nib-form-description>
    <nib-form-field>
      <nib-input [fullWidth]="true" [label]="'Label'"></nib-input>
    </nib-form-field>
    <nib-form-divider></nib-form-divider>
    <nib-form-field>
      <nib-input [fullWidth]="true" [label]="'Item'"></nib-input>
    </nib-form-field>
    <nib-form-divider></nib-form-divider>
    <nib-form-field>
      <nib-input [fullWidth]="true" [label]="'Last string'"></nib-input>
    </nib-form-field>
    <nib-form-divider></nib-form-divider>
    <nib-form-field [hint]="'Add text here'">
      <nib-input [fullWidth]="true" [label]="'With hint'"></nib-input>
    </nib-form-field>
    <nib-form-field [error]="'Use this as error text'">
      <nib-input [fullWidth]="true" [label]="'With error'"></nib-input>
    </nib-form-field>
  </nib-form>
</nib-demo-item>
<nib-demo-item>
  <h3>Image Manager</h3>
  <p>Create an images grid with drag and drop feature to reorder and delete items</p>
  <div class="nib-demo-route-item">
    <nib-image-manager [(images)]="images"></nib-image-manager>
  </div>
</nib-demo-item>
<nib-demo-item>
  <h3>Inputs</h3>
  <p>List of available inputs</p>
  <div class="nib-demo-route-item">
    <p>Simple</p>
    <nib-input [placeholder]="'Type here...'"></nib-input>
  </div>
  <div class="nib-demo-route-item">
    <p>Disabled mode</p>
    <nib-input [disabled]="true" [placeholder]="'Or here...'"></nib-input>
  </div>
  <div class="nib-demo-route-item">
    <p>Full width</p>
    <nib-input [fullWidth]="true" [placeholder]="'Why not here too...'"></nib-input>
  </div>
</nib-demo-item>
<nib-demo-item>
  <h3>Map</h3>
  <p>Generate a navigable version of an image where to add markers</p>
  <div class="nib-demo-route-item nib-demo-route-item-map">
    <p>Map</p>
    <nib-map
      [height]="534"
      [image]="images[0]"
      [markers]="markers"
      [markerTemplate]="marker"
      [width]="801"
    >
      <ng-template #marker let-name="name">
        <div class="nib-demo-route-item-map-marker">{{ name }}</div>
      </ng-template>
    </nib-map>
  </div>
</nib-demo-item>
<nib-demo-item>
  <h3>Media breakpoints</h3>
  <p>List of breakpoints</p>
  <div class="nib-demo-route-item">
    <p>Extra Small</p>
    <p>from <code>0</code> to <code>479px</code></p>
  </div>
  <div class="nib-demo-route-item">
    <p>Small</p>
    <p>from <code>480px</code> to <code>767px</code></p>
  </div>
  <div class="nib-demo-route-item">
    <p>Medium</p>
    <p>from <code>768px</code> to <code>1199px</code></p>
  </div>
  <div class="nib-demo-route-item">
    <p>Large</p>
    <p>from <code>1200px</code> upwards</p>
  </div>
</nib-demo-item>
<nib-demo-item>
  <h3>Multi select</h3>
  <div class="nib-demo-route-item">
    <p>Simple</p>
    <nib-multi-select [options]="options"></nib-multi-select>
  </div>
</nib-demo-item>
<nib-demo-item>
  <h3>Progress Bar</h3>
  <p>Horizontal bar for indicating progress and activity</p>
  <div class="nib-demo-route-item">
    <p>Progress bar primary (this is the default one)</p>
    <nib-progress-bar [value]="75"></nib-progress-bar>
  </div>
  <div class="nib-demo-route-item">
    <p>Progress bar informative</p>
    <nib-progress-bar [color]="'informative'" [value]="15"></nib-progress-bar>
  </div>
  <div class="nib-demo-route-item">
    <p>Progress bar notice</p>
    <nib-progress-bar [color]="'notice'" [value]="45"></nib-progress-bar>
  </div>
  <div class="nib-demo-route-item">
    <p>Progress bar positive</p>
    <nib-progress-bar [color]="'positive'" [value]="35"></nib-progress-bar>
  </div>
  <div class="nib-demo-route-item">
    <p>Progress bar negative</p>
    <nib-progress-bar [color]="'negative'" [value]="56"></nib-progress-bar>
  </div>
</nib-demo-item>
<nib-demo-item>
  <h3>Rich Text Editor</h3>
  <p>WYSIWYG editor</p>
  <div class="nib-demo-route-item">
    <nib-rich-text-editor [label]="'Editor'"></nib-rich-text-editor>
  </div>
</nib-demo-item>
<nib-demo-item>
  <h3>Selects</h3>
  <p>List of available selects</p>
  <div class="nib-demo-route-item">
    <p>Simple</p>
    <nib-select [options]="options"></nib-select>
  </div>
  <div class="nib-demo-route-item">
    <p>Disabled mode</p>
    <nib-select [disabled]="true" [options]="options"></nib-select>
  </div>
  <div class="nib-demo-route-item">
    <p>Full width</p>
    <nib-select [fullWidth]="true" [options]="options"></nib-select>
  </div>
</nib-demo-item>
<nib-demo-item>
  <h3>Switch</h3>
  <p>On/off control that can be toggled via clicking</p>
  <div class="nib-demo-route-item">
    <nib-switch [checked]="true" [label]="'Simple'"></nib-switch>
  </div>
  <div class="nib-demo-route-item">
    <nib-switch [checked]="true" [disabled]="true" [label]="'Disabled mode'"></nib-switch>
  </div>
</nib-demo-item>
<nib-demo-item>
  <h3>Tab</h3>
  <p>Organize contents into separate views where only one can be visible at a time.</p>
  <div class="nib-demo-route-item">
    <nib-tab-group [color]="'white'">
      <nib-tab [label]="'First'">Content 1</nib-tab>
      <nib-tab [label]="'Second'">Content 2</nib-tab>
      <nib-tab [label]="'Third'">Content 3</nib-tab>
    </nib-tab-group>
  </div>
</nib-demo-item>
<nib-demo-item>
  <h3>Textareas</h3>
  <p>List of available textareas</p>
  <div class="nib-demo-route-item">
    <p>Simple</p>
    <nib-textarea [placeholder]="'Type here...'"></nib-textarea>
  </div>
  <div class="nib-demo-route-item">
    <p>Disabled mode</p>
    <nib-textarea [disabled]="true" [placeholder]="'Or here...'"></nib-textarea>
  </div>
  <div class="nib-demo-route-item">
    <p>Full width</p>
    <nib-textarea [fullWidth]="true" [placeholder]="'Why not here too...'"></nib-textarea>
  </div>
</nib-demo-item>
<nib-demo-item>
  <h3>Toast</h3>
  <p>List of available toast severity levels</p>
  <div class="nib-demo-route-item">
    <p><code>informative</code></p>
    <nib-toast
      [content]="'Show toast inline into application'"
      [severity]="'informative'"
      [title]="'Informative'"
    ></nib-toast>
  </div>
  <div class="nib-demo-route-item">
    <p><code>positive</code></p>
    <nib-toast
      [content]="'Show toast inline into application'"
      [severity]="'positive'"
      [title]="'Positive'"
    ></nib-toast>
  </div>
  <div class="nib-demo-route-item">
    <p><code>notice</code></p>
    <nib-toast
      [content]="'Show toast inline into application'"
      [severity]="'notice'"
      [title]="'Notice'"
    ></nib-toast>
  </div>
  <div class="nib-demo-route-item">
    <p><code>negative</code></p>
    <nib-toast
      [content]="'Show toast inline into application'"
      [severity]="'negative'"
      [title]="'Negative'"
    ></nib-toast>
  </div>
  <div class="nib-demo-route-item">
    <p>Show toast in application shell</p>
    <nib-button (onClick)="showToast()">Show Toast</nib-button>
  </div>
  <div class="nib-demo-route-item">
    <p>Show toast in application shell with custom action</p>
    <nib-button (onClick)="showToast(okToastAction)">Show Toast</nib-button>
    <ng-template #okToastAction>
      <nib-button (onClick)="dismissToastWithCustomAction()">OK</nib-button>
    </ng-template>
  </div>
</nib-demo-item>
<nib-demo-item>
  <h3>Tooltip</h3>
  <p>Similar to Floating Panel, it shows only text content with a call to action if necessary</p>
  <div class="nib-demo-route-item">
    Some text with help tooltip
    <nib-icon
      [color]="'var(--color-gray-3)'"
      [name]="'question-medium'"
      [nibTooltip]="tooltipContent"
    ></nib-icon>
    <ng-template #tooltipContent>
      <nib-tooltip>
        This is an help message integrated with a call to action
        <a
          class="nib-button nib-button-dark nib-button-full-width nib-button-sm nib-button-with-icon"
        >
          <nib-icon [name]="'external-link'"></nib-icon> Go to External Link
        </a>
      </nib-tooltip>
    </ng-template>
  </div>
</nib-demo-item>
<nib-demo-item>
  <h3>Tree Selection</h3>
  <p>Display hiararchy data with three-state checkbox to selecte them.</p>
  <div class="nib-demo-route-item">
    <p>Simple</p>
    <nib-tree-selection [options]="buildingOptions"></nib-tree-selection>
  </div>
</nib-demo-item>
<nib-demo-item>
  <h3>Typography</h3>
  <p>List of available classes</p>
  <div class="nib-demo-route-item">
    <p>
      <code>.nib-heading-1</code>
    </p>
    <span class="nib-heading-1">Used for level 1 titles</span>
  </div>
  <div class="nib-demo-route-item">
    <p>
      <code>.nib-heading-2</code>
    </p>
    <span class="nib-heading-2">Used for level 2 titles</span>
  </div>
  <div class="nib-demo-route-item">
    <p>
      <code>.nib-heading-3</code>
    </p>
    <span class="nib-heading-3">Used for level 3 titles</span>
  </div>
  <div class="nib-demo-route-item">
    <p>
      <code>.nib-heading-4</code>
    </p>
    <span class="nib-heading-4">Used for level 4 titles</span>
  </div>
  <div class="nib-demo-route-item">
    <p>
      <code>.nib-body</code>
    </p>
    <span class="nib-body">Used for texts</span>
  </div>
  <div class="nib-demo-route-item">
    <p>
      <code>.nib-link</code>
    </p>
    <span class="nib-link">Used for links</span>
  </div>
</nib-demo-item>
