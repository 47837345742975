<label *ngIf="label" [for]="formControlName || id" class="nib-body">{{ label }}</label>
<mat-form-field
  [appearance]="'fill'"
  [ngClass]="ngClass"
  [ngSwitch]="type"
  class="nib-form-field-type-nib-native-input"
>
  <input
    *ngSwitchCase="'address'"
    [attr.list]="list"
    [autocomplete]="autocomplete || id"
    [autofocus]="autofocus"
    [country]="[]"
    [formControl]="proxiedControl"
    [id]="formControlName || id"
    [name]="formControlName || id"
    [placeholder]="placeholder"
    [readonly]="readonly"
    [required]="required"
    [type]="'address'"
    [types]="['address']"
    (onLocationSelected)="setAddressValues($event)"
    matGoogleMapsAutocomplete
    matInput
    placeIdOnly
    strictBounds
  />
  <ng-container *ngSwitchCase="'date'">
    <input
      [autocomplete]="autocomplete || id"
      [autofocus]="autofocus"
      [formControl]="proxiedControl"
      [id]="formControlName || id"
      [matDatepicker]="picker"
      [name]="formControlName || id"
      [placeholder]="placeholder"
      [readonly]="readonly"
      [required]="required"
      [type]="'text'"
      (change)="onChange.emit({ value: $event.target.value })"
      (click)="picker.open()"
      (focus)="onFocus.emit({ value: $event.target.value })"
      (keyup)="onKeyup.emit({ value: $event.target.value })"
      matInput
    />
    <button (click)="picker.open()" class="nib-input-suffix nib-input-suffix-button" type="button">
      <nib-icon [name]="'reservations-received'" matSuffix></nib-icon>
    </button>
    <mat-datepicker #picker [startAt]="startAt"></mat-datepicker>
  </ng-container>
  <input
    *ngSwitchCase="'password'"
    [autocomplete]="autocomplete || id"
    [autofocus]="autofocus"
    [formControl]="proxiedControl"
    [id]="formControlName || id"
    [name]="formControlName || id"
    [placeholder]="placeholder"
    [readonly]="readonly"
    [required]="required"
    [type]="isHidden ? 'password' : 'text'"
    (change)="onChange.emit({ value: $event.target.value })"
    (focus)="onFocus.emit({ value: $event.target.value })"
    (keyup)="onKeyup.emit({ value: $event.target.value })"
    matInput
  />
  <input
    *ngSwitchCase="'number'"
    [attr.list]="list"
    [autocomplete]="autocomplete || id"
    [autofocus]="autofocus"
    [formControl]="proxiedControl"
    [id]="formControlName || id"
    [max]="max"
    [min]="min"
    [name]="formControlName || id"
    [placeholder]="placeholder"
    [readonly]="readonly"
    [required]="required"
    [step]="step"
    [type]="type"
    (change)="onChange.emit({ value: $event.target.value })"
    (focus)="onFocus.emit({ value: $event.target.value })"
    (keyup)="onKeyup.emit({ value: $event.target.value })"
    matInput
  />
  <input
    *ngSwitchCase="'color'"
    [attr.list]="list"
    [autocomplete]="autocomplete || id"
    [autofocus]="autofocus"
    [email]="type === 'email'"
    [formControl]="proxiedControl"
    [id]="formControlName || id"
    [name]="formControlName || id"
    [placeholder]="placeholder"
    [readonly]="true"
    [required]="required"
    [type]="'text'"
    (change)="setColorValues($event.target.value)"
    (focus)="onFocus.emit({ value: $event.target.value })"
    (keyup)="onKeyup.emit({ value: $event.target.value })"
    matInput
  />
  <input
    *ngSwitchDefault
    [attr.list]="list"
    [autocomplete]="autocomplete || id"
    [autofocus]="autofocus"
    [email]="type === 'email'"
    [formControl]="proxiedControl"
    [id]="formControlName || id"
    [name]="formControlName || id"
    [placeholder]="placeholder"
    [readonly]="readonly"
    [required]="required"
    [type]="type"
    (change)="onChange.emit({ value: $event.target.value })"
    (focus)="onFocus.emit({ value: $event.target.value })"
    (keyup)="onKeyup.emit({ value: $event.target.value })"
    matInput
  />
  <div class="nib-input-suffix">
    <button
      *ngIf="proxiedControl.value?.length > 0 && (proxiedControl.errors || hasError)"
      (click)="proxiedControl.reset()"
      class="nib-input-suffix-button"
      type="button"
    >
      <nib-icon [color]="'var(--color-semantic-negative)'" [name]="'negative'" matSuffix></nib-icon>
    </button>
    <button
      *ngIf="type === 'password' && visibilityToggle"
      (click)="isHidden = !isHidden"
      class="nib-input-suffix-button"
      type="button"
    >
      <nib-icon [name]="visibilityIconName" matSuffix></nib-icon>
    </button>
    <button
      *ngIf="type === 'color'"
      [colorPicker]="proxiedControl.value"
      [cpAlphaChannel]="'disabled'"
      [cpUseRootViewContainer]="true"
      [disabled]="proxiedControl.disabled"
      (click)="showColorPicker = !showColorPicker"
      (colorPickerChange)="changeColor($event)"
      class="nib-input-suffix-button"
      type="button"
    >
      <nib-icon [name]="'color-picker'" matSuffix></nib-icon>
    </button>
  </div>
</mat-form-field>
<span *ngIf="hint" [innerHTML]="hint" class="nib-input-hint"></span>
