import { AfterViewChecked, Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[nibAppShellExplodeMenuItem]',
})
export class AppShellExplodeMenuItemDirective implements AfterViewChecked {
  @Input() nibAppShellExplodeMenuItem = false;

  private appShellMenuItem: HTMLElement | null = null;

  constructor(private readonly elementRef: ElementRef<HTMLElement>) {}

  ngAfterViewChecked(): void {
    this.appShellMenuItem = this.getAppShellMenuItem(this.elementRef.nativeElement);

    if (this.appShellMenuItem) {
      this.appShellMenuItem.classList.add('nib-app-shell-menu-item-toggle');
    }
  }

  // tslint:disable-next-line: no-host-decorator-in-concrete
  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.appShellMenuItem && this.nibAppShellExplodeMenuItem) {
      event.preventDefault();
      event.stopImmediatePropagation();

      this.appShellMenuItem.classList.toggle('nib-app-shell-menu-item-open');
    }
  }

  private getAppShellMenuItem(target: HTMLElement | null): HTMLElement | null {
    return target && target.closest('.nib-app-shell-menu-item-link');
  }
}
