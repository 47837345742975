import { TranslationService } from '@nibol/translation';
import { CalendarTranslationsConfig } from '@nibol/ui';

export function calendarTranslationsFactory(
  translationService: TranslationService,
): CalendarTranslationsConfig {
  return {
    dayViewName: translationService.translate('calendar_switch-view_day'),
    goToNextCTA: '>',
    goToPrevCTA: '<',
    goToTodayCTA: translationService.translate('calendar_today'),
    weekViewName: translationService.translate('calendar_switch-view_week'),
  };
}
