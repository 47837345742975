import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Select } from '@ngxs/store';
import { VersionsStateModelType } from '@nibol/store';
import { SelectOption } from '@nibol/ui';
import { Observable } from 'rxjs';

/** @dynamic */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nib-version-switcher',
  styleUrls: ['version-switcher.component.scss'],
  templateUrl: 'version-switcher.component.html',
})
export class VersionSwitcherComponent {
  @Input() version = '0.0.0';

  @Output() versionChange = new EventEmitter<string>();

  @Select(({ versions }: VersionsStateModelType) =>
    Object.keys(versions).map(version => ({ label: version, value: version })),
  )
  options$!: Observable<SelectOption[]>;
}
