import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormValueAccessor } from '@nibol/ui';
import { NewsletterFormValue } from './newsletter-form-value.type';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewsletterFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NewsletterFormComponent),
      multi: true,
    },
  ],
  selector: 'nib-newsletter-form',
  styleUrls: ['newsletter-form.component.scss'],
  templateUrl: 'newsletter-form.component.html',
})
export class NewsletterFormComponent extends FormValueAccessor<NewsletterFormValue> {
  form = new FormGroup({
    newsletterEmail: new FormControl(true),
    newsletterPush: new FormControl(true),
    newsletterPushWeb: new FormControl(true),
    newsletterSms: new FormControl(true),
  });
}
