import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { TermsAndPrivacyPolicySelectors } from './store/terms-and-privacy-policy.selectors';

@Injectable({ providedIn: 'root' })
export class TermsAndPrivacyPolicyTitleResolver implements Resolve<Observable<string>> {
  @Select(TermsAndPrivacyPolicySelectors.company) company$!: Observable<string>;

  resolve(): Observable<Observable<string>> {
    return of(this.company$);
  }
}
