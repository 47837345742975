import { Selector } from '@ngxs/store';
import { BuildingStateModel } from './building.model';
import { BuildingState } from './building.state';

export class BuildingSelectors {
  @Selector([BuildingState])
  static commission({
    commission,
  }: BuildingStateModel): NonNullable<BuildingStateModel['commission']> {
    return commission || 0;
  }

  @Selector([BuildingState])
  static fullname({ name }: BuildingStateModel): BuildingStateModel['name'] {
    return name;
  }

  @Selector([BuildingState])
  static nibolCosts({
    commission,
    leadFee,
  }: BuildingStateModel): Pick<BuildingStateModel, 'commission' | 'leadFee'> {
    return {
      commission: (commission || 0) * 100,
      leadFee: leadFee || 0,
    };
  }
}
