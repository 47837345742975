import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { MatBottomSheet, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { BottomSheetDataModel } from './bottom-sheet-data.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'nib-bottom-sheet',
  styleUrls: ['bottom-sheet.component.scss'],
  templateUrl: 'bottom-sheet.component.html',
})
export class BottomSheetComponent {
  /** Message to show in bottom sheet body. */
  @Input()
  get content(): BottomSheetDataModel['content'] {
    return this.data.content || this._content;
  }
  set content(value: BottomSheetDataModel['content']) {
    this._content = value;
  }
  private _content: BottomSheetDataModel['content'];

  /** Custom action to use. */
  @Input()
  get customAction(): BottomSheetDataModel['customAction'] {
    return this.data.customAction || this._customAction;
  }
  set customAction(value: BottomSheetDataModel['customAction']) {
    this._customAction = value;
  }
  private _customAction: BottomSheetDataModel['customAction'];

  /** Where the bottom sheet should be visible. */
  @Input()
  get position(): BottomSheetDataModel['position'] {
    return this.data.position || this._position;
  }
  set position(value: BottomSheetDataModel['position']) {
    this._position = value;
  }
  private _position: BottomSheetDataModel['position'] = 'right';

  /** A short text which describe the content. */
  @Input()
  get title(): BottomSheetDataModel['title'] {
    return this.data.title || this._title;
  }
  set title(value: BottomSheetDataModel['title']) {
    this._title = value;
  }
  private _title: BottomSheetDataModel['title'];

  /** Whether the content is a string */
  get isContentString(): boolean {
    return typeof this.content === 'string';
  }

  /** Whether the title is a string */
  get isTitleString(): boolean {
    return typeof this.title === 'string';
  }

  get ngClass(): { [className: string]: boolean } {
    return {
      'nib-bottom-sheet': true,
      [`nib-bottom-sheet-position-${this.position}`]: true,
    };
  }

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) readonly data: BottomSheetDataModel,
    private readonly matBottomSheet: MatBottomSheet,
  ) {}

  /** Close the bottom shet itself. */
  dismiss(): void {
    this.matBottomSheet.dismiss();
  }
}
