import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { NibLetContext } from './let.type';

/**
 * @see https://github.com/angular/angular/issues/15280#issuecomment-582104362
 */
@Directive({
  selector: '[nibLet]',
})
export class LetDirective<T = unknown> {
  @Input()
  set nibLet(value: T) {
    // if embedded view doesn't exist yet create it (only once)
    if (!this.embeddedViewRef) {
      this.createView();
    }

    // add the value to the context
    if (this.embeddedViewRef) {
      (this.embeddedViewRef.context as NibLetContext<T>).$implicit = this.context.nibLet = value;
    }
  }

  context: Partial<NibLetContext<T>> = {};

  embeddedViewRef?: EmbeddedViewRef<unknown>;

  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<NibLetContext<T>>,
  ) {}

  createView(): void {
    this.viewContainerRef.clear();
    this.embeddedViewRef = this.viewContainerRef.createEmbeddedView(this.templateRef, this.context);
  }
}
