import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    class: 'nib-button-group',
  },
  selector: 'nib-button-group',
  styleUrls: ['button-group.component.scss'],
  templateUrl: 'button-group.component.html',
})
export class ButtonGroupComponent {}
