import { Selector } from '@ngxs/store';
import { PermissionsState } from '@nibol/auth';
import { NpsProperties } from '@nibol/nps';
import {
  AppSelectors,
  AppStateModel,
  FeatureTogglesState,
  FeatureTogglesStateModel,
} from '@nibol/store';
import { UserStateModel } from '../../../store/user/user.model';
import { UserSelectors } from '../../../store/user/user.selectors';

export class AppShellAuthSelectors {
  @Selector([
    AppSelectors.client,
    AppSelectors.platform,
    FeatureTogglesState,
    PermissionsState,
    UserSelectors.email,
  ])
  static nps(
    client: AppStateModel['client'],
    platform: AppStateModel['platform'],
    featureToggles: FeatureTogglesStateModel,
    roles: string[],
    email: UserStateModel['email'],
  ): NpsProperties | undefined {
    return featureToggles.TOGGLE_ALLOW_WOOTRIC
      ? {
          accountName: email,
          clientCode: client,
          createdAt: Math.round(new Date().getTime() / 1000),
          osCode: window.navigator.userAgent.toLowerCase(),
          platformCode: platform || '',
          userRoles: roles,
        }
      : undefined;
  }
}
