import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormValueAccessor } from '@nibol/ui';
import { AnnouncementsFormValue } from './announcements-form-value.type';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AnnouncementsFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AnnouncementsFormComponent),
      multi: true,
    },
  ],
  selector: 'nib-announcements-form',
  styleUrls: ['announcements-form.component.scss'],
  templateUrl: 'announcements-form.component.html',
})
export class AnnouncementsFormComponent extends FormValueAccessor<AnnouncementsFormValue> {
  form = new FormGroup({
    announcementsEmail: new FormControl(true),
    announcementsPush: new FormControl(true),
    announcementsPushWeb: new FormControl(true),
    announcementsSms: new FormControl(true),
  });
}
