import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslationModule } from '@nibol/translation';
import { ButtonModule, IconModule, LetModule } from '@nibol/ui';
import { OktaButtonComponent } from './okta-button.component';

@NgModule({
  declarations: [OktaButtonComponent],
  exports: [OktaButtonComponent],
  imports: [ButtonModule, CommonModule, IconModule, LetModule, TranslationModule],
})
export class OktaButtonModule {}
