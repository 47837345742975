/**
 * Get dark or light color based on color
 * @see https://24ways.org/2010/calculating-color-contrast/
 * @param color color to calculate contrast by
 * @returns light or dark color
 */
export function getDarkOrLightColor(color: string): string {
  const parsedColor = color.replace('#', '');

  return (parseInt(parsedColor.substr(0, 2), 16) * 299 +
    parseInt(parsedColor.substr(2, 2), 16) * 587 +
    parseInt(parsedColor.substr(4, 2), 16) * 114) /
    1000 >=
    128
    ? '#000000'
    : '#ffffff';
}
