import { ModuleWithProviders, NgModule } from '@angular/core';
import { STRIPE_ELEMENTS_OPTIONS } from './core/stripe-elements-options.token';
import { STRIPE_KEY } from './core/stripe-key.token';
import { StripeModuleOptions } from './stripe-module-options.type';
import { StripeService } from './stripe.service';

@NgModule({
  providers: [StripeService],
})
export class StripeModule {
  static forRoot(options: StripeModuleOptions): ModuleWithProviders<StripeModule> {
    return {
      ngModule: StripeModule,
      providers: [
        {
          provide: STRIPE_KEY,
          useValue: options.key,
        },
        {
          provide: STRIPE_ELEMENTS_OPTIONS,
          useValue: options.elementsOptions,
        },
      ],
    };
  }
}
