import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslationModule } from '@nibol/translation';
import {
  ButtonModule,
  ChipModule,
  FormModule,
  InputModule,
  SelectModule,
  SwitchModule,
} from '@nibol/ui';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { HubRouteCardModule } from '../../components/hub-route-card/hub-route-card.module';
import { AccountFormComponent } from './account-notifications-route/account-form/account-form.component';
import { AccountNotificationsRouteComponent } from './account-notifications-route/account-notifications-route.component';
import { AnnouncementsFormComponent } from './account-notifications-route/announcements-form/announcements-form.component';
import { NewsletterFormComponent } from './account-notifications-route/newsletter-form/newsletter-form.component';
import { ServiceFormComponent } from './account-notifications-route/service-form/service-form.component';
import { AccountProfileRouteComponent } from './account-profile-route/account-profile-route.component';
import { LanguageSelectionFormComponent } from './account-profile-route/language-selection-form/language-selection-form.component';
import { LogoutFormComponent } from './account-profile-route/logout-form/logout-form.component';
import { PersonalInfoFormComponent } from './account-profile-route/personal-info-form/personal-info-form.component';
import { AccountRouteComponent } from './account-route/account-route.component';
import { AccountRoutingModule } from './account-routing.module';

@NgModule({
  imports: [
    AccountRoutingModule,
    ButtonModule,
    ChipModule,
    CommonModule,
    HubRouteCardModule,
    InputModule,
    FeatureToggleModule,
    FlexModule,
    FormModule,
    ReactiveFormsModule,
    SelectModule,
    SwitchModule,
    TranslationModule,
  ],
  declarations: [
    AccountFormComponent,
    AccountNotificationsRouteComponent,
    AccountProfileRouteComponent,
    AccountRouteComponent,
    AnnouncementsFormComponent,
    LanguageSelectionFormComponent,
    LogoutFormComponent,
    NewsletterFormComponent,
    PersonalInfoFormComponent,
    ServiceFormComponent,
  ],
})
export class AccountModule {}
