import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { environment } from '@marketplace/environment';
import { PermissionsGuard, Roles } from '@nibol/auth';
import { AppShells } from '@nibol/shared';
import { NgxFeatureToggleCanLoadGuard } from 'ngx-feature-toggle';

@NgModule({
  imports: [
    RouterModule.forRoot([
      {
        path: '',
        runGuardsAndResolvers: 'always',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: environment.homepage,
          },
          {
            path: 'access-denied',
            loadChildren: () =>
              import('./access-denied/access-denied.module').then(m => m.AccessDeniedModule),
            canActivateChild: [PermissionsGuard],
            canLoad: [PermissionsGuard],
            data: {
              permissions: {
                only: [Roles.guest],
                redirectTo: environment.homepage,
              },
              shell: AppShells.public,
            },
          },
          {
            path: 'account',
            loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
            canActivateChild: [PermissionsGuard],
            canLoad: [PermissionsGuard],
            data: {
              permissions: {
                only: [Roles.admin, Roles.ambassador, Roles.manager],
                redirectTo: '/login',
              },
            },
          },
          {
            path: 'add-new-venue',
            loadChildren: () =>
              import('./become-a-host/become-a-host.module').then(m => m.BecomeAHostModule),
            canActivateChild: [PermissionsGuard],
            canLoad: [PermissionsGuard],
            data: {
              isWizard: true,
              permissions: {
                only: [Roles.admin, Roles.ambassador, Roles.manager],
                // redirectTo with this value in order to mantain dependency between core routes
                redirectTo: '/login',
              },
            },
          },
          {
            path: 'admin',
            loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
            canActivateChild: [PermissionsGuard],
            canLoad: [PermissionsGuard],
            data: {
              permissions: {
                only: [Roles.admin],
                redirectTo: '/login',
              },
            },
          },
          {
            path: 'analytics',
            loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule),
            canActivateChild: [PermissionsGuard],
            canLoad: [NgxFeatureToggleCanLoadGuard, PermissionsGuard],
            data: {
              featureToggle: ['TOGGLE_ENABLE_ANALYTICS'],
              permissions: {
                only: [Roles.admin, Roles.ambassador, Roles.manager],
                redirectTo: '/login',
              },
            },
          },
          {
            path: 'billing',
            loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule),
            canActivateChild: [PermissionsGuard],
            canLoad: [PermissionsGuard],
            data: {
              permissions: {
                only: [Roles.admin, Roles.manager],
                redirectTo: '/login',
              },
            },
          },
          {
            path: 'become-a-host',
            loadChildren: () =>
              import('./become-a-host/become-a-host.module').then(m => m.BecomeAHostModule),
            canActivateChild: [PermissionsGuard],
            canLoad: [PermissionsGuard],
            data: {
              isWizard: true,
              permissions: {
                only: [Roles.wannabeManager],
                redirectTo: '/login',
              },
            },
          },
          {
            path: 'calendar',
            loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule),
            canActivateChild: [PermissionsGuard],
            canLoad: [PermissionsGuard],
            data: {
              permissions: {
                only: [Roles.admin, Roles.ambassador, Roles.manager],
                // redirectTo with this value in order to mantain dependency between core routes
                redirectTo: '/become-a-host',
              },
            },
          },
          {
            path: 'create-password',
            loadChildren: () =>
              import('./create-password/create-password.module').then(m => m.CreatePasswordModule),
            canActivateChild: [PermissionsGuard],
            canLoad: [PermissionsGuard],
            data: {
              permissions: {
                only: [Roles.hasToChangePassword],
                // redirectTo with this value in order to mantain dependency between core routes
                redirectTo: '/login',
              },
              showMenu: false,
              showTitle: false,
            },
          },
          {
            path: 'help',
            loadChildren: () => import('./help/help.module').then(m => m.HelpModule),
            canActivateChild: [PermissionsGuard],
            canLoad: [NgxFeatureToggleCanLoadGuard, PermissionsGuard],
            data: {
              featureToggle: ['TOGGLE_ENABLE_HELP'],
              permissions: {
                only: [Roles.admin, Roles.ambassador, Roles.manager],
                redirectTo: '/login',
              },
            },
          },
          {
            path: 'login',
            loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
            canActivateChild: [PermissionsGuard],
            canLoad: [PermissionsGuard],
            data: {
              permissions: {
                only: [Roles.guest],
                // redirectTo with this value in order to mantain dependency between core routes
                redirectTo: environment.homepage,
              },
            },
          },
          {
            path: 'permission-denied',
            loadChildren: () =>
              import('./permission-denied/permission-denied.module').then(
                m => m.PermissionDeniedModule,
              ),
            data: {
              shell: AppShells.public,
            },
          },
          {
            path: 'recover-password',
            loadChildren: () =>
              import('./recover-password/recover-password.module').then(
                m => m.RecoverPasswordModule,
              ),
            data: {
              shell: AppShells.public,
              showMenu: false,
              showTitle: false,
            },
          },
          {
            path: 'reset-password',
            loadChildren: () =>
              import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule),
            data: {
              shell: AppShells.public,
              showMenu: false,
              showTitle: false,
            },
          },
          {
            path: 'reservations-list',
            loadChildren: () =>
              import('./reservations-list/reservations-list.module').then(
                m => m.ReservationsListModule,
              ),
            canActivateChild: [PermissionsGuard],
            canLoad: [NgxFeatureToggleCanLoadGuard, PermissionsGuard],
            data: {
              featureToggle: ['TOGGLE_ENABLE_RESERVATIONS_LIST'],
              permissions: {
                only: [Roles.admin, Roles.manager],
                redirectTo: '/login',
              },
            },
          },
          {
            path: 'settings',
            loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
            canActivateChild: [PermissionsGuard],
            canLoad: [PermissionsGuard],
            data: {
              permissions: {
                only: [Roles.admin, Roles.ambassador, Roles.manager],
                redirectTo: '/login',
              },
            },
          },
          {
            path: 'signup',
            loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule),
            canActivateChild: [PermissionsGuard],
            canLoad: [PermissionsGuard],
            data: {
              permissions: {
                only: [Roles.guest],
                redirectTo: environment.homepage,
              },
            },
          },
          {
            path: 'spaces',
            loadChildren: () => import('./space/space.module').then(m => m.SpaceModule),
            canActivateChild: [PermissionsGuard],
            canLoad: [PermissionsGuard],
            data: {
              permissions: {
                only: [Roles.admin, Roles.ambassador, Roles.manager],
                redirectTo: '/login',
              },
            },
          },
          {
            path: 'terms-and-privacy-policy',
            loadChildren: () =>
              import('./terms-and-privacy-policy/terms-and-privacy-policy.module').then(
                m => m.TermsAndPrivacyPolicyModule,
              ),
            canActivateChild: [PermissionsGuard],
            canLoad: [PermissionsGuard],
            data: {
              permissions: {
                only: [Roles.hasToAcceptTermsAndPrivacyPolicy],
                // redirectTo with this value in order to mantain dependency between core routes
                redirectTo: '/create-password',
              },
              showMenu: false,
              showTitle: false,
            },
          },
          {
            path: 'transfers',
            loadChildren: () => import('./transfers/transfers.module').then(m => m.TransfersModule),
            canActivateChild: [PermissionsGuard],
            canLoad: [NgxFeatureToggleCanLoadGuard, PermissionsGuard],
            data: {
              featureToggle: ['TOGGLE_ENABLE_PAYMENTS', 'TOGGLE_ENABLE_INCOMES'],
              permissions: {
                only: [Roles.admin, Roles.manager],
                redirectTo: '/login',
              },
            },
          },
        ],
      },
    ]),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
