import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

// tslint:disable-next-line: validate-decorators
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'nib-form-group',
  },
  selector: 'nib-form-group',
  template: `
    <fieldset>
      <legend *ngIf="title" class="nib-form-group-legend nib-heading-3">{{ title }}</legend>
      <ng-content></ng-content>
    </fieldset>
  `,
})
export class FormGroupComponent {
  /** Group title. */
  @Input() title = '';
}
