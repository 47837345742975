import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DEBUG_MODE } from './core/debug-mode.token';
import { DEFAULT_LANGUAGE } from './core/default-language.token';
import { LanguageCode } from './core/language-code.type';
import { TranslationPrismicService } from './prismic/translation-prismic.service';
import { TranslationsFromPrismic } from './prismic/translations-from-prismic.type';

@Injectable({ providedIn: 'root' })
export class TranslationService {
  current: LanguageCode | null = this.defaultLanguage;

  dictionary$ = new BehaviorSubject<TranslationsFromPrismic>({});

  private readonly exposedWarns: string[] = [];

  constructor(
    @Inject(DEBUG_MODE) private readonly debugMode: boolean,
    @Inject(DEFAULT_LANGUAGE) private readonly defaultLanguage: LanguageCode,
    private readonly translationPrismicService: TranslationPrismicService,
  ) {}

  async changeLanguage(
    language: LanguageCode | null = this.defaultLanguage,
  ): Promise<TranslationsFromPrismic> {
    return new Promise(async resolve => {
      this.dictionary$.next(await this.translationPrismicService.getDictionary(language));
      this.current = language;

      resolve(this.dictionary$.getValue());
    });
  }

  // tslint:disable-next-line: no-any
  translate(name: string = '', params?: { [key: string]: any }): string {
    const translation = this.dictionary$.getValue()?.[name];

    if (this.debugMode) {
      this.warnsForMissingTranslations(name, translation);
    }

    if (typeof translation === 'string') {
      let parsedTranslation = translation.replace(/\n/g, '<br />');

      if (params) {
        Object.keys(params).forEach(param => {
          parsedTranslation = parsedTranslation.replace(
            new RegExp(`{{${param}}}`, 'g'),
            params[param],
          );
        });
      }

      return parsedTranslation;
    }

    if (Array.isArray(translation)) {
      return translation.map(content => content.text).join('\n\n');
    }

    return name;
  }

  private warnsForMissingTranslations(
    name: string,
    translation: TranslationsFromPrismic['name'],
  ): void {
    if (typeof translation !== 'string' && this.exposedWarns.indexOf(name) === -1) {
      this.exposedWarns.push(name);

      if (typeof translation === 'undefined') {
        // tslint:disable-next-line: no-console
        console.warn(`@nibol/translation Missing key \`${name}\` for translate`);
      }

      if (translation === null) {
        // tslint:disable-next-line: no-console
        console.warn(`@nibol/translation Missing translation for key \`${name}\``);
      }
    }
  }
}
