import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { AuthStateAction } from '@nibol/auth';
import { BehaviorSubject } from 'rxjs';
import { CreatePasswordFormValue } from './create-password-form/create-password-form-value.type';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nib-create-password-route',
  styleUrls: ['create-password-route.component.scss'],
  templateUrl: 'create-password-route.component.html',
})
export class CreatePasswordRouteComponent implements OnInit {
  @Dispatch() createPasswordTry = (password: string) =>
    new AuthStateAction.CreatePassword.Try({
      new_password: password,
    });

  form = new FormGroup({
    createPassword: new FormControl({
      password: null,
    }),
  });
  isCreatePasswordFormDisabled$ = new BehaviorSubject(false);
  isCreatePasswordRequested$ = new BehaviorSubject(false);

  constructor(private readonly actions$: Actions) {}

  ngOnInit(): void {
    this.manageSubmit();
  }

  createPassword(): void {
    if (this.form.valid) {
      const { password } = this.form.value.createPassword as CreatePasswordFormValue;

      this.createPasswordTry(password);
      this.isCreatePasswordFormDisabled$.next(true);
    }
  }

  private manageSubmit(): void {
    this.actions$
      .pipe(ofActionSuccessful(AuthStateAction.CreatePassword.Failure), untilDestroyed(this))
      .subscribe(() => {
        this.isCreatePasswordFormDisabled$.next(false);
      });

    this.actions$
      .pipe(ofActionSuccessful(AuthStateAction.CreatePassword.Success), untilDestroyed(this))
      .subscribe(() => {
        this.isCreatePasswordRequested$.next(true);
      });
  }
}
