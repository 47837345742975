import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Select } from '@ngxs/store';
import {
  FeatureTogglesState,
  FeatureTogglesStateAction,
  VersionState,
  VersionStateAction,
} from '@nibol/store';
import { Observable } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nib-enable-features-route',
  styleUrls: ['enable-features-route.component.scss'],
  templateUrl: 'enable-features-route.component.html',
})
export class EnableFeaturesRouteComponent<
  Version extends string,
  FeatureToggles extends { [key: string]: boolean }
> {
  @Dispatch() changeCurrentVersion = (version: Version) =>
    new VersionStateAction.Change.Try(version);
  @Dispatch() changeFeatureToggle = (featureToggle: FeatureToggles) =>
    this.shouldUpdateFeatureToggles(featureToggle) &&
    new FeatureTogglesStateAction.Update.Try(featureToggle);

  @Select(VersionState) currentVersion$!: Observable<Version>;
  @Select(FeatureTogglesState) featureToggles$!: Observable<FeatureToggles>;

  @SelectSnapshot(FeatureTogglesState) featureToggles!: FeatureToggles;

  onChangeFeatureToggleValue(featureToggles: FeatureToggles): void {
    if (this.shouldUpdateFeatureToggles(featureToggles)) {
      this.changeFeatureToggle(featureToggles);
    }
  }

  private shouldUpdateFeatureToggles(featureToggles: FeatureToggles): boolean {
    return Object.keys(featureToggles)
      .map(
        featureToggleName =>
          featureToggleName in this.featureToggles &&
          this.featureToggles[featureToggleName as keyof FeatureToggles] !==
            featureToggles[featureToggleName as keyof FeatureToggles],
      )
      .every(featureToggleShouldBeUpdated => featureToggleShouldBeUpdated);
  }
}
