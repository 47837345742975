// tslint:disable: no-namespace max-classes-per-file

import { AppStateModel } from './app.model';

export namespace AppStateAction {
  export namespace Client {
    export namespace Change {
      export class Try {
        static readonly type = '[App] try Change Client';
        constructor(public client: AppStateModel['client']) {}
      }
      export class Success {
        static readonly type = '[App] then Change Client success';
        constructor(public app: AppStateModel) {}
      }
      export class Failure {
        static readonly type = '[App] catch Change Client failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export class Destroy {
    static readonly type = '[App] Destroy App';
  }

  export namespace Init {
    export class Try {
      static readonly type = '[App] try Init App';
    }
    export class Success {
      static readonly type = '[App] then Init App success';
      constructor(public app: AppStateModel) {}
    }
    export class Failure {
      static readonly type = '[App] catch Init App failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Notifications {
    export namespace Change {
      export class Try {
        static readonly type = '[App] try Change Notifications';
        constructor(public notifications: AppStateModel['notifications']) {}
      }
      export class Success {
        static readonly type = '[App] then Change Notifications success';
        constructor(public app: AppStateModel) {}
      }
      export class Failure {
        static readonly type = '[App] catch Change Notifications failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace Platform {
    export namespace Change {
      export class Try {
        static readonly type = '[App] try Change Platform';
        constructor(public platform: AppStateModel['platform']) {}
      }
      export class Success {
        static readonly type = '[App] then Change Platform success';
        constructor(public app: AppStateModel) {}
      }
      export class Failure {
        static readonly type = '[App] catch Change Platform failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace ScrollTop {
    export class Try {
      static readonly type = '[App] try Scroll Top';
    }
    export class Success {
      static readonly type = '[App] then Scroll Top success';
      constructor(public app: AppStateModel) {}
    }
    export class Failure {
      static readonly type = '[App] catch Scroll Top failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace State {
    export namespace Change {
      export class Try {
        static readonly type = '[App] try Change State';
        constructor(public state: AppStateModel['state'], public delay = 0) {}
      }
      export class Success {
        static readonly type = '[App] then Change State success';
        constructor(public app: AppStateModel) {}
      }
      export class Failure {
        static readonly type = '[App] catch Change State failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }

    export namespace Ready {
      export class Try {
        static readonly type = '[App] try Change State to Ready';
        constructor(public state: AppStateModel['state'], public delay = 0) {}
      }
      export class Success {
        static readonly type = '[App] then Change State to Ready success';
        constructor(public app: AppStateModel) {}
      }
      export class Failure {
        static readonly type = '[App] catch Change State to Ready failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace SupportContacts {
    export namespace Change {
      export class Try {
        static readonly type = '[App] try Change Support Contacts';
        constructor(public supportContacts: AppStateModel['supportContacts']) {}
      }
      export class Success {
        static readonly type = '[App] then Change Support Contacts success';
        constructor(public app: AppStateModel) {}
      }
      export class Failure {
        static readonly type = '[App] catch Change Support Contacts failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }
}
