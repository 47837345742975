import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Data, Router, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngxs/router-plugin';
import { CustomRouterStateSnapshot } from './router.model';

@Injectable()
export class CustomRouterStateSerializer
  implements RouterStateSerializer<CustomRouterStateSnapshot> {
  constructor(private readonly router: Router) {}

  serialize(routerState: RouterStateSnapshot): CustomRouterStateSnapshot {
    const {
      url,
      root: { queryParams },
    } = routerState;

    let { root: route } = routerState;
    while (route.firstChild) {
      route = route.firstChild;
    }

    const { params } = route;
    const data = this.getData(route);

    if (route.params.title) {
      data.title = route.params.title;
    }

    return { data, params, queryParams, url };
  }

  private getData(route: ActivatedRouteSnapshot): Data {
    return {
      ...route.data,
      ...this.router.getCurrentNavigation()?.extras.state?.data,
    };
  }
}
