import { Injectable } from '@angular/core';
import { BuildingService } from '@marketplace/api/building';
import { Action, State, StateContext } from '@ngxs/store';
import { insertItem, patch } from '@ngxs/store/operators';
import { catchError, tap } from 'rxjs/operators';
import { CompanyState } from '../company/company.state';
import { ReservationsState } from '../reservations/reservations.state';
import { VenuesState } from '../venues/venues.state';
import { BuildingStateAction } from './building.actions';
import { mapBuildingToApi, mapBuildingToClient } from './building.helper';
import { BuildingStateModel } from './building.model';

@State<BuildingStateModel>({
  name: 'building',
  children: [CompanyState, ReservationsState, VenuesState],
})
@Injectable()
export class BuildingState {
  constructor(private readonly buildingService: BuildingService) {}

  @Action(BuildingStateAction.Venue.Create.Try)
  createBuildingVenue(
    { dispatch, getState, setState }: StateContext<BuildingStateModel>,
    { id, mode, name }: BuildingStateAction.Venue.Create.Try,
  ) {
    return this.buildingService.createVenue({ id, data: { mode, name } }).pipe(
      catchError(error => {
        dispatch(new BuildingStateAction.Venue.Create.Failure(error));

        throw error;
      }),
      tap(({ status }) => {
        if (status) {
          dispatch(new BuildingStateAction.Venue.Create.Success(getState()));

          setState(patch({ venues: insertItem({ mode, name }) }));
        } else {
          dispatch(new BuildingStateAction.Venue.Create.Failure(status));

          throw status;
        }
      }),
    );
  }

  @Action(BuildingStateAction.Item.Read.Try)
  readItem(
    { dispatch, getState, setState }: StateContext<BuildingStateModel>,
    { id }: BuildingStateAction.Item.Read.Try,
  ) {
    return this.buildingService.read({ id }).pipe(
      catchError(error => {
        dispatch(new BuildingStateAction.Item.Read.Failure(error));

        throw error;
      }),
      tap(building => {
        setState(patch(mapBuildingToClient(building)));

        dispatch(new BuildingStateAction.Item.Read.Success(getState()));
      }),
    );
  }

  @Action(BuildingStateAction.CostsAndPricingModel.Update.Try)
  updateCostsAndPricingModel(
    { dispatch, getState }: StateContext<BuildingStateModel>,
    { costs, id }: BuildingStateAction.CostsAndPricingModel.Update.Try,
  ) {
    return dispatch(new BuildingStateAction.Item.Update.Try(id, costs)).pipe(
      catchError(error => {
        dispatch(new BuildingStateAction.Information.Update.Failure(error));

        throw error;
      }),
      tap(() => {
        dispatch(new BuildingStateAction.CostsAndPricingModel.Update.Success(getState()));
      }),
    );
  }

  @Action(BuildingStateAction.Information.Update.Try)
  updateInformation(
    { dispatch, getState }: StateContext<BuildingStateModel>,
    { data, id }: BuildingStateAction.Information.Update.Try,
  ) {
    return dispatch(new BuildingStateAction.Item.Update.Try(id, data)).pipe(
      catchError(error => {
        dispatch(new BuildingStateAction.Information.Update.Failure(error));

        throw error;
      }),
      tap(() => {
        dispatch(new BuildingStateAction.Information.Update.Success(getState()));
      }),
    );
  }

  @Action(BuildingStateAction.Item.Update.Try)
  updateItem(
    { dispatch, getState, setState }: StateContext<BuildingStateModel>,
    { data, id }: BuildingStateAction.Item.Update.Try,
  ) {
    return this.buildingService.update({ data: mapBuildingToApi(data), id }).pipe(
      catchError(error => {
        dispatch(new BuildingStateAction.Item.Update.Failure(error));

        throw error;
      }),
      tap(({ status }) => {
        if (status) {
          setState(patch({ ...data, id }));

          dispatch(new BuildingStateAction.Item.Update.Success(getState()));
        } else {
          dispatch(new BuildingStateAction.Item.Update.Failure(status));

          throw status;
        }
      }),
    );
  }
}
