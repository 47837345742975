import { WeekDay } from '@angular/common';
import { WeekdayFormControlValue } from './weekday-form-control-value.type';

export function defaultWeekday(weekday: WeekDay): WeekdayFormControlValue {
  return { availability: false, timeSlots: {}, weekday };
}

export function atLeastOneTimeSlot(weekday: WeekDay): WeekdayFormControlValue {
  return {
    ...defaultWeekday(weekday),
    availability: true,
    timeSlots: { [0]: { from: '09:00', to: '18:00' } },
  };
}
