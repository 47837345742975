import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Select } from '@ngxs/store';
import { Breadcrumb } from '@nibol/store';
import { isObservable, Observable, of } from 'rxjs';
import { FrontendySelectors } from '../../../store/frontendy.selectors';
import { AppShellAuthSelectors } from './app-shell-auth.selectors';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nib-app-shell-auth',
  styleUrls: ['app-shell-auth.component.scss'],
  templateUrl: 'app-shell-auth.component.html',
})
export class AppShellAuthComponent {
  @Input() breadcrumbs!: Breadcrumb[];
  @Input() fullHeight!: boolean;
  @Input() fullWidth!: boolean;
  @Input() showHeader!: boolean;
  @Input() showMenu!: boolean;
  @Input() showTitle!: boolean;
  @Input() scope!: string;
  @Input() user!: string;
  @Input() withTabs!: boolean;
  @Input() avatar?: string;
  @Input() logo?: string;
  @Input() logoLink?: string;

  @Input()
  get title(): Observable<string> {
    return this._title;
  }
  set title(value: Observable<string>) {
    this._title = isObservable(value) ? value : of<string>(value);
  }
  private _title!: Observable<string>;

  @Select(FrontendySelectors.disableHeader) disableHeader$!: Observable<boolean>;
  @Select(AppShellAuthSelectors.nps) nps$!: Observable<{ [key: string]: unknown }>;
  @Select(FrontendySelectors.shellClasses) shellClasses$!: Observable<string[]>;
  @Select(FrontendySelectors.showHeaderActions) showHeaderActions$!: Observable<boolean>;
}
