import { NgClass } from '@angular/common';
import { Selector } from '@ngxs/store';
import { PermissionsState, Role } from '@nibol/auth';
import {
  AppSelectors,
  AppStateModel,
  Breadcrumb,
  CustomRouterStateSnapshot,
  RouteData,
  RouterSelectors,
  States,
} from '@nibol/store';
import { AppShell } from '../components/public-api';

/** @dynamic */
export class FrontendySelectors {
  @Selector([RouterSelectors.data])
  static disableHeader(data: CustomRouterStateSnapshot['data']): boolean {
    return typeof data?.disableHeader !== 'undefined' ? data.disableHeader : false;
  }

  @Selector([RouterSelectors.data])
  static fullHeight(data: CustomRouterStateSnapshot['data']): boolean {
    return typeof data?.fullHeight !== 'undefined' ? data.fullHeight : false;
  }

  @Selector([RouterSelectors.data])
  static fullWidth(data: CustomRouterStateSnapshot['data']): boolean {
    return typeof data?.fullWidth !== 'undefined' ? data.fullWidth : false;
  }

  @Selector([AppSelectors.state])
  static isInProgress(state: AppStateModel['state']): boolean {
    return state === States['in-progress'];
  }

  @Selector([AppSelectors.state])
  static isReady(state: AppStateModel['state']): boolean {
    return state === States.ready;
  }

  @Selector([RouterSelectors.data])
  static shell(data: RouteData<string[], AppShell>): RouteData['shell'] {
    return data?.shell;
  }

  @Selector([RouterSelectors.data])
  static showHeader(data: CustomRouterStateSnapshot['data']): boolean {
    return typeof data?.showHeader !== 'undefined' ? data.showHeader : true;
  }

  @Selector([RouterSelectors.data])
  static showHeaderActions(data: CustomRouterStateSnapshot['data']): boolean {
    return typeof data?.showHeaderActions !== 'undefined' ? data.showHeaderActions : false;
  }

  @Selector([RouterSelectors.data])
  static showMenu(data: CustomRouterStateSnapshot['data']): boolean {
    return typeof data?.showMenu !== 'undefined' ? data.showMenu : true;
  }

  @Selector([RouterSelectors.data])
  static showTitle(data: CustomRouterStateSnapshot['data']): boolean {
    return typeof data?.showTitle !== 'undefined' ? data.showTitle : true;
  }

  @Selector([PermissionsState, RouterSelectors.url, RouterSelectors.data])
  static shellClasses(
    roles: Role[],
    url: CustomRouterStateSnapshot['url'],
    data: CustomRouterStateSnapshot['data'],
  ): NgClass['ngClass'] {
    return [
      ...(data?.shellClasses || []),
      ...roles.map(
        role => `nib-role-${role.toLowerCase().replace(/\./g, '-').replace(/\_/g, '-')}`,
      ),
      `nib-screen${url?.replace(/\//g, '-')}`,
    ];
  }

  @Selector([RouterSelectors.data])
  static title(data: CustomRouterStateSnapshot['data']): Breadcrumb['name'] {
    return data?.title || '';
  }

  @Selector([RouterSelectors.data])
  static withTabs(data: CustomRouterStateSnapshot['data']): boolean {
    return typeof data?.withTabs !== 'undefined' ? data.withTabs : false;
  }
}
