<cdk-virtual-scroll-viewport
  [tvsItemSize]="itemSize"
  [headerHeight]="itemSize"
  class="nib-table-scroll-container"
>
  <table [dataSource]="dataSource" mat-table>
    <ng-content></ng-content>

    <tr *matHeaderRowDef="columns; sticky: true" mat-header-row></tr>
    <tr *matRowDef="let row; columns: columns" mat-row></tr>
  </table>
</cdk-virtual-scroll-viewport>
