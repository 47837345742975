import { Injectable } from '@angular/core';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Action, State, StateContext } from '@ngxs/store';
import { AppStateAction, States } from '@nibol/store';

@State({
  name: 'routersaga',
})
@Injectable()
export class RouterSaga {
  @Action(RouterNavigation)
  routerDataResolved({ dispatch }: StateContext<unknown>) {
    dispatch([
      new AppStateAction.State.Change.Try(States.ready, 1000),
      new AppStateAction.ScrollTop.Try(),
    ]);
  }
}
