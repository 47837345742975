import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import { Icon } from '../icon/icon.type';
import { FileUploaderTranslationsConfig } from './file-uploader-config.type';
import { FileUploaderFiles } from './file-uploader-files.type';
import { FILE_UPLOADER_TRANSLATIONS } from './file-uploader-translations.token';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    class: 'nib-file-uploader',
  },
  selector: 'nib-file-uploader',
  styleUrls: ['file-uploader.component.scss'],
  templateUrl: 'file-uploader.component.html',
})
export class FileUploaderComponent {
  /** MIME type to accept */
  @Input() accept = '';

  /** Icon to use */
  @Input() icon: Icon = 'upload';

  /** Determinated progress spinner value */
  @Input() progress = 0;

  /** Current value to show */
  @Input() value: string | undefined;

  /** Emit dropped file list to upload */
  @Output() onFileDropped = new EventEmitter<FileUploaderFiles>();

  /** File input element */
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  isDraggingOver = new Subject<boolean>();
  isDraggingOverError = new Subject<boolean>();

  constructor(
    @Inject(FILE_UPLOADER_TRANSLATIONS) readonly translations: FileUploaderTranslationsConfig,
  ) {}

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer?.types.every(type => type === 'Files')) {
      this.isDraggingOver.next(true);
    } else {
      this.isDraggingOverError.next(true);
    }
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.isDraggingOver.next(false);
    this.isDraggingOverError.next(false);
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.isDraggingOver.next(false);
    this.isDraggingOverError.next(false);

    this.emitFiles(event.dataTransfer?.files);
  }

  onInputChange({ files }: HTMLInputElement): void {
    this.isDraggingOver.next(false);
    this.isDraggingOverError.next(false);

    this.emitFiles(files);
  }

  private emitFiles(files?: FileList | File[] | null): void {
    if (files) {
      const filesToEmit: FileUploaderFiles = { error: [], success: [] };

      // tslint:disable-next-line: prefer-for-of
      for (let index = 0; index < files.length; index++) {
        const file = files[index];

        if (this.isFileAccepted(file.type)) {
          filesToEmit.success.push(file);
        } else {
          filesToEmit.error.push(file);
        }
      }

      this.onFileDropped.emit(filesToEmit);
    }
  }

  private isFileAccepted(type: Blob['type']): boolean {
    return type.split('/')[0].startsWith(this.accept.split('/')[0]);
  }
}
