import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

/**
 * ProxyValueAccessor provides a ControlValueAccessor implementation
 * that allows your component to wrap a FormControl.
 * When extending this class you have to provide an implementation of the FormControl getter.
 */
@UntilDestroy()
export abstract class ProxyValueAccessor implements ControlValueAccessor, Validator {
  abstract get proxiedControl(): FormControl;

  get value(): unknown {
    return this.proxiedControl.value;
  }

  writeValue(value: string | number | null): void {
    this.proxiedControl.setValue(value, { emitEvent: false });
  }

  registerOnChange(callback: (value: string) => void): void {
    this.proxiedControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value: string) => {
      callback(value);
    });
  }

  // tslint:disable-next-line: no-empty
  registerOnTouched(_callback: () => void): void {}

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.proxiedControl.disable();
    } else {
      this.proxiedControl.enable();
    }
  }

  validate(_control: AbstractControl): ValidationErrors | null {
    return this.proxiedControl.valid ? null : this.proxiedControl.errors;
  }
}
