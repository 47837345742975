// tslint:disable: no-console
import { Inject, Injectable } from '@angular/core';
import { DEBUG_LEVEL } from './core/debug-level.token';
import { DebugLevel, DebugLevels } from './core/debug-level.type';

/**
 * Manage how log should be performed
 */
@Injectable({ providedIn: 'root' })
export class LoggerService {
  constructor(@Inject(DEBUG_LEVEL) private readonly debugLevel: DebugLevel) {}

  /**
   * Log a debug type message
   * @param message Message to log
   * @param params  Other params
   */
  debug(message: unknown, ...params: unknown[]): void {
    if (this.debugLevelIsEnoughFor('debug')) {
      console.debug(message, ...params);
    }
  }

  /**
   * Log a error type message
   * @param message Message to log
   * @param params  Other params
   */
  error(message: unknown, ...params: unknown[]): void {
    if (this.debugLevelIsEnoughFor('error')) {
      console.error(message, ...params);
    }
  }

  /**
   * Log a info type message
   * @param message Message to log
   * @param params  Other params
   */
  info(message: unknown, ...params: unknown[]): void {
    if (this.debugLevelIsEnoughFor('info')) {
      console.info(message, ...params);
    }
  }

  /**
   * Log a warn type message
   * @param message Message to log
   * @param params  Other params
   */
  warn(message: unknown, ...params: unknown[]): void {
    if (this.debugLevelIsEnoughFor('warn')) {
      console.warn(message, ...params);
    }
  }

  /**
   * Whether the method should log the message
   * @param  debugLevel The debug level of the method
   * @return True if method should log the message
   */
  private debugLevelIsEnoughFor(debugLevel: DebugLevel): boolean {
    // @WARN The order is important here
    const orderedDebugLevels: DebugLevel[] = [
      DebugLevels.debug,
      DebugLevels.info,
      DebugLevels.warn,
      DebugLevels.error,
    ];

    return orderedDebugLevels.indexOf(debugLevel) >= orderedDebugLevels.indexOf(this.debugLevel);
  }
}
