import { WeekDay } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormValueAccessor } from '@nibol/ui';
import { WeekTimeSlotsFormFieldValue } from './week-time-slots-form-field-value.type';
import { defaultWeekday } from './weekday-form-control/weekday-form-control.helper';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WeekTimeSlotsFormFieldComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => WeekTimeSlotsFormFieldComponent),
      multi: true,
    },
  ],
  selector: 'nib-week-time-slots-form-field',
  styleUrls: ['week-time-slots-form-field.component.scss'],
  templateUrl: 'week-time-slots-form-field.component.html',
})
// tslint:disable-next-line: max-line-length
export class WeekTimeSlotsFormFieldComponent extends FormValueAccessor<WeekTimeSlotsFormFieldValue> {
  @Input() timeSteps = 15;

  form = new FormGroup({
    mon: new FormControl(defaultWeekday(WeekDay.Monday)),
    tue: new FormControl(defaultWeekday(WeekDay.Tuesday)),
    wed: new FormControl(defaultWeekday(WeekDay.Wednesday)),
    thu: new FormControl(defaultWeekday(WeekDay.Thursday)),
    fri: new FormControl(defaultWeekday(WeekDay.Friday)),
    sat: new FormControl(defaultWeekday(WeekDay.Saturday)),
    sun: new FormControl(defaultWeekday(WeekDay.Sunday)),
  });

  get formControlsNames(): string[] {
    return Object.keys(this.form.controls);
  }

  trackByFormControlName(
    _index: number,
    formControlName: this['formControlsNames'][number],
  ): string {
    return formControlName;
  }
}
