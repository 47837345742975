import { Selector } from '@ngxs/store';
import { PrivateVenueStateModel, SharedVenueStateModel, VenueStateModel } from '../venue';
import { Modes } from '../venue/venue.type';
import { VenuesState } from './venues.state';

export class VenuesSelectors {
  @Selector([VenuesState])
  static privates(venues: VenueStateModel[]): PrivateVenueStateModel[] {
    return venues.filter(venue => venue.mode === Modes.private) as PrivateVenueStateModel[];
  }

  @Selector([VenuesState])
  static shared(venues: VenueStateModel[]): SharedVenueStateModel[] {
    return venues.filter(venue => venue.mode === Modes.shared) as SharedVenueStateModel[];
  }
}
