import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CardModule } from '../card/card.module';
import { IconModule } from '../icon/icon.module';
import { MultiSelectComponent } from './multi-select.component';

@NgModule({
  imports: [
    CardModule,
    CommonModule,
    IconModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    ScrollingModule,
  ],
  exports: [MultiSelectComponent],
  declarations: [MultiSelectComponent],
})
export class MultiSelectModule {}
