import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslationModule } from '@nibol/translation';
import {
  ButtonModule,
  CardModule,
  CheckboxModule,
  ChipModule,
  DrawerModule,
  FileUploaderModule,
  FloatingPanelModule,
  FormModule,
  IconModule,
  ImageManagerModule,
  InputModule,
  MapModule,
  MultiSelectModule,
  ProgressBarModule,
  RichTextEditorModule,
  SelectModule,
  SwitchModule,
  TabModule,
  TextareaModule,
  ToastModule,
  TooltipModule,
  TreeSelectionModule,
} from '@nibol/ui';
import { AdminRoutingModule } from './admin-routing.module';
import { DemoItemComponent } from './demo-route/demo-item/demo-item.component';
import { DemoRouteComponent } from './demo-route/demo-route.component';
import { EnableFeaturesRouteComponent } from './enable-features-route/enable-features-route.component';
import { FeatureTogglesListComponent } from './enable-features-route/feature-toggles-list/feature-toggles-list.component';
import { VersionSwitcherComponent } from './enable-features-route/version-switcher/version-switcher.component';

@NgModule({
  declarations: [
    DemoItemComponent,
    DemoRouteComponent,
    EnableFeaturesRouteComponent,
    FeatureTogglesListComponent,
    VersionSwitcherComponent,
  ],
  imports: [
    AdminRoutingModule,
    ButtonModule,
    CardModule,
    CheckboxModule,
    ChipModule,
    CommonModule,
    DrawerModule,
    FileUploaderModule,
    FloatingPanelModule,
    FormModule,
    FormsModule,
    IconModule,
    ImageManagerModule,
    InputModule,
    MapModule,
    MultiSelectModule,
    ProgressBarModule,
    RichTextEditorModule,
    SelectModule,
    SwitchModule,
    TabModule,
    TextareaModule,
    ToastModule,
    TooltipModule,
    TranslationModule,
    TreeSelectionModule,
  ],
})
export class AdminModule {}
