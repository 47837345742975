import { BreakpointObserver } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CalendarEvent, DAYS_OF_WEEK } from 'angular-calendar';
import { BehaviorSubject, Subject } from 'rxjs';
import { NIBOL_BREAKPOINTS } from '../breakpoints/public-api';
import { CalendarTranslationsConfig } from './calendar-config.type';
import { CALENDAR_TRANSLATIONS } from './calendar-translations.token';
import { CalendarType } from './calendar-type.type';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'nib-calendar',
  styleUrls: ['calendar.component.scss'],
  templateUrl: 'calendar.component.html',
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    class: 'nib-calendar',
  },
})
export class CalendarComponent implements AfterViewInit {
  /** List of events to show in calendar. */
  @Input() events: CalendarEvent[] = [];

  /** Locale to use to format date and strings. */
  @Input() locale = 'en-gb';

  /** Define which type of calendar should be used. */
  @Input()
  set type(value: CalendarType) {
    this.view$.next(value);
  }

  /** Define which type of calendar should be used */
  @Input('type.gt-sm')
  set typeGtSm(value: CalendarType) {
    const gtSm = NIBOL_BREAKPOINTS.find(item => item.alias === 'gt-sm');

    if (gtSm && this.breakpointObserver.isMatched(gtSm.mediaQuery)) {
      this.view$.next(value);
    }
  }

  /** Template to use for event content */
  @Input() eventContentTemplate?: TemplateRef<unknown>;

  /** Trigger refreshing with stricly related vendor input. */
  readonly refresh: Subject<unknown> = new Subject();

  /** Define which view should be shown. Strictly related to view. */
  view$ = new BehaviorSubject<CalendarType>(this.type);

  /** Define which date should be shown. */
  viewDate = new Date();

  /** Set start day of the week. Strictly related to vendor input. */
  readonly weekStartsOn = DAYS_OF_WEEK.MONDAY;

  /** Set weekend days of the week. Strictly related to vendor input. */
  readonly weekendDays = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];

  constructor(
    @Inject(CALENDAR_TRANSLATIONS) readonly translations: CalendarTranslationsConfig,
    private readonly breakpointObserver: BreakpointObserver,
  ) {}

  ngAfterViewInit(): void {
    this.view$.pipe(untilDestroyed(this)).subscribe(() => {
      // timout is necessary to trigger DOM update
      setTimeout(() => {
        this.initializeScrollPoint();
      });
    });
  }

  private initializeScrollPoint(): void {
    const currentHourElement = document.getElementsByClassName('cal-hour')[new Date().getHours()];
    const viewToScrollElements = [
      document.getElementsByTagName('html')[0],
      document.getElementsByClassName('cal-week-view')[0],
      document.getElementsByClassName('nib-app-shell-main-container')[0],
    ];

    viewToScrollElements.forEach(viewToScrollElement => {
      if (viewToScrollElement && currentHourElement) {
        viewToScrollElement.scrollTop = 0;

        const howToScroll =
          currentHourElement.getBoundingClientRect().top -
          viewToScrollElement.getBoundingClientRect().top -
          currentHourElement.clientHeight * 2;

        viewToScrollElement.scrollTop = howToScroll;
      }
    });
  }
}
