import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Size, Sizes } from '../core/sizes';
import { DialogDataModel } from './dialog-data.model';
import { DialogComponent } from './dialog.component';

@Injectable({ providedIn: 'root' })
export class DialogService {
  constructor(private readonly matDialog: MatDialog) {}

  /** Close the currently visible dialog */
  dismiss(): void {
    this.matDialog.closeAll();
  }

  /**
   * Open a new dialog
   * @param title Short text which describe the content
   * @param content Long test to show in body
   * @param size Size of the dialog container
   * @param options Options to use
   */
  open(
    title: DialogDataModel['title'],
    content: DialogDataModel['content'],
    size?: Size,
    options?: DialogDataModel,
  ): ReturnType<DialogService['openFromMatDialog']> {
    return this.openFromMatDialog({ content, customAction: undefined, title, ...options }, size);
  }

  /**
   * Open a new dialog with custom action
   * @param title Short text which describe the content
   * @param content Long text to show as body
   * @param customAction A template to use to dismiss dialog
   * @param size Size of the dialog container
   * @param options Options to use
   */
  openWithCustomAction(
    title: DialogDataModel['title'],
    content: DialogDataModel['content'],
    customAction: DialogDataModel['customAction'],
    size?: Size,
    options?: DialogDataModel,
  ): ReturnType<DialogService['openFromMatDialog']> {
    return this.openFromMatDialog({ content, customAction, title, ...options }, size);
  }

  /**
   * Use MatDialog to show dialog
   * @param data Data to use in dialog component
   * @param size Size of the dialog container
   */
  private openFromMatDialog(
    data: DialogDataModel,
    size: Size = Sizes.sm,
  ): MatDialogRef<DialogComponent, DialogDataModel> {
    return this.matDialog.open(DialogComponent, {
      panelClass: `nib-dialog-container-${size}`,
      data: {
        content: data.content,
        customAction: data.customAction,
        showCloseButton: data.showCloseButton,
        title: data.title,
      },
    });
  }
}
