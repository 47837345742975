import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_ROOT_URL } from '@nibol/auth';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Area } from './area.type';

@Injectable({ providedIn: 'root' })
export class AreaService {
  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  readList(): Observable<Area.List.Read.Response> {
    return this.httpClient.get<Area.List.Read.Response>(`${this.apiRootUrl}/area`).pipe(
      catchError(error => {
        throw error;
      }),
    );
  }
}
