import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule, NGXS_LOGGER_PLUGIN_OPTIONS } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { API_ROOT_URL, AuthModule, AUTH_ROOT_URL, JWT_ERRORS, PLATFORM } from '@nibol/auth';
import { ErrorHandlerModule } from '@nibol/error-handler';
import { DEBUG_LEVEL } from '@nibol/logger';
import { WOOTRIC_ACCOUNT_TOKEN } from '@nibol/nps';
import { CustomRouterStateSerializer, StoreModule } from '@nibol/store';
import { STRIPE_ELEMENTS_OPTIONS, STRIPE_KEY } from '@nibol/stripe';
import {
  DEBUG_MODE,
  defaultCurrentLanguage,
  DEFAULT_LANGUAGE,
  DOCUMENT_TYPES,
  TranslationService,
  TRANSLATIONS_ENDPOINT,
} from '@nibol/translation';
import {
  BottomSheetModule,
  CALENDAR_TRANSLATIONS,
  DialogModule,
  FILE_UPLOADER_TRANSLATIONS,
  GOOGLE_API_KEY,
  ICONS_ROOT_URL_LOCATION,
  IMAGE_MANAGER_TRANSLATIONS,
  ToastModule,
  UiModule,
} from '@nibol/ui';
import { InlineSVGModule } from 'ng-inline-svg';
import { RECAPTCHA_LANGUAGE, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { calendarTranslationsFactory } from './core/tokens/calendar-translations.factory';
import { fileUploaderTranslationsFactory } from './core/tokens/file-uploader-translations.factory';
import { imageManagerTranslationsFactory } from './core/tokens/image-manager-translations-factory';
import { PLATFORM_NAME } from './core/tokens/platform-name.token';
import { recaptchaLanguageFactory } from './core/tokens/recaptcha-language.factory';
import { SharedConfig } from './core/tokens/shared-config.type';
import { VERSION } from './core/tokens/version.token';
import { VERSIONS } from './core/tokens/versions.token';
import { AppSaga } from './store/app-saga.state';
import { AuthSaga } from './store/auth-saga.state';
import { LanguageSaga } from './store/language-saga.state';
import { UserState } from './store/public-api';
import { RouterSaga } from './store/router-saga.state';
import { UserSaga } from './store/user-saga.state';
import { VersionSaga } from './store/version-saga.state';

@NgModule({
  imports: [
    AuthModule,
    BottomSheetModule,
    BrowserAnimationsModule,
    BrowserModule,
    DialogModule,
    ErrorHandlerModule,
    HttpClientModule,
    InlineSVGModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    NgxsDispatchPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot(),
    NgxsModule.forFeature([
      AppSaga,
      AuthSaga,
      LanguageSaga,
      RouterSaga,
      UserSaga,
      UserState,
      VersionSaga,
    ]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsResetPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsSelectSnapshotModule.forRoot(),
    StoreModule,
    ToastModule,
    UiModule,
  ],
  providers: [{ provide: RouterStateSerializer, useClass: CustomRouterStateSerializer }],
})
export class SharedModule {
  static forRoot(config: SharedConfig): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: API_ROOT_URL, useValue: config.environment.apiUrl },
        { provide: AUTH_ROOT_URL, useValue: config.environment.authUrl },
        {
          provide: CALENDAR_TRANSLATIONS,
          useFactory: calendarTranslationsFactory,
          deps: [TranslationService],
        },
        { provide: DEBUG_LEVEL, useValue: config.environment.debugLevel },
        { provide: DEBUG_MODE, useValue: !config.environment.production },
        { provide: DEFAULT_LANGUAGE, useValue: config.environment.defaultLanguage },
        {
          provide: FILE_UPLOADER_TRANSLATIONS,
          useFactory: fileUploaderTranslationsFactory,
          deps: [TranslationService],
        },
        { provide: GOOGLE_API_KEY, useValue: config.environment.googleKey },
        { provide: ICONS_ROOT_URL_LOCATION, useValue: config.environment.iconUrl },
        {
          provide: IMAGE_MANAGER_TRANSLATIONS,
          useFactory: imageManagerTranslationsFactory,
          deps: [TranslationService],
        },
        {
          provide: JWT_ERRORS,
          useValue: ['jwt must be provided', 'jwt expired', 'jwt malformed'],
        },
        { provide: LOCALE_ID, useValue: defaultCurrentLanguage() },
        {
          provide: NGXS_LOGGER_PLUGIN_OPTIONS,
          useValue: {
            disabled: config.environment.production,
            filter() {
              // return true;
            },
          },
        },
        { provide: PLATFORM, useValue: config.environment.platformCode },
        { provide: PLATFORM_NAME, useValue: config.environment.platformName },
        { provide: RECAPTCHA_LANGUAGE, useFactory: recaptchaLanguageFactory, deps: [LOCALE_ID] },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: config.environment.googleRecaptchaApiKey },
        { provide: STRIPE_KEY, useValue: config.environment.stripeKey },
        {
          provide: STRIPE_ELEMENTS_OPTIONS,
          useValue: {
            locale: 'it',
            fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Rubik:400' }],
          },
        },
        {
          provide: TRANSLATIONS_ENDPOINT,
          useValue: { prismicUrl: config.environment.prismicApiEndpoint },
        },
        {
          provide: DOCUMENT_TYPES,
          useValue: config.environment.prismicDocuments,
        },
        { provide: VERSION, useValue: config.environment.version },
        { provide: VERSIONS, useValue: config.versions },
        { provide: WOOTRIC_ACCOUNT_TOKEN, useValue: config.environment.wootricAccountToken },
      ],
    };
  }
}
