import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SwitchComponent } from './switch.component';

@NgModule({
  imports: [CommonModule, MatSlideToggleModule, ReactiveFormsModule],
  exports: [SwitchComponent],
  declarations: [SwitchComponent],
})
export class SwitchModule {}
