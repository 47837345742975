import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppShellService {
  /** Whether the menu should be opened */
  isMenuOpen = new BehaviorSubject(false);

  /** Set menu as closed */
  closeMenu(): void {
    this.isMenuOpen.next(false);
  }

  /** Set menu as opened */
  openMenu(): void {
    this.isMenuOpen.next(true);
  }

  /** Toggle opening menu state */
  toggleMenu(): void {
    this.isMenuOpen.next(!this.isMenuOpen.value);
  }
}
