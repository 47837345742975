/**
 * Open nibol-popup on center of the screen
 * @param url Url to open
 * @param popupWidth Width of the window
 * @param popupHeight Height of the window
 * @param closingCallback Action to call on window close
 * @returns Window
 */
export function openWindowPopup(
  url: string,
  popupWidth: number,
  popupHeight: number,
  closingCallback?: (popup?: Window | null) => void,
): Window | null {
  const popup = window.open(url, 'nibol-popup', features(popupWidth, popupHeight));

  const interval = window.setInterval(() => {
    try {
      if (popup === null || popup.closed) {
        window.clearInterval(interval);

        if (closingCallback) {
          closingCallback(popup);
        }
      }
    } catch (error) {
      throw error;
    }
  }, 500);

  if (document.hasFocus()) {
    popup?.window.focus();
  }

  return popup;
}

function features(popupWidth: number, popupHeight: number): string {
  const dualScreenLeft = window.screenLeft ?? window.screenX;
  const dualScreenTop = window.screenTop ?? window.screenY;
  const screenWidth = window.innerWidth ?? document.documentElement.clientWidth ?? screen.width;
  const scrennHeight = window.innerHeight ?? document.documentElement.clientHeight ?? screen.height;
  const left = screenWidth / 2 - popupWidth / 2 + dualScreenLeft;
  const top = scrennHeight / 2 - popupHeight / 2 + dualScreenTop;

  return `toolbar=no,menubar=no,scrollbars=yes,width=${popupWidth},height=${popupHeight},top=${top},left=${left}`;
}
