import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { AuthStateAction } from '@nibol/auth';
import { BehaviorSubject } from 'rxjs';
import { RecoverPasswordFormValue } from './recover-password-form/recover-password-form-value.type';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nib-recover-password-route',
  styleUrls: ['recover-password-route.component.scss'],
  templateUrl: 'recover-password-route.component.html',
})
export class RecoverPasswordRouteComponent implements OnInit {
  @Dispatch() recoverPasswordTry = (username: string) =>
    new AuthStateAction.RecoverPassword.Try({ email: username });

  form = new FormGroup({
    recoverPassword: new FormControl({
      username: '',
    }),
  });
  isRecoverPasswordFormDisabled$ = new BehaviorSubject(false);
  isRecoverPasswordRequested$ = new BehaviorSubject(false);

  constructor(private readonly actions$: Actions) {}

  ngOnInit(): void {
    this.manageSubmit();
  }

  recoverPassword(): void {
    if (this.form.valid) {
      const { username } = this.form.value.recoverPassword as RecoverPasswordFormValue;

      this.recoverPasswordTry(username);
      this.isRecoverPasswordFormDisabled$.next(true);
    }
  }

  private manageSubmit(): void {
    this.actions$
      .pipe(ofActionSuccessful(AuthStateAction.RecoverPassword.Failure), untilDestroyed(this))
      .subscribe(() => {
        this.isRecoverPasswordFormDisabled$.next(false);
      });

    this.actions$
      .pipe(ofActionSuccessful(AuthStateAction.RecoverPassword.Success), untilDestroyed(this))
      .subscribe(() => {
        this.isRecoverPasswordRequested$.next(true);
      });
  }
}
