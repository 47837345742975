import { AgmCoreModule, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ColorPickerModule } from 'ngx-color-picker';
import { ButtonModule } from '../button/public-api';
import { IconModule } from '../icon/public-api';
import { DatalistComponent } from './datalist.component';
import { NIB_DATE_FORMATS } from './date-adapter';
import { InputComponent } from './input.component';
import { GOOGLE_API_KEY } from './tokens/google-api-key.token';
import { InputConfig } from './tokens/input-config.type';
import { lazyMapsApiConfig } from './tokens/lazy-maps-api-config.factory';

/** @dynamic */
@NgModule({
  imports: [
    AgmCoreModule.forRoot(),
    ButtonModule,
    ColorPickerModule,
    CommonModule,
    FormsModule,
    IconModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatGoogleMapsAutocompleteModule,
    MatInputModule,
    MatNativeDateModule,
    ReactiveFormsModule,
  ],
  exports: [DatalistComponent, InputComponent],
  declarations: [DatalistComponent, InputComponent],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: NIB_DATE_FORMATS },
    { provide: LAZY_MAPS_API_CONFIG, useFactory: lazyMapsApiConfig, deps: [GOOGLE_API_KEY] },
  ],
})
export class InputModule {
  static forRoot(config: InputConfig): ModuleWithProviders<InputModule> {
    return {
      ngModule: InputModule,
      providers: [{ provide: GOOGLE_API_KEY, useValue: config.googleApiKey }],
    };
  }
}
