import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { UIColor } from '../../core/colors';
import { TabComponent } from '../tab.component';

// tslint:disable-next-line: validate-decorators
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    class: 'nib-tab-group',
  },
  selector: 'nib-tab-group',
  templateUrl: 'tab-group.component.html',
  styleUrls: ['tab-group.component.scss'],
})
export class TabGroupComponent {
  /** List of tabs to show. */
  @ContentChildren(TabComponent) tabs!: QueryList<TabComponent>;

  /** Color to use when label is active. */
  @Input() color: UIColor = 'default';

  /** Index of the tab to show. */
  @Input() selectedIndex = 0;

  /** Emit tab index when the tab selection will change. */
  @Output() onTabChange = new EventEmitter<number>();

  /** Class to apply to tab. */
  get ngClass(): { [key: string]: boolean } {
    return {
      [`nib-tab-group-${this.color}`]: true,
    };
  }
}
