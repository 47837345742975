// tslint:disable: no-namespace max-classes-per-file

import { DeepPartial } from '@nibol/shared';
import { VenueStateModel } from '../venue';
import { BuildingStateModel } from './building.model';

export namespace BuildingStateAction {
  export namespace CostsAndPricingModel {
    export namespace Update {
      export class Try {
        static readonly type = '[Building] try Update Building Costs And Pricing Model';
        constructor(
          public id: BuildingStateModel['id'],
          public costs: DeepPartial<Pick<BuildingStateModel, 'commission' | 'leadFee'>>,
        ) {}
      }
      export class Success {
        static readonly type = '[Building] then Update Building Costs And Pricing Model success';
        constructor(public building: BuildingStateModel) {}
      }
      export class Failure {
        static readonly type = '[Building] catch Update Building Costs And Pricing Model failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace Information {
    export namespace Update {
      export class Try {
        static readonly type = '[Building] try Update Building Information';
        constructor(
          public id: BuildingStateModel['id'],
          public data: Pick<BuildingStateModel, 'name'>,
        ) {}
      }
      export class Success {
        static readonly type = '[Building] then Update Building Information success';
        constructor(public building: BuildingStateModel) {}
      }
      export class Failure {
        static readonly type = '[Building] catch Update Building Information failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace Item {
    export namespace Read {
      export class Try {
        static readonly type = '[Building] try Read Building Item';
        constructor(public id: BuildingStateModel['id']) {}
      }
      export class Success {
        static readonly type = '[Building] then Read Building Item success';
        constructor(public building: BuildingStateModel) {}
      }
      export class Failure {
        static readonly type = '[Building] catch Read Building Item failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Building] try Update Building Item';
        constructor(
          public id: BuildingStateModel['id'],
          public data: Partial<Pick<BuildingStateModel, 'commission' | 'leadFee' | 'name'>>,
        ) {}
      }
      export class Success {
        static readonly type = '[Building] then Update Building Item success';
        constructor(public building: BuildingStateModel) {}
      }
      export class Failure {
        static readonly type = '[Building] catch Update Building Item failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace Venue {
    export namespace Create {
      export class Try {
        static readonly type = '[Venue] try Create Venue in Building';
        constructor(
          public name: VenueStateModel['name'],
          public mode: VenueStateModel['mode'],
          public id: BuildingStateModel['id'],
        ) {}
      }
      export class Success {
        static readonly type = '[Venue] then Create Venue in Building success';
        constructor(public building: BuildingStateModel) {}
      }
      export class Failure {
        static readonly type = '[Venue] catch Create Venue in Building failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }
}
