import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AccessDeniedFooterContentComponent } from './access-denied-footer-content/access-denied-footer-content.component';
import { AccessDeniedRouteComponent } from './access-denied-route/access-denied-route.component';
import { AccessDeniedGuard } from './access-denied.guard';

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forChild([
      {
        // root is '/access-denied'
        path: '',
        canActivate: [AccessDeniedGuard],
        canDeactivate: [AccessDeniedGuard],
        data: { title: 'accessdenied_title' },
        children: [
          { path: '', component: AccessDeniedRouteComponent },
          { path: '', component: AccessDeniedFooterContentComponent, outlet: 'footer-content' },
        ],
      },
    ]),
  ],
})
export class AccessDeniedRoutingModule {}
