import { AbstractControl, ControlValueAccessor, ValidationErrors, Validator } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

/*
 * FormValueAccessor provides a ControlValueAccessor implementation that allows your component to
 * make a FormGroup look like a FormControl.
 * When extending this class you have to provide the form value type and an implementation of the
 * form getter.
 */
@UntilDestroy()
export abstract class FormValueAccessor<Value = unknown>
  implements ControlValueAccessor, Validator {
  /** Provider of the internal FormGroup encapsulating sub-controls */
  abstract get form(): AbstractControl;

  get value(): Value {
    return this.form.value;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  /** needed for ControlValueAccessor */
  // tslint:disable-next-line: no-empty
  onTouched: () => void = () => {};

  writeValue(value: Value): void {
    if (value) {
      // when value is written from outside you must not trigger external OnChange
      this.form.patchValue(value, { emitEvent: false });
    }
  }

  registerOnChange(fn: (value: Value) => void): void {
    // It's better to notify the change after the status has been updated too
    this.form.statusChanges.pipe(untilDestroyed(this)).subscribe(_value => {
      fn(this.form.value);
    });
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /*
   * By default the validation fails if the form is invalid.
   * Override this function to provide specific error messages.
   */
  validate(_control: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : { valid: false };
  }
}
