// tslint:disable: no-namespace max-classes-per-file

import { UserStateModel } from '@nibol/shared';
import { BuildingStateModel } from '../building';
import { VenueStateModel } from '../venue';

export namespace UserStateAction {
  export namespace AssociatedVenueAndBuilding {
    export namespace Create {
      export class Try {
        static readonly type = '[User] try Create Associated Venue And Building';
        constructor(
          public building: Pick<BuildingStateModel, 'name'>,
          public venue: Pick<VenueStateModel, 'address' | 'coordinates' | 'mode' | 'name' | 'type'>,
        ) {}
      }
      export class Success {
        static readonly type = '[User] then Create Associated Venue And Building success';
        constructor(public user: UserStateModel) {}
      }
      export class Failure {
        static readonly type = '[User] catch Create Associated Venue And Building failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }
}
