import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { TranslationService } from '@nibol/translation';
import { FormValueAccessor, SelectOption } from '@nibol/ui';
import { isOn } from 'feature-toggle-service';
import { PersonalInfoFormValue } from './personal-info-form-value.type';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PersonalInfoFormComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: PersonalInfoFormComponent,
      multi: true,
    },
  ],
  selector: 'nib-personal-info-form',
  styleUrls: ['personal-info-form.component.scss'],
  templateUrl: 'personal-info-form.component.html',
})
export class PersonalInfoFormComponent
  extends FormValueAccessor<PersonalInfoFormValue>
  implements OnInit {
  // @todo(heavybeard): get phone prefixes from service
  get defaultPhonePrefixValue(): string {
    const defaultValue =
      this.phonePrefixes && this.phonePrefixes.find(phonePrefix => !!phonePrefix.selected);

    return (defaultValue && defaultValue.label) || '';
  }

  get isEmailEditingDisabled(): boolean {
    return !isOn('TOGGLE_ENABLE_EMAIL_EDITING');
  }

  form = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl({ value: '', disabled: !isOn('TOGGLE_SHOW_PHONE') }, [
      Validators.required,
    ]),
    phonePrefix: new FormControl({
      value: this.defaultPhonePrefixValue,
      disabled: !isOn('TOGGLE_SHOW_PHONE'),
    }),
  });

  // @todo(heavybeard): get phone prefixes from service
  readonly phonePrefixes: SelectOption[] = [
    {
      label: this.translationService.translate('inputs_text_mobilecountrycode_placeholder'),
      selected: true,
      value: '+39',
    },
  ];

  constructor(private readonly translationService: TranslationService) {
    super();
  }

  ngOnInit(): void {
    // This is a necessary 'cause of class' property order
    this.fixPhonePrefixDefaultValue();
  }

  private fixPhonePrefixDefaultValue(): void {
    this.form.patchValue({
      phonePrefix: this.defaultPhonePrefixValue,
    });
  }
}
