import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

// tslint:disable-next-line: validate-decorators
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'nib-form-field',
  },
  selector: 'nib-form-field',
  styleUrls: ['form.component.scss'],
  template: `
    <ng-content select="label"></ng-content>
    <div>
      <ng-content
        select="nib-button:not(.nib-floating), nib-checkbox, nib-input, nib-multi-select, nib-rich-text-editor, nib-select, nib-switch, nib-textarea"
      ></ng-content>
    </div>
    <span *ngIf="hint; else hintComponent" class="nib-form-field-hint">{{ hint }}</span>
    <ng-template #hintComponent>
      <ng-content select="nib-form-field-hint"></ng-content>
    </ng-template>
    <span *ngIf="error; else errorComponent" class="nib-form-field-error">{{ error }}</span>
    <ng-template #errorComponent>
      <ng-content select="nib-form-field-error"></ng-content>
    </ng-template>
    <ng-content select=".nib-floating"></ng-content>
  `,
})
export class FormFieldComponent {
  /** Show field's error */
  @Input() error = '';

  /** Show field's hint */
  @Input() hint = '';
}
