// tslint:disable: no-namespace

export namespace Area {
  export namespace List {
    export namespace Read {
      export type Response = {
        data: Array<{
          active: number;
          key: string;
          name: string;
          places: number;
        }>;
      };
    }
  }
}
