<div [ngSwitch]="status">
  <div *ngSwitchCase="'active'">
    <nib-chip [color]="'positive'">
      {{ 'inputs_text_status_active_short' | translate }}
    </nib-chip>
  </div>
  <div *ngSwitchCase="'creating'">
    <nib-chip [color]="'notice'">
      {{ 'inputs_text_status_creating_short' | translate }}
    </nib-chip>
  </div>
  <div *ngSwitchCase="'inactive'">
    <nib-chip [color]="'default'">
      {{ 'inputs_text_status_inactive_short' | translate }}
    </nib-chip>
  </div>
  <div *ngSwitchCase="'pending'">
    <nib-chip [color]="'notice'">
      {{ 'inputs_text_status_pending_short' | translate }}
    </nib-chip>
  </div>
  <div *ngSwitchCase="'promoted'">
    <nib-chip [color]="'notice'">
      {{ 'inputs_text_status_promoted_short' | translate }}
    </nib-chip>
  </div>
  <div *ngSwitchCase="'promoted_visible'">
    <nib-chip [color]="'notice'">
      {{ 'inputs_text_status_promoted_visible_short' | translate }}
    </nib-chip>
  </div>
  <div *ngSwitchCase="'refused'">
    <nib-chip [color]="'negative'">
      {{ 'inputs_text_status_refused_short' | translate }}
    </nib-chip>
  </div>
  <div *ngSwitchCase="'waiting'">
    <nib-chip [color]="'notice'">
      {{ 'inputs_text_status_waiting_short' | translate }}
    </nib-chip>
  </div>
</div>
