import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { IconModule } from '../icon/public-api';
import { ProgressSpinnerModule } from '../progress-spinner/public-api';
import { FileUploaderConfig } from './file-uploader-config.type';
import { FILE_UPLOADER_TRANSLATIONS } from './file-uploader-translations.token';
import { FileUploaderComponent } from './file-uploader.component';

@NgModule({
  imports: [CommonModule, IconModule, ProgressSpinnerModule],
  exports: [FileUploaderComponent],
  declarations: [FileUploaderComponent],
})
export class FileUploaderModule {
  static forRoot(config: FileUploaderConfig): ModuleWithProviders<FileUploaderModule> {
    return {
      ngModule: FileUploaderModule,
      providers: [
        {
          provide: FILE_UPLOADER_TRANSLATIONS,
          useValue: config.translations,
        },
      ],
    };
  }
}
