<fieldset>
  <div
    [fxLayout]="'column'"
    [fxLayout.gt-md]="'row'"
    [fxLayoutGap]="'16px'"
    class="nib-form-fieldset"
    role="group"
  >
    <legend
      *ngIf="formTitle || formDescription"
      [fxFlex]="100"
      [fxFlex.gt-md]="'calc(' + (100 / 3) * 1 + '% - 32px)'"
      class="nib-form-legend"
    >
      <ng-content select="nib-form-title"></ng-content>
      <ng-content select="nib-form-description"></ng-content>
    </legend>
    <nib-card [fxFlex]="100" [fxFlex.gt-md]="'calc(' + (100 / 3) * 2 + '% - 32px)'">
      <ng-content></ng-content>
    </nib-card>
  </div>
</fieldset>
