import { Role } from '@nibol/auth';
import { LanguageCode } from '@nibol/translation';

export class UserStateModel implements UserNotifications, Partial<UserProfile> {
  email!: UserProfile['email'];
  id!: string;
  language!: UserProfile['language'];
  notifications!: UserNotifications['notifications'];
  permissions!: string[];
  roles!: Role[];
  avatar?: string;
  firstname?: UserProfile['firstname'];
  lastname?: UserProfile['lastname'];
  lastPasswordChange?: Date;
  phone?: UserProfile['phone'];
}

export type UserNotifications = {
  notifications: {
    [type in 'account' | 'announcements' | 'newsletter' | 'service']: {
      [notification in 'email' | 'pushweb' | 'sms']: boolean;
    };
  };
};

export type UserProfile = {
  email: string;
  firstname: string;
  language: LanguageCode;
  lastname: string;
  phone?: {
    countryCode?: string;
    phoneNumber?: string;
  };
};
