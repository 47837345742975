<ng-container
  *ngIf="isResetPasswordRequested$ | async; else resetPasswordForm"
  class="nib-reset-password-route-form-container"
>
  <h1>{{ 'resetpassword_title' | translate }}</h1>
  <p [innerHTML]="'resetpassword_gotologin' | translate"></p>
</ng-container>
<ng-template #resetPasswordForm>
  <h1>{{ 'resetpassword_title' | translate }}</h1>
  <p>{{ 'resetpassword_description' | translate }}</p>
  <form [formGroup]="form" (ngSubmit)="resetPassword()">
    <nib-reset-password-form
      [disabled]="isResetPasswordFormDisabled$ | async"
      [formControlName]="'resetPassword'"
    ></nib-reset-password-form>
  </form>
</ng-template>
