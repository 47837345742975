import { Selector } from '@ngxs/store';
import { isPast } from 'date-fns';
import { ReservationStateModel } from './reservations.model';
import { ReservationsState } from './reservations.state';

export class ReservationsSelectors {
  @Selector([ReservationsState])
  static all(reservations: ReservationStateModel[]): ReservationStateModel[] {
    return reservations;
  }

  @Selector([ReservationsState])
  static amount(reservations: ReservationStateModel[]): ReservationStateModel[]['length'] {
    return reservations
      .filter(reservation => !isPast(reservation.end))
      .filter(reservation => !reservation.isCancelled && !reservation.isExternal).length;
  }
}
