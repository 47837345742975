import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'nib-card',
  styleUrls: ['card.component.scss'],
  templateUrl: 'card.component.html',
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    class: 'nib-card',
  },
})
export class CardComponent {}
