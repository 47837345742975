<h1>{{ 'permissiondenied_title' | translate }}</h1>
<p [ngSwitch]="failureMessage" class="nib-permission-denied-route-body">
  <span
    *ngSwitchCase="'user-terms-privacy-country-not-valid'"
    [innerHTML]="'permissiondenied_countrynotvalid' | translate"
  ></span>
</p>
<nib-input
  [fullWidth]="true"
  [id]="'username'"
  [label]="'inputs_text_email_label' | translate"
  [readonly]="true"
  [type]="'email'"
  [value]="email"
  class="nib-input-disabled"
></nib-input>
<p class="nib-permission-denied-route-change-email">
  <a [routerLink]="'/login'" (click)="logout()">{{ 'login_changeemail' | translate }}</a>
</p>
<div
  [fxLayout]="'column'"
  [fxLayout.gt-sm]="'row'"
  [fxLayoutGap]="'16px'"
  [ngSwitch]="failureMessage"
>
  <div
    *ngSwitchCase="'user-terms-privacy-country-not-valid'"
    [fxLayout]="'column'"
    [fxLayoutGap]="'16px'"
    class="nib-permission-denied-route-country-not-valid"
  >
    <a
      *ngFor="
        let supportContact of supportContacts$ | async as supportContacts;
        trackBy: trackBySupportContactName
      "
      [fxFlex]="'100%'"
      [href]="
        sanitize(
          'permissiondenied_contactyouradmin_link' | translate: { emails: supportContact.contacts }
        )
      "
      class="nib-button nib-button-primary"
    >
      <ng-container *ngIf="supportContacts.length > 1">{{ supportContact.name }} - </ng-container>
      {{ 'permissiondenied_contactyouradmin' | translate }}
    </a>
  </div>
</div>
