import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { ChipColor } from './chip-color.type';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'nib-chip',
  styleUrls: ['chip.component.scss'],
  templateUrl: 'chip.component.html',
})
export class ChipComponent {
  /** Define the color of the chip. */
  @Input() color: ChipColor | string = 'default';

  /** Class to apply to chip. */
  get ngClass(): { [key: string]: boolean } {
    return {
      [`nib-chip-${this.color}`]: true,
      'nib-chip': true,
    };
  }
}
