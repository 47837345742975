<label *ngIf="label" class="nib-body" for="value">{{ label }}</label>
<div [formGroup]="form">
  <nib-select
    *featureToggle="'TOGGLE_ENABLE_DOLLAR_CURRENCY'"
    [formControlName]="'currency'"
    [id]="'currency'"
    [options]="currencies"
  ></nib-select>
  <nib-input
    *featureToggle="'!TOGGLE_ENABLE_DOLLAR_CURRENCY'"
    [formControlName]="'currency'"
    [fxFlex]="'44px'"
    [readonly]="true"
    [value]="defaultCurrencyValue"
  ></nib-input>
  <nib-input
    [formControlName]="'value'"
    [fullWidth]="true"
    [fxFlex]="100"
    [id]="'value'"
    [min]="min"
    [type]="'number'"
    [step]="step"
    (onChange)="applyFormat($event)"
  ></nib-input>
</div>
<span *ngIf="hint" class="nib-form-field-hint">{{ hint }}</span>
