import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CreatePasswordRouteComponent } from './create-password-route/create-password-route.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        // root is '/create-password'
        path: '',
        component: CreatePasswordRouteComponent,
        data: {
          featureToggle: ['TOGGLE_ENABLE_CREATE_PASSWORD'],
          title: 'createpassword_title',
        },
      },
    ]),
  ],
})
export class CreatePasswordRoutingModule {}
