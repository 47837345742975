import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Status } from '@marketplace/store';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'mkt-space-status',
  styleUrls: ['space-status.component.scss'],
  templateUrl: 'space-status.component.html',
})
export class SpaceStatusComponent {
  @Input() status!: Status;
}
