import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ButtonModule } from '../button/public-api';
import { IconModule } from '../icon/public-api';
import { DialogDataModel } from './dialog-data.model';
import { DialogComponent } from './dialog.component';

@NgModule({
  entryComponents: [DialogComponent],
  declarations: [DialogComponent],
  exports: [DialogComponent],
  imports: [CommonModule, ButtonModule, IconModule, MatDialogModule],
  providers: [{ provide: MAT_DIALOG_DATA, useValue: DialogDataModel }],
})
export class DialogModule {}
