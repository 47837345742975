import { Injectable } from '@angular/core';
import { AreaService } from '@marketplace/api/area';
import { Action, State, StateContext } from '@ngxs/store';
import { catchError, first, tap } from 'rxjs/operators';
import { AreaStateModel } from './area.model';
import { AreasStateAction } from './areas.actions';

@State<AreaStateModel[]>({
  name: 'areas',
  defaults: [],
})
@Injectable()
export class AreasState {
  constructor(private readonly areaService: AreaService) {}

  @Action(AreasStateAction.List.Read.Try)
  readList({ dispatch, setState }: StateContext<AreaStateModel[]>) {
    return this.areaService.readList().pipe(
      first(),
      catchError(error => {
        dispatch(new AreasStateAction.List.Read.Failure(error));

        throw error;
      }),
      tap(areasList => {
        try {
          setState(areasList.data);

          dispatch(new AreasStateAction.List.Read.Success(areasList.data));
        } catch (error) {
          dispatch(new AreasStateAction.List.Read.Failure(error));
        }
      }),
    );
  }
}
