import { FocusMonitor } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Size, Sizes } from '../core/sizes';
import { IconComponent } from '../icon/public-api';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'nib-button-container',
    '[class.nib-button-full-width]': 'fullWidth',
  },
  selector: 'nib-button',
  styleUrls: ['button.component.scss'],
  templateUrl: 'button.component.html',
})
export class ButtonComponent implements AfterViewInit, OnDestroy {
  /** Whether button should be in clean style mode. */
  @Input() clean = false;

  /** Define the color of the button. */
  @Input() color: 'dark' | 'negative' | 'positive' | 'primary' | 'secondary' | 'tertiary' =
    'secondary';

  /** Whether button should be disabled. */
  @Input() disabled = false;

  /** Whether the button should be in full-width mode. */
  @Input() fullWidth = false;

  /** Define the size of the button. */
  @Input() size: Size = Sizes.md;

  /** Whether the button should be used as submit in a form. */
  @Input() submit = false;

  /** Click emitter. */
  @Output() onClick = new EventEmitter<Event>();

  @ContentChild(IconComponent) private readonly icon?: IconComponent;

  @ViewChild('button') private readonly button?: ElementRef<HTMLButtonElement>;

  /** Class to apply to button. */
  get ngClass(): { [key: string]: boolean } {
    return {
      [`nib-button-${this.color}`]: true,
      [`nib-button-${this.size}`]: true,
      'nib-button': true,
      'nib-button-clean': this.clean,
      'nib-button-disabled': this.disabled,
      'nib-button-full-width': this.fullWidth,
      'nib-button-with-icon': typeof this.icon !== 'undefined',
    };
  }

  constructor(private readonly focusMonitor: FocusMonitor) {}

  ngAfterViewInit(): void {
    this.wrapTextNodeIntoSpan();

    if (this.button) {
      this.focusMonitor.monitor(this.button, true);
    }
  }

  ngOnDestroy(): void {
    if (this.button) {
      this.focusMonitor.stopMonitoring(this.button);
    }
  }

  private wrapTextNodeIntoSpan(): void {
    const childNodes = this.button?.nativeElement.childNodes;

    if (childNodes) {
      Array.from(childNodes)
        .filter(
          node =>
            node.nodeType === 3 && node.textContent !== null && node.textContent.trim().length > 1,
        )
        .forEach(node => {
          const span = document.createElement('span');

          node.after(span);
          node.textContent = node.textContent?.trim() || null;

          span.appendChild(node);
        });
    }
  }
}
