import { Role, Roles } from '@nibol/auth';
import { User } from '../api/public-api';

export function mapRolesToClient(roles: User.Info.Read.Response['user']['roles']): Role[] {
  return roles.map(
    role =>
      ({
        [-1]: Roles.guest,
        0: Roles.admin,
        1: Roles.manager,
        2: Roles.user,
        3: Roles.tester,
        4: Roles.manager,
        5: Roles.ambassador,
        6: Roles.user,
        7: Roles.wannabeManager,
        8: Roles.user,
        9: Roles.user,
        10: Roles.manager,
        11: Roles.manager,
        12: Roles.frontdesk,
        1001: Roles.manager,
      }[role]),
  );
}

export function mapRoleToApi(
  role: Roles.manager | Roles.user | Roles.frontdesk,
): User.Info.Read.Response['user']['roles'][number] {
  return {
    [Roles.user]: 9,
    [Roles.manager]: 10,
    [Roles.frontdesk]: 12,
  }[role] as User.Info.Read.Response['user']['roles'][number];
}
