import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { of } from 'rxjs';
import { catchError, delay as delayOperator, tap } from 'rxjs/operators';
import { DEFAULT_LANGUAGE } from '../core/default-language.token';
import { LanguageCode } from '../core/language-code.type';
import { TranslationService } from '../translation.service';
import { TranslationStateAction } from './translation.actions';
import { defaultCurrentLanguage } from './translation.helper';
import { TranslationStateModel } from './translation.model';

/** @dynamic */
@State<TranslationStateModel>({
  name: 'translation',
  defaults: { currentLanguage: defaultCurrentLanguage(), state: null },
})
@Injectable()
export class TranslationState {
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(DEFAULT_LANGUAGE) private readonly defaultLanguage: LanguageCode,
    private readonly translationService: TranslationService,
  ) {}

  @Action(TranslationStateAction.CurrentLanguage.Change.Try)
  async changeCurrentLanguage(
    { dispatch, getState, setState }: StateContext<TranslationStateModel>,
    { currentLanguage }: TranslationStateAction.CurrentLanguage.Change.Try,
  ) {
    try {
      if (getState().currentLanguage !== currentLanguage) {
        setState(patch({ currentLanguage }));

        await this.translationService.changeLanguage(currentLanguage);
        this.document.documentElement.lang = currentLanguage || '';

        dispatch(new TranslationStateAction.CurrentLanguage.Change.Success(getState()));
      } else {
        dispatch(
          new TranslationStateAction.CurrentLanguage.Change.Cancel(
            "@nibol/translation Current language hasn't changed",
          ),
        );
      }
    } catch (error) {
      dispatch(new TranslationStateAction.CurrentLanguage.Change.Failure(error));
    }
  }

  @Action(TranslationStateAction.Init.Try)
  async init({ dispatch, getState }: StateContext<TranslationStateModel>) {
    try {
      const currentLanguage = getState().currentLanguage ?? this.defaultLanguage;

      await this.translationService.changeLanguage(currentLanguage);
      this.document.documentElement.lang = currentLanguage || '';
    } catch (error) {
      dispatch(new TranslationStateAction.Init.Failure(error));
    }
  }

  @Action(TranslationStateAction.State.Change.Try)
  changeState(
    { dispatch, getState, setState }: StateContext<TranslationStateModel>,
    { state, delay }: TranslationStateAction.State.Change.Try,
  ) {
    return of(state).pipe(
      delayOperator(delay),
      catchError(error => {
        dispatch(new TranslationStateAction.State.Change.Failure(error));

        throw error;
      }),
      tap(() => {
        setState(patch({ state }));

        dispatch(new TranslationStateAction.State.Change.Success(getState()));
      }),
    );
  }
}
