<div cdkDropListGroup class="nib-image-manager-container">
  <div
    [cdkDropListEnterPredicate]="dropListEnterPredicate"
    (cdkDropListDropped)="dropListDropped()"
    cdkDropList
    class="nib-image-manager-item nib-image-manager-item-placeholder"
  >
    <div *ngIf="targetIndex === 0" class="nib-image-manager-caption">
      <nib-chip [color]="'white'">
        {{ translations.caption }}
      </nib-chip>
    </div>
  </div>
  <ng-container *ngFor="let image of images; let index = index">
    <div
      *ngIf="image"
      [cdkDropListDisabled]="images.length === 1"
      [cdkDropListEnterPredicate]="dropListEnterPredicate"
      (cdkDropListDropped)="dropListDropped()"
      cdkDropList
      class="nib-image-manager-item"
    >
      <div *ngIf="translations.caption" class="nib-image-manager-caption">
        <nib-chip [color]="'white'">
          {{ translations.caption }}
        </nib-chip>
      </div>
      <div
        [style.background-image]="'url(' + image + ')'"
        (cdkDragMoved)="dragMoved($event)"
        cdkDrag
        class="nib-image-manager-item-image"
        role="img"
      >
        <nib-button
          [clean]="true"
          [color]="'tertiary'"
          [size]="'sm'"
          (onClick)="remove(image)"
          class="nib-image-manager-remove-button"
        >
          <nib-icon [name]="'close'"></nib-icon>
        </nib-button>
        <div
          *cdkDragPreview
          [style.width]="previewWidth + 'px'"
          class="nib-image-manager-item-image-preview"
        >
          <div
            [style.background-image]="'url(' + image + ')'"
            class="nib-image-manager-item-image"
            role="img"
          ></div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-content></ng-content>
</div>
