import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ButtonModule } from '../button/public-api';
import { ChipModule } from '../chip/public-api';
import { IconModule } from '../icon/public-api';
import { ImageManagerConfig } from './image-manager-config.type';
import { IMAGE_MANAGER_TRANSLATIONS } from './image-manager-translations.token';
import { ImageManagerComponent } from './image-manager.component';

@NgModule({
  imports: [ButtonModule, ChipModule, CommonModule, DragDropModule, IconModule],
  declarations: [ImageManagerComponent],
  exports: [ImageManagerComponent],
})
export class ImageManagerModule {
  static forRoot(config: ImageManagerConfig): ModuleWithProviders<ImageManagerModule> {
    return {
      ngModule: ImageManagerModule,
      providers: [
        {
          provide: IMAGE_MANAGER_TRANSLATIONS,
          useValue: config.translations,
        },
      ],
    };
  }
}
