import { ModuleWithProviders, NgModule } from '@angular/core';
import { DEBUG_LEVEL } from './core/debug-level.token';
import { LoggerModuleOptions } from './logger-module-options.type';
import { LoggerService } from './logger.service';

@NgModule({
  providers: [LoggerService],
})
export class LoggerModule {
  static forRoot(options: LoggerModuleOptions): ModuleWithProviders<LoggerModule> {
    return {
      ngModule: LoggerModule,
      providers: [
        {
          provide: DEBUG_LEVEL,
          useValue: options.level,
        },
      ],
    };
  }
}
