export class CompanyStateModel {
  address!: string;
  billingEmail!: string;
  city!: string;
  country!: string;
  einvoice!: string;
  extra!: string;
  name!: string;
  pec!: string;
  postal!: string;
  state!: string;
  vat!: string;
  iban?: string;
}
