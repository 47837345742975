import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { FormSaverSnackbarService } from '../../../components/app-shell/app-shell-auth/form-saver-snackbar/form-saver-snackbar.service';
import { UserStateAction } from '../../../store/user/user.actions';
import { UserProfile } from '../../../store/user/user.model';
import { UserSelectors } from '../../../store/user/user.selectors';
import { mapAccountProfileToForm, mapAccountProfileToStore } from './account-profile-route.helper';
import { LanguageSelectionFormValue } from './language-selection-form/language-selection-form-value.type';
import { PersonalInfoFormValue } from './personal-info-form/personal-info-form-value.type';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nib-account-profile-route',
  styleUrls: ['account-profile-route.component.scss'],
  templateUrl: 'account-profile-route.component.html',
})
export class AccountProfileRouteComponent implements OnDestroy, OnInit {
  @Dispatch() updateUserProfileInformation = (data: UserProfile) =>
    new UserStateAction.Profile.Update.Try(data);

  @Select(UserSelectors.profile) profile$!: Observable<UserProfile>;

  get form(): FormGroup {
    return this.formSaverSnackbarService.form;
  }

  constructor(
    private readonly formSaverSnackbarService: FormSaverSnackbarService<{
      language: LanguageSelectionFormValue;
      personalInfo: PersonalInfoFormValue;
    }>,
  ) {}

  ngOnDestroy(): void {
    this.formSaverSnackbarService.cancel();
  }

  ngOnInit(): void {
    this.createForm();
    this.manageFormSavings();
    this.setInitialValues();
  }

  private createForm(): void {
    this.formSaverSnackbarService.initialize({
      language: new FormControl({ language: 'it' }, Validators.required),
      personalInfo: new FormControl(
        {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          phonePrefix: '+39',
        },
        Validators.required,
      ),
    });
  }

  private manageFormSavings(): void {
    this.formSaverSnackbarService.onSave$.pipe(untilDestroyed(this)).subscribe(({ next }) => {
      this.updateUserProfileInformation(mapAccountProfileToStore(next));
    });
  }

  private setInitialValues(): void {
    this.profile$.pipe(untilDestroyed(this)).subscribe(profile => {
      this.formSaverSnackbarService.update(mapAccountProfileToForm(profile));
    });
  }
}
