import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomSheetDataModel } from './bottom-sheet-data.model';
import { BottomSheetComponent } from './bottom-sheet.component';

@Injectable({ providedIn: 'root' })
export class BottomSheetService {
  constructor(private readonly matBottomSheet: MatBottomSheet) {}

  /** Close the currently visible bottom sheet */
  dismiss(): void {
    this.matBottomSheet.dismiss();
  }

  /**
   * Open a new bottom sheet
   * @param title Short text which describe the content
   * @param content Long test to show in body
   */
  // tslint:disable-next-line: no-any
  open(
    title: BottomSheetDataModel['title'],
    content: BottomSheetDataModel['content'],
    position: BottomSheetDataModel['position'] = 'right',
  ): void {
    this.openFromMatBottomSheet({ content, customAction: undefined, position, title });
  }

  /**
   * Open a new bottom sheet with custom action
   * @param title Short text which describe the content
   * @param content Long text to show as body
   * @param customAction A template to use to dismiss bottom sheet
   * @param position Where the bottom sheet should be visible
   */
  openWithCustomAction(
    title: BottomSheetDataModel['title'],
    content: BottomSheetDataModel['content'],
    customAction: BottomSheetDataModel['customAction'],
    position: BottomSheetDataModel['position'] = 'right',
  ): void {
    this.openFromMatBottomSheet({ content, customAction, position, title });
  }

  /**
   * Use MatBottomSheet to show bottom sheet
   * @param data Data to use in bottom sheet component
   */
  private openFromMatBottomSheet(data: BottomSheetDataModel): void {
    this.matBottomSheet.open(BottomSheetComponent, {
      panelClass: 'nib-asd',
      data: {
        content: data.content,
        customAction: data.customAction,
        position: data.position,
        title: data.title,
      },
    });
  }
}
