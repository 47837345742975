import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppShellService } from '@nibol/ui';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nib-app-status-resume',
  styleUrls: ['app-status-resume.component.scss'],
  templateUrl: 'app-status-resume.component.html',
})
export class AppStatusResumeComponent {
  @Input() scope!: string;
  @Input() avatar?: string;
  @Input() user?: string;

  constructor(private readonly appShellService: AppShellService) {}

  closeMenu(): void {
    this.appShellService.closeMenu();
  }
}
