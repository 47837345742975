import {
  CdkDrag,
  CdkDragMove,
  CdkDropList,
  CdkDropListGroup,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { ViewportRuler } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ImageManagerTranslationsConfig } from './image-manager-config.type';
import { IMAGE_MANAGER_TRANSLATIONS } from './image-manager-translations.token';
import { indexOf, isInsideDropListClientRect, isTouchEvent } from './image-manager.helper';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'nib-image-manager',
  styleUrls: ['image-manager.component.scss'],
  templateUrl: 'image-manager.component.html',
})
export class ImageManagerComponent implements AfterViewInit {
  /** List of images to show */
  @Input() images: string[] = [];

  /** Emit list of images in new order */
  @Output() imagesChange = new EventEmitter<string[]>();

  @ViewChild(CdkDropListGroup) listGroup!: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList) placeholder!: CdkDropList;

  previewWidth = 320;
  targetIndex = 0;

  private activeContainer: CdkDropList | null = null;
  private sourceContainer: CdkDropList | null = null;
  private sourceIndex!: number;
  private targetContainer: CdkDropList | null = null;

  constructor(
    @Inject(IMAGE_MANAGER_TRANSLATIONS) readonly translations: ImageManagerTranslationsConfig,
    private readonly viewportRuler: ViewportRuler,
  ) {}

  ngAfterViewInit(): void {
    const placeholderElement = this.placeholder.element.nativeElement;

    this.previewWidth = placeholderElement.offsetWidth - 16;

    placeholderElement.style.display = 'none';

    if (placeholderElement.parentNode) {
      placeholderElement.parentNode.removeChild(placeholderElement);
    }
  }

  dragMoved(dragMoveEvent: CdkDragMove) {
    const point = this.getPointerPositionOnPage(dragMoveEvent.event);

    this.listGroup._items.forEach(dropList => {
      if (isInsideDropListClientRect(dropList, point.x, point.y)) {
        this.activeContainer = dropList;

        return;
      }
    });
  }

  dropListDropped() {
    if (!this.targetContainer) {
      return;
    }

    const placeholderElement = this.placeholder.element.nativeElement;
    const parent = placeholderElement.parentNode;

    placeholderElement.style.display = 'none';

    if (parent) {
      parent.removeChild(placeholderElement);
      parent.appendChild(placeholderElement);

      if (this.sourceContainer) {
        parent.insertBefore(
          this.sourceContainer.element.nativeElement,
          parent.children[this.sourceIndex],
        );
      }
    }

    this.targetContainer = null;
    this.sourceContainer = null;

    if (this.sourceIndex !== this.targetIndex) {
      const reorderedImages = this.images.slice(0);

      moveItemInArray(reorderedImages, this.sourceIndex, this.targetIndex);

      this.imagesChange.emit(reorderedImages);
    }
  }

  // tslint:disable-next-line: cyclomatic-complexity
  dropListEnterPredicate = (drag: CdkDrag, dropList: CdkDropList) => {
    if (dropList === this.placeholder) {
      return true;
    }

    if (dropList !== this.activeContainer) {
      return false;
    }

    const placeholderElement = this.placeholder.element.nativeElement;
    const sourceElement = drag.dropContainer.element.nativeElement;
    const dropElement = dropList.element.nativeElement;

    if (dropElement.parentElement) {
      const dragIndex = indexOf(
        dropElement.parentElement.children,
        this.sourceContainer ? placeholderElement : sourceElement,
      );
      const dropIndex = indexOf(dropElement.parentElement.children, dropElement);

      if (!this.sourceContainer) {
        this.sourceIndex = dragIndex;
        this.sourceContainer = drag.dropContainer;

        placeholderElement.style.width = `${sourceElement.clientWidth}px`;
        placeholderElement.style.height = `${sourceElement.clientHeight}px`;

        if (sourceElement.parentElement) {
          sourceElement.parentElement.removeChild(sourceElement);
        }
      }

      this.targetIndex = dropIndex;
      this.targetContainer = dropList;

      placeholderElement.style.display = '';
      dropElement.parentElement.insertBefore(
        placeholderElement,
        dropIndex > dragIndex ? dropElement.nextSibling : dropElement,
      );
    }

    if (this.sourceContainer) {
      this.sourceContainer._dropListRef.start();
    }

    this.placeholder._dropListRef.enter(
      drag._dragRef,
      drag.element.nativeElement.offsetLeft,
      drag.element.nativeElement.offsetTop,
    );

    return false;
  };

  remove(imageToRemove: string): void {
    this.images = this.images.filter(image => image !== imageToRemove);

    this.imagesChange.emit(this.images);
  }

  /** Determines the point of the page that was touched by the user. */
  private getPointerPositionOnPage(event: MouseEvent | TouchEvent) {
    // `touches` will be empty for start/end events so we have to fall back to `changedTouches`.
    const point = isTouchEvent(event) ? event.touches[0] || event.changedTouches[0] : event;
    const scrollPosition = this.viewportRuler.getViewportScrollPosition();

    return {
      x: point.pageX - scrollPosition.left,
      y: point.pageY - scrollPosition.top,
    };
  }
}
