import { Building } from '@marketplace/api/building';
import { BuildingStateModel } from '../building';

export function processBuildings(buildings: Building.List.Read.Response): BuildingStateModel[] {
  return buildings.map(building => ({
    id: building.id,
    name: building.name,
    status: building.status,
  }));
}
