// tslint:disable: no-namespace max-classes-per-file

import { Auth } from '../api/auth.type';
import { AuthStateModel } from './auth.model';

export namespace AuthStateAction {
  export namespace CheckIdentity {
    export class Try {
      static readonly type = '[Auth] try Check Email';
      constructor(public body: Auth.CheckIdentity.Body) {}
    }
    export class Success {
      static readonly type = '[Auth] then Check Email success';
      constructor(
        public auth: AuthStateModel,
        public body: Auth.CheckIdentity.Body,
        public response: Auth.CheckIdentity.Response,
      ) {}
    }
    export class Failure {
      static readonly type = '[Auth] catch Check Email failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace CreatePassword {
    export class Try {
      static readonly type = '[Auth] try Create Password';
      constructor(public body: Auth.CreatePassword.Body) {}
    }
    export class Success {
      static readonly type = '[Auth] then Create Password success';
      constructor(public auth: AuthStateModel) {}
    }
    export class Failure {
      static readonly type = '[Auth] catch Create Password failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Login {
    export class Try {
      static readonly type = '[Auth] try Login';
      constructor(public body: Auth.Login.Body) {}
    }
    export class Success {
      static readonly type = '[Auth] then Login success';
      constructor(public auth: AuthStateModel, public response: Auth.Login.Response) {}
    }
    export class Failure {
      static readonly type = '[Auth] catch Login failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any, public body: Auth.Login.Body) {}
    }
  }

  export namespace LoginTemporary {
    export class Try {
      static readonly type = '[Auth] try Temporary Login';
      constructor(public body: Auth.LoginTemporary.Body) {}
    }
    export class Success {
      static readonly type = '[Auth] then Temporary Login success';
      constructor(public auth: AuthStateModel, public response: Auth.LoginTemporary.Response) {}
    }
    export class Failure {
      static readonly type = '[Auth] catch Temporary Login failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any, public body: Auth.LoginTemporary.Body) {}
    }
  }

  export namespace Logout {
    export class Try {
      static readonly type = '[Auth] try Logout';
    }
    export class Success {
      static readonly type = '[Auth] then Logout Success';
      constructor(public auth: AuthStateModel) {}
    }
    export class Failure {
      static readonly type = '[Auth] catch Logout failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace PasswordCriteria {
    export class Try {
      static readonly type = '[Auth] try Read Password Criteria';
    }
    export class Success {
      static readonly type = '[Auth] then Read Password Criteria Success';
      constructor(public auth: AuthStateModel) {}
    }
    export class Failure {
      static readonly type = '[Auth] catch Read Password Criteria failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace RecoverPassword {
    export class Try {
      static readonly type = '[Auth] try Recover Password';
      constructor(public body: Auth.RecoverPassword.Body) {}
    }
    export class Success {
      static readonly type = '[Auth] then Recover Password success';
      constructor(public auth: AuthStateModel) {}
    }
    export class Failure {
      static readonly type = '[Auth] catch Recover Password failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace RefreshToken {
    export class Try {
      static readonly type = '[Auth] try Refresh Token';
    }
    export class Success {
      static readonly type = '[Auth] then Refresh Token success';
      constructor(public auth: AuthStateModel, public response: Auth.RefreshToken.Response) {}
    }
    export class Failure {
      static readonly type = '[Auth] catch Refresh Token failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Registration {
    export class Try {
      static readonly type = '[Auth] try Registration';
      constructor(public body: Auth.Registration.Body) {}
    }
    export class Success {
      static readonly type = '[Auth] then Registration success';
      constructor(public auth: AuthStateModel) {}
    }
    export class Failure {
      static readonly type = '[Auth] catch Registration failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace ResetPassword {
    export class Try {
      static readonly type = '[Auth] try Reset Password';
      constructor(public body: Auth.ResetPassword.Body) {}
    }
    export class Success {
      static readonly type = '[Auth] then Reset Password success';
      constructor(public auth: AuthStateModel) {}
    }
    export class Failure {
      static readonly type = '[Auth] catch Reset Password failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Sso {
    export namespace Change {
      export class Try {
        static readonly type = '[Auth] try Change SSO';
        constructor(public sso: AuthStateModel['sso']) {}
      }
      export class Success {
        static readonly type = '[Auth] then Change SSO success';
        constructor(public app: AuthStateModel) {}
      }
      export class Failure {
        static readonly type = '[Auth] catch Change SSO failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace SupportContacts {
    export class Try {
      static readonly type = '[Auth] try Read Support Contacts';
      constructor(public body: Auth.SupportContacts.Body) {}
    }
    export class Success {
      static readonly type = '[Auth] then Read Support Contacts success';
      constructor(public auth: AuthStateModel, public response: Auth.SupportContacts.Response) {}
    }
    export class Failure {
      static readonly type = '[Auth] catch Read Support Contacts failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Tenant {
    export namespace Change {
      export class Try {
        static readonly type = '[Auth] try Change Tenant';
        constructor(public tenant: AuthStateModel['tenant']) {}
      }
      export class Success {
        static readonly type = '[Auth] then Change Tenant success';
        constructor(public auth: AuthStateModel) {}
      }
      export class Failure {
        static readonly type = '[Auth] catch Change Tenant failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace TermsAndPrivacyPolicy {
    export namespace Read {
      export class Try {
        static readonly type = '[Auth] try Read Terms And Privacy Policy';
      }
      export class Success {
        static readonly type = '[Auth] then Read Terms And Privacy Policy Success';
        constructor(
          public auth: AuthStateModel,
          public response: Auth.TermsAndPrivacyPolicy.Read.Response,
        ) {}
      }
      export class Failure {
        static readonly type = '[Auth] catch Read Terms And Privacy Policy failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[Auth] try Update Terms And Privacy Policy';
        constructor(public body: Auth.TermsAndPrivacyPolicy.Update.Body) {}
      }
      export class Success {
        static readonly type = '[Auth] then Update Terms And Privacy Policy Success';
        constructor(
          public auth: AuthStateModel,
          public response: Auth.TermsAndPrivacyPolicy.Update.Response,
        ) {}
      }
      export class Failure {
        static readonly type = '[Auth] catch Update Terms And Privacy Policy failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }
}
