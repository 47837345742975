import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslationService } from '@nibol/translation';
import { FormValueAccessor } from '@nibol/ui';
import { roundToNearestMinutes } from 'time-turner-js';
import { TimeSlot, WeekdayFormControlValue } from './weekday-form-control-value.type';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WeekdayFormControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => WeekdayFormControlComponent),
      multi: true,
    },
  ],
  selector: 'nib-weekday-form-control',
  styleUrls: ['weekday-form-control.component.scss'],
  templateUrl: 'weekday-form-control.component.html',
})
export class WeekdayFormControlComponent
  extends FormValueAccessor<WeekdayFormControlValue>
  implements OnInit {
  @Input() formControlName!: string;
  @Input() timeSteps = 15;

  form = new FormGroup(
    {
      availability: new FormControl(false),
      timeSlots: new FormControl({}),
      weekday: new FormControl(0),
    },
    (control: AbstractControl) => {
      return this.validator(control);
    },
  );
  timeSlotsControls: FormGroup[] = [];

  // @todo(heavybeard): get ordered list of weekdays from service
  private readonly weekdays = [
    { id: 'mon', name: this.translationService.translate('list_dayweek_mon') },
    { id: 'tue', name: this.translationService.translate('list_dayweek_tue') },
    { id: 'wed', name: this.translationService.translate('list_dayweek_wed') },
    { id: 'thu', name: this.translationService.translate('list_dayweek_thu') },
    { id: 'fri', name: this.translationService.translate('list_dayweek_fri') },
    { id: 'sat', name: this.translationService.translate('list_dayweek_sat') },
    { id: 'sun', name: this.translationService.translate('list_dayweek_sun') },
  ];

  get weekdayName(): string {
    return this.weekdays.find(weekday => weekday.id === this.formControlName)?.name ?? '';
  }

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly translationService: TranslationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.addTimeSlotOnActivateAvailabilityStatus();
  }

  addNewTimeSlot(event?: MouseEvent): void {
    if (event) {
      event.preventDefault();
    }

    this.timeSlotsControls = [
      ...this.timeSlotsControls,
      this.buildTimeslotControls({ from: '', to: '' }),
    ];

    this.updateFormValue();
  }

  removeTimeSlot(index: number): void {
    this.timeSlotsControls = this.timeSlotsControls.filter(
      (_formGroup, formGroupIndex) => formGroupIndex !== index,
    );

    this.updateFormValue();
  }

  trackByTimeSlotIndex(index: number): number {
    return index;
  }

  writeValue(value: WeekdayFormControlValue): void {
    if (value && value.timeSlots) {
      this.timeSlotsControls = Object.values(value.timeSlots).map(slot =>
        this.buildTimeslotControls(slot),
      );
    }

    super.writeValue({
      availability: value.availability,
      timeSlots: value.timeSlots || {},
      weekday: value.weekday,
    });

    this.changeDetectorRef.markForCheck();
  }

  private addTimeSlotOnActivateAvailabilityStatus(): void {
    const availabilityControl = this.form.get('availability');
    const weekdayControl = this.form.get('weekday');

    if (availabilityControl) {
      availabilityControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value: boolean) => {
        if (value) {
          this.addNewTimeSlot();
        } else {
          this.writeValue({ availability: value, timeSlots: {}, weekday: weekdayControl?.value });
        }
      });
    }
  }

  private buildTimeslotControls(slot: TimeSlot): FormGroup {
    const fromControl = new FormControl(slot.from, Validators.required);
    const toControl = new FormControl(slot.to, Validators.required);
    const formGroup = new FormGroup(
      { from: fromControl, to: toControl },
      (control: AbstractControl) => {
        const value: TimeSlot = control.value;

        if (fromControl.errors) {
          return fromControl.errors;
        }

        if (toControl.errors) {
          return toControl.errors;
        }

        return value.from < value.to ? null : { error: 'invalid range' };
      },
    );

    formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      fromControl.setValue(roundToNearestMinutes(value.from, this.timeSteps), { emitEvent: false });
      toControl.setValue(roundToNearestMinutes(value.to, this.timeSteps), { emitEvent: false });

      this.updateFormValue();
    });

    return formGroup;
  }

  private updateFormValue(): void {
    const updatedTimeslots: { [index: number]: TimeSlot } = {};

    this.timeSlotsControls
      .map(timeSlot => timeSlot.value as TimeSlot)
      .forEach((value, index) => {
        updatedTimeslots[index] = value;
      });

    this.form.patchValue({ timeSlots: updatedTimeslots });
  }

  private validator(control: AbstractControl): ValidationErrors | null {
    const value: WeekdayFormControlValue = control.value;

    if (value.availability && Object.keys(value.timeSlots ?? {}).length > 0) {
      return (
        this.timeSlotsControls.map(formGroup => formGroup.errors).find(error => error !== null) ||
        null
      );
    }

    if (!value.availability) {
      return null;
    }

    return { error: 'invalid availability' };
  }
}
