<div
  [@toastVisibility]="toastVisibility"
  [ngClass]="ngClass"
  (@toastVisibility.done)="manageAnimationDone($event)"
>
  <nib-icon [color]="iconColor" [name]="data.severity || severity"></nib-icon>
  <div class="nib-toast-body">
    <span class="nib-toast-title nib-heading-3">
      <ng-container *ngIf="isTitleString; else titleTemplate">{{ title }}</ng-container>
      <ng-template #titleTemplate>
        <ng-template [ngTemplateOutlet]="title"></ng-template>
      </ng-template>
    </span>
    <span class="nib-toast-content nib-body">
      <ng-container *ngIf="isContentString; else contentTemplate">{{ content }}</ng-container>
      <ng-template #contentTemplate>
        <ng-template [ngTemplateOutlet]="content"></ng-template>
      </ng-template>
    </span>
  </div>
  <ng-container *ngIf="customAction; else defaultDismissAction">
    <ng-template [ngTemplateOutlet]="customAction" [ngTemplateOutletContext]="data"></ng-template>
  </ng-container>
  <ng-template #defaultDismissAction>
    <nib-button [clean]="true" (onClick)="dismiss()" class="nib-toast-close-button">
      <nib-icon [color]="'var(--color-gray-4)'" [name]="'close-small'"></nib-icon>
    </nib-button>
  </ng-template>
  <nib-progress-bar
    *ngIf="showProgress && (timer$ | async) as timer"
    [color]="(data.severity || severity) + '-light'"
    [value]="100 - timer"
    class="nib-toast-progress-bar"
  ></nib-progress-bar>
</div>
