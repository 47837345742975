import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { iif, interval, timer } from 'rxjs';
import { mergeMap, switchMap, tap } from 'rxjs/operators';
import { ToastDataModel } from './toast-data.model';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'nib-toast',
  styleUrls: ['toast.component.scss'],
  templateUrl: 'toast.component.html',
  animations: [
    trigger('toastVisibility', [
      state(
        'dismissed',
        style({
          opacity: 0,
          bottom: '-30px',
        }),
      ),
      state(
        'afterOpened',
        style({
          opacity: 1,
          bottom: '0',
        }),
      ),
      state(
        'afterDismissed',
        style({
          opacity: 0,
          bottom: '30px',
        }),
      ),
      transition('dismissed => afterOpened', animate('100ms 0s ease-in')),
      transition('afterOpened => afterDismissed', animate('200ms 0s ease-out')),
    ]),
  ],
})
export class ToastComponent implements OnInit {
  /** Message to show in toast body */
  @Input()
  get content(): ToastDataModel['content'] {
    return this.data.content || this._content;
  }
  set content(value: ToastDataModel['content']) {
    this._content = value;
  }
  private _content: ToastDataModel['content'];

  /** Custom action to use. */
  @Input()
  get customAction(): ToastDataModel['customAction'] {
    return this.data.customAction || this._customAction;
  }
  set customAction(value: ToastDataModel['customAction']) {
    this._customAction = value;
  }
  private _customAction: ToastDataModel['customAction'];

  /** A short text which describe the message. */
  @Input()
  get title(): ToastDataModel['title'] {
    return this.data.title || this._title;
  }
  set title(value: ToastDataModel['title']) {
    this._title = value;
  }
  private _title: ToastDataModel['title'];

  /** Define the severity level of the message */
  @Input()
  get severity(): ToastDataModel['severity'] {
    return this.data.severity || this._severity;
  }
  set severity(value: ToastDataModel['severity']) {
    this._severity = value;
  }
  private _severity = new ToastDataModel().severity;

  /** Icon color to use in icon */
  get iconColor(): string {
    return {
      negative: 'var(--color-semantic-negative)',
      informative: 'var(--color-semantic-informative)',
      positive: 'var(--color-semantic-positive)',
      notice: 'var(--color-semantic-notice)',
    }[this.severity];
  }

  /** Whether the content is a string */
  get isContentString(): boolean {
    return typeof this.content === 'string';
  }

  /** Whether the title is a string */
  get isTitleString(): boolean {
    return typeof this.title === 'string';
  }

  /** Whether the toast is created inline in a component */
  get isCreatedInline(): boolean {
    return !this.matSnackBarRef.instance;
  }

  /** Class to apply to toast */
  get ngClass(): { [key: string]: boolean } {
    return {
      'nib-toast': true,
      [`nib-toast-${this.data.severity || this.severity}`]: true,
    };
  }

  /** Whether a duration is configured */
  get showProgress(): boolean {
    return (
      !this.isCreatedInline &&
      typeof this.matSnackBarRef.containerInstance.snackBarConfig.duration !== 'undefined'
    );
  }

  /** Timer for the progress bar */
  readonly timer$ = !this.isCreatedInline
    ? interval(
        ((this.matSnackBarRef.containerInstance.snackBarConfig.duration as number) - 200) / 100,
      )
    : interval((5000 - 200) / 100);

  /** Manage animation status */
  toastVisibility = 'dismissed';

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) readonly data: ToastDataModel,
    private readonly matSnackBarRef: MatSnackBarRef<ToastComponent>,
  ) {}

  ngOnInit(): void {
    this.manageAnimations();
  }

  /** Close the toast itself */
  dismiss(): void {
    if (!this.isCreatedInline) {
      this.toastVisibility = 'afterDismissed';
    }
  }

  /** Manage animation done callbacks */
  manageAnimationDone(event: AnimationEvent): void {
    switch (event.toState) {
      case 'afterDismissed':
        if (!this.isCreatedInline) {
          this.matSnackBarRef.dismiss();
        }
        break;
    }
  }

  /** Manage the animation steps */
  private manageAnimations(): void {
    if (!this.isCreatedInline) {
      this.matSnackBarRef
        .afterOpened()
        .pipe(
          tap(() => (this.toastVisibility = 'afterOpened')),
          switchMap(() =>
            timer((this.matSnackBarRef.containerInstance.snackBarConfig.duration as number) - 200),
          ),
          mergeMap(() =>
            iif(
              () =>
                typeof this.matSnackBarRef.containerInstance.snackBarConfig.duration !==
                'undefined',
            ),
          ),
          untilDestroyed(this),
        )
        .subscribe(() => (this.toastVisibility = 'afterDismissed'));
    } else {
      this.toastVisibility = 'afterOpened';
    }
  }
}
