import { ChangeDetectionStrategy, Component, OnInit, ViewContainerRef } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { SubscriptionService } from '@marketplace/api';
import { environment } from '@marketplace/environment';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Navigate } from '@ngxs/router-plugin';
import { LoggerService } from '@nibol/logger';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'mkt-app',
  styleUrls: ['app.component.scss'],
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
  @Dispatch() navigateToHomepage = () => new Navigate([environment.homepage]);

  constructor(
    public viewContainerRef: ViewContainerRef,
    private readonly loggerService: LoggerService,
    private readonly subscriptionService: SubscriptionService,
    private readonly swPush: SwPush,
  ) {}

  ngOnInit(): void {
    this.requestPWASubscription();
  }

  private requestPWASubscription() {
    this.swPush
      .requestSubscription({
        serverPublicKey: environment.vapidPublicKey,
      })
      .then(subscription => {
        // this.subscriptionService.create(subscription.toJSON()).toPromise();
      })
      .catch(error => {
        this.loggerService.warn(`marketplace ${error.message}`);
      });

    this.swPush.notificationClicks.toPromise().then(({ notification }) => {
      /** @see https://www.notion.so/nibol/Communication-flow-48cac2f3e1cd483facbf3b20f82dd95f */
      switch (notification.data.id) {
        case 'mng001':
        case 'mng002':
        case 'mng003':
        case 'mng004':
          this.navigateToHomepage();
          break;

        default:
          break;
      }
    });
  }
}
