import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { API_ROOT_URL, Auth, AuthSelectors, AuthStateAction, SsoList } from '@nibol/auth';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { openWindowPopup } from '../../helpers/window-open-popup.helper';
import { UserSelectors } from '../../store/public-api';
import { MicrosoftButtonSelectors } from './microsoft-button.selectors';
import { MicrosoftMessageEventData } from './microsoft-button.type';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nib-microsoft-button',
  styleUrls: ['microsoft-button.component.scss'],
  templateUrl: 'microsoft-button.component.html',
})
export class MicrosoftButtonComponent implements OnInit {
  @Dispatch() tryLogin = (token: Auth.Login.Body['token']) =>
    new AuthStateAction.Login.Try({ email: this.email, keep: true, method: SsoList.azure, token });

  @Output() onMicrosoftFailedToLogin = new EventEmitter<string | null>();

  @SelectSnapshot(UserSelectors.email) email!: string;
  @SelectSnapshot(MicrosoftButtonSelectors.loginUrl) loginUrl!: string;
  @SelectSnapshot(AuthSelectors.tenant) tenant!: string;

  inProgress$ = new BehaviorSubject(false);

  private loginPopup?: Window | null;

  constructor(@Inject(API_ROOT_URL) private readonly apiRootUrl: string) {}

  ngOnInit(): void {
    this.manageMicrosoftPopupMessages();
  }

  loginWithMicrosoft(): void {
    this.inProgress$.next(true);
    this.onMicrosoftFailedToLogin.emit(null);

    this.loginPopup = openWindowPopup(this.loginUrl, 400, 550, () => {
      this.inProgress$.next(false);
      this.onMicrosoftFailedToLogin.emit('azure:window-closed');
    });
  }

  private manageMicrosoftPopupMessages(): void {
    fromEvent<MessageEvent<MicrosoftMessageEventData>>(window, 'message')
      .pipe(
        filter(
          ({ origin }) =>
            origin ===
            new URL(this.apiRootUrl.replace('https://api', `https://${this.tenant}`)).origin,
        ),
        untilDestroyed(this),
      )
      .subscribe(({ data: { message, status, token } }) => {
        this.loginPopup?.close();

        if (status && token) {
          this.tryLogin(token);
          this.onMicrosoftFailedToLogin.emit(null);
        } else {
          this.inProgress$.next(false);
          this.onMicrosoftFailedToLogin.emit(message);
        }
      });
  }
}
