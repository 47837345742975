<nib-form>
  <nib-form-title>{{ 'toggle_toggle_subtitle' | translate }}</nib-form-title>
  <nib-form-description>
    {{ 'toggle_toggle_body' | translate }}
  </nib-form-description>
  <ng-container *ngIf="featureToggles">
    <nib-form-field
      *ngFor="let featureToggle of featureToggles | keyvalue; trackBy: featureToggleKey"
    >
      <nib-switch
        [id]="featureToggle.key"
        [label]="featureToggle.key.toLowerCase() | translate"
        [(ngModel)]="featureToggle.value"
        (checkedChange)="changeFeatureToggleValue(featureToggle.key, $event)"
      ></nib-switch>
    </nib-form-field>
  </ng-container>
</nib-form>
