import { Selector } from '@ngxs/store';
import { AuthSelectors, AuthStateModel, SsoList, SsoSetting } from '@nibol/auth';

/** @dynamic */
export class MicrosoftButtonSelectors {
  @Selector([AuthSelectors.sso])
  static loginUrl(sso: AuthStateModel['sso']): SsoSetting['loginLink'] {
    return sso.find(ssoSetting => ssoSetting.sso === SsoList.azure)?.loginLink;
  }
}
