import { Building } from '@marketplace/api/building';
import { LanguageCodes } from '@nibol/translation';
import { BuildingStateModel } from '../building';
import { VenueStateModel } from '../venue';

export function mapReservationsToStore(
  reservations: Building.Reservations.Read.Response['reservations'],
  venues: VenueStateModel[],
): NonNullable<BuildingStateModel[][number]['reservations']> {
  return reservations.map(reservation => ({
    credit: reservation.payment?.total,
    discount: reservation.discount,
    end: createDate(reservation.time.day, reservation.time.to),
    id: reservation.id,
    isCancelled: isCancelled(reservation.status),
    isCheckedIn: isCheckedIn(reservation.status),
    isExternal: isExternal(reservation.status),
    isLost: isLost(reservation.status),
    people: reservation.seats,
    start: createDate(reservation.time.day, reservation.time.from),
    summary: reservation.summary,
    title: '',
    venue: venueName(reservation.venue, venues),
    ...(reservation.user && {
      user: {
        firstname: reservation.user.data.first_name,
        image: reservation.user.data.pic,
        language: {
          en: LanguageCodes['en-gb'],
          es: LanguageCodes['es-es'],
          fr: LanguageCodes['fr-fr'],
          it: LanguageCodes['it-it'],
        }[reservation.user.info.language],
        lastname: reservation.user.data.last_name,
      },
    }),
  }));
}

function createDate(date: number, time: number): Date {
  const isThreeDigit = time >= 1000;

  return new Date(
    parseInt(date.toString().slice(0, 4), 10),
    parseInt(date.toString().slice(4, 6), 10) - 1,
    parseInt(date.toString().slice(6, 8), 10),
    parseInt(time.toString().slice(0, isThreeDigit ? 2 : 1), 10),
    parseInt(time.toString().slice(isThreeDigit ? 2 : 1, isThreeDigit ? 4 : 3), 10),
  );
}

function isCancelled(
  statuses: Building.Reservations.Read.Response['reservations'][number]['status'],
): boolean {
  return statuses.some(status => ['cancelled'].includes(status));
}

function isCheckedIn(
  statuses: Building.Reservations.Read.Response['reservations'][number]['status'],
): boolean {
  return statuses.some(status => ['checked_in', 'check_in'].includes(status));
}

function isExternal(
  statuses: Building.Reservations.Read.Response['reservations'][number]['status'],
): boolean {
  return statuses.some(status => ['external'].includes(status));
}

function isLost(
  statuses: Building.Reservations.Read.Response['reservations'][number]['status'],
): boolean {
  return statuses.some(status => ['lost'].includes(status));
}

function venueName(
  venueId: Building.Reservations.Read.Response['reservations'][number]['venue'],
  venues: VenueStateModel[],
): NonNullable<BuildingStateModel[][number]['reservations']>[number]['title'] {
  return venues.find(venue => venue.id === venueId)?.name || venueId;
}
