import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { FormValueAccessor } from '@nibol/ui';
import { SignupFormValue } from './signup-form-value.type';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SignupFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SignupFormComponent),
      multi: true,
    },
  ],
  selector: 'nib-signup-form',
  styleUrls: ['signup-form.component.scss'],
  templateUrl: 'signup-form.component.html',
})
export class SignupFormComponent extends FormValueAccessor<SignupFormValue> {
  @Input() disabled = false;

  form = new FormGroup({
    firstname: new FormControl('', Validators.required),
    lastname: new FormControl('', Validators.required),
    password: new FormControl('', [
      Validators.pattern(/.{8,}/),
      Validators.pattern(/[a-zA-Z]/),
      Validators.pattern(/[0-9]/),
      Validators.required,
    ]),
    username: new FormControl('', [Validators.email, Validators.required]),
    buildingName: new FormControl('', Validators.required),
  });
}
