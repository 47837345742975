<ng-container *ngFor="let breadcrumb of breadcrumbs">
  <ng-container *ngIf="breadcrumb.path; else breadcrumbWithoutLink">
    <a
      *ngIf="breadcrumb.name | async | translate as name"
      [attr.title]="name"
      [routerLink]="breadcrumb.path"
      class="nib-breadcrumb-link"
    >
      {{ name }}
    </a>
  </ng-container>
  <ng-template #breadcrumbWithoutLink>
    <span
      *ngIf="breadcrumb.name | async | translate as name"
      [attr.title]="name"
      class="nib-breadcrumb-link"
    >
      {{ name }}
    </span>
  </ng-template>
  <span class="nib-breadcrumb-separator">></span>
</ng-container>
<span class="nib-breadcrumb-current"><ng-content></ng-content></span>
