import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Navigate } from '@ngxs/router-plugin';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { AuthStateAction } from '@nibol/auth';
import { RouterSelectors } from '@nibol/store';
import { BehaviorSubject } from 'rxjs';
import { ResetPasswordFormValue } from './reset-password-form/reset-password-form-value.type';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nib-reset-password-route',
  styleUrls: ['reset-password-route.component.scss'],
  templateUrl: 'reset-password-route.component.html',
})
export class ResetPasswordRouteComponent implements OnInit {
  @Dispatch() navigateToLogin = () => new Navigate(['/login']);
  @Dispatch() resetPasswordTry = (password: string, token: string) =>
    new AuthStateAction.ResetPassword.Try({
      new_password: password,
      token,
    });

  @SelectSnapshot(RouterSelectors.queryParams) queryParams!: { t: string };

  form = new FormGroup({
    resetPassword: new FormControl({
      password: null,
      token: this.queryParams.t,
    }),
  });
  isResetPasswordFormDisabled$ = new BehaviorSubject(false);
  isResetPasswordRequested$ = new BehaviorSubject(false);

  constructor(private readonly actions$: Actions) {}

  ngOnInit(): void {
    this.manageSubmit();
  }

  resetPassword(): void {
    if (this.form.valid) {
      const { password, token } = this.form.value.resetPassword as ResetPasswordFormValue;

      this.resetPasswordTry(password, token);
      this.isResetPasswordFormDisabled$.next(true);
    }
  }

  private manageSubmit(): void {
    this.actions$
      .pipe(ofActionSuccessful(AuthStateAction.ResetPassword.Failure), untilDestroyed(this))
      .subscribe(() => {
        this.isResetPasswordFormDisabled$.next(false);
      });

    this.actions$
      .pipe(ofActionSuccessful(AuthStateAction.ResetPassword.Success), untilDestroyed(this))
      .subscribe(() => {
        this.isResetPasswordRequested$.next(true);
        setTimeout(() => {
          this.navigateToLogin();
        }, 5000);
      });
  }
}
