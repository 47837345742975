import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Actions, ofActionDispatched, Select } from '@ngxs/store';
import { Auth, AuthStateAction } from '@nibol/auth';
import { BehaviorSubject, Observable } from 'rxjs';
import { Flag } from '../store/terms-and-privacy-policy.model';
import { TermsAndPrivacyPolicySelectors } from '../store/terms-and-privacy-policy.selectors';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nib-terms-and-privacy-policy-route',
  styleUrls: ['terms-and-privacy-policy-route.component.scss'],
  templateUrl: 'terms-and-privacy-policy-route.component.html',
})
export class TermsAndPrivacyPolicyRouteComponent implements OnInit {
  @Dispatch() updateTerms = (terms: Auth.TermsAndPrivacyPolicy.Update.Body) =>
    new AuthStateAction.TermsAndPrivacyPolicy.Update.Try(terms);

  @Select(TermsAndPrivacyPolicySelectors.body) body$!: Observable<string>;
  @Select(TermsAndPrivacyPolicySelectors.company) company$!: Observable<string>;
  @Select(TermsAndPrivacyPolicySelectors.flags) flags$!: Observable<Flag[] | undefined>;

  form = new FormGroup({});
  isTermsAndPrivacyPolicyFormDisabled$ = new BehaviorSubject(false);

  constructor(private readonly actions$: Actions) {}

  ngOnInit(): void {
    this.manageTermsAndPrivacyPolicyUpdating();
    this.manageTermsAndPrivacyPolicyReading();
  }

  async acceptTermsAndPrivacyPolicy(): Promise<void> {
    if (this.form.valid) {
      const flags: { [flag: string]: boolean } = this.form.value;

      this.isTermsAndPrivacyPolicyFormDisabled$.next(true);
      this.updateTerms({ flags: Object.keys(flags).filter(flagName => flags[flagName]) });
    }
  }

  flagValue(_index: number, flag: Flag): string {
    return flag.value;
  }

  private manageTermsAndPrivacyPolicyReading(): void {
    this.flags$.pipe(untilDestroyed(this)).subscribe(flags => {
      if (typeof flags !== 'undefined') {
        this.form = new FormGroup(
          flags.reduce(
            (previous: { [key: string]: FormControl }, current) => ({
              ...previous,
              [current.value]: new FormControl(false, [
                ...(current.required ? [Validators.requiredTrue] : []),
              ]),
            }),
            {},
          ),
        );
      }
    });
  }

  private manageTermsAndPrivacyPolicyUpdating(): void {
    this.actions$
      .pipe(
        ofActionDispatched(
          AuthStateAction.TermsAndPrivacyPolicy.Update.Failure,
          AuthStateAction.TermsAndPrivacyPolicy.Update.Success,
        ),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.isTermsAndPrivacyPolicyFormDisabled$.next(false);
      });
  }
}
