<section [fxLayout]="'row wrap'" [fxLayoutAlign]="'stretch'" [fxLayoutGap]="'16px grid'">
  <nib-hub-route-card [fxFlex]="100" [fxFlex.gt-md]="100 / 3" [link]="'profile'">
    <h3>{{ 'account_profile_title' | translate }}</h3>
    <span>{{ 'account_profile_text' | translate }}</span>
  </nib-hub-route-card>
  <nib-hub-route-card [fxFlex]="100" [fxFlex.gt-md]="100 / 3" [link]="'notifications'">
    <h3>{{ 'account_notifications_title' | translate }}</h3>
    <span>{{ 'account_notifications_text' | translate }}</span>
  </nib-hub-route-card>
  <nib-hub-route-card
    *featureToggle="'TOGGLE_ENABLE_RESET_PASSWORD'"
    [fxFlex]="100"
    [fxFlex.gt-md]="100 / 3"
    [link]="'../recover-password'"
  >
    <h3>{{ 'account_recoverpassword_title' | translate }}</h3>
    <span>{{ 'account_recoverpassword_text' | translate }}</span>
  </nib-hub-route-card>
</section>
