import { ChangeDetectionStrategy, Component, ContentChild, ViewEncapsulation } from '@angular/core';
import { FormDescriptionComponent } from './form-description.component';
import { FormTitleComponent } from './form-title.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'nib-form',
  styleUrls: ['form.component.scss'],
  templateUrl: 'form.component.html',
})
export class FormComponent {
  /** Form description from ng-content */
  @ContentChild(FormDescriptionComponent)
  formDescription = new FormDescriptionComponent();

  /** Form title from ng-content */
  @ContentChild(FormTitleComponent) formTitle = new FormTitleComponent();
}
