// tslint:disable: no-namespace max-classes-per-file

import { AreaStateModel } from './area.model';

export namespace AreasStateAction {
  export namespace List {
    export namespace Read {
      export class Try {
        static readonly type = '[Areas] try Read Areas List';
      }
      export class Success {
        static readonly type = '[Areas] then Read Areas List success';
        constructor(public areas: AreaStateModel[]) {}
      }
      export class Failure {
        static readonly type = '[Areas] catch Read Areas List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }
}
