import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { QuillEditorBase } from 'ngx-quill';
import { RichTextEditorBaseComponent } from './rich-text-editor-base.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'nib-rich-text-editor',
  styleUrls: [
    'quill-editor-bubble.css',
    'quill-editor-snow.css',
    'quill-editor-default.css',
    'rich-text-editor.component.scss',
  ],
  templateUrl: 'rich-text-editor.component.html',
  // tslint:disable-next-line: no-host-metadata-property
  host: { class: 'nib-rich-text-editor-container' },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RichTextEditorComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RichTextEditorComponent),
      multi: true,
    },
  ],
})
export class RichTextEditorComponent
  extends RichTextEditorBaseComponent
  implements ControlValueAccessor, Validator {
  /** Whether Rich Text Editor should be disabled. */
  @Input() disabled = false;

  /** Formats to enable */
  @Input() formats: QuillEditorBase['formats'] = ['bold', 'header', 'italic', 'link'];

  /** Rich Text Editor id and name. */
  @Input() id = '';

  /** Rich Text Editor label. */
  @Input() label = '';

  /** Rich Text Editor type. */
  @Input() type: 'default' | 'ghost' = 'default';

  touched = false;
  value: string | null = '';
  // tslint:disable-next-line: no-empty
  onChange = (_value: this['value']) => {};
  // tslint:disable-next-line: no-empty
  onTouched = () => {};

  get isGhostType(): boolean {
    return this.type === 'ghost';
  }

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.errors?.required) {
      return this.value && this.value.length > 0 ? null : { required: true };
    }

    return null;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  registerOnChange(callback: (value: this['value']) => void): void {
    this.onChange = callback;
  }

  registerOnTouched(callback: () => void): void {
    this.onTouched = callback;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  triggerChange(value: string) {
    this.markAsTouched();

    if (!this.disabled) {
      this.value = value;
      this.onChange(value);
    }
  }

  writeValue(value: this['value']): void {
    this.value = value;
    this.changeDetectorRef.markForCheck();
  }
}
