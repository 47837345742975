import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MapFilter } from '@marketplace/store';
import { Icon } from '@nibol/ui';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'mkt-space-icon',
  styleUrls: ['space-icon.component.scss'],
  templateUrl: 'space-icon.component.html',
})
export class SpaceIconComponent {
  @Input() mapFilter!: MapFilter;

  get iconName(): Icon {
    // @todo(heavybeard): move in init configuration
    return (({
      shared: 'nibol-cafe',
      promoted: 'seats',
      coworking: 'coworking',
      private: 'private-spaces',
    } as unknown) as { [key in MapFilter]: Icon })[this.mapFilter];
  }
}
