import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthStateAction } from '../store/auth.actions';
import { JWT_ERRORS } from '../tokens/jwt-errors.token';

@Injectable({ providedIn: 'root' })
export class LogoutAfterJwtErrorInterceptor implements HttpInterceptor {
  @Dispatch() logout = () => new AuthStateAction.Logout.Try();

  constructor(@Inject(JWT_ERRORS) private readonly jwtErrors: string[]) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403 && this.jwtErrors.indexOf(error.error)) {
          this.logout();
        }
        return throwError(error);
      }),
    );
  }
}
