import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ProxyValueAccessor } from '../form/proxy-value-accessor';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    class: 'nib-checkbox',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CheckboxComponent,
      multi: true,
    },
  ],
  selector: 'nib-checkbox',
  styleUrls: ['checkbox.component.scss'],
  templateUrl: 'checkbox.component.html',
})
export class CheckboxComponent extends ProxyValueAccessor {
  /** Whether the checkbox should be checked. */
  @Input()
  get checked(): boolean {
    return this.proxiedControl.value;
  }
  set checked(value: boolean) {
    this.proxiedControl.setValue(coerceBooleanProperty(value));
  }

  /** Whether the checkbox should be disabled. */
  @Input()
  get disabled(): boolean {
    return this.proxiedControl.disabled;
  }
  set disabled(value: boolean) {
    if (coerceBooleanProperty(value)) {
      this.proxiedControl.disable();
    } else {
      this.proxiedControl.enable();
    }
  }

  /** Input id and name. */
  @Input() id = '';

  /** Whether the checkbox should be indeterminate. */
  @Input()
  get indeterminate(): boolean {
    return this._indeterminate;
  }
  set indeterminate(value: boolean) {
    this._indeterminate = coerceBooleanProperty(value);
  }
  private _indeterminate = false;

  /** Input label. */
  @Input() label = '';

  proxiedControl = new FormControl();

  /** Whether the checkbox should be required. */
  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }
  private _required = false;

  /** checkbox type. */
  @Input() type = 'text';

  /** Emit checked state. */
  @Output() readonly checkedChange = new EventEmitter<boolean>();

  /** Class to apply to checkbox. */
  get ngClass(): { [key: string]: boolean } {
    return {
      'nib-checkbox-disabled': this.disabled,
    };
  }
}
