// tslint:disable: no-namespace max-classes-per-file

import { UserNotifications, UserProfile, UserStateModel } from './user.model';

export namespace UserStateAction {
  export namespace Item {
    export namespace Read {
      export class Try {
        static readonly type = '[User] try Read User Item';
      }
      export class Success {
        static readonly type = '[User] then Read User Item success';
        constructor(public user: UserStateModel) {}
      }
      export class Failure {
        static readonly type = '[User] catch Read User Item failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Store {
      export class Try {
        static readonly type = '[User] try Store User Item';
        constructor(public data: Partial<UserStateModel>) {}
      }
      export class Success {
        static readonly type = '[User] then Store User Item success';
        constructor(public user: UserStateModel) {}
      }
      export class Failure {
        static readonly type = '[User] catch Store User Item failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Update {
      export class Try {
        static readonly type = '[User] try Update User Item';
        constructor(public data: Partial<UserStateModel>) {}
      }
      export class Success {
        static readonly type = '[User] then Update User Item success';
        constructor(public user: UserStateModel) {}
      }
      export class Failure {
        static readonly type = '[User] catch Update User Item failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace Notifications {
    export namespace Update {
      export class Try {
        static readonly type = '[User] try Update User Notifications';
        constructor(public data: UserNotifications) {}
      }
      export class Success {
        static readonly type = '[User] then Update User Notifications success';
        constructor(public user: UserStateModel) {}
      }
      export class Failure {
        static readonly type = '[User] catch Update User Notifications failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }

  export namespace Profile {
    export namespace Update {
      export class Try {
        static readonly type = '[User] try Update User Profile';
        constructor(public data: Partial<UserProfile>) {}
      }
      export class Success {
        static readonly type = '[User] then Update User Profile success';
        constructor(public user: UserStateModel) {}
      }
      export class Failure {
        static readonly type = '[User] catch Update User Profile failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }
}
