import { A11yModule } from '@angular/cdk/a11y';
import { NgModule } from '@angular/core';
import { CardModule } from '../card/public-api';
import { FloatingPanelTriggerForOnContextMenuDirective } from './floating-panel-trigger-for-on-context-menu.directive';
import { FloatingPanelTriggerForDirective } from './floating-panel-trigger-for.directive';
import { FloatingPanelComponent } from './floating-panel.component';

@NgModule({
  declarations: [
    FloatingPanelComponent,
    FloatingPanelTriggerForDirective,
    FloatingPanelTriggerForOnContextMenuDirective,
  ],
  exports: [
    FloatingPanelComponent,
    FloatingPanelTriggerForDirective,
    FloatingPanelTriggerForOnContextMenuDirective,
  ],
  imports: [A11yModule, CardModule],
})
export class FloatingPanelModule {}
