import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { TableVirtualScrollComponent } from './table-virtual-scroll/table-virtual-scroll.component';
import { TableComponent } from './table.component';

@NgModule({
  declarations: [TableComponent, TableVirtualScrollComponent],
  exports: [MatPaginatorModule, MatTableModule, TableComponent, TableVirtualScrollComponent],
  imports: [
    CommonModule,
    MatPaginatorModule,
    MatTableModule,
    ScrollingModule,
    TableVirtualScrollModule,
  ],
})
export class TableModule {}
