import { Injectable } from '@angular/core';
import { BuildingService } from '@marketplace/api/building';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Action, State, StateContext } from '@ngxs/store';
import { patch, removeItem, updateItem } from '@ngxs/store/operators';
import { catchError, tap } from 'rxjs/operators';
import { CurrentBuildingState } from '../current-building';
import { VenueStateModel } from '../venue';
import { VenuesStateAction } from './venues.actions';
import { mapVenuesToClient } from './venues.helper';

@State<VenueStateModel[]>({
  name: 'venues',
  defaults: [],
})
@Injectable()
export class VenuesState {
  @SelectSnapshot(CurrentBuildingState) currentBuildingId!: string;

  constructor(private readonly buildingService: BuildingService) {}

  @Action(VenuesStateAction.List.Read.Try)
  readList(
    { dispatch, getState, setState }: StateContext<VenueStateModel[]>,
    { buildingId }: VenuesStateAction.List.Read.Try,
  ) {
    return this.buildingService.readVenues({ id: buildingId }).pipe(
      catchError(error => {
        dispatch(new VenuesStateAction.List.Read.Failure(error));

        throw error;
      }),
      tap(venues => {
        setState(mapVenuesToClient(venues));

        dispatch(new VenuesStateAction.List.Read.Success(getState(), buildingId));
      }),
    );
  }

  @Action(VenuesStateAction.List.Remove.Try)
  removeFromList(
    { dispatch, getState, setState }: StateContext<VenueStateModel[]>,
    { id }: VenuesStateAction.List.Remove.Try,
  ) {
    try {
      setState(removeItem(venue => venue?.id === id));

      dispatch(new VenuesStateAction.List.Remove.Success(getState(), this.currentBuildingId));
    } catch (error) {
      dispatch(new VenuesStateAction.List.Remove.Failure(error));

      throw error;
    }
  }

  @Action(VenuesStateAction.List.Update.Try)
  updateList(
    { dispatch, getState, setState }: StateContext<VenueStateModel[]>,
    { venue }: VenuesStateAction.List.Update.Try,
  ) {
    try {
      setState(updateItem(stateVenue => stateVenue?.id === venue.id, patch(venue)));

      dispatch(new VenuesStateAction.List.Update.Success(getState(), this.currentBuildingId));
    } catch (error) {
      dispatch(new VenuesStateAction.List.Update.Failure(error));

      throw error;
    }
  }
}
