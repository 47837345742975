import { Pipe, PipeTransform } from '@angular/core';
import {
  DomSanitizer,
  SafeHtml,
  SafeResourceUrl,
  SafeScript,
  SafeStyle,
  SafeUrl,
} from '@angular/platform-browser';

@Pipe({
  name: 'sanitize',
})
export class SanitizePipe implements PipeTransform {
  constructor(protected domSanitizer: DomSanitizer) {}

  // tslint:disable-next-line: cyclomatic-complexity
  transform(
    // tslint:disable-next-line: no-any
    value: any,
    type: string,
  ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case 'html':
        return this.domSanitizer.bypassSecurityTrustHtml(value);

      case 'style':
        return this.domSanitizer.bypassSecurityTrustStyle(value);

      case 'script':
        return this.domSanitizer.bypassSecurityTrustScript(value);

      case 'url':
        return this.domSanitizer.bypassSecurityTrustUrl(value);

      case 'resourceUrl':
        return this.domSanitizer.bypassSecurityTrustResourceUrl(value);

      default:
        throw new Error(`Invalid sanitize type specified: ${type}`);
    }
  }
}
