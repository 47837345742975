import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'nib-app-status-resume-ghost',
  styleUrls: ['app-status-resume-ghost.component.scss'],
  templateUrl: 'app-status-resume-ghost.component.html',
})
export class AppStatuResumeGhostComponent {
  @Input() showAvatar = false;
}
