import { AfterViewInit, Directive, ElementRef, Inject, Input, OnChanges } from '@angular/core';
import { NpsProperties } from './nps.type';
import { WOOTRIC_ACCOUNT_TOKEN } from './wootric/wootric-account-token.token';
import './wootric/wootric-window.interface';

@Directive({
  selector: '[nibNps]',
})
export class NpsDirective implements AfterViewInit, OnChanges {
  /** NPS should be initialized. */
  @Input() nibNps?: NpsProperties;

  constructor(
    @Inject(WOOTRIC_ACCOUNT_TOKEN) private readonly wootrickAccountToken: string,
    private readonly elementRef: ElementRef,
  ) {
    window.wootricSettings = {
      account_token: this.wootrickAccountToken,
      aria: true,
    };
  }

  ngAfterViewInit(): void {
    if (this.nibNps) {
      this.createAndInitializeWootric();
    }
  }

  ngOnChanges(): void {
    if (this.nibNps) {
      this.updateSettings();
    }
  }

  private createAndInitializeWootric(): void {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = 'https://cdn.wootric.com/wootric-sdk.js';
    script.async = true;
    script.onload = () => {
      window.WootricSurvey?.run();
    };

    this.elementRef.nativeElement.appendChild(script);
  }

  private updateSettings(): void {
    window.wootricSettings.created_at = this.nibNps?.createdAt;
    window.wootricSettings.email = this.nibNps?.accountName;
    window.wootricSettings.properties = {
      nib_client: this.nibNps?.clientCode,
      nib_os: this.nibNps?.osCode,
      nib_platform: this.nibNps?.platformCode,
      nib_user_roles: this.nibNps?.userRoles.join(','),
    };
  }
}
