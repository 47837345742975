// tslint:disable: no-namespace max-classes-per-file

import { VersionsStateModelType } from './versions-state-model.type';

export namespace VersionsStateAction {
  export namespace Init {
    export class Try {
      static readonly type = '[Versions] try Init Versions';
      constructor(public versions: VersionsStateModelType) {}
    }
    export class Success {
      static readonly type = '[Versions] then Init Versions success';
      constructor(public version: VersionsStateModelType) {}
    }
    export class Failure {
      static readonly type = '[Versions] catch Init Versions failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }
}
