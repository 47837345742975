// tslint:disable: no-namespace max-classes-per-file

export namespace PermissionsStateAction {
  export namespace List {
    export namespace Add {
      export class Try<Permissions> {
        static readonly type = '[Permissions] try Add Role to List';
        constructor(public roles: Permissions) {}
      }
      export class Success<Permissions> {
        static readonly type = '[Permissions] then Add Role to List success';
        constructor(public roles: Permissions) {}
      }
      export class Failure {
        static readonly type = '[Permissions] catch Add Role to List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Change {
      export class Try<Permissions> {
        static readonly type = '[Permissions] try Change Role List';
        constructor(public roles: Permissions) {}
      }
      export class Success<Permissions> {
        static readonly type = '[Permissions] then Change Role List success';
        constructor(public roles: Permissions) {}
      }
      export class Failure {
        static readonly type = '[Permissions] catch Change Role List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
    export namespace Remove {
      export class Try<Permissions> {
        static readonly type = '[Permissions] try Remove Role from List';
        constructor(public roles: Permissions) {}
      }
      export class Success<Permissions> {
        static readonly type = '[Permissions] then Remove Role from List success';
        constructor(public roles: Permissions) {}
      }
      export class Failure {
        static readonly type = '[Permissions] catch Remove Role from List failure';
        // tslint:disable-next-line: no-any
        constructor(public error: any) {}
      }
    }
  }
}
