import { Selector } from '@ngxs/store';
import { LanguageName } from '../core/language-code.type';
import { TranslationStateModel } from './translation.model';
import { TranslationState } from './translation.state';

export class TranslationSelectors {
  @Selector([TranslationState])
  static currentLanguage({
    currentLanguage,
  }: TranslationStateModel): TranslationStateModel['currentLanguage'] {
    return currentLanguage;
  }

  @Selector([TranslationSelectors.currentLanguage])
  static currentLanguageName(
    currentLanguage: TranslationStateModel['currentLanguage'],
  ): LanguageName {
    switch (currentLanguage) {
      case 'fr-fr':
        return 'fr';

      case 'it-it':
        return 'it';

      default:
        return 'en';
    }
  }

  @Selector([TranslationState])
  static state({ state }: TranslationStateModel): TranslationStateModel['state'] {
    return state;
  }
}
