// tslint:disable: no-namespace max-classes-per-file

import { BuildingStateModel } from '../building';
import { CompanyStateModel } from './company.model';

export namespace CompanyStateAction {
  export namespace Read {
    export class Try {
      static readonly type = '[Company] try Read Company';
      constructor(public buildingId: BuildingStateModel['id']) {}
    }
    export class Success {
      static readonly type = '[Company] then Read Company success';
      constructor(public company: CompanyStateModel, public buildingId: BuildingStateModel['id']) {}
    }
    export class Failure {
      static readonly type = '[Company] catch Read Company failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }

  export namespace Update {
    export class Try {
      static readonly type = '[Company] try Update Company';
      constructor(
        public buildingId: BuildingStateModel['id'],
        public data: Partial<CompanyStateModel>,
      ) {}
    }
    export class Success {
      static readonly type = '[Company] then Update Company success';
      constructor(public company: CompanyStateModel, public buildingId: BuildingStateModel['id']) {}
    }
    export class Failure {
      static readonly type = '[Company] catch Update Company failure';
      // tslint:disable-next-line: no-any
      constructor(public error: any) {}
    }
  }
}
