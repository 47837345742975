import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_ROOT_URL } from '@nibol/auth';
import { forkJoin, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from './user.type';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(
    @Inject(API_ROOT_URL) protected apiRootUrl: string,
    protected readonly httpClient: HttpClient,
  ) {}

  read(): Observable<User.Item.Read.Response> {
    return forkJoin([
      this.httpClient.get<User.Info.Read.Response>(`${this.apiRootUrl}/user/info`),
      this.httpClient.get<User.Notification.Read.Response>(`${this.apiRootUrl}/user/notification`),
    ]).pipe(
      map(([info, notification]) => ({
        ...info,
        ...notification,
      })),
      catchError(error => {
        throw error;
      }),
    );
  }

  updateInfo({ user }: User.Info.Update.Params): Observable<User.Info.Update.Response> {
    return this.httpClient[
      this.apiRootUrl.includes('manager') ? 'post' : 'put'
    ]<User.Info.Update.Response>(`${this.apiRootUrl}/user/info`, { user }).pipe(
      catchError(error => {
        throw error;
      }),
    );
  }

  updateNotification({
    notification,
  }: User.Notification.Update.Params): Observable<User.Notification.Update.Response> {
    return this.httpClient[
      this.apiRootUrl.includes('manager') ? 'post' : 'put'
    ]<User.Notification.Update.Response>(`${this.apiRootUrl}/user/notification`, {
      notification,
    }).pipe(
      catchError(error => {
        throw error;
      }),
    );
  }
}
