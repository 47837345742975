import {
  ChangeDetectorRef,
  Directive,
  EmbeddedViewRef,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BreakpointAliasExtended } from './breakpoint.type';

@UntilDestroy()
@Directive({ selector: '[nibIfBreakpointIs]' })
export class IfBreakpointIsDirective implements OnInit {
  @Input() nibIfBreakpointIs: BreakpointAliasExtended | '' = '';

  private embeddedViewRef?: EmbeddedViewRef<unknown>;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly mediaObserver: MediaObserver,
    private readonly templateRef: TemplateRef<unknown>,
    private readonly viewContainerRef: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    this.mediaObserver
      .asObservable()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.shouldBeCreated()) {
          if (!this.embeddedViewRef) {
            this.embeddedViewRef = this.viewContainerRef.createEmbeddedView(this.templateRef);
            this.changeDetectorRef.markForCheck();
          }
        } else {
          this.embeddedViewRef = undefined;
          this.viewContainerRef.clear();
          this.changeDetectorRef.markForCheck();
        }
      });
  }

  private shouldBeCreated(): boolean {
    return (
      (this.nibIfBreakpointIs[0] === '!' &&
        !this.mediaObserver.isActive(this.nibIfBreakpointIs.substr(1))) ||
      this.mediaObserver.isActive(this.nibIfBreakpointIs)
    );
  }
}
