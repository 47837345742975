import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  AppShellAuthModule,
  AppShellGhostModule,
  AppShellPublicModule,
  AppShellWizardModule,
} from '@nibol/shared';
import { TranslationModule } from '@nibol/translation';
import {
  AppShellModule as NibolAppShellModule,
  ChipModule,
  IconModule,
  IfBreakpointIsModule,
  LetModule,
  MultiSelectModule,
} from '@nibol/ui';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AppShellComponent } from './app-shell.component';
import { ChangeBuildingComponent } from './change-building/change-building.component';
import { SpaceIconComponent } from './space-icon/space-icon.component';
import { SpaceStatusComponent } from './space-status/space-status.component';

@NgModule({
  imports: [
    AppShellAuthModule,
    AppShellGhostModule,
    AppShellPublicModule,
    AppShellWizardModule,
    ChipModule,
    CommonModule,
    FeatureToggleModule,
    IconModule,
    IfBreakpointIsModule,
    LetModule,
    MultiSelectModule,
    NgxPermissionsModule,
    NibolAppShellModule,
    ReactiveFormsModule,
    RouterModule,
    TranslationModule,
  ],
  exports: [AppShellComponent],
  declarations: [
    AppShellComponent,
    ChangeBuildingComponent,
    SpaceIconComponent,
    SpaceStatusComponent,
  ],
  providers: [],
})
export class AppShellModule {}
