import { registerLocaleData } from '@angular/common';
// tslint:disable: match-default-export-name
import localeEn from '@angular/common/locales/en-GB';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import { LanguageCodes } from '../core/language-code.type';
// tslint:enable: match-default-export-name

export function registerLocalesData() {
  registerLocaleData(localeEn, LanguageCodes['en-gb']);
  registerLocaleData(localeEs, LanguageCodes['es-es']);
  registerLocaleData(localeFr, LanguageCodes['fr-fr']);
  registerLocaleData(localeIt, LanguageCodes['it-it']);
}
