import { NgModule } from '@angular/core';
import { BREAKPOINT } from '@angular/flex-layout';
import { MATERIAL_SANITY_CHECKS } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { NIBOL_BREAKPOINTS } from './breakpoints/public-api';

@NgModule({
  providers: [
    { provide: BREAKPOINT, useValue: NIBOL_BREAKPOINTS, multi: true },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { float: 'never' } },
    { provide: MATERIAL_SANITY_CHECKS, useValue: false },
  ],
})
export class UiModule {}
