import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Select } from '@ngxs/store';
import { AppSelectors, AppStateModel, Platform, RouterSelectors } from '@nibol/store';
import { Observable } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nib-access-denied-route',
  styleUrls: ['access-denied-route.component.scss'],
  templateUrl: 'access-denied-route.component.html',
})
export class AccessDeniedRouteComponent {
  @Select(AppSelectors.supportContacts)
  supportContacts$!: Observable<AppStateModel['supportContacts']>;

  @SelectSnapshot(RouterSelectors.email) email!: string;
  @SelectSnapshot(RouterSelectors.failureMessage) failureMessage!: string;
  @SelectSnapshot(AppSelectors.platform) platform!: Platform;

  constructor(private readonly sanitizer: DomSanitizer) {}

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  trackBySupportContactName(
    _index: number,
    supportContact: AppStateModel['supportContacts'][number],
  ): string {
    return supportContact.name;
  }
}
