import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { FormValueAccessor } from '@nibol/ui';
import { RecoverPasswordFormValue } from './recover-password-form-value.type';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecoverPasswordFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RecoverPasswordFormComponent),
      multi: true,
    },
  ],
  selector: 'nib-recover-password-form',
  styleUrls: ['recover-password-form.component.scss'],
  templateUrl: 'recover-password-form.component.html',
})
export class RecoverPasswordFormComponent extends FormValueAccessor<RecoverPasswordFormValue> {
  @Input() disabled = false;

  form = new FormGroup({
    username: new FormControl(null, [Validators.email, Validators.required]),
  });
}
