export function mapTimeNumberToTimeString(time: number): string {
  let timeInString = time.toString();

  while (timeInString.length < 4) {
    timeInString = `0${timeInString}`;
  }

  timeInString = timeInString.substring(0, 4);

  return `${timeInString.substring(0, 2)}:${timeInString.slice(2, 4)}`;
}
