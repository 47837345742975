import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { TranslationModule } from '@nibol/translation';
import { AppShellModule, ButtonModule, IfBreakpointIsModule, ProgressBarModule } from '@nibol/ui';
import { InlineSVGModule } from 'ng-inline-svg';
import { AppShellWizardComponent } from './app-shell-wizard.component';
import { StepActionsComponent } from './step-actions/step-actions.component';

@NgModule({
  imports: [
    AppShellModule,
    ButtonModule,
    CommonModule,
    FlexModule,
    IfBreakpointIsModule,
    InlineSVGModule,
    ProgressBarModule,
    RouterModule,
    TranslationModule,
  ],
  declarations: [AppShellWizardComponent, StepActionsComponent],
  exports: [AppShellWizardComponent, StepActionsComponent],
})
export class AppShellWizardModule {}
