import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { FormValueAccessor } from '@nibol/ui';
import { CreatePasswordFormValue } from './create-password-form-value.type';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CreatePasswordFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CreatePasswordFormComponent),
      multi: true,
    },
  ],
  selector: 'nib-create-password-form',
  styleUrls: ['create-password-form.component.scss'],
  templateUrl: 'create-password-form.component.html',
})
export class CreatePasswordFormComponent extends FormValueAccessor<CreatePasswordFormValue> {
  @Input() disabled = false;

  form = new FormGroup({
    password: new FormControl(null, [
      Validators.pattern(/.{8,}/),
      Validators.pattern(/[a-zA-Z]/),
      Validators.pattern(/[0-9]/),
      Validators.required,
    ]),
  });
}
