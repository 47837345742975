<nib-app-shell>
  <nib-app-shell-logo
    [fxFlex]="100"
    [fxLayout]="'row-reverse'"
    [fxLayout.gt-sm]="'row'"
    [fxLayoutAlign]="'space-between center'"
    [fxLayoutAlign.gt-sm]="'start center'"
  >
    <div *ngIf="logo" [inlineSVG]="logo"></div>
  </nib-app-shell-logo>
  <nib-app-shell-header>
    <nib-app-status-resume-ghost [showAvatar]="showAvatar"></nib-app-status-resume-ghost>
  </nib-app-shell-header>
  <nib-app-shell-menu>
    <nib-app-shell-menu-item class="nib-ghost nib-ghost-size-lg"></nib-app-shell-menu-item>
    <nib-app-shell-menu-item class="nib-ghost"></nib-app-shell-menu-item>
    <nib-app-shell-menu-divider class="nib-ghost nib-ghost-lighter nib-ghost-size-sm">
      &nbsp;
    </nib-app-shell-menu-divider>
    <nib-app-shell-menu-item class="nib-ghost nib-ghost-size-lg"></nib-app-shell-menu-item>
    <nib-app-shell-menu-item class="nib-ghost nib-ghost-size-lg"></nib-app-shell-menu-item>
    <nib-app-shell-menu-divider class="nib-ghost nib-ghost-lighter nib-ghost-size-sm">
      &nbsp;
    </nib-app-shell-menu-divider>
    <nib-app-shell-menu-item class="nib-ghost nib-ghost-size-md"></nib-app-shell-menu-item>
    <nib-app-shell-menu-item class="nib-ghost"></nib-app-shell-menu-item>
  </nib-app-shell-menu>
  <nib-app-shell-main-header>
    <div class="nib-app-breadcrumbs nib-ghost nib-ghost-size-xs nib-ghost-lighter">&nbsp;</div>
    <div class="nib-heading-1 nib-ghost nib-ghost-size-sm nib-ghost-darker">&nbsp;</div>
  </nib-app-shell-main-header>
</nib-app-shell>
