<mat-slide-toggle
  [ngClass]="ngClass"
  [required]="required"
  [formControl]="proxiedControl"
  (ngModelChange)="checkedChange.emit($event)"
>
  <span *ngIf="label; else ngContentTemplate" [innerHTML]="label"></span>
  <ng-template #ngContentTemplate>
    <ng-content></ng-content>
  </ng-template>
</mat-slide-toggle>
