import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import {
  CalendarDateFormatter,
  CalendarEventTitleFormatter,
  CalendarModule as AngularCalendarModule,
  CalendarUtils,
  DateAdapter,
} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ButtonGroupModule } from '../button-group/public-api';
import { ButtonModule } from '../button/public-api';
import { CalendarConfig } from './calendar-config.type';
import { CALENDAR_TRANSLATIONS } from './calendar-translations.token';
import { CalendarComponent } from './calendar.component';
import { CustomCalendarUtils } from './custom-calendar-utils.provide';
import { CustomDateFormatter } from './custom-date-formatter.provider';
import { CustomEventTitleFormatter } from './custom-event-title-formatter.provider';

@NgModule({
  imports: [
    AngularCalendarModule.forRoot(
      {
        provide: DateAdapter,
        useFactory: adapterFactory,
      },
      {
        dateFormatter: {
          provide: CalendarDateFormatter,
          useClass: CustomDateFormatter,
        },
        eventTitleFormatter: {
          provide: CalendarEventTitleFormatter,
          useClass: CustomEventTitleFormatter,
        },
        utils: {
          provide: CalendarUtils,
          useClass: CustomCalendarUtils,
        },
      },
    ),
    ButtonGroupModule,
    ButtonModule,
    CommonModule,
    FlexModule,
    LayoutModule,
  ],
  exports: [CalendarComponent],
  declarations: [CalendarComponent],
})
export class CalendarModule {
  static forRoot(config: CalendarConfig): ModuleWithProviders<CalendarModule> {
    return {
      ngModule: CalendarModule,
      providers: [
        {
          provide: CALENDAR_TRANSLATIONS,
          useValue: config.translations,
        },
      ],
    };
  }
}
