import { Selector } from '@ngxs/store';
import { AuthStateModel } from './auth.model';
import { AuthState } from './auth.state';

export class AuthSelectors {
  @Selector([AuthState])
  static isAuthenticated({ token }: AuthStateModel): boolean {
    return !!token;
  }

  @Selector([AuthState])
  static sso({ sso }: AuthStateModel): AuthStateModel['sso'] {
    return sso;
  }

  @Selector([AuthState])
  static tenant({ tenant }: AuthStateModel): AuthStateModel['tenant'] {
    return tenant;
  }

  @Selector([AuthState])
  static token({ token }: AuthStateModel): AuthStateModel['token'] {
    return token;
  }
}
