<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node
    *matTreeNodeDef="let node"
    class="nib-tree-selection-node"
    matTreeNodePadding
    matTreeNodePaddingIndent="36"
    matTreeNodeToggle
  >
    <nib-checkbox
      [checked]="checklistSelection.isSelected(node)"
      (checkedChange)="leafItemSelectionToggle(node)"
      class="nib-tree-selection-checkbox"
    >
      {{ node.item.label }}
    </nib-checkbox>
  </mat-tree-node>

  <mat-tree-node
    *matTreeNodeDef="let node; when: hasChild"
    class="nib-tree-selection-node"
    matTreeNodePadding
    matTreeNodePaddingIndent="36"
  >
    <nib-button [clean]="true" [size]="'xxs'" matTreeNodeToggle>
      <nib-icon [name]="treeControl.isExpanded(node) ? 'caret-down' : 'caret-right'"> </nib-icon>
    </nib-button>
    <nib-checkbox
      [checked]="areAllDescendantsSelected(node)"
      [indeterminate]="areDescendantsPartiallySelected(node)"
      (checkedChange)="toggleItemSelection(node)"
      class="nib-tree-selection-checkbox"
    >
      {{ node.item.label }}
    </nib-checkbox>
  </mat-tree-node>
</mat-tree>
