import { WeekDay } from '@angular/common';
import { WeekTimeSlotsFormFieldValue } from './week-time-slots-form-field-value.type';
import {
  atLeastOneTimeSlot,
  defaultWeekday,
} from './weekday-form-control/weekday-form-control.helper';

export function defaultTimeSlots(): WeekTimeSlotsFormFieldValue {
  return {
    mon: defaultWeekday(WeekDay.Monday),
    tue: defaultWeekday(WeekDay.Tuesday),
    wed: defaultWeekday(WeekDay.Wednesday),
    thu: defaultWeekday(WeekDay.Thursday),
    fri: defaultWeekday(WeekDay.Friday),
    sat: defaultWeekday(WeekDay.Saturday),
    sun: defaultWeekday(WeekDay.Sunday),
  };
}

export function atLeastOneTimeSlotPerWeekday(): WeekTimeSlotsFormFieldValue {
  return {
    mon: atLeastOneTimeSlot(WeekDay.Monday),
    tue: atLeastOneTimeSlot(WeekDay.Tuesday),
    wed: atLeastOneTimeSlot(WeekDay.Wednesday),
    thu: atLeastOneTimeSlot(WeekDay.Thursday),
    fri: atLeastOneTimeSlot(WeekDay.Friday),
    sat: atLeastOneTimeSlot(WeekDay.Saturday),
    sun: atLeastOneTimeSlot(WeekDay.Sunday),
  };
}
