import { RouterState, RouterStateModel } from '@ngxs/router-plugin';
import { Selector } from '@ngxs/store';
import { NgxPermissionsRouterData } from 'ngx-permissions';
import { RouteData } from './route-data.type';
import { RouteParams } from './route-params.type';
import { CustomRouterStateSnapshot } from './router.model';

export class RouterSelectors {
  @Selector([RouterSelectors.data])
  static breadcrumbs(data: CustomRouterStateSnapshot['data']): RouteData['breadcrumbs'] {
    return data?.breadcrumbs;
  }

  @Selector([RouterSelectors.queryParams])
  static code(queryParams: CustomRouterStateSnapshot['queryParams']): RouteParams['code'] {
    return queryParams?.code;
  }

  @Selector([RouterSelectors.data])
  static completionPercentage(
    data: CustomRouterStateSnapshot['data'],
  ): RouteData['completionPercentage'] {
    return data?.completionPercentage;
  }

  @Selector([RouterState])
  static data({
    state,
  }: RouterStateModel<CustomRouterStateSnapshot>): CustomRouterStateSnapshot['data'] {
    return state?.data;
  }

  @Selector([RouterSelectors.queryParams])
  static email(queryParams: CustomRouterStateSnapshot['queryParams']): RouteParams['email'] {
    return queryParams?.email;
  }

  @Selector([RouterSelectors.queryParams])
  static failureMessage(
    queryParams: CustomRouterStateSnapshot['queryParams'],
  ): RouteParams['error'] {
    return queryParams?.error;
  }

  @Selector([RouterSelectors.data])
  static isWizard(data: CustomRouterStateSnapshot['data']): boolean {
    return data?.isWizard || false;
  }

  @Selector([RouterState])
  static navigationId({
    navigationId,
  }: RouterStateModel<CustomRouterStateSnapshot>): RouterStateModel['navigationId'] {
    return navigationId;
  }

  @Selector([RouterState])
  static params({
    state,
  }: RouterStateModel<CustomRouterStateSnapshot>): CustomRouterStateSnapshot['params'] {
    return state?.params;
  }

  @Selector([RouterSelectors.data])
  static permissions(
    data: CustomRouterStateSnapshot['data'],
  ): NgxPermissionsRouterData | undefined {
    return data?.permissions;
  }

  @Selector([RouterState])
  static queryParams({
    state,
  }: RouterStateModel<CustomRouterStateSnapshot>): CustomRouterStateSnapshot['queryParams'] {
    return state?.queryParams;
  }

  @Selector([RouterSelectors.queryParams])
  static successMessage(
    queryParams: CustomRouterStateSnapshot['queryParams'],
  ): RouteParams['success'] {
    return queryParams?.success;
  }

  @Selector([RouterSelectors.data])
  static title(data: CustomRouterStateSnapshot['data']): RouteData['title'] {
    return data?.title;
  }

  @Selector([RouterState])
  static url({
    state,
  }: RouterStateModel<CustomRouterStateSnapshot>): CustomRouterStateSnapshot['url'] {
    return state?.url;
  }
}
