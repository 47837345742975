<div
  *ngFor="let isMenuOpen of [appShellService.isMenuOpen | async]"
  [class.nib-app-shell-menu-open]="isMenuOpen"
  [gdAreas]="'header header | menu main'"
  [gdColumns]="containerColumns"
  [gdColumns.gt-sm]="containerColumnsGtSm"
  [gdRows]="containerRows"
  [gdRows.gt-sm]="containerRowsGtSm"
  class="nib-app-shell-wrapper"
>
  <div
    *ngIf="showHeader"
    [gdArea]="'header'"
    [gdAreas]="isMenuOpen ? 'items logo toggle' : 'toggle logo items'"
    [gdAreas.gt-sm]="'toggle logo items'"
    [gdColumns]="isMenuOpen ? 'auto 0 56px' : '56px auto 0'"
    [gdColumns.gt-sm]="'0 240px auto'"
    class="nib-app-shell-header-container"
  >
    <nib-button
      [clean]="true"
      [color]="'tertiary'"
      [disabled]="!showMenu"
      [fxLayoutAlign]="isMenuOpen ? 'center start' : 'center center'"
      [gdArea]="'toggle'"
      [size]="'sm'"
      (onClick)="appShellService.toggleMenu()"
      class="nib-app-shell-menu-toggle"
    >
      <nib-icon
        *ngIf="showMenu"
        [color]="isMenuOpen ? 'var(--color-white)' : 'var(--color-gray-4)'"
        [name]="isMenuOpen ? 'close' : 'hamburger'"
      ></nib-icon>
    </nib-button>
    <div
      *ngIf="!isMenuOpen"
      [fxLayoutAlign]="'end center'"
      [fxLayoutAlign.gt-sm]="'start center'"
      [gdArea]="'logo'"
      class="nib-app-shell-logo-container"
    >
      <ng-content select="nib-app-shell-logo"></ng-content>
    </div>
    <div
      [fxLayoutAlign]="isMenuOpen ? 'start center' : 'end center'"
      [fxLayoutAlign.gt-sm]="'end center'"
      [gdArea]="'items'"
      class="nib-app-shell-header-items"
    >
      <ng-content select="nib-app-shell-header"></ng-content>
    </div>
  </div>
  <div
    *ngIf="showMenu"
    [gdArea]="isMenuOpen ? 'menu' : null"
    [gdArea.gt-sm]="'menu'"
    class="nib-app-shell-menu-container"
  >
    <ng-content></ng-content>
  </div>
  <main [gdArea]="'main'" class="nib-app-shell-main-container">
    <div
      [gdAreas]="'main-header | main-content'"
      [gdColumns]="'100%'"
      [gdRows]="mainRows"
      [gdRows.gt-xs]="mainRowsGtXs"
      [class.nib-app-shell-main-full-height]="fullHeight"
      [class.nib-app-shell-main-full-width]="fullWidth"
      [class.nib-app-shell-main-with-tabs]="withTabs"
      class="nib-app-shell-main-wrapper"
    >
      <header *ngIf="showTitle" [fxFlexAlign]="'end'">
        <ng-content select="nib-app-shell-main-header"></ng-content>
      </header>
      <ng-content select="nib-app-shell-main"></ng-content>
    </div>
  </main>
</div>
