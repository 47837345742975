import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { AuthStateAction } from '@nibol/auth';
import { LanguageCode, LanguageCodes, TranslationSelectors } from '@nibol/translation';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { BehaviorSubject } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { SignupFormValue } from './signup-form/signup-form-value.type';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nib-signup-route',
  styleUrls: ['signup-route.component.scss'],
  templateUrl: 'signup-route.component.html',
})
export class SignupRouteComponent implements OnInit {
  @Dispatch() registrationTry = (
    firstname: string,
    lastname: string,
    email: string,
    password: string,
    recaptcha: string,
    language: LanguageCode,
    buildingName: string,
  ) =>
    new AuthStateAction.Registration.Try({
      auth: { email, password },
      data: {
        first_name: firstname,
        last_name: lastname,
      },
      recaptcha,
      language: {
        [LanguageCodes['en-gb']]: 'en' as 'en',
        [LanguageCodes['es-es']]: 'es' as 'es',
        [LanguageCodes['fr-fr']]: 'fr' as 'fr',
        [LanguageCodes['it-it']]: 'it' as 'it',
      }[language],
      buildingName,
    });

  @SelectSnapshot(TranslationSelectors.currentLanguage) currentLanguage!: LanguageCode;

  form = new FormGroup({
    registration: new FormControl({
      firstname: '',
      lastname: '',
      language: 'it-it',
      password: '',
      username: '',
    }),
  });
  isSignupFormDisabled$ = new BehaviorSubject(false);

  constructor(
    private readonly actions$: Actions,
    private readonly recaptchaV3Service: ReCaptchaV3Service,
  ) {}

  ngOnInit(): void {
    this.manageSubmit();
  }

  registration(): void {
    if (this.form.valid) {
      this.isSignupFormDisabled$.next(true);

      this.recaptchaV3Service
        .execute('hostSignup')
        .pipe(
          catchError(error => {
            this.isSignupFormDisabled$.next(false);

            throw error;
          }),
          first(),
        )
        .subscribe(recaptcha => {
          const { firstname, lastname, password, username, buildingName } = this.form.value
            .registration as SignupFormValue;

          this.registrationTry(
            firstname,
            lastname,
            username,
            password,
            recaptcha,
            this.currentLanguage,
            buildingName,
          );
        });
    }
  }

  private manageSubmit(): void {
    this.actions$
      .pipe(ofActionSuccessful(AuthStateAction.Registration.Failure), untilDestroyed(this))
      .subscribe(() => {
        this.isSignupFormDisabled$.next(false);
      });
  }
}
