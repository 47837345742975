import { enableProdMode, NgModuleRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { errorHandlerInitialize } from '@nibol/error-handler';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

declare global {
  interface Window {
    ngRef: NgModuleRef<AppModule>;
  }
}

if (!['dev', 'local'].includes(environment.env)) {
  errorHandlerInitialize({
    debug: environment.production,
    env: environment.env,
    name: `nibol-manager-${environment.project}`,
    origin: environment.origin,
    sentryDsn: environment.sentryDsn,
    version: environment.version,
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(ref => {
    if (window.ngRef) {
      window.ngRef.destroy();
    }

    window.ngRef = ref;
  })
  .catch(err => {
    throw new Error(err);
  });
