import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslationModule } from '@nibol/translation';
import {
  ButtonModule,
  CardModule,
  CheckboxModule,
  FormModule,
  IconModule,
  InputModule,
} from '@nibol/ui';
import { DateSlotFormComponent } from './date-slot-form/date-slot-form.component';

import { SpecialClosingDaysFormComponent } from './special-closing-days-form.component';

@NgModule({
  declarations: [DateSlotFormComponent, SpecialClosingDaysFormComponent],
  exports: [SpecialClosingDaysFormComponent],
  imports: [
    ButtonModule,
    CardModule,
    CheckboxModule,
    CommonModule,
    FlexLayoutModule,
    FormModule,
    IconModule,
    InputModule,
    ReactiveFormsModule,
    TranslationModule,
  ],
})
export class SpecialClosingDaysFormModule {}
