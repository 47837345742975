import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { FormValueAccessor } from '@nibol/ui';
import { isOn } from 'feature-toggle-service';
import { LanguageSelectionFormValue } from './language-selection-form-value.type';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: LanguageSelectionFormComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: LanguageSelectionFormComponent,
      multi: true,
    },
  ],
  selector: 'nib-language-selection-form',
  styleUrls: ['language-selection-form.component.scss'],
  templateUrl: 'language-selection-form.component.html',
})
export class LanguageSelectionFormComponent extends FormValueAccessor<LanguageSelectionFormValue> {
  // @todo(heavybeard): get languages from service
  readonly languages = [
    { label: 'Italiano', value: 'it-it', selected: true },
    { label: 'English', value: 'en-gb' },
    ...(isOn('TOGGLE_ENABLE_LANGUAGE_SPANISH') ? [{ label: 'Español', value: 'es-es' }] : []),
    { label: 'Français', value: 'fr-fr' },
  ];

  // @todo(heavybeard): get languages from service
  get defaultLanguageValue(): string {
    const defaultValue = this.languages.find(language => !!language.selected);

    return (defaultValue && defaultValue.value) || '';
  }

  form = new FormGroup({
    language: new FormControl(
      { value: this.defaultLanguageValue, disabled: !isOn('TOGGLE_ENABLE_LANGUAGE_SELECTION') },
      Validators.required,
    ),
  });
}
