import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from '../api/auth.service';
import { AuthStateAction } from './auth.actions';
import { AuthStateModel } from './auth.model';
import { PermissionsState } from './permissions/permissions.state';

@State<AuthStateModel>({
  name: 'auth',
  children: [PermissionsState],
  defaults: { sso: [{ sso: 'password' }], tenant: 'api', token: null },
})
@Injectable()
export class AuthState {
  constructor(private readonly authService: AuthService) {}

  @Action(AuthStateAction.Sso.Change.Try)
  changeSso(
    { dispatch, getState, setState }: StateContext<AuthStateModel>,
    { sso }: AuthStateAction.Sso.Change.Try,
  ) {
    try {
      setState(patch({ sso }));

      dispatch(new AuthStateAction.Tenant.Change.Success(getState()));
    } catch (error) {
      dispatch(new AuthStateAction.Tenant.Change.Failure(error));

      throw error;
    }
  }

  @Action(AuthStateAction.Tenant.Change.Try)
  changeTenant(
    { dispatch, getState, setState }: StateContext<AuthStateModel>,
    { tenant }: AuthStateAction.Tenant.Change.Try,
  ) {
    try {
      setState(patch({ tenant }));

      dispatch(new AuthStateAction.Tenant.Change.Success(getState()));
    } catch (error) {
      dispatch(new AuthStateAction.Tenant.Change.Failure(error));

      throw error;
    }
  }

  @Action(AuthStateAction.CheckIdentity.Try)
  checkIdentity(
    { dispatch, getState, setState }: StateContext<AuthStateModel>,
    { body }: AuthStateAction.CheckIdentity.Try,
  ) {
    return this.authService.checkIdentity(body).pipe(
      catchError(error => {
        dispatch(new AuthStateAction.CheckIdentity.Failure(error));

        throw error;
      }),
      tap(response => {
        setState(patch({ tenant: response.tenant }));

        dispatch(new AuthStateAction.CheckIdentity.Success(getState(), body, response));
      }),
    );
  }

  @Action(AuthStateAction.CreatePassword.Try)
  createPassword(
    { dispatch, getState }: StateContext<AuthStateModel>,
    { body }: AuthStateAction.CreatePassword.Try,
  ) {
    return this.authService.createPassword(body).pipe(
      catchError(error => {
        dispatch(new AuthStateAction.CreatePassword.Failure(error));

        throw error;
      }),
      tap(() => {
        dispatch(new AuthStateAction.CreatePassword.Success(getState()));
      }),
    );
  }

  @Action(AuthStateAction.Login.Try)
  login(
    { dispatch, getState, patchState }: StateContext<AuthStateModel>,
    { body }: AuthStateAction.Login.Try,
  ) {
    return this.authService.login(body).pipe(
      catchError(error => {
        dispatch(new AuthStateAction.Login.Failure(error, body));

        throw error;
      }),
      tap(response => {
        patchState({ token: response.token });

        dispatch(new AuthStateAction.Login.Success(getState(), response));
      }),
    );
  }

  @Action(AuthStateAction.LoginTemporary.Try)
  loginTemporary(
    { dispatch, getState, patchState }: StateContext<AuthStateModel>,
    { body }: AuthStateAction.LoginTemporary.Try,
  ) {
    return this.authService.loginTemporary(body).pipe(
      catchError(error => {
        dispatch(new AuthStateAction.LoginTemporary.Failure(error, body));

        throw error;
      }),
      tap(response => {
        patchState({ token: response.token });

        dispatch(new AuthStateAction.LoginTemporary.Success(getState(), response));
      }),
    );
  }

  @Action(AuthStateAction.Logout.Try)
  logout({ dispatch, getState, setState }: StateContext<AuthStateModel>) {
    try {
      setState({ sso: [{ sso: 'password' }], token: null, tenant: 'api' });

      dispatch(new AuthStateAction.Logout.Success(getState()));
    } catch (error) {
      dispatch(new AuthStateAction.Logout.Failure(error));

      throw error;
    }
  }

  @Action(AuthStateAction.PasswordCriteria.Try)
  passwordCriteria({ dispatch, getState }: StateContext<AuthStateModel>) {
    return this.authService.passwordCriteria().pipe(
      catchError(error => {
        dispatch(new AuthStateAction.PasswordCriteria.Failure(error));

        throw error;
      }),
      tap(() => {
        dispatch(new AuthStateAction.PasswordCriteria.Success(getState()));
      }),
    );
  }

  @Action(AuthStateAction.RecoverPassword.Try)
  recoverPassword(
    { dispatch, getState }: StateContext<AuthStateModel>,
    { body }: AuthStateAction.RecoverPassword.Try,
  ) {
    return this.authService.recoverPassword(body).pipe(
      catchError(error => {
        dispatch(new AuthStateAction.RecoverPassword.Failure(error));

        throw error;
      }),
      tap(() => {
        dispatch(new AuthStateAction.RecoverPassword.Success(getState()));
      }),
    );
  }

  @Action(AuthStateAction.RefreshToken.Try)
  refreshToken({ dispatch, getState, patchState }: StateContext<AuthStateModel>) {
    return this.authService.refreshToken().pipe(
      catchError(error => {
        dispatch(new AuthStateAction.RefreshToken.Failure(error));

        throw error;
      }),
      tap(response => {
        patchState({ token: response.token });

        dispatch(new AuthStateAction.RefreshToken.Success(getState(), response));
      }),
    );
  }

  @Action(AuthStateAction.Registration.Try)
  registration(
    { dispatch, getState, patchState }: StateContext<AuthStateModel>,
    { body }: AuthStateAction.Registration.Try,
  ) {
    return this.authService.registration(body).pipe(
      catchError(error => {
        dispatch(new AuthStateAction.Registration.Failure(error));

        throw error;
      }),
      tap(({ token }) => {
        patchState({ token });

        dispatch(new AuthStateAction.Registration.Success(getState()));
      }),
    );
  }

  @Action(AuthStateAction.ResetPassword.Try)
  resetPassword(
    { dispatch, getState }: StateContext<AuthStateModel>,
    { body }: AuthStateAction.ResetPassword.Try,
  ) {
    return this.authService.resetPassword(body).pipe(
      catchError(error => {
        dispatch(new AuthStateAction.ResetPassword.Failure(error));

        throw error;
      }),
      tap(() => {
        dispatch(new AuthStateAction.ResetPassword.Success(getState()));
      }),
    );
  }

  @Action(AuthStateAction.SupportContacts.Try)
  supportContacts(
    { dispatch, getState }: StateContext<AuthStateModel>,
    { body }: AuthStateAction.SupportContacts.Try,
  ) {
    return this.authService.supportContacts(body).pipe(
      catchError(error => {
        dispatch(new AuthStateAction.SupportContacts.Failure(error));

        throw error;
      }),
      tap(response => {
        dispatch(new AuthStateAction.SupportContacts.Success(getState(), response));
      }),
    );
  }

  @Action(AuthStateAction.TermsAndPrivacyPolicy.Read.Try)
  termsAndPrivacyPolicy({ dispatch, getState }: StateContext<AuthStateModel>) {
    return this.authService.termsAndPrivacyPolicy().pipe(
      catchError(error => {
        dispatch(new AuthStateAction.TermsAndPrivacyPolicy.Read.Failure(error));

        throw error;
      }),
      tap(response => {
        dispatch(new AuthStateAction.TermsAndPrivacyPolicy.Read.Success(getState(), response));
      }),
    );
  }

  @Action(AuthStateAction.TermsAndPrivacyPolicy.Update.Try)
  updateTermsAndPrivacyPolicy(
    { dispatch, getState }: StateContext<AuthStateModel>,
    { body }: AuthStateAction.TermsAndPrivacyPolicy.Update.Try,
  ) {
    return this.authService.updateTermsAndPrivacyPolicy(body).pipe(
      catchError(error => {
        dispatch(new AuthStateAction.TermsAndPrivacyPolicy.Update.Failure(error));

        throw error;
      }),
      tap(response => {
        dispatch(new AuthStateAction.TermsAndPrivacyPolicy.Update.Success(getState(), response));
      }),
    );
  }
}
