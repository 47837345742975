import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { TranslationModule } from '@nibol/translation';
import { InputModule } from '@nibol/ui';
import { AccessDeniedFooterContentComponent } from './access-denied-footer-content/access-denied-footer-content.component';
import { AccessDeniedRouteComponent } from './access-denied-route/access-denied-route.component';
import { AccessDeniedRoutingModule } from './access-denied-routing.module';

@NgModule({
  declarations: [AccessDeniedFooterContentComponent, AccessDeniedRouteComponent],
  imports: [AccessDeniedRoutingModule, CommonModule, FlexModule, InputModule, TranslationModule],
})
export class AccessDeniedModule {}
