<nib-form [formGroup]="form">
  <nib-form-title>
    {{ 'account_language_subtitle' | translate }}
    <nib-chip *featureToggle="'!TOGGLE_ENABLE_LANGUAGE_SELECTION'" [color]="'notice'">
      {{ 'main_badge_soon' | translate }}
    </nib-chip>
  </nib-form-title>
  <nib-form-description>
    {{ 'account_language_body' | translate }}
  </nib-form-description>
  <nib-form-field>
    <nib-select
      [formControlName]="'language'"
      [fullWidth]="true"
      [id]="'language'"
      [label]="'inputs_text_language_label' | translate"
      [options]="languages"
      [required]="true"
    ></nib-select>
  </nib-form-field>
</nib-form>
