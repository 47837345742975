export class TermsAndPrivacyPolicyModel {
  body!: string;
  company!: string;
  flags?: Flag[];
}

export type Flag = {
  required: boolean;
  text: string;
  value: string;
};
