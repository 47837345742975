import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Host,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ButtonComponent } from '../button/public-api';
import { CONNECTION_POSITION_PAIRS } from './floating-panel.helper';

@UntilDestroy()
@Directive({
  exportAs: 'nibFloatingPanelTriggerFor',
  selector: 'nib-button[nibFloatingPanelTriggerFor]',
})
export class FloatingPanelTriggerForDirective implements OnInit, OnDestroy, AfterViewInit {
  @Input() elementToPosition?: HTMLElement;
  @Input() nibFloatingPanelTriggerFor?: TemplateRef<unknown>;
  @Input() positions = [
    CONNECTION_POSITION_PAIRS.bottomLeft,
    CONNECTION_POSITION_PAIRS.topLeft,
    CONNECTION_POSITION_PAIRS.bottomRight,
    CONNECTION_POSITION_PAIRS.topRight,
  ];

  @Output() onPanelClosingWithoutActions = new EventEmitter<void>();

  get elementToPositionRef(): ElementRef<HTMLElement> {
    return this.elementToPosition ? new ElementRef(this.elementToPosition) : this.elementRef;
  }

  protected overlayRef!: OverlayRef;

  constructor(
    @Host() private readonly button: ButtonComponent,
    protected readonly elementRef: ElementRef<HTMLElement>,
    protected readonly overlay: Overlay,
    protected readonly viewContainerRef: ViewContainerRef,
  ) {}

  ngAfterViewInit(): void {
    this.attachOverlay();
  }

  ngOnDestroy(): void {
    this.detachOverlay();
  }

  ngOnInit(): void {
    this.createOverlay();
  }

  // tslint:disable-next-line: no-host-decorator-in-concrete
  @HostListener('document:keydown', ['$event'])
  handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.close();
    }
  }

  close(): void {
    this.overlayRef.detach();
  }

  private attachOverlay(): void {
    this.button.onClick
      .asObservable()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (!this.overlayRef.hasAttached() && this.nibFloatingPanelTriggerFor) {
          this.overlayRef.attach(
            new TemplatePortal(this.nibFloatingPanelTriggerFor, this.viewContainerRef),
          );
        }
      });
  }

  private createOverlay(): void {
    this.overlayRef = this.overlay.create({
      backdropClass: 'nib-floating-panel-backdrop',
      hasBackdrop: true,
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.elementToPositionRef)
        .withPositions(this.positions)
        .withPush(false),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });

    this.overlayRef
      .backdropClick()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.detachOverlay();
        this.onPanelClosingWithoutActions.emit();
      });
  }

  private detachOverlay(): void {
    if (this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }
}
