import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

// tslint:disable-next-line: validate-decorators
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'nib-app-shell-main',
  },
  selector: 'nib-app-shell-main',
  template: ` <ng-content></ng-content> `,
})
export class AppShellMainComponent {}
