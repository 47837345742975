import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

// tslint:disable-next-line: validate-decorators
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'nib-form-title',
  template: '<ng-content></ng-content>',
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    class: 'nib-form-title nib-heading-3',
  },
})
export class FormTitleComponent {}
