import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_ROOT_URL } from '@nibol/auth';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Subscription } from './subscription.type';

@Injectable({ providedIn: 'root' })
export class SubscriptionService {
  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  create(subscription: Subscription.Create.Params): Observable<Subscription.Create.Response> {
    return this.httpClient
      .post<Subscription.Create.Response>(`${this.apiRootUrl}/subscription`, subscription)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }
}
