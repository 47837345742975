import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChange,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { LeafletDirective } from '@asymmetrik/ngx-leaflet';
import { detailedDiff } from 'deep-object-diff';
import {
  CRS,
  divIcon,
  DivIconOptions,
  DragEndEvent,
  Draw,
  DrawMap,
  imageOverlay,
  LatLng,
  latLngBounds,
  LayerEvent,
  LeafletEvent,
  LeafletMouseEvent,
  Map,
  MapOptions,
  Marker,
  marker,
  Point,
  polygon,
  Polygon,
} from 'leaflet';
import {
  getFirstPolygonTemplateElementMutationObserver,
  mapMarkersToObject,
  mapPolygonsToObject,
  syncPathElementToFirstPolygonTemplateElement,
} from './map.helper';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'nib-map' },
  selector: 'nib-map',
  styleUrls: ['map.component.scss'],
  templateUrl: 'map.component.html',
})
export class MapComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input()
  set activateDraw(value: boolean) {
    if (value) {
      this.drawPolygon?.enable();
    } else {
      this.drawPolygon?.disable();
    }
  }

  /** Whether the map should disable click. */
  @Input() disableClick = false;

  /** Height of the image. */
  @Input() height!: number;

  /** Image to use as map. */
  @Input() image!: string;

  /** List of markers to show on map. */
  @Input() markers: Array<Point & unknown> = [];

  /** Template to use as marker element. */
  @Input() markerTemplate?: TemplateRef<this['markers'][number]>;

  /** List of polygons to show on map. */
  @Input() polygons: Array<{ [key: string]: unknown; points: Point[] }> = [];

  /** Template to use as polygon element. */
  @Input() polygonTemplate?: TemplateRef<this['polygons'][number]>;

  /** Width of the image. */
  @Input() width!: number;

  /** Draw on map created */
  @Output() onDrawCreated = new EventEmitter<Point[]>();

  /** Click on map emitter. */
  @Output() onMapClick = new EventEmitter<Point>();

  /** Double click on map emitter. */
  @Output() onMapDoubleClick = new EventEmitter<Point>();

  /** Map ready emitter. */
  @Output() onMapReady = new EventEmitter<Map>();

  /** Click on marker emitter. */
  @Output() onMarkerClick = new EventEmitter<{
    point: Point;
    marker?: MapComponent['markers'][number];
  }>();

  /** Drag ending on marker emitter. */
  @Output() onMarkerDragEnd = new EventEmitter<{
    point: Point;
    marker?: MapComponent['markers'][number];
  }>();

  /** Click on polygon emitter. */
  @Output() onPolygonClick = new EventEmitter<{
    point: Point;
    polygon?: MapComponent['polygons'][number];
  }>();

  /** Leaflet map coontainer */
  @ViewChild(LeafletDirective) leaflet!: LeafletDirective;

  readonly leafletOptions: MapOptions = {
    drawControlTooltips: false,
    attributionControl: false,
    crs: CRS.Simple,
    maxZoom: 2,
    minZoom: -2,
    zoom: 0,
    zoomControl: false,
    zoomDelta: 0.25,
  };

  private drawPolygon?: Draw.Polygon;
  private readonly markersInMap: { [key: string]: Marker | undefined } = {};
  private readonly polygonsInMap: { [key: string]: Polygon | undefined } = {};

  private readonly linkActiveMutationObservers: MutationObserver[] = [];

  ngAfterViewInit(): void {
    window.dispatchEvent(new Event('resize'));

    this.initializeMap();
    this.addInitialMarkersToMap();
    this.addInitialPolygonsToMap();
  }

  ngOnChanges(changes: { markers?: SimpleChange; polygons?: SimpleChange }): void {
    if (typeof changes.markers !== 'undefined' && !changes.markers.firstChange) {
      this.updateMarkersInMap(changes.markers);
    }

    if (typeof changes.polygons !== 'undefined' && !changes.polygons.firstChange) {
      this.updatePolygonsInMap(changes.polygons);
    }
  }

  ngOnDestroy(): void {
    this.linkActiveMutationObservers.forEach(linkActiveMutationObserver => {
      linkActiveMutationObserver.disconnect();
    });
  }

  handleOnDrawCreated(event: { layer: Polygon }) {
    this.onDrawCreated.emit(
      (event.layer.getLatLngs()[0] as LatLng[]).map(latLng =>
        this.leaflet.map.project(latLng, this.leaflet.map.getMaxZoom() - 1),
      ),
    );
  }

  handleOnMapClick(event: LeafletMouseEvent) {
    if (!this.disableClick) {
      this.onMapClick.emit(
        this.leaflet.map.project(event.latlng, this.leaflet.map.getMaxZoom() - 1),
      );
    }
  }

  handleOnMapDoubleClick(event: LeafletMouseEvent) {
    this.onMapDoubleClick.emit(
      this.leaflet.map.project(event.latlng, this.leaflet.map.getMaxZoom() - 1),
    );
  }

  private addInitialMarkersToMap(): void {
    this.markers.forEach(markerInput => {
      this.markersInMap[`${markerInput.x}.${markerInput.y}`] = this.createMarker(markerInput);
    });
  }

  private addInitialPolygonsToMap(): void {
    this.polygons.forEach(polygonInMap => {
      this.polygonsInMap[
        `${polygonInMap.points[0].x}.${polygonInMap.points[0].y}`
      ] = this.createPolygon(polygonInMap);
    });
  }

  private createMarker(point: MapComponent['markers'][number]): Marker {
    return marker(
      this.leaflet.map.unproject([point.x, point.y], this.leaflet.map.getMaxZoom() - 1),
      {
        icon: divIcon({ html: this.generateMarkerTemplate(point) }),
        keyboard: false,
        draggable: true,
      },
    )
      .on('click', (event: LeafletMouseEvent) => {
        this.onMarkerClick.emit({
          marker: this.markers.find(
            markerFromInput => markerFromInput.x === point.x && markerFromInput.y === point.y,
          ),
          point: this.leaflet.map.project(event.latlng, this.leaflet.map.getMaxZoom() - 1),
        });
      })
      .on('dragstart', (event: LeafletEvent) => {
        (event.target.getElement() as HTMLElement).classList.add('nib-map-marker-dragging');
      })
      .on('dragend', (event: DragEndEvent) => {
        (event.target.getElement() as HTMLElement).classList.remove('nib-map-marker-dragging');

        this.onMarkerDragEnd.emit({
          marker: this.markers.find(
            markerFromInput => markerFromInput.x === point.x && markerFromInput.y === point.y,
          ),
          point: this.leaflet.map.project(
            event.target.getLatLng(),
            this.leaflet.map.getMaxZoom() - 1,
          ),
        });
      })
      .addTo(this.leaflet.map);
  }

  private createPolygon(polygonToCreate: MapComponent['polygons'][number]): Polygon {
    return polygon(
      polygonToCreate.points.map(point =>
        this.leaflet.map.unproject([point.x, point.y], this.leaflet.map.getMaxZoom() - 1),
      ),
    )
      .once('add', (event: LayerEvent) => {
        const pathElement = event.target._path as SVGPathElement;
        const polygonTemplateElement = this.generatePolygonTemplate(polygonToCreate);

        if (polygonTemplateElement) {
          pathElement?.appendChild(polygonTemplateElement);

          syncPathElementToFirstPolygonTemplateElement(pathElement, polygonTemplateElement);

          this.linkActiveMutationObservers.push(
            getFirstPolygonTemplateElementMutationObserver(pathElement, polygonTemplateElement),
          );
        }
      })
      .on('click', (event: LeafletMouseEvent) => {
        this.onPolygonClick.emit({
          polygon: this.polygons.find(
            polygonFromInput =>
              polygonFromInput.points[0].x === polygonToCreate.points[0].x &&
              polygonFromInput.points[0].y === polygonToCreate.points[0].y,
          ),
          point: this.leaflet.map.project(event.latlng, this.leaflet.map.getMaxZoom() - 1),
        });
      })
      .addTo(this.leaflet.map);
  }

  private generateMarkerTemplate(context: this['markers'][number]): DivIconOptions['html'] {
    if (this.markerTemplate) {
      const embeddedView = this.markerTemplate.createEmbeddedView(context);
      embeddedView.detectChanges();

      return embeddedView.rootNodes[0];
    } else {
      return undefined;
    }
  }

  private generatePolygonTemplate(context: this['polygons'][number]): HTMLElement | undefined {
    if (this.polygonTemplate) {
      const embeddedView = this.polygonTemplate.createEmbeddedView(context);
      embeddedView.detectChanges();

      return embeddedView.rootNodes[0];
    } else {
      return undefined;
    }
  }

  private initializeMap(): void {
    const bounds = latLngBounds(
      this.leaflet.map.unproject([0, this.height], this.leaflet.map.getMaxZoom() - 1),
      this.leaflet.map.unproject([this.width, 0], this.leaflet.map.getMaxZoom() - 1),
    );

    this.leaflet.map.setMaxBounds(bounds);
    this.leaflet.map.panTo(bounds.getCenter());

    imageOverlay(this.image, bounds).addTo(this.leaflet.map);

    this.drawPolygon = new Draw.Polygon(this.leaflet.map as DrawMap, {
      allowIntersection: false,
      shapeOptions: { color: 'var(--color-semantic-positive)', className: 'nib-map-polygon' },
    });

    this.onMapReady.emit(this.leaflet.map);
  }

  private updateMarkersInMap({ previousValue, currentValue }: SimpleChange): void {
    const currentValueObject = mapMarkersToObject(currentValue);
    const previousValueObject = mapMarkersToObject(previousValue);
    const { added, deleted, updated } = detailedDiff(previousValueObject, currentValueObject) as {
      [key in 'added' | 'deleted' | 'updated']: { [key: string]: undefined };
    };

    Object.keys(added).forEach(id => {
      this.markersInMap[id] = this.createMarker(currentValueObject[id]);
    });

    Object.keys(deleted).forEach(id => {
      this.markersInMap[id]?.remove();
    });

    Object.keys(updated).forEach(id => {
      this.markersInMap[id]?.setIcon(
        divIcon({ html: this.generateMarkerTemplate(currentValueObject[id]) }),
      );
    });
  }

  private updatePolygonsInMap({ previousValue, currentValue }: SimpleChange): void {
    const currentValueObject = mapPolygonsToObject(currentValue);
    const previousValueObject = mapPolygonsToObject(previousValue);
    const { added, deleted, updated } = detailedDiff(previousValueObject, currentValueObject) as {
      [key in 'added' | 'deleted' | 'updated']: { [key: string]: undefined };
    };

    Object.keys(added).forEach(id => {
      this.polygonsInMap[id] = this.createPolygon(currentValueObject[id]);
    });

    Object.keys(deleted).forEach(id => {
      this.polygonsInMap[id]?.remove();
    });

    Object.keys(updated).forEach(id => {
      this.polygonsInMap[id]?.remove();
      this.polygonsInMap[id] = this.createPolygon(currentValueObject[id]);
    });
  }
}
