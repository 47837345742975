import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { NgxRolesService } from 'ngx-permissions';
import { PermissionsStateAction } from './permissions.actions';

@State<string[]>({
  name: 'permissions',
  defaults: [],
})
@Injectable()
export class PermissionsState<Permissions extends string[]> {
  constructor(private readonly ngxRolesService: NgxRolesService) {}

  @Action(PermissionsStateAction.List.Add.Try)
  async addPermissionsToList(
    { dispatch, getState }: StateContext<Permissions>,
    { roles }: PermissionsStateAction.List.Add.Try<Permissions>,
  ) {
    try {
      await dispatch(
        new PermissionsStateAction.List.Change.Try([...getState(), ...roles]),
      ).toPromise();

      dispatch(new PermissionsStateAction.List.Add.Success(getState()));
    } catch (error) {
      dispatch(new PermissionsStateAction.List.Add.Failure(error));

      throw error;
    }
  }

  @Action(PermissionsStateAction.List.Change.Try)
  changePermissionsList(
    { dispatch, getState, setState }: StateContext<Permissions>,
    { roles }: PermissionsStateAction.List.Change.Try<Permissions>,
  ) {
    try {
      setState(roles.filter((role, index) => roles.indexOf(role) === index) as Permissions);

      this.ngxRolesService.addRoles(
        roles.reduce((previous, current) => {
          previous[current] = () => roles.includes(current);

          return previous;
        }, ({} as unknown) as { [key: string]: () => boolean }),
      );

      dispatch(new PermissionsStateAction.List.Change.Success(getState()));
    } catch (error) {
      dispatch(new PermissionsStateAction.List.Change.Failure(error));

      throw error;
    }
  }

  @Action(PermissionsStateAction.List.Remove.Try)
  async removePermissionsToList(
    { dispatch, getState }: StateContext<Permissions>,
    { roles }: PermissionsStateAction.List.Remove.Try<Permissions>,
  ) {
    try {
      await dispatch(
        new PermissionsStateAction.List.Change.Try(
          getState().filter(role => roles.indexOf(role) < 0),
        ),
      ).toPromise();

      dispatch(new PermissionsStateAction.List.Remove.Success(getState()));
    } catch (error) {
      dispatch(new PermissionsStateAction.List.Remove.Failure(error));

      throw error;
    }
  }
}
