// tslint:disable: no-namespace

export namespace Auth {
  export namespace CheckIdentity {
    export type Body = {
      method: 'apple' | 'azure' | 'email' | 'facebook' | 'google' | 'okta';
      token: string;
    };
    export type Response = {
      platform: 'mkt' | 'bus';
      registration_statuses: RegistrationStatus[];
      tenant: string;
      active_sso?: Array<'apple' | 'azure' | 'facebook' | 'google' | 'okta' | 'password'>;
      company?: {
        name: string;
        info: { domain: string; logo: string };
        settings: { login_token_valid_time: number };
      };
      last_password_change?: string;
      login_link_sso?: {
        apple?: string;
        azure?: string;
        facebook?: string;
        google?: string;
        okta?: string;
      };
    };
  }

  export namespace CreatePassword {
    export type Body = {
      new_password: string;
    };
    export type Response = {
      status: boolean;
    };
  }

  export namespace Login {
    export type Body = {
      email: string;
      keep: boolean;
      method: 'azure' | 'facebook' | 'google' | 'okta' | 'password';
      token: string;
    };
    export type Response = {
      company_policy: boolean;
      keep: boolean;
      need_password_change: boolean;
      terms_privacy: boolean;
      token: string;
    };
  }

  export namespace LoginTemporary {
    export type Body = {
      code: string;
    };
    export type Response = Login.Response;
  }

  export namespace PasswordCriteria {
    export type Body = {};
    export type Response = {
      count: number;
      key: string;
      pattern: string;
    };
  }

  export namespace RecoverPassword {
    export type Body = {
      email: string;
    };
    export type Response = {
      status: boolean;
    };
  }

  export namespace RefreshToken {
    export type Body = {};
    export type Response = {
      company_policy: boolean;
      keep: boolean;
      need_password_change: boolean;
      terms_privacy: boolean;
      token: string;
    };
  }

  export namespace Registration {
    export type Body = {
      auth: { email: string; password: string };
      data: { first_name: string; last_name: string };
      language: 'en' | 'es' | 'fr' | 'it';
      recaptcha: string;
      buildingName: string;
    };
    export type Response = {
      status: boolean;
      token: string;
      user_id: string;
    };
  }

  export namespace ResetPassword {
    export type Body = {
      new_password: string;
      token: string;
    };
    export type Response = {
      status: boolean;
    };
  }

  export namespace TermsAndPrivacyPolicy {
    export namespace Read {
      export type Body = {};
      export type Response = {
        body: string;
        company: string;
        flags: Array<{ required: boolean; text: string; value: string }>;
      };
    }
    export namespace Update {
      export type Body = { flags: Array<Read.Response['flags'][number]['text']> };
      export type Response = {
        status: boolean;
      };
    }
  }

  export namespace SupportContacts {
    export type Body = {
      email: string;
    };
    export type Response = Array<{
      name: string;
      support_contacts: string;
    }>;
  }
}

export type RegistrationStatus = keyof typeof RegistrationStatuses;

export const enum RegistrationStatuses {
  not_registered = 'not_registered',
  not_invited = 'not_invited',
  bus_employee = 'bus_employee',
  bus_manager = 'bus_manager',
  mkt_user = 'mkt_user',
  mkt_manager = 'mkt_manager',
  password = 'password',
  sso = 'sso',
}
