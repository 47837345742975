import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxFeatureToggleCanActivateGuard } from 'ngx-feature-toggle';
import { RecoverPasswordRouteComponent } from './recover-password-route/recover-password-route.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        // root is '/recover-password'
        path: '',
        component: RecoverPasswordRouteComponent,
        canActivate: [NgxFeatureToggleCanActivateGuard],
        data: {
          featureToggle: ['TOGGLE_ENABLE_RESET_PASSWORD'],
          title: 'recoverpassword_title',
        },
      },
    ]),
  ],
})
export class RecoverPasswordRoutingModule {}
