import { User } from '@marketplace/api';
import { BuildingStateModel } from '../building';
import { VenueStateModel } from '../venue/venue.model';

export function mapAssociatedBuildingToApi(
  building: Pick<BuildingStateModel, 'name'>,
): User.AssociatedVenueAndBuilding.Create.Params['building'] {
  return building.name;
}

export function mapAssociatedVenueToApi(
  venue: Pick<VenueStateModel, 'address' | 'coordinates' | 'mode' | 'name' | 'type'>,
): User.AssociatedVenueAndBuilding.Create.Params['venue'] {
  return {
    mode: venue.mode,
    name: venue.name,
    position: {
      address: venue.address,
      coordinates: {
        lat: venue.coordinates?.lat,
        lng: venue.coordinates?.lng,
      },
    },
    type: venue.type,
  };
}
