import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslationModule } from '@nibol/translation';
import { CheckboxModule, FormModule } from '@nibol/ui';
import { WeekdaysFormGroupComponent } from './weekdays-form-group.component';

@NgModule({
  declarations: [WeekdaysFormGroupComponent],
  exports: [WeekdaysFormGroupComponent],
  imports: [CheckboxModule, CommonModule, FormModule, ReactiveFormsModule, TranslationModule],
})
export class WeekdaysFormGroupModule {}
