import { Breakpoint, BreakpointAlias } from './breakpoint.type';

/** List of breakpoints */
const bp: { [alias in BreakpointAlias]: number } = {
  xs: 480,
  sm: 768,
  md: 1200,
  lg: 1920,
  xl: 9999,
};

/**
 * Breakpoints to override to default flex-layout ones
 */
export const NIBOL_BREAKPOINTS: Breakpoint[] = [
  {
    alias: 'xs',
    mediaQuery: `screen and (min-width: 0) and (max-width: ${bp.xs - 0.1}px)`,
    priority: 1000,
  },
  {
    alias: 'sm',
    mediaQuery: `screen and (min-width: ${bp.xs}px) and (max-width: ${bp.sm - 0.1}px)`,
    priority: 900,
  },
  {
    alias: 'md',
    mediaQuery: `screen and (min-width: ${bp.sm}px) and (max-width: ${bp.md - 0.1}px)`,
    priority: 800,
  },
  {
    alias: 'lg',
    mediaQuery: `screen and (min-width: ${bp.md}px) and (max-width: ${bp.lg - 0.1}px)`,
    priority: 700,
  },
  {
    alias: 'xl',
    mediaQuery: `screen and (min-width: ${bp.lg}px) and (max-width: 4999.99px)`,
    priority: 600,
  },
  {
    alias: 'lt-sm',
    overlapping: true,
    mediaQuery: `screen and (max-width: ${bp.xs - 0.1}px)`,
    priority: 950,
  },
  {
    alias: 'lt-md',
    overlapping: true,
    mediaQuery: `screen and (max-width: ${bp.sm - 0.1}px)`,
    priority: 850,
  },
  {
    alias: 'lt-lg',
    overlapping: true,
    mediaQuery: `screen and (max-width: ${bp.md - 0.1}px)`,
    priority: 750,
  },
  {
    alias: 'lt-xl',
    overlapping: true,
    priority: 650,
    mediaQuery: `screen and (max-width: ${bp.lg - 0.1}px)`,
  },
  {
    alias: 'gt-xs',
    overlapping: true,
    mediaQuery: `screen and (min-width: ${bp.xs}px)`,
    priority: -950,
  },
  {
    alias: 'gt-sm',
    overlapping: true,
    mediaQuery: `screen and (min-width: ${bp.sm}px)`,
    priority: -850,
  },
  {
    alias: 'gt-md',
    overlapping: true,
    mediaQuery: `screen and (min-width: ${bp.md}px)`,
    priority: -750,
  },
  {
    alias: 'gt-lg',
    overlapping: true,
    mediaQuery: `screen and (min-width: ${bp.lg}px)`,
    priority: -650,
  },
];
