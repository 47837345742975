<div [fxLayout]="'row-reverse'" [fxLayout.gt-sm]="'row'" [fxLayoutAlign]="'space-between center'">
  <ng-content></ng-content>
  <div [class.nib-app-status-resume-with-avatar]="!!avatar" class="nib-app-status-resume">
    <span class="nib-app-status-resume-building-name nib-heading-3">{{ scope }}</span>
    <a
      [routerLink]="'/account'"
      (click)="closeMenu()"
      class="nib-app-status-resume-user-name nib-heading-5 nib-link"
    >
      <ng-container *ngIf="user; else noUsername">{{ user }}</ng-container>
      <ng-template #noUsername>{{ 'account_account_title' | translate }}</ng-template>
    </a>
  </div>
  <nib-avatar
    *ngIf="avatar"
    [image]="avatar"
    [size]="32"
    class="nib-app-status-resume-avatar"
  ></nib-avatar>
</div>
