import { Building } from '@marketplace/api/building';
import { DeepPartial } from '@nibol/shared';
import { CompanyStateModel } from './company.model';

export function mapCompanyToClient(
  company: DeepPartial<Building.Company.Read.Response>,
): DeepPartial<CompanyStateModel> {
  return {
    address: company.info?.address,
    billingEmail: company.info?.billing_email,
    city: company.info?.city,
    country: company.info?.country,
    einvoice: company.info?.einvoice,
    extra: company.info?.extra,
    iban: company.iban,
    name: company.info?.name,
    pec: company.info?.pec,
    postal: company.info?.postal,
    state: company.info?.state,
    vat: company.info?.vat,
  };
}

export function mapCompanyToApi(
  company: DeepPartial<CompanyStateModel>,
): DeepPartial<Pick<Building.Company.Read.Response, 'iban' | 'info'>> {
  return {
    iban: company.iban,
    info: {
      address: company.address,
      billing_email: company.billingEmail,
      city: company.city,
      country: company.country,
      einvoice: company.einvoice,
      extra: company.extra,
      name: company.name,
      pec: company.pec,
      postal: company.postal,
      state: company.state,
      vat: company.vat,
    },
  };
}
