import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DemoRouteComponent } from './demo-route/demo-route.component';
import { EnableFeaturesRouteComponent } from './enable-features-route/enable-features-route.component';

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forChild([
      {
        // root is 'admin'
        path: '',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'demo',
          },
          {
            path: 'demo',
            component: DemoRouteComponent,
            data: {
              breadcrumbs: [{ name: 'main_sidebar_admin', path: '/admin' }],
              title: 'demo_title',
            },
          },
          {
            path: 'enable-features',
            component: EnableFeaturesRouteComponent,
            data: {
              breadcrumbs: [{ name: 'main_sidebar_admin', path: '/admin' }],
              title: 'toggle_title',
            },
          },
        ],
      },
    ]),
  ],
})
export class AdminRoutingModule {}
