import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivationStart, Router, RouterOutlet } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nib-app-shell-public',
  styleUrls: ['app-shell-public.component.scss'],
  templateUrl: 'app-shell-public.component.html',
})
export class AppShellPublicComponent implements OnInit {
  @Input() logo?: string;

  @ViewChild('footerContentOutlet', { read: RouterOutlet }) footerContentOutlet?: RouterOutlet;

  constructor(private readonly router: Router) {}

  ngOnInit(): void {
    this.manageFooterContentDeactivation();
  }

  private manageFooterContentDeactivation(): void {
    this.router.events
      .pipe(
        filter(
          event => event instanceof ActivationStart && event.snapshot.outlet === 'footer-content',
        ),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.footerContentOutlet?.deactivate();
      });
  }
}
