import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { of } from 'rxjs';
import { catchError, delay as delayOperator, tap } from 'rxjs/operators';
import { AppStateAction } from './app.actions';
import { AppStateModel } from './app.model';
import { Clients, States } from './app.type';

/** @dynamic */
@State<AppStateModel>({
  name: 'app',
  defaults: {
    client: Clients.man,
    notifications: true,
    state: States['in-progress'],
    // @todo(heavybeard): get default support contact from init config
    supportContacts: [{ contacts: 'business@nibol.com', name: 'Nibol' }],
  },
})
@Injectable()
export class AppState {
  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  @Action(AppStateAction.Client.Change.Try)
  changeClient(
    { dispatch, getState, setState }: StateContext<AppStateModel>,
    { client }: AppStateAction.Client.Change.Try,
  ) {
    return of(client).pipe(
      catchError(error => {
        dispatch(new AppStateAction.Client.Change.Failure(error));

        throw error;
      }),
      tap(() => {
        setState(patch({ client }));

        dispatch(new AppStateAction.Client.Change.Success(getState()));
      }),
    );
  }

  @Action(AppStateAction.Notifications.Change.Try)
  changeNotifications(
    { dispatch, getState, setState }: StateContext<AppStateModel>,
    { notifications }: AppStateAction.Notifications.Change.Try,
  ) {
    return of(notifications).pipe(
      catchError(error => {
        dispatch(new AppStateAction.Notifications.Change.Failure(error));

        throw error;
      }),
      tap(() => {
        setState(patch({ notifications }));

        dispatch(new AppStateAction.Notifications.Change.Success(getState()));
      }),
    );
  }

  @Action(AppStateAction.Platform.Change.Try)
  changePlatform(
    { dispatch, getState, setState }: StateContext<AppStateModel>,
    { platform }: AppStateAction.Platform.Change.Try,
  ) {
    return of(platform).pipe(
      catchError(error => {
        dispatch(new AppStateAction.Platform.Change.Failure(error));

        throw error;
      }),
      tap(() => {
        setState(patch({ platform }));

        dispatch(new AppStateAction.Platform.Change.Success(getState()));
      }),
    );
  }

  @Action(AppStateAction.State.Change.Try)
  changeState(
    { dispatch, getState, setState }: StateContext<AppStateModel>,
    { state, delay }: AppStateAction.State.Change.Try,
  ) {
    return of(state).pipe(
      delayOperator(delay),
      catchError(error => {
        dispatch(new AppStateAction.State.Change.Failure(error));

        throw error;
      }),
      tap(() => {
        setState(patch({ state }));

        dispatch(new AppStateAction.State.Change.Success(getState()));

        if (state === 'ready') {
          dispatch(new AppStateAction.State.Ready.Success(getState()));
        }
      }),
    );
  }

  @Action(AppStateAction.SupportContacts.Change.Try)
  changeSupportContacts(
    { dispatch, getState, setState }: StateContext<AppStateModel>,
    { supportContacts }: AppStateAction.SupportContacts.Change.Try,
  ) {
    return of(supportContacts).pipe(
      catchError(error => {
        dispatch(new AppStateAction.SupportContacts.Change.Failure(error));

        throw error;
      }),
      tap(() => {
        setState(patch({ supportContacts }));

        dispatch(new AppStateAction.SupportContacts.Change.Success(getState()));
      }),
    );
  }

  @Action(AppStateAction.Init.Try)
  init({ dispatch, getState }: StateContext<AppStateModel>) {
    try {
      dispatch(new AppStateAction.Init.Success(getState()));
    } catch (error) {
      dispatch(new AppStateAction.Init.Failure(error));
    }
  }

  @Action(AppStateAction.ScrollTop.Try)
  scrollTop({ dispatch, getState }: StateContext<AppStateModel>) {
    try {
      const appShellMainContainer = this.document
        .getElementsByClassName('nib-app-shell-main-container')
        .item(0);

      if (appShellMainContainer) {
        appShellMainContainer.scrollTop = 0;
      }

      dispatch(new AppStateAction.ScrollTop.Success(getState()));
    } catch (error) {
      dispatch(new AppStateAction.ScrollTop.Failure(error));

      throw error;
    }
  }
}
