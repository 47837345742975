import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_ROOT_URL } from '@nibol/auth';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Photo } from './photo.type';

@Injectable({ providedIn: 'root' })
export class PhotoService {
  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  upload(uploadData: Photo.Upload.Params): Observable<Photo.Upload.Response> {
    return this.httpClient
      .post<Photo.Upload.Response>(`${this.apiRootUrl}/venue/pictures/upload`, uploadData)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }
}
