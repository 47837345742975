import { Component, Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { TranslationService } from '@nibol/translation';
import { FormSaverSnackbarService } from './form-saver-snackbar.service';

@Injectable({ providedIn: 'root' })
export class FormSaverSnackbarCanDeactivateGuard implements CanDeactivate<Component> {
  constructor(
    private readonly formSaverSnackbarService: FormSaverSnackbarService,
    private readonly translationService: TranslationService,
  ) {}

  canDeactivate(): boolean {
    if (this.formSaverSnackbarService.form.dirty) {
      return confirm(this.translationService.translate('main_cancelconfirmation_text'));
    }

    return true;
  }
}
