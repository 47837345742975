import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { TableComponent } from '../table.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  // tslint:disable-next-line: no-host-metadata-property
  host: { class: 'nib-table' },
  selector: 'nib-table-virtual-scroll',
  styleUrls: ['../table.component.scss'],
  templateUrl: 'table-virtual-scroll.component.html',
})
export class TableVirtualScrollComponent<T> extends TableComponent<T> {
  @Input() dataSource!: TableVirtualScrollDataSource<T>;
  @Input() itemSize?: number;

  @ViewChild(CdkVirtualScrollViewport) virtualScrollViewport?: CdkVirtualScrollViewport;
}
