import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

// tslint:disable-next-line: validate-decorators
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'nib-form-divider',
  template: '',
})
export class FormDividerComponent {}
