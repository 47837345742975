import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { IconConfig } from './icon-config.type';
import { ICONS_ROOT_URL_LOCATION } from './icon-root-url-location.token';
import { IconComponent } from './icon.component';

@NgModule({
  declarations: [IconComponent],
  imports: [HttpClientModule, InlineSVGModule.forRoot()],
  exports: [IconComponent],
})
export class IconModule {
  static forRoot(config: IconConfig): ModuleWithProviders<IconModule> {
    return {
      ngModule: IconModule,
      providers: [
        {
          provide: ICONS_ROOT_URL_LOCATION,
          useValue: config.iconsRootUrlLocation,
        },
      ],
    };
  }
}
