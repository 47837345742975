import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { DatalistType } from './datalist-type.type';

// tslint:disable-next-line: validate-decorators
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'nib-datalist',
  template: `
    <datalist *ngIf="timeSteps" [ngSwitch]="type" [id]="name">
      <ng-container *ngSwitchCase="'time'">
        <ng-container *ngFor="let hour of hours">
          <option
            *ngFor="let minute of minutes; index as index"
            [value]="hour + ':' + minute"
          ></option>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <ng-content></ng-content>
      </ng-container>
    </datalist>
  `,
})
export class DatalistComponent {
  /** Id of datalist. */
  @Input() name!: string;

  /** Default list type. */
  @Input() type: DatalistType = '';

  /** Time steps to use in minutes. */
  @Input() timeSteps = 15;

  /** Existing hours. */
  readonly hours = Array.apply(null, Array(24)).map((_value, index) => `0${index}`.slice(-2));

  get minutes() {
    return Array.apply(null, Array(60 / this.timeSteps)).map((_value, index) =>
      `0${this.timeSteps * index}`.slice(-2),
    );
  }
}
