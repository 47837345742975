import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'nib-step-actions',
  styleUrls: ['step-actions.component.scss'],
  templateUrl: 'step-actions.component.html',
})
export class StepActionsComponent {
  @Input() disableNext = false;

  @Output() onBack = new EventEmitter<Event>();
  @Output() onNext = new EventEmitter<Event>();

  get layoutAlign(): string {
    return this.showBack ? 'space-between center' : 'end center';
  }

  get showBack(): boolean {
    return this.onBack.observers.length > 0;
  }

  back(event: Event): void {
    this.onBack.emit(event);
  }

  next(event: Event): void {
    this.onNext.emit(event);
  }
}
