import { CompanyStateModel } from '../company/company.model';
import { ReservationStateModel } from '../reservations/reservations.model';
import { VenueStateModel } from '../venue';

export class BuildingStateModel {
  id!: string;
  name!: string;
  status!: VenueStateModel['status'];
  commission?: number;
  company?: CompanyStateModel;
  leadFee?: number;
  reservations?: ReservationStateModel[];
  venues?: VenueStateModel[];
}
