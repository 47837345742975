<p class="nib-signup-footer-content-login">
  {{ 'signup_login_text' | translate }}
  <a [routerLink]="'/login'">{{ 'login_title' | translate }}</a>
</p>
<div class="nib-signup-footer-content-talk-about-us nib-heading-5">
  {{ 'signup_talkaboutus_text' | translate }}
  <div [fxLayout]="'row'" [fxLayoutAlign]="'space-between center'" [fxLayoutGap]="'16px'">
    <div
      class="nib-signup-footer-content-logo"
      [inlineSVG]="'https://cdn.nibol.co/web/logos/corriere-della-sera.svg'"
    ></div>
    <div
      class="nib-signup-footer-content-logo"
      [inlineSVG]="'https://cdn.nibol.co/web/logos/il-sole-24-ore.svg'"
    ></div>
    <div
      class="nib-signup-footer-content-logo"
      [inlineSVG]="'https://cdn.nibol.co/web/logos/rai.svg'"
    ></div>
    <div
      class="nib-signup-footer-content-logo"
      [inlineSVG]="'https://cdn.nibol.co/web/logos/sky-tg24.svg'"
    ></div>
  </div>
</div>
<p
  *featureToggle="'TOGGLE_ENABLE_SUPPORT'"
  [innerHTML]="'signup_support_text' | translate"
  class="nib-signup-footer-content-support nib-heading-5"
></p>
<p class="nib-signup-footer-content-company nib-heading-5">
  {{ 'signup_footer_text_1' | translate }}<br />
  {{ 'signup_footer_text_2' | translate }}
</p>
