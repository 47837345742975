import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_ROOT_URL } from '@nibol/auth';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Building } from './building.type';

@Injectable({ providedIn: 'root' })
export class BuildingService {
  constructor(
    @Inject(API_ROOT_URL) private readonly apiRootUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  createVenue({
    id,
    data: { mode, name },
  }: Building.Venues.Create.Params): Observable<Building.Venues.Create.Response> {
    return this.httpClient
      .post<Building.Venues.Create.Response>(`${this.apiRootUrl}/building/venues/add`, {
        id,
        venue: { mode, name },
      })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  read({ id }: Building.Item.Read.Params): Observable<Building.Item.Read.Response> {
    return this.httpClient
      .get<Building.Item.Read.Response>(`${this.apiRootUrl}/building/info`, { params: { id } })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  readCompany({
    building,
  }: Building.Company.Read.Params): Observable<Building.Company.Read.Response> {
    return this.httpClient
      .get<Building.Company.Read.Response>(`${this.apiRootUrl}/company`, { params: { building } })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  readList(): Observable<Building.List.Read.Response> {
    return this.httpClient
      .get<Building.List.Read.Response>(`${this.apiRootUrl}/building/list`)
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  readReservations({
    building,
    fromDay,
    toDay,
  }: Building.Reservations.Read.Params): Observable<Building.Reservations.Read.Response> {
    return this.httpClient.get<Building.Reservations.Read.Response>(
      `${this.apiRootUrl}/building/reservations`,
      { params: { ...(toDay && { toDay }), building, fromDay } },
    );
  }

  readVenues({ id }: Building.Venues.Read.Params): Observable<Building.Venues.Read.Response> {
    return this.httpClient
      .get<Building.Venues.Read.Response>(`${this.apiRootUrl}/building/venues`, { params: { id } })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  update({ data, id }: Building.Item.Update.Params): Observable<Building.Item.Update.Response> {
    return this.httpClient
      .put<Building.Item.Update.Response>(`${this.apiRootUrl}/building/info`, { ...data, id })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }

  updateCompany({
    building,
    data,
  }: Building.Company.Update.Params): Observable<Building.Company.Update.Response> {
    return this.httpClient
      .put<Building.Company.Update.Response>(`${this.apiRootUrl}/company`, { ...data, building })
      .pipe(
        catchError(error => {
          throw error;
        }),
      );
  }
}
