import { Injectable } from '@angular/core';
import { BuildingService } from '@marketplace/api/building';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Action, State, StateContext } from '@ngxs/store';
import { format } from 'date-fns';
import { catchError, tap } from 'rxjs/operators';
import { VenueStateModel } from '../venue/venue.model';
import { VenuesState } from '../venues';
import { ReservationsStateAction } from './reservations.actions';
import { mapReservationsToStore } from './reservations.helper';
import { ReservationStateModel } from './reservations.model';

@State<ReservationStateModel[]>({
  name: 'reservations',
  defaults: [],
})
@Injectable()
export class ReservationsState {
  @SelectSnapshot(VenuesState) venues!: VenueStateModel[];

  constructor(private readonly buildingService: BuildingService) {}

  @Action(ReservationsStateAction.List.Read.Try)
  readList(
    { dispatch, getState, setState }: StateContext<ReservationStateModel[]>,
    { buildingId, fromDay, toDay }: ReservationsStateAction.List.Read.Try,
  ) {
    return this.buildingService
      .readReservations({
        building: buildingId,
        fromDay: format(fromDay, 'yyyyMMdd'),
        toDay: toDay && format(toDay, 'yyyyMMdd'),
      })
      .pipe(
        catchError(error => {
          dispatch(new ReservationsStateAction.List.Read.Failure(error));

          throw error;
        }),
        tap(reservations => {
          setState(mapReservationsToStore(reservations.reservations, this.venues));

          dispatch(new ReservationsStateAction.List.Read.Success(getState(), buildingId));
        }),
      );
  }
}
