import { init, routingInstrumentation } from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { InitializerConfig } from './core/initializer-config.type';

export function errorHandlerInitialize(config: InitializerConfig) {
  init({
    debug: !config.debug,
    dsn: config.sentryDsn,
    environment: config.env,
    integrations: [
      new Integrations.BrowserTracing({ tracingOrigins: [config.origin], routingInstrumentation }),
    ],
    release: `${config.name}@${config.version}`,
    tracesSampleRate: 1,
  });
}
