import { ConnectionPositionPair, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';

@UntilDestroy()
@Directive({
  selector: '[nibTooltip]',
})
export class TooltipDirective implements OnInit, OnDestroy, AfterViewInit {
  /** Tooltip content to show */
  @Input() nibTooltip?: TemplateRef<unknown>;

  private overlayRef!: OverlayRef;

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly overlay: Overlay,
    private readonly viewContainerRef: ViewContainerRef,
  ) {}

  ngAfterViewInit(): void {
    this.attachOverlay();
  }

  ngOnDestroy(): void {
    this.detachOverlay();
  }

  ngOnInit(): void {
    this.createOverlay();
  }

  // tslint:disable-next-line: no-host-decorator-in-concrete
  @HostListener('document:keydown', ['$event'])
  handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.close();
    }
  }

  close(): void {
    this.overlayRef.detach();
  }

  private attachOverlay(): void {
    fromEvent(this.elementRef.nativeElement, 'mouseenter')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (!this.overlayRef.hasAttached() && this.nibTooltip) {
          this.overlayRef.attach(new TemplatePortal(this.nibTooltip, this.viewContainerRef));
        }
      });

    fromEvent(this.overlayRef.overlayElement, 'mouseleave')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.close();
      });
  }

  private createOverlay(): void {
    this.overlayRef = this.overlay.create({
      backdropClass: 'nib-tooltip-backdrop',
      hasBackdrop: true,
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions([
          new ConnectionPositionPair(
            { originX: 'center', originY: 'top' },
            { overlayX: 'center', overlayY: 'bottom' },
            0,
            0,
            'nib-overlay-pane-top',
          ),
          new ConnectionPositionPair(
            { originX: 'center', originY: 'bottom' },
            { overlayX: 'center', overlayY: 'top' },
            0,
            0,
            'nib-overlay-pane-bottom',
          ),
          new ConnectionPositionPair(
            { originX: 'end', originY: 'center' },
            { overlayX: 'start', overlayY: 'center' },
            0,
            0,
            'nib-overlay-pane-right',
          ),
          new ConnectionPositionPair(
            { originX: 'start', originY: 'center' },
            { overlayX: 'end', overlayY: 'center' },
            0,
            0,
            'nib-overlay-pane-left',
          ),
        ])
        .withPush(false),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });

    this.overlayRef
      .backdropClick()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.detachOverlay();
      });
  }

  private detachOverlay(): void {
    if (this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }
}
