<label *ngIf="label" [for]="id" class="nib-body">{{ label }}</label>
<quill-editor
  [class.nib-rich-text-editor-ghost]="isGhostType"
  [disabled]="disabled"
  [formats]="formats"
  [id]="id"
  [(ngModel)]="value"
  (ngModelChange)="triggerChange($event)"
  (onEditorCreated)="initializeQuill($event)"
  (onSelectionChanged)="changeToolbarButtonStatus()"
  class="nib-rich-text-editor"
>
  <div quill-editor-toolbar>
    <div class="nib-rich-text-editor-toolbar" *ngIf="!disabled">
      <div *ngIf="formats?.includes('header')" class="nib-rich-text-editor-group">
        <nib-button
          [class.nib-rich-text-editor-button-active]="isHeader1$ | async"
          [color]="'tertiary'"
          [size]="'sm'"
          (onClick)="toggleH1()"
        >
          <nib-icon [name]="'format-header-1'"></nib-icon>
        </nib-button>
        <nib-button
          [class.nib-rich-text-editor-button-active]="isHeader2$ | async"
          [color]="'tertiary'"
          [size]="'sm'"
          (onClick)="toggleH2()"
        >
          <nib-icon [name]="'format-header-2'"></nib-icon>
        </nib-button>
      </div>
      <div class="nib-rich-text-editor-group">
        <nib-button
          *ngIf="formats?.includes('bold')"
          [class.nib-rich-text-editor-button-active]="hasBold$ | async"
          [color]="'tertiary'"
          [size]="'sm'"
          (onClick)="toggleBold()"
        >
          <nib-icon [name]="'format-bold'"></nib-icon>
        </nib-button>
        <nib-button
          *ngIf="formats?.includes('italic')"
          [class.nib-rich-text-editor-button-active]="hasItalic$ | async"
          [color]="'tertiary'"
          [size]="'sm'"
          (onClick)="toggleItalic()"
        >
          <nib-icon [name]="'format-italic'"></nib-icon>
        </nib-button>
      </div>
      <div class="nib-rich-text-editor-group">
        <nib-button
          *ngIf="formats?.includes('link')"
          [class.nib-rich-text-editor-button-active]="isLink$ | async"
          [color]="'tertiary'"
          [size]="'sm'"
          (onClick)="createOrUpdateLink()"
        >
          <nib-icon [name]="'format-link'"></nib-icon>
        </nib-button>
      </div>
      <div class="nib-rich-text-editor-group">
        <nib-button [color]="'tertiary'" [size]="'sm'" (onClick)="cleanStyle()">
          <nib-icon [name]="'format-clean'"></nib-icon>
        </nib-button>
      </div>
      <ng-content select=".nib-rich-text-editor-group"></ng-content>
    </div>
  </div>
</quill-editor>
