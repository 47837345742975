import { UserNotifications } from '../../../store/user/user.model';
import { AccountNotificationsRouteFormValue } from './account-notifications-route-form-value.type';

export function mapAccountNotificationsToStore({
  account: { accountEmail, accountPushWeb, accountSms },
  announcements: { announcementsEmail, announcementsPushWeb, announcementsSms },
  newsletter: { newsletterEmail, newsletterPushWeb, newsletterSms },
  service: { serviceEmail, servicePushWeb, serviceSms },
}: AccountNotificationsRouteFormValue): UserNotifications {
  return {
    notifications: {
      account: { email: accountEmail, pushweb: accountPushWeb, sms: accountSms },
      announcements: {
        email: announcementsEmail,
        pushweb: announcementsPushWeb,
        sms: announcementsSms,
      },
      newsletter: { email: newsletterEmail, pushweb: newsletterPushWeb, sms: newsletterSms },
      service: { email: serviceEmail, pushweb: servicePushWeb, sms: serviceSms },
    },
  };
}

export function mapAccountNotificationsToForm({
  account,
  announcements,
  newsletter,
  service,
}: UserNotifications['notifications']): AccountNotificationsRouteFormValue {
  return {
    account: {
      accountEmail: account.email,
      accountPushWeb: account.pushweb,
      accountSms: account.sms,
    },
    announcements: {
      announcementsEmail: announcements.email,
      announcementsPushWeb: announcements.pushweb,
      announcementsSms: announcements.sms,
    },
    newsletter: {
      newsletterEmail: newsletter.email,
      newsletterPushWeb: newsletter.pushweb,
      newsletterSms: newsletter.sms,
    },
    service: {
      serviceEmail: service.email,
      servicePushWeb: service.pushweb,
      serviceSms: service.sms,
    },
  };
}
