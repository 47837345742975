import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nib-feature-toggles-list',
  styleUrls: ['feature-toggles-list.component.scss'],
  templateUrl: 'feature-toggles-list.component.html',
})
export class FeatureTogglesListComponent<FeatureToggles extends { [key: string]: boolean }> {
  @Input() featureToggles: FeatureToggles | undefined;

  @Output() onChangeFeatureToggleValue = new EventEmitter<{ [name: string]: boolean }>();

  changeFeatureToggleValue(featureToggle: string, value: boolean) {
    this.onChangeFeatureToggleValue.emit({ [featureToggle]: value });
  }

  featureToggleKey(_index: number, featureToggle: { key: string; value: boolean }): string {
    return featureToggle.key;
  }
}
