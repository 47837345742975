import { Component, Injectable } from '@angular/core';
import { CanActivate, CanDeactivate } from '@angular/router';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { AppStateAction } from '@nibol/store';

@Injectable({ providedIn: 'root' })
export class PermissionDeniedGuard implements CanActivate, CanDeactivate<Component> {
  @Dispatch() disableNotifications = () => new AppStateAction.Notifications.Change.Try(false);
  @Dispatch() enableNotifications = () => new AppStateAction.Notifications.Change.Try(true);

  canActivate(): boolean {
    this.disableNotifications();

    return true;
  }

  canDeactivate(): boolean {
    this.enableNotifications();

    return true;
  }
}
