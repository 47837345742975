export type Icon = keyof typeof Icons;

// tslint:disable-next-line: prefer-const-enum
export enum Icons {
  analytics = 'analytics.svg',
  business = 'business.svg',
  cafe = 'cafe.svg',
  'cafe-add' = 'cafe-add.svg',
  'caret-down' = 'caret-down.svg',
  'caret-down-small' = 'caret-down-small.svg',
  'caret-left' = 'caret-left.svg',
  'caret-right' = 'caret-right.svg',
  checkbox = 'checkbox.svg',
  'checkbox-active' = 'checkbox-active.svg',
  circle = 'circle.svg',
  close = 'close.svg',
  'close-small' = 'close-small.svg',
  'color-picker' = 'color-picker.svg',
  confirm = 'confirm.svg',
  connections = 'connections.svg',
  'connections-list' = 'connections-list.svg',
  coworking = 'coworking.svg',
  cursor = 'cursor.svg',
  'custom-entities' = 'custom-entities.svg',
  dashboard = 'dashboard.svg',
  delete = 'delete.svg',
  deliveries = 'deliveries.svg',
  desk = 'desk.svg',
  droplist = 'droplist.svg',
  edit = 'edit.svg',
  'employees-directory' = 'employees-directory.svg',
  'flag-en-gb' = 'en-gb.svg',
  'flag-fr-fr' = 'fr-fr.svg',
  'flag-it-it' = 'it-it.svg',
  deny = 'deny.svg',
  'export-csv' = 'export-csv.svg',
  'external-link' = 'external-link.svg',
  'feature-toggle' = 'other-filters.svg',
  fit = 'fit.svg',
  'format-bold' = 'format-bold.svg',
  'format-clean' = 'format-clean.svg',
  'format-header-1' = 'format-header-1.svg',
  'format-header-2' = 'format-header-2.svg',
  'format-italic' = 'format-italic.svg',
  'format-link' = 'format-link.svg',
  google = 'google.svg',
  hamburger = 'hamburger-menu.svg',
  help = 'help.svg',
  informative = 'informative.svg',
  'informative-small' = 'informative-small.svg',
  integrations = 'integrations.svg',
  'invite-mail' = 'invite-mail.svg',
  minus = 'minus.svg',
  microsoft = 'microsoft.svg',
  negative = 'negative.svg',
  nibol = 'nibol.svg',
  'nibol-cafe' = 'nibol-cafe.svg',
  notice = 'notice.svg',
  office = 'office.svg',
  'open-new-tab' = 'open-new-tab.svg',
  parkings = 'parkings.svg',
  'payments-in' = 'payment-in.svg',
  'payments-out' = 'payment-out.svg',
  'personal-profile' = 'personal-profile.svg',
  photo = 'photo.svg',
  planimetry = 'planimetry.svg',
  plus = 'plus.svg',
  'plus-small' = 'plus-small.svg',
  positive = 'positive.svg',
  'private-spaces' = 'meeting-room.svg',
  profile = 'profile.svg',
  progress = 'progress.svg',
  question = 'question.svg',
  'question-medium' = 'question-medium.svg',
  radio = 'radio.svg',
  'radio-active' = 'radio-active.svg',
  recharge = 'recharge.svg',
  'reservations-received' = 'res-received-active.svg',
  rooms = 'rooms.svg',
  safe = 'safe.svg',
  search = 'search.svg',
  seats = 'seats.svg',
  'send-mail' = 'send-mail.svg',
  settings = 'settings.svg',
  slack = 'slack.svg',
  'special-closing-days' = 'special-closing-days.svg',
  stripe = 'stripe.svg',
  survey = 'survey.svg',
  'thumb-up' = 'thumb-up.svg',
  toilette = 'toilette.svg',
  tracing = 'tracing.svg',
  undo = 'undo.svg',
  upload = 'upload.svg',
  'user-add' = 'user-add.svg',
  'user-assigned' = 'user-assigned.svg',
  'visibility-hide' = 'visibility-hide.svg',
  'visibility-show' = 'visibility-show.svg',
  visitors = 'visitors.svg',
}
