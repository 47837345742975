import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslationModule } from '@nibol/translation';
import { FormModule, InputModule, SelectModule } from '@nibol/ui';
import { NgxPermissionsModule } from 'ngx-permissions';
import { WifiInformationFormComponent } from './wifi-information-form.component';

@NgModule({
  declarations: [WifiInformationFormComponent],
  exports: [WifiInformationFormComponent],
  imports: [
    CommonModule,
    FormModule,
    InputModule,
    NgxPermissionsModule,
    ReactiveFormsModule,
    SelectModule,
    TranslationModule,
  ],
})
export class WifiInformationFormModule {}
