import { LanguageCode, LanguageCodes } from '../core/language-code.type';

export function defaultCurrentLanguage(): LanguageCode | null {
  const languageCodeFromLocalStorage = localStorage.getItem('translation.currentLanguage');

  return [null, 'null'].includes(languageCodeFromLocalStorage)
    ? parsedLanguageCode()
    : JSON.parse(languageCodeFromLocalStorage as string);
}

export function parsedLanguageCode(): LanguageCode {
  return {
    [1]: LanguageCodes['en-gb'],
    [navigator.language.indexOf('es') + 1]: LanguageCodes['es-es'],
    [navigator.language.indexOf('fr') + 1]: LanguageCodes['fr-fr'],
    [navigator.language.indexOf('it') + 1]: LanguageCodes['it-it'],
  }[1];
}
