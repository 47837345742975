<form [formGroup]="form">
  <nib-personal-info-form
    class="nib-section"
    [formControlName]="'personalInfo'"
  ></nib-personal-info-form>
  <nib-language-selection-form
    *featureToggle="'TOGGLE_ENABLE_LANGUAGE_SELECTION'"
    [formControlName]="'language'"
    class="nib-section"
  ></nib-language-selection-form>
  <nib-language-selection-form
    *featureToggle="'!TOGGLE_ENABLE_LANGUAGE_SELECTION'"
    class="nib-section"
  ></nib-language-selection-form>
  <nib-logout-form class="nib-section"></nib-logout-form>
</form>
