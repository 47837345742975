import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { AppShellModule, CardModule } from '@nibol/ui';
import { InlineSVGModule } from 'ng-inline-svg';
import { AppShellPublicComponent } from './app-shell-public.component';

@NgModule({
  imports: [AppShellModule, CardModule, CommonModule, FlexModule, InlineSVGModule, RouterModule],
  exports: [AppShellPublicComponent],
  declarations: [AppShellPublicComponent],
})
export class AppShellPublicModule {}
