<nib-button
  *nibLet="inProgress$ | async as inProgress"
  [disabled]="inProgress"
  [fullWidth]="true"
  (onClick)="loginWithGoogle()"
  class="nib-google-button"
>
  <nib-icon *ngIf="inProgress" [name]="'progress'"></nib-icon>
  {{
    'login_continuewith' | translate: { sso: 'integrations_googleworkspacedirectory' | translate }
  }}
</nib-button>
