import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'nib-tooltip-container' },
  selector: 'nib-tooltip',
  styleUrls: ['tooltip.component.scss'],
  templateUrl: 'tooltip.component.html',
})
export class TooltipComponent {}
