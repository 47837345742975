import { Inject, Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import {
  FeatureTogglesStateAction,
  VersionsStateModelType,
  VersionStateAction,
} from '@nibol/store';
import { VERSIONS } from '../core/tokens/versions.token';

@State({
  name: 'versionsaga',
})
@Injectable()
export class VersionSaga {
  constructor(@Inject(VERSIONS) private readonly versions: VersionsStateModelType) {}

  @Action(VersionStateAction.Change.Success)
  async changeCurrentVersionSuccess(
    { dispatch }: StateContext<unknown>,
    { version }: VersionStateAction.Change.Success,
  ) {
    dispatch(new FeatureTogglesStateAction.Update.Try(this.versions[version]));
  }
}
