import {
  Location,
  MatGoogleMapsAutocompleteDirective,
} from '@angular-material-extensions/google-maps-autocomplete';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Size, Sizes } from '../core/sizes';
import { ProxyValueAccessor } from '../form/proxy-value-accessor';
import { getDarkOrLightColor } from '../helpers/get-dark-or-light-color.helper';
import { Data } from './data.type';
import { GOOGLE_API_KEY } from './tokens/google-api-key.token';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'nib-input',
  styleUrls: ['input.component.scss'],
  templateUrl: 'input.component.html',
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    class: 'nib-input',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent extends ProxyValueAccessor {
  /** Enable specific autocomplete value. */
  @Input() autocomplete = '';

  /** Whether the input should be autofocused. */
  @Input() autofocus = false;

  /** Whether input should be disabled. */
  @Input()
  get disabled(): boolean {
    return this.proxiedControl.disabled;
  }
  set disabled(value: boolean) {
    if (coerceBooleanProperty(value)) {
      this.proxiedControl.disable();
    } else {
      this.proxiedControl.enable();
    }
  }

  /** Form control name. */
  @Input() formControlName = '';

  /** Whether the input should be in full-width mode. */
  @Input() fullWidth = false;

  /** Whether the input should show error. */
  @Input() hasError = false;

  /** Input hint. */
  @Input() hint = '';

  /** Input id and name. */
  @Input() id = '';

  /** Input label. */
  @Input() label = '';

  /** Input list. */
  @Input() list = '';

  /** Max value. */
  @Input() max: number | string = '';

  /** Min value. */
  @Input() min: number | string = '';

  /** Text to show in empty field. */
  @Input() placeholder = '';

  /** Whether the input should be readonly. */
  @Input() readonly = false;

  /** Whether the input should be required. */
  @Input() required = false;

  /** Define the size of the input. */
  @Input() size: Size = Sizes.md;

  /** Define the start date of the datepicker. */
  @Input()
  get startAt(): string | number | undefined {
    return this._startAt;
  }
  set startAt(value: string | number | undefined) {
    this._startAt = value;
  }
  private _startAt?: string | number | undefined;

  /** Step value. */
  @Input() step = NaN;

  /** Input type. */
  @Input() type = 'text';

  /** Input value. */
  @Input()
  get value(): string | number {
    return this.proxiedControl.value;
  }
  set value(value: string | number) {
    this.proxiedControl.setValue(value);
  }

  /** Whether the input should show or hide the password. */
  @Input() visibilityToggle = false;

  /** Emit everytime value change */
  @Output() onChange = new EventEmitter<{
    value: InputComponent['value'];
    data?: InputComponent['data'];
  }>();

  /** Emit everytime user release a key  */
  @Output() onKeyup = new EventEmitter<{
    value: InputComponent['value'];
    data?: InputComponent['data'];
  }>();

  /** Emit everytime user focus the input  */
  @Output() onFocus = new EventEmitter<{
    value: InputComponent['value'];
    data?: InputComponent['data'];
  }>();

  @ViewChild(MatGoogleMapsAutocompleteDirective) inputAddress?: MatGoogleMapsAutocompleteDirective;

  /** Whether the input should hide the password. */
  isHidden = true;

  /** The control for managing field */
  proxiedControl = new FormControl('');

  /** Whether the input should show the color picker. */
  showColorPicker = true;

  /** Class to apply to input. */
  get ngClass(): { [key: string]: boolean } {
    return {
      [`nib-input-${this.size}`]: true,
      'nib-input-color': this.type === 'color',
      'nib-input-disabled': this.disabled,
      'nib-input-full-width': this.fullWidth,
      'nib-input-readonly': this.readonly,
    };
  }

  /** The name of the icon to show to toggle password visibility. */
  get visibilityIconName(): 'visibility-show' | 'visibility-hide' {
    return this.isHidden ? 'visibility-show' : 'visibility-hide';
  }

  /** Store extra data information which integrate the input value */
  private data?: Data = {};

  constructor(
    @Inject(GOOGLE_API_KEY) private readonly googleApiKey: string,
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly httpClient: HttpClient,
  ) {
    super();
  }

  /** Change color of input background and text */
  changeColor(color: string): void {
    this.proxiedControl.setValue(color);
    this.changeInputColors(color);
    this.setColorValues(color);
  }

  /** Store address value and data */
  async setAddressValues(location: Location): Promise<void> {
    this.data = { location, timezone: await this.calculateTimezoneFromLocation(location) };
    this.proxiedControl.setValue(
      this.inputAddress && this.inputAddress.elemRef.nativeElement.value,
    );

    this.onChange.emit({ value: this.value, data: this.data });
  }

  setColorValues(color: string): void {
    this.onChange.emit({ value: color, data: { highContrast: getDarkOrLightColor(color) } });
  }

  writeValue(value: string | number) {
    super.writeValue(value);

    if (this.type === 'color' && typeof value === 'string') {
      this.changeInputColors(value);
    }
  }

  private changeInputColors(color: string): void {
    const highContrastColor = getDarkOrLightColor(color);
    const matFormFieldInfix = this.elementRef.nativeElement.querySelector<HTMLElement>(
      '.mat-form-field-infix',
    );

    if (matFormFieldInfix) {
      matFormFieldInfix.style.backgroundColor = color;
      matFormFieldInfix.style.color = highContrastColor;
    }
  }

  private async calculateTimezoneFromLocation(location: Location): Promise<string> {
    return this.httpClient
      .get<{ timeZoneId: string }>('https://maps.googleapis.com/maps/api/timezone/json', {
        params: {
          location: `${location.latitude},${location.longitude}`,
          timestamp: (Date.now() / 1000).toString(),
          key: this.googleApiKey,
        },
      })
      .pipe(map(response => response.timeZoneId))
      .toPromise<string>();
  }
}
