import {
  Activities,
  AllowedGuests,
  Amenities,
  Coordinates,
  CreatingStatus,
  ImportedCalendar,
  Info,
  MapFilter,
  Mode,
  Modes,
  SpecialClosingTimes,
  Status,
  Type,
  Wifi,
  WorkHours,
} from './venue.type';

export class VenueStateModel {
  id!: string;
  mode!: Mode;
  name!: string;
  activities?: Activities;
  additionalInfo?: string;
  address?: string;
  allowedGuests?: AllowedGuests;
  amenities?: Amenities;
  area?: string;
  coordinates?: Coordinates;
  creatingStatus?: CreatingStatus;
  description?: string;
  discount?: number;
  exportCalendarUrl?: string;
  importedCalendars?: ImportedCalendar[];
  info?: Info;
  instantBooking?: boolean;
  mapFilter?: MapFilter;
  photos?: string[];
  photosLink?: string;
  privacyLevel?: number;
  promoted?: boolean;
  sanitized?: boolean;
  seats?: number;
  specialClosingTimes?: SpecialClosingTimes;
  status?: Status;
  toilette?: boolean;
  type?: Type | null;
  wifi?: Wifi;
  workHoursFullDay?: WorkHours;
  workHoursHalfDay?: WorkHours;
  workHoursHourly?: WorkHours;
}

export type NameAndAddressStateModel = Pick<
  VenueStateModel,
  'additionalInfo' | 'address' | 'area' | 'coordinates' | 'description' | 'name'
>;

export type PrivateVenueStateModel = Omit<VenueStateModel, 'mode'> & {
  mode: Modes.private;
};

export type SeatsAndAmenitiesStateModel = Pick<
  VenueStateModel,
  'amenities' | 'info' | 'privacyLevel' | 'seats' | 'toilette' | 'wifi'
>;

export type SettingsStateModel = Pick<
  VenueStateModel,
  'activities' | 'allowedGuests' | 'discount' | 'instantBooking' | 'sanitized' | 'type' | 'status'
>;

export type SharedVenueStateModel = Omit<VenueStateModel, 'mode'> & {
  mode: Modes.shared;
};
