<label *ngIf="label" [for]="formControlName || id" class="nib-body">{{ label }}</label>
<mat-form-field [ngClass]="ngClass" class="nib-form-field-type-nib-native-select">
  <select
    [formControl]="proxiedControl"
    [id]="formControlName || id"
    [name]="formControlName || id"
    [tabIndex]="tabIndex"
    (change)="!disabled && onChange.emit({ value: $event.target.value })"
    class="nib-select"
    matNativeControl
  >
    <option
      *ngFor="let option of options; trackBy: trackByOptionValue"
      [attr.selected]="option.selected || null"
      [disabled]="option.disabled"
      [hidden]="option.hidden"
      [value]="option.value"
    >
      {{ option.label }}
    </option>
  </select>
</mat-form-field>
<span *ngIf="hint" [innerHTML]="hint" class="nib-select-hint"></span>
