import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { VersionStateModelType } from './version-state-model.type';
import { VersionStateAction } from './version.actions';

@State<VersionStateModelType | null>({
  name: 'version',
  defaults: null,
})
@Injectable()
export class VersionState {
  @Action(VersionStateAction.Change.Try)
  changeVersion(
    { dispatch, getState, setState }: StateContext<VersionStateModelType>,
    { version }: VersionStateAction.Change.Try,
  ) {
    try {
      setState(version);

      dispatch(new VersionStateAction.Change.Success(getState()));
    } catch (error) {
      dispatch(new VersionStateAction.Change.Failure(error));
    }
  }
}
