import { ErrorHandler, Injectable, isDevMode } from '@angular/core';
import { captureException, configureScope } from '@sentry/angular';

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService implements ErrorHandler {
  configureScopeUser(id: string, username: string) {
    // do nothing now, use this to set user data before error handling with sentry
    // configureScope(scope => {
    //   scope.setUser({ id, username });
    // });
  }

  // tslint:disable-next-line: no-any
  handleError(error: any) {
    captureException(error.originalError || error.error || error);

    if (isDevMode()) {
      throw error;
    }
  }
}
