import { Injectable } from '@angular/core';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Action, State, StateContext } from '@ngxs/store';
import { AuthStateAction } from '@nibol/auth';
import { UserStateAction } from '@nibol/shared';
import { AppSelectors } from '@nibol/store';
import { TranslationService } from '@nibol/translation';
import { ToastService } from '@nibol/ui';
import { BuildingStateAction } from './building';
import { CompanyStateAction } from './company';
import { VenueStateAction } from './venue';

@State({
  name: 'feedbacksaga',
})
@Injectable()
export class FeedbackSaga {
  @SelectSnapshot(AppSelectors.notifications) notifications!: boolean;

  constructor(
    private readonly toastService: ToastService,
    private readonly translationService: TranslationService,
  ) {}

  @Action(AuthStateAction.Login.Success)
  @Action(AuthStateAction.LoginTemporary.Success)
  authLoginSuccess() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('login_toast_success_title'),
        this.translationService.translate('login_toast_success_text'),
        'positive',
      );
    }
  }

  @Action(AuthStateAction.Registration.Failure)
  authRegistrationFailure(
    {}: StateContext<unknown>,
    { error }: AuthStateAction.Registration.Failure,
  ) {
    if (this.notifications) {
      switch (error.error) {
        case 'email-already-registered':
          this.toastService.open(
            this.translationService.translate('signup_accountalreadycreated_title'),
            this.translationService.translate('signup_accountalreadycreated_text'),
            'negative',
          );
          break;

        default:
          this.toastService.open(
            this.translationService.translate('signup_genericerror_title'),
            this.translationService.translate('signup_genericerror_text'),
            'negative',
          );
          break;
      }
    }
  }

  @Action(BuildingStateAction.CostsAndPricingModel.Update.Failure)
  buildingCostsAndPricingModelUpdateFailure() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('save_toast_error_title'),
        `"${this.translationService.translate(
          'building_fees_title',
        )}" ${this.translationService.translate('save_toast_error_text')}`,
        'negative',
      );
    }
  }

  @Action(BuildingStateAction.CostsAndPricingModel.Update.Success)
  buildingCostsAndPricingModelUpdateSuccess() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('save_toast_success_title'),
        `"${this.translationService.translate(
          'building_fees_title',
        )}" ${this.translationService.translate('save_toast_success_text')}`,
        'positive',
      );
    }
  }

  @Action(BuildingStateAction.Information.Update.Failure)
  buildingInformationUpdateFailure() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('save_toast_error_title'),
        `"${this.translationService.translate(
          'building_buildinginfo_title',
        )}" ${this.translationService.translate('save_toast_error_text')}`,
        'negative',
      );
    }
  }

  @Action(BuildingStateAction.Information.Update.Success)
  buildingInformationUpdateSuccess() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('save_toast_success_title'),
        `"${this.translationService.translate(
          'building_buildinginfo_title',
        )}" ${this.translationService.translate('save_toast_success_text')}`,
        'positive',
      );
    }
  }

  @Action(BuildingStateAction.Venue.Create.Failure)
  buildingVenuesCreateFailure(
    {}: StateContext<unknown>,
    { error }: BuildingStateAction.Venue.Create.Failure,
  ) {
    if (this.notifications) {
      switch (error.error) {
        case 'venue-name-already-used':
          this.toastService.open(
            this.translationService.translate('privatespaces_addition_namealreadyused_title'),
            this.translationService.translate('privatespaces_addition_namealreadyused_text'),
            'negative',
          );
          break;

        default:
          this.toastService.open(
            this.translationService.translate('privatespaces_addition_genericerror_title'),
            this.translationService.translate('privatespaces_addition_genericerror_text'),
            'negative',
          );
          break;
      }
    }
  }

  @Action(BuildingStateAction.Venue.Create.Success)
  buildingVenuesCreateSuccess() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('privatespaces_addition_success_title'),
        this.translationService.translate('privatespaces_addition_success_text'),
        'positive',
      );
    }
  }

  @Action(CompanyStateAction.Update.Failure)
  companyUpdateFailure() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('save_toast_error_title'),
        `"${this.translationService.translate(
          'company_billing_title',
        )}" ${this.translationService.translate('save_toast_error_text')}`,
        'negative',
      );
    }
  }

  @Action(CompanyStateAction.Update.Success)
  companyUpdateSuccess() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('save_toast_success_title'),
        `"${this.translationService.translate(
          'company_billing_title',
        )}" ${this.translationService.translate('save_toast_success_text')}`,
        'positive',
      );
    }
  }

  @Action(UserStateAction.Notifications.Update.Failure)
  userNotificationsUpdateFailure() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('save_toast_error_title'),
        `"${this.translationService.translate(
          'account_notifications_title',
        )}" ${this.translationService.translate('save_toast_error_text')}`,
        'negative',
      );
    }
  }

  @Action(UserStateAction.Notifications.Update.Success)
  userNotificationsUpdateSuccess() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('save_toast_success_title'),
        `"${this.translationService.translate(
          'account_notifications_title',
        )}" ${this.translationService.translate('save_toast_success_text')}`,
        'positive',
      );
    }
  }

  @Action(UserStateAction.Profile.Update.Failure)
  userProfileUpdateFailure() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('save_toast_error_title'),
        `"${this.translationService.translate(
          'account_profile_title',
        )}" ${this.translationService.translate('save_toast_error_text')}`,
        'negative',
      );
    }
  }

  @Action(UserStateAction.Profile.Update.Success)
  userProfileUpdateSuccess() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('save_toast_success_title'),
        `"${this.translationService.translate(
          'account_profile_title',
        )}" ${this.translationService.translate('save_toast_success_text')}`,
        'positive',
      );
    }
  }

  @Action(VenueStateAction.ImportedCalendars.Create.Failure)
  venueImportedCalendarsCreateFailure() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('privatespaces_importcalendar_error_title'),
        this.translationService.translate('privatespaces_importcalendar_error_body'),
        'negative',
      );
    }
  }

  @Action(VenueStateAction.ImportedCalendars.Create.Success)
  venueImportedCalendarsCreateSuccess() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('privatespaces_importcalendar_success_title'),
        this.translationService.translate('privatespaces_importcalendar_success_body'),
        'positive',
      );
    }
  }

  @Action(VenueStateAction.ImportedCalendars.Delete.Failure)
  venueImportedCalendarsDeleteFailure() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('privatespaces_removecalendar_error_title'),
        this.translationService.translate('privatespaces_removecalendar_error_body'),
        'negative',
      );
    }
  }

  @Action(VenueStateAction.ImportedCalendars.Delete.Success)
  venueImportedCalendarsDeleteSuccess() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('privatespaces_removecalendar_success_title'),
        this.translationService.translate('privatespaces_removecalendar_success_body'),
        'positive',
      );
    }
  }

  @Action(VenueStateAction.ImportedCalendars.Update.Failure)
  venueImportedCalendarsUpdateFailure() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('privatespaces_importcalendar_error_title'),
        this.translationService.translate('privatespaces_importcalendar_error_body'),
        'negative',
      );
    }
  }

  @Action(VenueStateAction.ImportedCalendars.Update.Success)
  venueImportedCalendarsUpdateSuccess() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('privatespaces_importcalendar_success_title'),
        this.translationService.translate('privatespaces_importcalendar_success_body'),
        'positive',
      );
    }
  }

  @Action(VenueStateAction.NameAndAddress.Update.Failure)
  venueNameAndAddressUpdateFailure() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('save_toast_error_title'),
        `"${this.translationService.translate(
          'sharedspaces_nameaddress_title',
        )}" ${this.translationService.translate('save_toast_error_text')}`,
        'negative',
      );
    }
  }

  @Action(VenueStateAction.NameAndAddress.Update.Success)
  venueNameAndAddressUpdateSuccess() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('save_toast_success_title'),
        `"${this.translationService.translate(
          'sharedspaces_nameaddress_title',
        )}" ${this.translationService.translate('save_toast_success_text')}`,
        'positive',
      );
    }
  }

  @Action(VenueStateAction.Photos.Update.Failure)
  venuePhotosUpdateFailure(
    {}: StateContext<FeedbackSaga>,
    { error }: { error: { files: File[]; reason: string } },
  ) {
    if (this.notifications) {
      if (error && error.files.length > 0 && error.reason === 'unsupported-format') {
        this.toastService.open(
          this.translationService.translate('save_toast_warning_title'),
          `${this.translationService.translate(
            'save_toast_warning_text_unsupportedformat',
          )} "${error.files.map(file => file.name).join('", "')}"`,
          'notice',
        );
      } else {
        this.toastService.open(
          this.translationService.translate('save_toast_error_title'),
          `"${this.translationService.translate(
            'sharedspaces_photos_title',
          )}" ${this.translationService.translate('save_toast_error_text')}`,
          'negative',
        );
      }
    }
  }

  @Action(VenueStateAction.Photos.Update.Success)
  venuePhotosUpdateSuccess() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('save_toast_success_title'),
        `"${this.translationService.translate(
          'sharedspaces_photos_title',
        )}" ${this.translationService.translate('save_toast_success_text')}`,
        'positive',
      );
    }
  }

  @Action(VenueStateAction.SeatsAndAmenities.Update.Failure)
  venueSeatsAndAmenitiesUpdateFailure() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('save_toast_error_title'),
        `"${this.translationService.translate(
          'sharedspaces_seatsamenities_title',
        )}" ${this.translationService.translate('save_toast_error_text')}`,
        'negative',
      );
    }
  }

  @Action(VenueStateAction.SeatsAndAmenities.Update.Success)
  venueSeatsAndAmenitiesUpdateSuccess() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('save_toast_success_title'),
        `"${this.translationService.translate(
          'sharedspaces_seatsamenities_title',
        )}" ${this.translationService.translate('save_toast_success_text')}`,
        'positive',
      );
    }
  }

  @Action(VenueStateAction.Settings.Update.Failure)
  venueSettingsUpdateFailure() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('save_toast_error_title'),
        `"${this.translationService.translate(
          'sharedspaces_settings_title',
        )}" ${this.translationService.translate('save_toast_error_text')}`,
        'negative',
      );
    }
  }

  @Action(VenueStateAction.Settings.Update.Success)
  venueSettingsUpdateSuccess() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('save_toast_success_title'),
        `"${this.translationService.translate(
          'sharedspaces_settings_title',
        )}" ${this.translationService.translate('save_toast_success_text')}`,
        'positive',
      );
    }
  }

  @Action(VenueStateAction.SpecialClosingTimes.Update.Failure)
  venueSpecialClosingTimesUpdateFailure() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('save_toast_error_title'),
        `"${this.translationService.translate(
          'sharedspaces_specialclose_title',
        )}" ${this.translationService.translate('save_toast_error_text')}`,
        'negative',
      );
    }
  }

  @Action(VenueStateAction.SpecialClosingTimes.Update.Success)
  venueSpecialClosingTimesUpdateSuccess() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('save_toast_success_title'),
        `"${this.translationService.translate(
          'sharedspaces_specialclose_title',
        )}" ${this.translationService.translate('save_toast_success_text')}`,
        'positive',
      );
    }
  }

  @Action(VenueStateAction.WorkHoursHourly.Update.Failure)
  venueWorkHoursUpdateFailure() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('save_toast_error_title'),
        `"${this.translationService.translate(
          'sharedspaces_workhours_title',
        )}" ${this.translationService.translate('save_toast_error_text')}`,
        'negative',
      );
    }
  }

  @Action(VenueStateAction.WorkHoursHourly.Update.Success)
  venueWorkHoursUpdateSuccess() {
    if (this.notifications) {
      this.toastService.open(
        this.translationService.translate('save_toast_success_title'),
        `"${this.translationService.translate(
          'sharedspaces_workhours_title',
        )}" ${this.translationService.translate('save_toast_success_text')}`,
        'positive',
      );
    }
  }
}
