import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { catchError, first, tap } from 'rxjs/operators';
import { UserService } from '../../api/user/user.service';
import { UserStateAction } from './user.actions';
import { mapUserInfoToApi, mapUserNotificationsToApi, mapUserToClient } from './user.helper';
import { UserStateModel } from './user.model';

@State<UserStateModel>({
  name: 'user',
})
@Injectable()
export class UserState {
  constructor(protected readonly userService: UserService) {}

  @Action(UserStateAction.Item.Read.Try)
  readUser({ dispatch, getState, setState }: StateContext<UserStateModel>) {
    return this.userService.read().pipe(
      first(),
      catchError(error => {
        dispatch(new UserStateAction.Item.Read.Failure(error));

        throw error;
      }),
      tap(({ user, notification }) => {
        setState(patch(mapUserToClient({ user, notification })));

        dispatch(new UserStateAction.Item.Read.Success(getState()));
      }),
    );
  }

  @Action(UserStateAction.Item.Store.Try)
  storeUser(
    { dispatch, getState, setState }: StateContext<UserStateModel>,
    { data }: UserStateAction.Item.Store.Try,
  ) {
    try {
      setState(patch(data));

      dispatch(new UserStateAction.Item.Store.Success(getState()));
    } catch (error) {
      dispatch(new UserStateAction.Item.Store.Failure(error));

      throw error;
    }
  }

  @Action(UserStateAction.Notifications.Update.Try)
  updateNotifications(
    { dispatch, getState, setState }: StateContext<UserStateModel>,
    { data }: UserStateAction.Notifications.Update.Try,
  ) {
    return this.userService.updateNotification(mapUserNotificationsToApi(data.notifications)).pipe(
      catchError(error => {
        dispatch(new UserStateAction.Profile.Update.Failure(error));

        throw error;
      }),
      tap(response => {
        if (response.status) {
          setState(patch(data));

          dispatch(new UserStateAction.Profile.Update.Success(getState()));
        } else {
          dispatch(new UserStateAction.Profile.Update.Failure(response.status));

          throw response.status;
        }
      }),
    );
  }

  @Action(UserStateAction.Profile.Update.Try)
  updateProfile(
    { dispatch, getState, setState }: StateContext<UserStateModel>,
    { data }: UserStateAction.Profile.Update.Try,
  ) {
    return this.userService.updateInfo(mapUserInfoToApi(data)).pipe(
      catchError(error => {
        dispatch(new UserStateAction.Profile.Update.Failure(error));

        throw error;
      }),
      tap(response => {
        if (response.status) {
          setState(patch(data));

          dispatch(new UserStateAction.Profile.Update.Success(getState()));
        } else {
          dispatch(new UserStateAction.Profile.Update.Failure(response.status));

          throw response.status;
        }
      }),
    );
  }
}
