import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '@marketplace/environment';
import { CurrentBuildingState, CurrentBuildingStateAction } from '@marketplace/store';
import { BuildingStateModel } from '@marketplace/store/building';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Navigate } from '@ngxs/router-plugin';
import { Select } from '@ngxs/store';
import { SelectOption } from '@nibol/ui';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'mkt-change-building',
  styleUrls: ['change-building.component.scss'],
  templateUrl: 'change-building.component.html',
})
export class ChangeBuildingComponent implements OnInit {
  @Select(({ buildings }: { buildings: BuildingStateModel[] }) =>
    buildings
      .map(building => ({
        label: `${building.status?.toLocaleUpperCase()} - ${building.name}` || '',
        value: building.id,
      }))
      .sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }

        if (a.label > b.label) {
          return 1;
        }

        return 0;
      }),
  )
  buildings$!: Observable<SelectOption[]>;

  @SelectSnapshot(CurrentBuildingState) currentBuildingId!: string;

  @Dispatch() changeCurrentBuildingId = (buildingId: string) =>
    new CurrentBuildingStateAction.Change.Try(buildingId);
  @Dispatch() navigateToCalendar = () => new Navigate([environment.homepage]);

  form = new FormGroup({
    currentBuilding: new FormControl(this.currentBuildingId, Validators.required),
  });

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
    this.fixChangeDetection();
  }

  private fixChangeDetection(): void {
    this.buildings$.pipe(first(), untilDestroyed(this)).subscribe(() => {
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(({ currentBuilding }: { currentBuilding: string }) => {
        this.changeCurrentBuildingId(currentBuilding);
        this.navigateToCalendar();
      });
  }
}
