import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { TranslationService } from '@nibol/translation';
import { FormValueAccessor, SelectOption } from '@nibol/ui';
import { PriceFormFieldValue } from './price-form-field-value.type';
import { floatValueToTwoDecimal } from './price-form-field.helper';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'nib-form-field',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PriceFormFieldComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PriceFormFieldComponent),
      multi: true,
    },
  ],
  selector: 'nib-price-form-field',
  styleUrls: ['price-form-field.component.scss'],
  templateUrl: 'price-form-field.component.html',
})
export class PriceFormFieldComponent
  extends FormValueAccessor<PriceFormFieldValue>
  implements OnInit {
  @Input() hint?: string;
  @Input() label?: string;
  @Input() min = 0;
  @Input() step = 0.01;

  // @todo(heavybeard): move in init configuration
  readonly currencies: SelectOption[] = [
    { value: '€', label: 'list_currency_€', selected: true },
    { value: '$', label: 'list_currency_$' },
  ];

  // @todo(heavybeard): move in init configuration
  get defaultCurrencyValue(): string {
    const defaultValue = this.currencies.find(currency => !!currency.selected);

    return (defaultValue && this.translationService.translate(defaultValue.value)) || '';
  }

  form = new FormGroup({
    currency: new FormControl(this.defaultCurrencyValue, Validators.required),
    value: new FormControl(`${this.min}`, [Validators.required, Validators.min(this.min)]),
  });

  constructor(readonly translationService: TranslationService) {
    super();
  }

  ngOnInit(): void {
    this.updateFormValidator();
  }

  applyFormat({ value }: { value: string }) {
    if (Number.isInteger(this.step)) {
      this.form.get('value')?.patchValue(Math.round(parseFloat(value)).toFixed(2));
    }
  }

  writeValue({ currency, value }: PriceFormFieldValue): void {
    super.writeValue({
      currency,
      value: floatValueToTwoDecimal(value),
    });
  }

  private updateFormValidator() {
    this.form.setControl(
      'value',
      new FormControl(`${this.min}`, [Validators.required, Validators.min(this.min)]),
    );
  }
}
