import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeepPartial } from '../../../../helpers/deep-partial.type';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class FormSaverSnackbarService<FormValue = { [formControlName: string]: unknown }> {
  form: FormGroup = new FormGroup({});
  initialValue: FormValue = this.form.value;
  onCancel$ = new Subject<{ should: FormValue; next: FormValue }>();
  onSave$ = new Subject<{ prev: FormValue; next: FormValue }>();
  statusChanges$ = new Subject<boolean>();
  valueChanges$ = new Subject<FormValue>();
  visibleChanges$ = new Subject<boolean>();

  addControl(name: keyof FormValue & string, control: AbstractControl): void {
    this.form.addControl(name, control);
    this.update(this.form.value);
  }

  cancel(): void {
    this.onCancel$.next({ should: this.form.value, next: this.initialValue });
    this.form.reset(this.initialValue);
    this.valueChanges$.next(this.form.value);
  }

  disable(): void {
    this.form.disable();
    this.cancel();
  }

  initialize(controls: { [key in keyof FormValue]: AbstractControl }): void {
    const formGroup = new FormGroup(controls);

    this.form = formGroup;
    this.initialValue = this.form.value;

    this.valueChanges$.next(this.initialValue);

    this.form.statusChanges
      .pipe(map(() => formGroup.valid))
      .pipe(untilDestroyed(this))
      .subscribe(validity => {
        this.statusChanges$.next(validity);
      });

    formGroup.valueChanges
      .pipe(map(() => formGroup.dirty))
      .pipe(untilDestroyed(this))
      .subscribe(dirty => {
        this.visibleChanges$.next(dirty);
      });
  }

  removeControl(name: keyof FormValue & string): void {
    this.form.removeControl(name);
    this.update(this.form.value);
  }

  replaceControl(name: keyof FormValue & string, control: AbstractControl): void {
    this.form.setControl(name, control);
    this.update(this.form.value);
  }

  save(): void {
    if (this.form.valid) {
      this.form.reset(this.form.value);
      this.valueChanges$.next(this.form.value);
      this.onSave$.next({ prev: this.initialValue, next: this.form.value });
      this.initialValue = this.form.value;
    }
  }

  update(values: DeepPartial<FormValue>): void {
    this.form.patchValue(values);
    this.initialValue = this.form.value;
    this.valueChanges$.next(this.form.value);
    this.form.updateValueAndValidity({ emitEvent: false });
  }
}
