import { Building } from '@marketplace/api/building';
import { BuildingStateModel } from './building.model';

export function mapBuildingToClient(
  building: Building.Item.Read.Response,
): Partial<BuildingStateModel> {
  return {
    ...(building.id ? { id: building.id } : {}),
    ...(building.name ? { name: building.name } : {}),
    ...mapFeesToClient(building.fees),
  };
}

export function mapBuildingToApi(
  building: Partial<BuildingStateModel>,
): Building.Item.Update.Params['data'] {
  return {
    ...(building.id ? { id: building.id } : {}),
    ...(building.name ? { name: building.name } : {}),
    ...(building.commission && building.leadFee
      ? { fees: mapFeesToApi(building.commission, building.leadFee) }
      : {}),
  };
}

function mapFeesToClient(
  fees: Building.Item.Read.Response['fees'],
): Pick<BuildingStateModel, 'commission' | 'leadFee'> {
  return { commission: fees?.hp, leadFee: fees?.ppp };
}

function mapFeesToApi(
  commission: NonNullable<BuildingStateModel['commission']>,
  leadFee: NonNullable<BuildingStateModel['leadFee']>,
): Building.Item.Update.Params['data']['fees'] {
  return { fc: commission, hp: commission, ppp: leadFee };
}
