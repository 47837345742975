import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogDataModel } from './dialog-data.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'nib-dialog',
  styleUrls: ['dialog.component.scss'],
  templateUrl: 'dialog.component.html',
})
export class DialogComponent {
  /** Message to show in dialog body. */
  @Input()
  get content(): DialogDataModel['content'] {
    return this.data.content ?? this._content;
  }
  set content(value: DialogDataModel['content']) {
    this._content = value;
  }
  private _content: DialogDataModel['content'];

  /** Custom action to use. */
  @Input()
  get customAction(): DialogDataModel['customAction'] {
    return this.data.customAction ?? this._customAction;
  }
  set customAction(value: DialogDataModel['customAction']) {
    this._customAction = value;
  }
  private _customAction: DialogDataModel['customAction'];

  /** Whether the close button should be shown. */
  @Input()
  get showCloseButton(): DialogDataModel['showCloseButton'] {
    return this.data.showCloseButton ?? this._showCloseButton;
  }
  set showCloseButton(value: DialogDataModel['showCloseButton']) {
    this._showCloseButton = value;
  }
  private _showCloseButton: DialogDataModel['showCloseButton'] = true;

  /** A short text which describe the content. */
  @Input()
  get title(): DialogDataModel['title'] {
    return this.data.title ?? this._title;
  }
  set title(value: DialogDataModel['title']) {
    this._title = value;
  }
  private _title: DialogDataModel['title'];

  /** Whether the content is a string */
  get isContentString(): boolean {
    return typeof this.content === 'string';
  }

  /** Whether the title is a string */
  get isTitleString(): boolean {
    return typeof this.title === 'string';
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: DialogDataModel,
    private readonly matDialog: MatDialog,
  ) {}

  /** Close the dialog itself. */
  dismiss(): void {
    this.matDialog.closeAll();
  }
}
