<nib-form [formGroup]="form">
  <nib-form-title>{{ 'account_notifaccount_subtitle' | translate }}</nib-form-title>
  <nib-form-description>{{ 'account_notifaccount_body' | translate }}</nib-form-description>
  <nib-form-field>
    <nib-switch
      [disabled]="true"
      [checked]="((form.valueChanges | async) || form.value).accountEmail"
      [label]="'inputs_text_email_label' | translate"
    ></nib-switch>
  </nib-form-field>
  <nib-form-field *featureToggle="'TOGGLE_SHOW_PUSH_MOBILE'">
    <nib-switch
      [formControlName]="'accountPush'"
      [label]="'inputs_text_push_label' | translate"
    ></nib-switch>
  </nib-form-field>
  <nib-form-field>
    <nib-switch
      [formControlName]="'accountSms'"
      [label]="'inputs_text_sms_label' | translate"
    ></nib-switch>
  </nib-form-field>
  <nib-form-field>
    <nib-switch
      [formControlName]="'accountPushWeb'"
      [label]="'inputs_text_push_label' | translate"
    ></nib-switch>
  </nib-form-field>
</nib-form>
