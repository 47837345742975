import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { AppShellModule } from '@nibol/ui';
import { InlineSVGModule } from 'ng-inline-svg';
import { AppShellGhostComponent } from './app-shell-ghost.component';
import { AppStatuResumeGhostComponent } from './app-status-resume-ghost/app-status-resume-ghost.component';

@NgModule({
  imports: [AppShellModule, CommonModule, ExtendedModule, FlexModule, InlineSVGModule],
  exports: [AppShellGhostComponent],
  declarations: [AppShellGhostComponent, AppStatuResumeGhostComponent],
})
export class AppShellGhostModule {}
