import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormValueAccessor } from '@nibol/ui';
import { DateSlotFormValue } from './date-slot-form-value.type';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateSlotFormComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateSlotFormComponent),
      multi: true,
    },
  ],
  selector: 'nib-date-slot-form',
  styleUrls: ['date-slot-form.component.scss'],
  templateUrl: 'date-slot-form.component.html',
})
export class DateSlotFormComponent extends FormValueAccessor<DateSlotFormValue> implements OnInit {
  @Output() onCloseClick = new EventEmitter<void>();

  fromControlError = false;
  toControlError = false;
  onControlError = false;
  rangeError = false;

  readonly isInterval = new FormControl(true);

  private readonly fromControl = new FormControl('');
  private readonly onControl = new FormControl('');
  private readonly toControl = new FormControl('');

  form = new FormGroup({
    from: this.fromControl,
    to: this.toControl,
    on: this.onControl,
  });

  ngOnInit(): void {
    this.subscribeToIntervalCheckbox();
  }

  registerOnChange(callback: (value: DateSlotFormValue) => void): void {
    // It's better to notify the change after the status has been updated too
    this.form.statusChanges.pipe(untilDestroyed(this)).subscribe(_value => {
      if (this.isInterval.value) {
        callback({ from: this.fromControl.value, to: this.toControl.value });
      } else {
        callback({ on: this.onControl.value });
      }
    });
  }

  writeValue(value: DateSlotFormValue): void {
    if (Object.values(value).every(formValue => formValue === null) || value.on instanceof Date) {
      this.isInterval.reset(false, { emitEvent: false });
    } else {
      this.isInterval.reset(true, { emitEvent: false });
    }

    super.writeValue(value);
  }

  // tslint:disable-next-line: cyclomatic-complexity
  validate(_control: AbstractControl): ValidationErrors | null {
    this.fromControlError = false;
    this.toControlError = false;
    this.onControlError = false;
    this.rangeError = false;

    if (this.isInterval.value) {
      this.fromControlError = !!Validators.required(this.fromControl);
      this.toControlError = !!Validators.required(this.toControl);
      this.rangeError =
        !this.fromControlError &&
        !this.toControlError &&
        this.fromControl.value >= this.toControl.value;

      return this.fromControlError || this.toControlError || this.rangeError
        ? { error: 'invalid range' }
        : null;
    }

    this.onControlError = !!Validators.required(this.onControl);

    return Validators.required(this.onControl);
  }

  private subscribeToIntervalCheckbox(): void {
    this.isInterval.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.fromControl.setValue(this.onControl.value || this.fromControl.value);
      this.onControl.setValue(this.fromControl.value || this.onControl.value);
      this.form.updateValueAndValidity();
    });
  }
}
